// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHChecklistDefinitionCrudStore from './w-h-checklist-definition-crud-store.reducer';
import { WHChecklistDefinitionCrudStoreEffects } from './w-h-checklist-definition-crud-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHChecklistDefinitionCrudStore.workheldChecklistDefinitionCrudStoreFeatureKey, fromWHChecklistDefinitionCrudStore.workheldChecklistDefinitionCrudReducer),
    EffectsModule.forFeature([WHChecklistDefinitionCrudStoreEffects])
  ]
})
export class WHChecklistDefinitionCrudStoreModule { }
