// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { WorkStepStaticDataRoutingModule } from './work-step-static-data-routing.module';
import { WorkStepStaticDataComponent } from './work-step-static-data.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';

@NgModule({
  declarations: [
    WorkStepStaticDataComponent
  ],
  exports: [
    WorkStepStaticDataComponent
  ],
  imports: [
    AppSharedModule,
    WorkStepStaticDataRoutingModule
  ]
})
export class WorkStepStaticDataModule { }
