// ENVIRONMENT
import { environment } from '../../environments/environment';

// ANGULAR
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

// RxJS 6
import { Observable } from 'rxjs';

// MODELS
import { IWHToolDTO, IWHToolsPreviewDTO } from '@workheld/workheld-shared-lib';
import { FLOW_GATEWAY } from '@workheld/workheld-shared-lib';

@Injectable()
export class ToolAsyncService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public initToolFormGroup(): FormGroup {
    return new FormBuilder().group({
      id: [null],
      extId: [null, Validators.maxLength(100)],
      toolStatus: [null],
      name: [null, [Validators.required]],
      description: [null],
      serialNumber: [null, Validators.maxLength(100)],
    });
  }

  public removeImageFile(toolId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.apiUrl + FLOW_GATEWAY}/tools/${toolId}/toolPicture`);
  }

  public getToolDtoList(): Observable<IWHToolDTO[]> {
    return this.httpClient.get<IWHToolDTO[]>(`${environment.apiUrl + FLOW_GATEWAY}/tools`);
  }

  public getToolDtoByToolId(toolID: string): Observable<IWHToolDTO> {
    console.log("CALLED !!!!!");

    return this.httpClient.get<IWHToolDTO>(`${environment.apiUrl + FLOW_GATEWAY}/tools/${toolID}`);
  }

  public createToolByToolDTO(toolDTO: IWHToolDTO): Observable<IWHToolDTO> {
    return this.httpClient.post<IWHToolDTO>(`${environment.apiUrl + FLOW_GATEWAY}/tools`, toolDTO);
  }

  public updateToolByToolDTO(toolID: string, toolDTO: IWHToolDTO): Observable<IWHToolDTO> {
    return this.httpClient.post<IWHToolDTO>(`${environment.apiUrl + FLOW_GATEWAY}/tools/${toolID}`, toolDTO);
  }

  public postToolImageFile(equipmentID: string, fileBlob: Blob): Observable<IWHToolDTO> {

    const formData: FormData = new FormData();
    let mimeType = 'undefined';
    let extension = '.png';
    if (fileBlob.type) {
      mimeType = fileBlob.type;
      extension = '.' + mimeType.split('/')[1];
    }
    formData.append('file', fileBlob, 'avatar' + extension);

    const headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', mimeType);

    return this.httpClient.post<IWHToolDTO>(`${environment.apiUrl + FLOW_GATEWAY}/tools/${equipmentID}/files`, formData, { headers });
  }

  public getToolPreview(httpParams: HttpParams): Observable<IWHToolsPreviewDTO> {
    return this.httpClient.get<IWHToolsPreviewDTO>(`${environment.apiUrl + FLOW_GATEWAY}/tools/preview`, { params: httpParams });
  }

}
