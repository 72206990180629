// ENVIRONMENT
import { environment } from '../../environments/environment';

// ANGULAR
import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpParams, HttpClient } from '@angular/common/http';

// RxJS 6
import { Observable } from 'rxjs';

// MODELS
import { IWHLocationDTO } from '@workheld/workheld-shared-lib';
import { IWHWorkObjectTemplateDTO, IWHWorkObjectTemplatePreviewDTO } from '@workheld/workheld-shared-lib';
import { FLOW_GATEWAY } from '@workheld/workheld-shared-lib';

export class WHUseWorkObjectTemplatePayloadDTO {
  workObjectTemplateId: string;
  workObjectId: string;
  selectedWorkOrderTemplates: any[];
  address: IWHLocationDTO;
}

@Injectable()
export class WorkObjectTemplateAsyncService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public initCreateWorkObjectTemplateFromGroup(): FormGroup {
    return new FormBuilder().group({
      templateName: [null, [Validators.required]],
      extId: [null],
      templateDescription: [null],
      workObjectId: [null]
    });
  }

  public initApplyWorkObjectTemplateFromGroup(): FormGroup {
    return new FormBuilder().group({
      workObjectTemplateId: [null, [Validators.required]],
      workObjectId: [null],
      selectedWorkOrderTemplates: [null],
      address: [null],
    });
  }

  public getWorkObjectTemplateDtoByWorkObjectTemplateId(workObjectTemplateID: string): Observable<IWHWorkObjectTemplateDTO> {
    return this.httpClient.get<IWHWorkObjectTemplateDTO>(`${environment.apiUrl + FLOW_GATEWAY}/workObjectTemplates/${workObjectTemplateID}`);
  }

  // TODO - models
  public createWorkObjectTemplate(templatePayload: { templateName: string, templateDescription: string, workObjectId: string }) {
    return this.httpClient.post(`${environment.apiUrl + FLOW_GATEWAY}/workObjectTemplates`, templatePayload);
  }

  public getWorkObjectTemplateDtoList() {
    return this.httpClient.get<IWHWorkObjectTemplateDTO[]>(`${environment.apiUrl + FLOW_GATEWAY}/workObjectTemplates`);
  }

  public useWorkObjectTemplate(templateID: string, useWorkObjectTemplatePayload: WHUseWorkObjectTemplatePayloadDTO) {
    return this.httpClient.post(`${environment.apiUrl + FLOW_GATEWAY}/workObjectTemplates/${templateID}/use`, useWorkObjectTemplatePayload);
  }

  public getWorkObjectTemplatePreview(httpParams?: HttpParams): Observable<IWHWorkObjectTemplatePreviewDTO> {
    return this.httpClient.get<IWHWorkObjectTemplatePreviewDTO>(`${environment.apiUrl + FLOW_GATEWAY}/workObjectTemplates/preview`, { params: httpParams });
  }

  public deleteWorkObjectTemplate(workObjectTemplateId: string): Observable<null> {
    return this.httpClient.delete<null>(`${environment.apiUrl + FLOW_GATEWAY}/workObjectTemplates/${workObjectTemplateId}`);
  }

}
