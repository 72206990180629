<section class="contact-principal-mat-dialog">
    <div class="detail-card-header">
        <span whFlex class="w-h-header-icon-span">
            <i mat-card-avatar class="workheld-icons header-icon" [innerHtml]="userIcon"></i>
        </span>
        <span whFlex class="w-h-header-span">
            <div class="single-header">
                {{ 'workobject.ui.contactprincipal.header' | translate }}
            </div>
        </span>
    </div>
    <div class="contact-principal-mat-dialog-body">
        <form [formGroup]="contactPrincipalFormGroup">
            <div>
                <mat-form-field whFlex>
                    <mat-label>{{ 'workobject.model.contactprincipal.message' | translate }}</mat-label>
                    <textarea matInput placeholder="{{ 'workobject.model.contactprincipal.message' | translate }}"
                              formControlName="message"
                              [rows]="6">
                    </textarea>
                </mat-form-field>
            </div>
        </form>    
    </div>
    <div class="dialog-action-wrapper" mat-dialog-actions>
        
        <button
            class="w-h-default-button dialog-button mat-elevation-z2"
            mat-flat-button
            type="button"
            tabindex="-1"
            (click)="cancel()"
            [disabled]="saving">
            <i class="workheld-icons" [innerHTML]="cancelIcon"></i> 
            {{ ('app.cancel.label') | translate | uppercase }}
        </button>
        <button
            color="accent"
            class="dialog-button mat-elevation-z2"
            mat-flat-button type="button"
            tabindex="-1"
            [disabled]="isFormGroupInvalid || saving"
            (click)="createContactLog()">
            <mat-spinner
                style="display: inline-block;"
                *ngIf="saving"
                [diameter]="18">
            </mat-spinner>
            <i class="workheld-icons" [innerHTML]="saveIcon" *ngIf="!saving"></i> 
            {{ ('app.save.label') | translate | uppercase }}
        </button>
    </div>

</section>