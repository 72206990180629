// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { ChecklistDefinitionAsyncService } from 'src/app/app-services-async/checklist-definition-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHChecklistDefinitionMasterDataStoreActionTypes,
  WHChecklistDefinitionMasterDataStoreActions,
  SetWHChecklistDefinitionDomList,
  GetWHChecklistDefinitionDtoList,
} from './w-h-checklist-definition-master-data-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';
import { WHChecklistDefinitionDOM } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHChecklistDefinitionMasterDataStoreEffects {
  getWHChecklistDefinitionDtoList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHChecklistDefinitionMasterDataStoreActionTypes.GetWHChecklistDefinitionDtoList
      ),
      switchMap((action: GetWHChecklistDefinitionDtoList) => {
        return this.checklistDefinitionAsyncService.getChecklistDefinitionDtoList(
          action.payload
        );
      }),
      mergeMap((checklistDefinitionDtoList: WHChecklistDefinitionDOM[]) => {
        const checklistDefinitionDomList: WHChecklistDefinitionDOM[] = [];
        checklistDefinitionDtoList.forEach(
          (checklistDefinitionDOM: WHChecklistDefinitionDOM) => {
            checklistDefinitionDomList.push(
              this.dataModelHelperService.initChecklistDefinitionDOM(
                checklistDefinitionDOM
              )
            );
          }
        );
        return [
          new SetWHChecklistDefinitionDomList({
            checklistDefinitionDomList,
            loading: false,
            loaded: true,
          }),
        ];
      })
    );
  });

  constructor(
    private actions$: Actions<WHChecklistDefinitionMasterDataStoreActions>,
    private checklistDefinitionAsyncService: ChecklistDefinitionAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
