// ANGULAR
import { Injectable } from '@angular/core';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHAllAssignmentStoreActionTypes,
  WHAllAssignmentStoreActions,
  GetWHAllAssignmentDTO,
  SetWHAllAssignmentDOM,
} from './w-h-all-assignment-store.actions';

// SERVICES
import { WorkOrderAsyncService } from 'src/app/app-services-async/work-order-async.service';
import {
  FLOW_GATEWAY,
  IWHBomNodeDTO,
  IWHWorkObjectEndpointConfigModel,
  WHNestedNodeDOM,
  WHWorkObjectDataService,
} from '@workheld/workheld-shared-lib';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// RxJS 6
import { of } from 'rxjs';
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import { IWHAllAssginmentsDTO, WHAllAssginmentsDOM } from '@workheld/workheld-shared-lib';
import { WHEquipmentDOM, IWHEquipmentDTO } from '@workheld/workheld-shared-lib';
import { IWHWorkerDTO, WHWorkerDOM } from '@workheld/workheld-shared-lib';
import { IWHToolDTO, WHToolDOM } from '@workheld/workheld-shared-lib';
import { environment } from 'src/environments/environment';

@Injectable()
export class WHAllAssignmentStoreEffects {
  GetWHAllAssignmentDTO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHAllAssignmentStoreActionTypes.GetWHAllAssignmentDTO),
      switchMap((action: GetWHAllAssignmentDTO) => {
        const endpointConfig: IWHWorkObjectEndpointConfigModel = {
          apiUrl: environment.apiUrl + FLOW_GATEWAY,
          workObjectId: action.payload.targetId,
        };
        switch (action.payload.type) {
          case 'WORK_OBJECT':
            return this.workObjectDataService.getAllAssignments(endpointConfig);
          case 'WORK_ORDER':
            return this.workOrderAsyncService.getAllWorkOrderAssignments(
              action.payload.targetId
            );
          default:
            return of(null);
        }
      }),
      mergeMap((allAssginmentsDTO: IWHAllAssginmentsDTO) => {
        const allAssginmentsDOM = new WHAllAssginmentsDOM();
        if (
          allAssginmentsDTO.assignedEquipments &&
          allAssginmentsDTO.assignedEquipments.length > 0
        ) {
          allAssginmentsDTO.assignedEquipments.forEach(
            (equipmentDTO: IWHEquipmentDTO) => {
              const equipmentDOM: WHEquipmentDOM =
                this.dataModelHelperService.initEquipmentDOM(equipmentDTO);
              allAssginmentsDOM.assignedEquipments.push(equipmentDOM);
            }
          );
        }
        if (
          allAssginmentsDTO.assignedTools &&
          allAssginmentsDTO.assignedTools.length > 0
        ) {
          allAssginmentsDTO.assignedTools.forEach((toolDTO: IWHToolDTO) => {
            const toolDOM: WHToolDOM =
              this.dataModelHelperService.initToolDOM(toolDTO);
            allAssginmentsDOM.assignedTools.push(toolDOM);
          });
        }
        if (
          allAssginmentsDTO.assignedWorkers &&
          allAssginmentsDTO.assignedWorkers.length > 0
        ) {
          allAssginmentsDTO.assignedWorkers.forEach(
            (workerDTO: IWHWorkerDTO) => {
              const workerDOM: WHWorkerDOM =
                this.dataModelHelperService.initWorkerDOM(workerDTO);
              allAssginmentsDOM.assignedWorkers.push(workerDOM);
            }
          );
        }
        if (allAssginmentsDTO?.assignedBomNodes?.length > 0) {
          allAssginmentsDTO.assignedBomNodes.forEach((bomNode: IWHBomNodeDTO) => {
            allAssginmentsDOM.assignedBomNodes.push(new WHNestedNodeDOM(bomNode));
          });
        }
        return [
          new SetWHAllAssignmentDOM({
            allAssignmentsDOM: allAssginmentsDOM,
            loading: false,
            loaded: true,
          }),
        ];
      })
    );
  });

  constructor(
    private actions$: Actions<WHAllAssignmentStoreActions>,
    private workOrderAsyncService: WorkOrderAsyncService,
    private workObjectDataService: WHWorkObjectDataService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
