// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { WorkObjectTemplateAsyncService } from 'src/app/app-services-async/work-object-template-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHWorkObjectTemplateCrudStoreActionTypes,
  WHWorkObjectTemplateCrudStoreActions,
  GetWHWorkObjectTemplateDTO,
  SetWHWorkObjectTemplateDOM,
} from './w-h-work-object-template-crud-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import {
  IWHWorkObjectTemplateDTO,
  WHWorkObjectTemplateDOM,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WHWorkObjectTemplateCrudStoreEffects {
  getWHWorkObjectTemplateDTO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHWorkObjectTemplateCrudStoreActionTypes.GetWHWorkObjectTemplateDTO
      ),
      switchMap((action: GetWHWorkObjectTemplateDTO) => {
        return this.workObjectTemplateAsyncService.getWorkObjectTemplateDtoByWorkObjectTemplateId(
          action.payload.targetID
        );
      }),
      mergeMap((workObjectTemplateDTO: IWHWorkObjectTemplateDTO) => {
        const workObjectTemplateDOM: WHWorkObjectTemplateDOM =
          this.dataModelHelperService.initWorkObjectTemplateDOM(
            workObjectTemplateDTO
          );
        return [new SetWHWorkObjectTemplateDOM(workObjectTemplateDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHWorkObjectTemplateCrudStoreActions>,
    private workObjectTemplateAsyncService: WorkObjectTemplateAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
