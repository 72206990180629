<section
  class="check-instance-file-img-gallery"
  *ngIf="checkInstanceFileDomArray"
>
  <div class="w-h-label attachment-label m-b--8">
    {{ "checklistinstance.ui.checkinstancefile.label" | translate }}
  </div>

  <div>
    <div>
      <div
        class="thumbnail-list-wrapper"
        *ngIf="loaded && checkInstanceFileDomArray.length === 0"
      >
        <div class="check-instance-file-message">
          {{ "app.nodata.label" | translate }}
        </div>
      </div>

      <div class="thumbnail-list-wrapper" *ngIf="loading">
        <div class="check-instance-file-message">
          {{ "app.loading.label" | translate }}
        </div>
      </div>

      <div
        class="thumbnail-list-wrapper flex--row gap--16 flex--wrap"
        *ngIf="loaded && checkInstanceFileDomArray.length > 0"
      >
        <span
          class="thumbnail-list-item-wrapper"
          *ngFor="let checkInstanceFileDOM of checkInstanceFileDomDisplayPage"
        >
          <div
            matBadgePosition="after"
            [matBadge]="checkInstanceFileDOM?.checkSortPosition + 1"
            class="thumbnail-wrapper"
            (click)="
              filePreview({
                file: checkInstanceFileDOM?.checkInstanceFile,
                comment: checkInstanceFileDOM?.comment
              })
            "
          >
            <img
              alt="previewimage"
              class="thumbnail mat-elevation-z2"
              [src]="
                checkInstanceFileDOM?.fileTypeImage | WHImgAuthHeader | async
              "
            />
          </div>
          <div class="thumbnail-meta-wrapper flex--row gap--4">
            <span class="html-overflow">
              <div
                class="file-name"
                matTooltip="{{
                  checkInstanceFileDOM?.checkInstanceFile?.fileName
                }}{{ checkInstanceFileDOM?.checkInstanceFile?.extension }}"
              >
                {{ checkInstanceFileDOM?.checkInstanceFile?.fileName
                }}{{ checkInstanceFileDOM?.checkInstanceFile?.extension }}
              </div>
            </span>

            <span>
              <i
                class="workheld-icons download-img-icon"
                [innerHTML]="downloadIcon"
                (click)="downloadFile(checkInstanceFileDOM?.checkInstanceFile)"
                matTooltip="{{ 'app.download.label' | translate }}"
              >
              </i>
            </span>
          </div>
        </span>
      </div>

      <mat-paginator
        class="check-instance-file-paginator"
        [length]="checkInstanceFileDomArray.length"
        [pageSize]="10"
        [pageSizeOptions]="[10]"
        (page)="pageEvent($event)"
      >
      </mat-paginator>
    </div>
  </div>
</section>
