// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { ChecklistDefinitionDetailRoutingModule } from './checklist-definition-detail-routing.module';
import { ChecklistDefinitionDetailComponent } from './checklist-definition-detail.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';
import { CheckDefinitionCreateEditFormModule } from '../check-definition-create-edit-form/check-definition-create-edit-form.module';
import { WHPermissionCheckModule } from '@workheld/workheld-shared-lib';

@NgModule({
  declarations: [ChecklistDefinitionDetailComponent],
  exports: [ChecklistDefinitionDetailComponent],
  imports: [
    AppSharedModule,
    CheckDefinitionCreateEditFormModule,
    ChecklistDefinitionDetailRoutingModule,
    WHPermissionCheckModule,
  ],
})
export class ChecklistDefinitionDetailModule {}
