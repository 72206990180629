<div class="modal-wrapper">
  <div
    class="modal-header"
  >
    <mat-icon>draw</mat-icon>
    <div class="modal-body">
      <h2>{{ 'ui.dialog.discardAllChanges' | translate }}</h2>
      <p>
        {{ 'ui.dialog.cantBeUndone' | translate }}
      </p>
    </div>
  </div>
  <div
    class="modal-actions"
  >
    <button
      type="button"
      mat-flat-button
      class="w-h-default-button project-crud-button mat-elevation-z2"
      (click)="closeDialog(true)"
    >
      {{ 'ui.dialog.button.discardChanges' | translate }}
    </button>

    <button
      type="button"
      mat-flat-button
      color="accent"
      (click)="closeDialog(false)"
      class="project-crud-button mat-elevation-z2"
    >
      {{ 'ui.dialog.button.keepEditing' | translate }}
    </button>
  </div>
</div>
