<div class="create-report-mat-dialog">
    <div class="detail-card-header">
        <span whFlex class="w-h-header-icon-span">
            <i mat-card-avatar class="workheld-icons header-icon" [innerHtml]="reportIcon"></i>
        </span>
        <span whFlex class="w-h-header-span">
            <div class="single-header">
                {{ 'equipmentreport.model.root' | translate }}
            </div>
        </span>
    </div>
    <mat-horizontal-stepper (selectionChange)="onStepChange($event)" [linear]="true" #stepper>
        <mat-step [completed]="!!reportHTML">
          <form [formGroup]="payloadFormGroup">
            <ng-template matStepLabel>{{ 'equipmentreport.ui.stepper.1.label' | translate }}</ng-template>
            <div>
              <div style="width: 312px">
                <lib-w-h-dropdown
                  [valueLabel]="'equipmentreport.ui.reporttype.label'"
                  [itemKeys]="['name']"
                  [required]="true"
                  [valueFormControl]="reportTypeFormControl"
                  [dropdownItemDomList]="reportTypes"
                  [loading]="false"
                  (onDomEvent)="onDomChildEvent($event)">
                </lib-w-h-dropdown>
              </div>
              <div style="width: 312px">
                <mat-form-field whFlex>
                  <mat-label>{{ 'equipmentreport.ui.reporttitle.label' | translate }}</mat-label>
                  <input matInput formControlName="reportTitle" required>
                </mat-form-field>
              </div>
              <div class="default-max-width">
                <lib-w-h-datetime-picker
                  [parentFormGroup]="tempFormGroup"
                  startDateForm="startForm"
                  endDateForm="endForm"
                  [isDisabled]="false"
                  [hasEndDate]="true"
                  [isStartDateReqired]="true"
                  [isEndDateReqired]="true"
                  [startDateLabel]="'equipmentreport.ui.selectstartdate.label'"
                  [endDateLabel]="'equipmentreport.ui.selectenddate.label'">
                </lib-w-h-datetime-picker>
            </div>
            </div>
            <div class="dialog-action-wrapper flex--r-c-r">
                <button
                    type="button"
                    mat-flat-button
                    class="w-h-default-button w-h-dialog-button mat-elevation-z2"
                    (click)="cancel()"
                    [disabled]="saving">
                    <i class="workheld-icons" [innerHTML]="cancelIcon"></i> 
                    {{ ('app.cancel.label' | translate) | uppercase }}
                </button>
                <button
                    type="button"
                    mat-flat-button
                    color="accent"
                    class="mat-elevation-z2"
                    [disabled]="saving || createInvalid"
                    (click)="previewReport()">
                    <mat-spinner
                        style="display: inline-block;"
                        *ngIf="saving"
                        [diameter]="18">
                    </mat-spinner>
                    <i class="workheld-icons" [innerHTML]="saveIcon" *ngIf="!saving"></i> 
                    {{ ('equipmentreport.ui.createreport.label' | translate) | uppercase }}
                </button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <form>
            <ng-template matStepLabel>{{ 'equipmentreport.ui.stepper.2.label' | translate }}</ng-template>
            <div>
              <div class="report-body">
                <div id="reportContent" [innerHtml]="reportHTML" style="padding-right: 28px">

                </div>
              </div>
              <div class="dialog-action-wrapper">
                
                <button
                    type="button"
                    mat-flat-button
                    class="w-h-default-button w-h-dialog-button mat-elevation-z2"
                    (click)="cancel()"
                    [disabled]="saving">
                    <i class="workheld-icons" [innerHTML]="cancelIcon"></i> 
                    {{ ('app.cancel.label' | translate) | uppercase }}
                </button>
                <button
                    type="button"
                    mat-flat-button
                    matStepperPrevious
                    class="w-h-default-button w-h-dialog-button mat-elevation-z2"
                    [disabled]="saving">
                    {{ ('app.back.label' | translate) | uppercase }}
                </button>
                <button
                    type="button"
                    mat-flat-button
                    color="accent"
                    class="mat-elevation-z2"
                    [disabled]="saving"
                    (click)="handleSave()">
                    <mat-spinner
                        style="display: inline-block;"
                        *ngIf="saving"
                        [diameter]="18">
                    </mat-spinner>
                    <i class="workheld-icons" [innerHTML]="saveIcon" *ngIf="!saving"></i> 
                    {{ ('app.save.label' | translate) | uppercase }}
                </button>
              </div>
            </div>
          </form>
        </mat-step>
      </mat-horizontal-stepper>
</div>
