// HELPERS
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHAllAssignmentStoreActions, WHAllAssignmentStoreActionTypes } from './w-h-all-assignment-store.actions';

export const workheldAllAssignmentStoreFeatureKey = 'workheldAllAssignmentStore$';

// MODELS
import { WHAllAssginmentsDOM } from '@workheld/workheld-shared-lib';

export interface IWHAllAssignmentStore {
  allAssignmentsDOM: WHAllAssginmentsDOM;
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHAllAssignmentStore = {
  allAssignmentsDOM: null,
  loading: false,
  loaded: false
};

export function workheldAllAssignmentReducer(state = initialState, action: WHAllAssignmentStoreActions): IWHAllAssignmentStore {
  switch (action.type) {

    case WHAllAssignmentStoreActionTypes.GetWHAllAssignmentDTO:
      return updateObject(state, {
        loading: true,
        loaded: false
      } as any);

    case WHAllAssignmentStoreActionTypes.SetWHAllAssignmentDOM:
      return action.payload;

    default:
      return state;
  }
}
