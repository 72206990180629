// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHWorkerTimeReportStore from './w-h-worker-time-report-store.reducer';
import { WHWorkerTimeReportStoreEffects } from './w-h-worker-time-report-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHWorkerTimeReportStore.workheldWorkerTimeReportStoreFeatureKey, fromWHWorkerTimeReportStore.workheldWorkerTimeReportReducer),
    EffectsModule.forFeature([WHWorkerTimeReportStoreEffects])
  ]
})
export class WHWorkerTimeReportStoreModule { }
