// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHToolCrudStore from './w-h-tool-crud-store.reducer';
import { WHToolCrudStoreEffects } from './w-h-tool-crud-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHToolCrudStore.workheldToolCrudStoreFeatureKey, fromWHToolCrudStore.workheldToolCrudReducer),
    EffectsModule.forFeature([WHToolCrudStoreEffects])
  ]
})
export class WHToolCrudStoreModule { }
