// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { IWHDndAssignmentStore } from './w-h-dnd-assignment-store.reducer';

export enum WHDndAssignmentStoreActionTypes {
  GetWHDndAssignmentDtoListWithParams = '[WHDndAssignmentStore] GetWHDndAssignmentDtoListWithParams',
  SetWHDndAssignmentDtoList = '[WHDndAssignmentStore] SetWHDndAssignmentDtoList',
}

export class GetWHDndAssignmentDtoListWithParams implements Action {
  readonly type = WHDndAssignmentStoreActionTypes.GetWHDndAssignmentDtoListWithParams;

  constructor(public payload?: { targetParent: string, workOrderId: string, httpParams?: WHHttpParams }) {
    // console.log('GetWHWorkerDtoListForWorkOrderAssignment' + JSON.stringify(payload));
  }
}

export class SetWHDndAssignmentDtoList implements Action {
  readonly type = WHDndAssignmentStoreActionTypes.SetWHDndAssignmentDtoList;

  constructor(public payload: IWHDndAssignmentStore) {
    // console.log('SetWHDndAssignmentDtoList' + JSON.stringify(payload));
  }
}

export type WHDndAssignmentStoreActions = GetWHDndAssignmentDtoListWithParams | SetWHDndAssignmentDtoList;
