<!--
/**
 * Custom, Angular-based event editor template
 */
-->
<h2 mat-dialog-title>{{name}}&nbsp;</h2>

<mat-dialog-content>

    <mat-form-field class = "field-name">
        <input
            matInput
            [(ngModel)] = "name"
            placeholder = "Event name"
        >
    </mat-form-field>

    <mat-form-field class = "field-desc">
        <input
            matInput
            [(ngModel)] = "desc"
            placeholder = "Description"
        >
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>

    <button
        class = "mat-raised-button"
        (click) = "close()"
    >{{ 'app.cancel.label' | translate }}</button>

    <button
        class = "mat-raised-button mat-primary"
        (click) = "save()"
    >{{ 'app.save.label' | translate }}</button>

</mat-dialog-actions>
