import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription, map, startWith } from 'rxjs';
import {
  CertificateDTO,
  IWHQualificationsDTO,
} from '@workheld/workheld-shared-lib';

@Component({
  selector: 'app-mat-dialog-add-certificate',
  templateUrl: './mat-dialog-add-certificate.component.html',
  styleUrls: ['./mat-dialog-add-certificate.component.scss'],
})
export class MatDialogAddCertificateComponent implements OnInit, OnDestroy {
  qualification: string = '';

  form: FormGroup;
  qualifications$: Observable<IWHQualificationsDTO[]>;
  filteredOptions: Observable<IWHQualificationsDTO[]>;
  private subscriptions: Subscription[] = [];

  constructor(
    public matDialogRef: MatDialogRef<MatDialogAddCertificateComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      qualifications$: Observable<IWHQualificationsDTO[]>;
      certificateDto: CertificateDTO;
    }
  ) {}

  ngOnInit() {
    this.qualifications$ = this.data.qualifications$;

    this.form = new FormGroup({
      qualification: new FormControl('', Validators.required),
      issuer: new FormControl('', Validators.required),
      acquiredAt: new FormControl(new Date(), [Validators.required]),
      validDue: new FormControl(null),
      renewed: new FormControl(false, Validators.required),
    });

    this.subscriptions.push(
      this.qualifications$.subscribe((qualifications) => {
        this.filteredOptions =
          this.form.controls.qualification.valueChanges.pipe(
            startWith(''),
            map((mapValue) => {
              const name =
                typeof mapValue === 'string' ? mapValue : mapValue?.name;
              return name
                ? qualifications.filter((option) =>
                    option?.name?.toLowerCase().includes(name?.toLowerCase())
                  )
                : qualifications;
            })
          );
      })
    );

    if (this.data.certificateDto) {
      this.form.get('qualification').disable();
      this.form.patchValue({
        acquiredAt: this.data.certificateDto.acquiredAt,
        issuer: this.data.certificateDto.issuer,
        renewed: this.data.certificateDto.renewed,
        validDue: this.data.certificateDto.validDue,
        qualification: this.data.certificateDto.qualificationExtId,
      });

      this.qualification = this.data.certificateDto.qualificationName;
    }
  }

  confirm() {
    const certificateId = this.data.certificateDto
      ? this.data.certificateDto.id
      : null;
    const qualificationID = this.data.certificateDto
      ? this.data.certificateDto.qualificationID
      : this.form.value.qualification.id;
    const qualificationExitId = this.data.certificateDto
      ? this.data.certificateDto.qualificationExtId
      : this.form.value.qualification.extId;
    const qualificationName = this.data.certificateDto
      ? this.data.certificateDto.qualificationName
      : this.form.value.qualification.name;

    const returnObject: CertificateDTO = {
      id: certificateId,
      qualificationID: qualificationID,
      qualificationExtId: qualificationExitId,
      qualificationName: qualificationName,
      acquiredAt: this.form.value.acquiredAt,
      issuer: this.form.value.issuer,
      renewed: this.form.value.renewed,
      validDue: this.form.value.validDue,
    };

    this.matDialogRef.close(returnObject);
  }

  formInvalid() {
    //if qualification is a string, it means that the user has not selected a qualification from the dropdown
    const qualificationIsAString =
      typeof this.form.value.qualification === 'string';
    const formInvalid = this.form.invalid;
    return qualificationIsAString || formInvalid;
  }

  clear() {
    this.form.controls['qualification'].reset();
    this.qualification = '';
  }

  isFormValid() {
    return this.form.valid;
  }

  hasQualification() {
    return this.form.value.qualification;
  }

  cancel() {
    this.matDialogRef.close(false);
  }
  onOptionClick(event) {
    this.qualification = event?.option?.value;
  }

  displayFn(qualification: any): string {
    if (this.qualification.length > 0) {
      return this.qualification;
    }
    this.qualification = qualification?.name ? qualification.name : '';
    return this.qualification;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
