// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHChecklistInstancePreviewDOM } from '@workheld/workheld-shared-lib';

export enum WHChecklistInstancePreviewStoreActionTypes {
  GetWHChecklistInstancePreviewDtoWithParams = '[WHChecklistInstancePreviewStore] GetWHChecklistInstancePreviewDtoWithParams',
  SetWHChecklistInstancePreview = '[WHChecklistInstancePreviewStore] SetWHChecklistInstancePreview',
}

export class GetWHChecklistInstancePreviewDtoWithParams implements Action {
  readonly type = WHChecklistInstancePreviewStoreActionTypes.GetWHChecklistInstancePreviewDtoWithParams;

  constructor(public payload: { params: WHHttpParams, targetParent: string, targetID?: string; workObjectTypeId?: number }) {
    // console.log('GetWHChecklistInstancePreviewDtoWithParams ' + JSON.stringify(payload));
  }
}

export class SetWHChecklistInstancePreview implements Action {
  readonly type = WHChecklistInstancePreviewStoreActionTypes.SetWHChecklistInstancePreview;

  constructor(public payload: WHChecklistInstancePreviewDOM) {
    // console.log('SetWHChecklistInstancePreview ' + JSON.stringify(payload));
  }
}

export type WHChecklistInstancePreviewStoreActions = GetWHChecklistInstancePreviewDtoWithParams | SetWHChecklistInstancePreview;
