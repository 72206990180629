// REGISTER ALL DIALOG SERVICES

// APP

// INDEX API DIALOGS
import {
  WHNgxAceWrapperMatDialogComponent,
  WHNgxImageCropperMatDialogComponent,
  WHNgxAceWrapperMatDialogModule,
  WHNgxImageCropperMatDialogModule,
  WHSharedMatDialogService
} from '@workheld/workheld-shared-lib';

// INDEX MODULE
import {
  IndexDialogModule,
  registredEntryComponents
} from './index-dialog.module';

// INDEX APP DIALOG SERVICES
import { AppDialogService } from './app-dialog.service';

// INDEX DIALOG MODULE
export const dialogModules: any[] = [
  WHNgxAceWrapperMatDialogModule,
  WHNgxImageCropperMatDialogModule,
  IndexDialogModule,
];

// INDEX DIALOG/ENTRY COMPONENTS
export const entryComponents: any[] = [
  WHNgxAceWrapperMatDialogComponent,
  WHNgxImageCropperMatDialogComponent
].concat(registredEntryComponents);

// INDEX DIALOG SERVICES
export const SERVICES_DIALOG: any[] = [
  WHSharedMatDialogService,
  AppDialogService,
];
