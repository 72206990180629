// HELPERS
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHChecklistDefinitionCrudStoreActions, WHChecklistDefinitionCrudStoreActionTypes } from './w-h-checklist-definition-crud-store.actions';

// MODELS
import { WHChecklistDefinitionDOM } from '@workheld/workheld-shared-lib';

// CONST
export const workheldChecklistDefinitionCrudStoreFeatureKey = 'workheldChecklistDefinitionCrudStore$';

export const initialState: WHChecklistDefinitionDOM = new WHChecklistDefinitionDOM();

export function workheldChecklistDefinitionCrudReducer(state = initialState, action: WHChecklistDefinitionCrudStoreActions): WHChecklistDefinitionDOM {
  switch (action.type) {

    case WHChecklistDefinitionCrudStoreActionTypes.GetWHChecklistDefinitionDTO:
      return updateObject(new WHChecklistDefinitionDOM(), { loading: true, loaded: false } as any);
    case WHChecklistDefinitionCrudStoreActionTypes.ClearWHChecklistDefinitionCrudSTORE:
      return new WHChecklistDefinitionDOM();

    case WHChecklistDefinitionCrudStoreActionTypes.SetWHChecklistDefinitionDOM:
      return updateObject(action.payload, {
        loading: false,
        loaded: true,
      } as any);

    default:
      return state;
  }
}
