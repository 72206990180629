// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { WorkOrderAsyncService } from 'src/app/app-services-async/work-order-async.service';
import { WorkStepAsyncService } from 'src/app/app-services-async/work-step-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHWorkStepMasterDataStoreActionTypes,
  WHWorkStepMasterDataStoreActions,
  GetWHWorkStepDtoList,
  SetWHWorkStepDomList,
  SortWorkStepListByWorkStepSortMode,
  SortWorkStepListByWorkStepIdList,
  GetAfterDeleteWHWorkStepDtoList,
  ScheduleAllWorkStepForWorkOrder,
} from './w-h-work-step-master-data-store.actions';
// WORK ORDER ACTIONS - TODO REMOVE?
import { GetWHWorkOrderDTO } from '../../w-h-work-order-stores/w-h-work-order-crud-store/w-h-work-order-crud-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import {
  IWHWorkStepDTO,
  WHWorkStepDOM,
  WHWorkObjectTypeENUM,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WHWorkStepMasterDataStoreEffects {
  getWHWorkStepDtoList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHWorkStepMasterDataStoreActionTypes.GetWHWorkStepDtoList),
      switchMap((action: GetWHWorkStepDtoList) => {
        return this.workStepAsyncService.getWorkStepDtoList(
          action.payload.workOrderId
        );
      }),
      mergeMap((workStepDtoList: IWHWorkStepDTO[]) => {
        const workStepDomList: WHWorkStepDOM[] = [];
        workStepDtoList.forEach((workStepDTO: IWHWorkStepDTO) => {
          const workStepDOM: WHWorkStepDOM =
            this.dataModelHelperService.initWorkStepDOM(workStepDTO);
          workStepDomList.push(workStepDOM);
        });
        return [
          new SetWHWorkStepDomList({
            workStepDomList,
            loading: false,
            loaded: true,
          }),
        ];
      })
    );
  });

  getAfterDeleteWHWorkStepDtoList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHWorkStepMasterDataStoreActionTypes.GetAfterDeleteWHWorkStepDtoList
      ),
      switchMap((action: GetAfterDeleteWHWorkStepDtoList) => {
        return this.workStepAsyncService.getWorkStepDtoList(
          action.payload.workOrderId
        );
      }),
      mergeMap((workStepDtoList: IWHWorkStepDTO[]) => {
        const workStepDomList: WHWorkStepDOM[] = [];
        workStepDtoList.forEach((workStepDTO: IWHWorkStepDTO) => {
          const workStepDOM: WHWorkStepDOM =
            this.dataModelHelperService.initWorkStepDOM(workStepDTO);
          workStepDomList.push(workStepDOM);
        });
        return [
          new SetWHWorkStepDomList({
            workStepDomList,
            loading: false,
            loaded: true,
          }),
        ];
      })
    );
  });

  sortWorkStepListByWorkStepSortMode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHWorkStepMasterDataStoreActionTypes.SortWorkStepListByWorkStepSortMode
      ),
      switchMap((action: SortWorkStepListByWorkStepSortMode) => {
        return this.workStepAsyncService.sortWorkStepsBySortMode(
          action.payload
        );
      }),
      mergeMap((workStepDtoList: IWHWorkStepDTO[]) => {
        const workStepDomList: WHWorkStepDOM[] = [];
        workStepDtoList.forEach((workStepDTO: IWHWorkStepDTO) => {
          const workStepDOM: WHWorkStepDOM =
            this.dataModelHelperService.initWorkStepDOM(workStepDTO);
          workStepDomList.push(workStepDOM);
        });
        return [
          new SetWHWorkStepDomList({
            workStepDomList,
            loading: false,
            loaded: true,
          }),
        ];
      })
    );
  });

  sortWorkStepListByWorkStepIdList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHWorkStepMasterDataStoreActionTypes.SortWorkStepListByWorkStepIdList
      ),
      switchMap((action: SortWorkStepListByWorkStepIdList) => {
        return this.workStepAsyncService.sortWorkStepsByWorkStepIdList(
          action.payload
        );
      }),
      mergeMap((workStepDtoList: IWHWorkStepDTO[]) => {
        const workStepDomList: WHWorkStepDOM[] = [];
        workStepDtoList.forEach((workStepDTO: IWHWorkStepDTO) => {
          const workStepDOM: WHWorkStepDOM =
            this.dataModelHelperService.initWorkStepDOM(workStepDTO);
          workStepDomList.push(workStepDOM);
        });
        return [
          new SetWHWorkStepDomList({
            workStepDomList,
            loading: false,
            loaded: true,
          }),
        ];
      })
    );
  });

  scheduleAllWorkStepForWorkOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHWorkStepMasterDataStoreActionTypes.ScheduleAllWorkStepForWorkOrder
      ),
      switchMap((action: ScheduleAllWorkStepForWorkOrder) => {
        return this.workOrderAsyncService.scheduleAllWorkStepsForWorkOrder(
          action.payload
        );
      }),
      mergeMap((workStepDtoList: IWHWorkStepDTO[]) => {
        if (!workStepDtoList) {
          workStepDtoList = [];
        }
        const workStepDomList: WHWorkStepDOM[] = [];

        const workOrderId: string = workStepDtoList[0]?.workOrderId;
        const workObjectTypeId: number = workStepDtoList[0]?.workObjectTypeId;
        workStepDtoList.forEach((workStepDTO: IWHWorkStepDTO) => {
          const workStepDOM: WHWorkStepDOM =
            this.dataModelHelperService.initWorkStepDOM(workStepDTO);
          workStepDomList.push(workStepDOM);
        });

        if (workObjectTypeId === WHWorkObjectTypeENUM.CASE) {
          return [
            new SetWHWorkStepDomList({
              workStepDomList,
              loading: false,
              loaded: true,
            }),
          ];
        } else {
          return [
            new GetWHWorkOrderDTO({ workOrderId }),
            new SetWHWorkStepDomList({
              workStepDomList,
              loading: false,
              loaded: true,
            }),
          ];
        }
      })
    );
  });

  constructor(
    private actions$: Actions<WHWorkStepMasterDataStoreActions>,
    private workOrderAsyncService: WorkOrderAsyncService,
    private workStepAsyncService: WorkStepAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
