// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHToolPreviewStore from './w-h-tool-preview-store.reducer';
import { WHToolPreviewStoreEffects } from './w-h-tool-preview-store.effects';

@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(fromWHToolPreviewStore.workheldToolPreviewStoreFeatureKey, fromWHToolPreviewStore.workheldToolPreviewReducer),
  EffectsModule.forFeature([WHToolPreviewStoreEffects])]
})
export class WHToolPreviewStoreModule { }
