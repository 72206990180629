// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// SERVICES
import { MaterialAsyncService } from 'src/app/app-services-async/material-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHMaterialPreviewStoreActionTypes,
  WHMaterialPreviewStoreActions,
  GetWHMaterialPreviewDtoWithParams,
  SetWHMaterialPreview,
  GetWHMaterialCatalogPreviewDtoWithParams,
} from './w-h-material-preview-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import {
  FLOW_GATEWAY,
  initHttpParams,
  IWHMaterialEndpointConfigModel,
  IWHMaterialPageResultDTO,
  WHMaterialDataService,
  WHMaterialPageResultDOM,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WHMaterialPreviewStoreEffects {
  getWHMaterialPreviewDtoWithParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHMaterialPreviewStoreActionTypes.GetWHMaterialPreviewDtoWithParams
      ),
      switchMap((action: GetWHMaterialPreviewDtoWithParams) => {
        const httpParams: HttpParams = initHttpParams(action.payload);
        // console.log(httpParams);
        return this.materialAsyncService.getMaterialPreview(httpParams);
      }),
      mergeMap((materialPreviewDTO: IWHMaterialPageResultDTO) => {
        const materialPreviewDOM: WHMaterialPageResultDOM =
          this.dataModelHelperService.initMaterialPreviewDOM(
            materialPreviewDTO
          );
        return [new SetWHMaterialPreview(materialPreviewDOM)];
      })
    );
  });

  getWHMaterialCatalogPreviewDtoWithParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHMaterialPreviewStoreActionTypes.GetWHMaterialCatalogPreviewDtoWithParams
      ),
      switchMap((action: GetWHMaterialCatalogPreviewDtoWithParams) => {
        const httpParams: HttpParams = initHttpParams(action.payload.params);
        const endpointConfig: IWHMaterialEndpointConfigModel = {
          apiUrl: environment.apiUrl + FLOW_GATEWAY,
          workStepId: action.payload.workStepId,
        };
        // console.log(httpParams);
        return this.materialDataService.getMaterialCatalogPreview(
          endpointConfig,
          httpParams
        );
      }),
      mergeMap((materialPreviewDTO: IWHMaterialPageResultDTO) => {
        console.log(materialPreviewDTO);
        const materialPreviewDOM: WHMaterialPageResultDOM =
          this.dataModelHelperService.initMaterialPreviewDOM(
            materialPreviewDTO
          );
        return [new SetWHMaterialPreview(materialPreviewDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHMaterialPreviewStoreActions>,
    private materialAsyncService: MaterialAsyncService,
    private materialDataService: WHMaterialDataService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
