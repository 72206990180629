// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHDndAssignmentStore from './w-h-dnd-assignment-store.reducer';
import { WHDndAssignmentStoreEffects } from './w-h-dnd-assignment-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHDndAssignmentStore.workheldDndAssignmentStoreFeatureKey, fromWHDndAssignmentStore.workheldDndAssignmentReducer),
    EffectsModule.forFeature([WHDndAssignmentStoreEffects, WHDndAssignmentStoreEffects]),
  ]
})
export class WHDndAssignmentStoreModule { }
