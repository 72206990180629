// ANGULAR
import { ElementRef, Injectable } from '@angular/core';

// THIRD
import Scrollbar from 'smooth-scrollbar';

@Injectable()
export class ScrollbarService {

  scrollbars: { [name: string]: { scrollbar: Scrollbar, element: any } };

  add(name: string, scrollbar: Scrollbar, element: ElementRef) {
    if (!this.scrollbars) {
      this.scrollbars = { };
    }

    this.scrollbars[name] = {
      scrollbar,
      element
    };
  }

  remove(name: string) {
    delete this.scrollbars[name];
  }

  get(name: string) {
    return this.scrollbars[name];
  }
}
