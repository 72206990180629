// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import * as fromWHWorkObjectMaterialUsageReport from './w-h-work-object-material-usage-report.reducer';
import { EffectsModule } from '@ngrx/effects';
import { WHWorkObjectMaterialUsageReportEffects } from './w-h-work-object-material-usage-report.effects';



@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHWorkObjectMaterialUsageReport.workheldWorkObjectMaterialUsageReportFeatureKey, fromWHWorkObjectMaterialUsageReport.workheldWorkObjectMaterialUsageReportReducer),
    EffectsModule.forFeature([WHWorkObjectMaterialUsageReportEffects])
  ]
})
export class WHWorkObjectMaterialUsageReportModule { }
