// HELPER
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHWorkObjectMaterialUsageReportStoreActionTypes } from './w-h-work-object-material-usage-report.actions';
import { IWHMaterialUsageReportDTO } from '@workheld/workheld-shared-lib';

export const workheldWorkObjectMaterialUsageReportFeatureKey = 'workheldWorkObjectMaterialUsageReportStore$';

export interface IWHWorkObjectMaterialUsageReportStore {
  materialUsageReportDTO: IWHMaterialUsageReportDTO;
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHWorkObjectMaterialUsageReportStore = {
  materialUsageReportDTO: null,
  loading: false,
  loaded: false
};

export function workheldWorkObjectMaterialUsageReportReducer(state: IWHWorkObjectMaterialUsageReportStore | undefined = initialState, action: any) {
  switch (action.type) {

    case WHWorkObjectMaterialUsageReportStoreActionTypes.GetWHWorkObjectMaterialUsageReportDTO:
      return updateObject<IWHWorkObjectMaterialUsageReportStore>(state, {
        materialUsageReportDTO: state.materialUsageReportDTO,
        loading: true,
        loaded: false
      });

    case WHWorkObjectMaterialUsageReportStoreActionTypes.SetWHWorkObjectMaterialUsageReportDOM:
      return updateObject<IWHWorkObjectMaterialUsageReportStore>(state, {
        materialUsageReportDTO: action.payload.materialUsageReportDTO,
        loading: false,
        loaded: true
      });

    default:
      return state;
  }
}
