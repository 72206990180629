<div class="html-overflow">
  <div class="w-h-label html-overflow" *ngIf="withLabel">
    {{ label | translate }}
  </div>
  <div class="default-value">
    <span
      class="html-overflow"
      style="display: block"
      matTooltip="{{ customerDOM?.name }}"
    >
      {{ customerDOM?.name ? customerDOM?.name : "-" }}
    </span>
  </div>
</div>
