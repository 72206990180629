// REDUX
import { WHWorkStepMasterDataStoreActions, WHWorkStepMasterDataStoreActionTypes } from './w-h-work-step-master-data-store.actions';

// MODELS
import { WHWorkStepDOM } from '@workheld/workheld-shared-lib';

export const workheldWorkStepMasterDataStoreFeatureKey = 'workheldWorkStepMasterDataStore$';

export interface IWHWorkStepMasterDataStore {
  // TODO - DOM model
  workStepDomList: WHWorkStepDOM[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHWorkStepMasterDataStore = {
  workStepDomList: [],
  loading: false,
  loaded: false,
};

export function workheldWorkStepMasterDataReducer(state = initialState, action: WHWorkStepMasterDataStoreActions): IWHWorkStepMasterDataStore {
  switch (action.type) {

    case WHWorkStepMasterDataStoreActionTypes.GetWHWorkStepDtoList:
      return {
        workStepDomList: [],
        loading: true,
        loaded: false,
      };

    case WHWorkStepMasterDataStoreActionTypes.GetAfterDeleteWHWorkStepDtoList:
      return {
        workStepDomList: state.workStepDomList,
        loading: true,
        loaded: true,
      };

    case WHWorkStepMasterDataStoreActionTypes.SortWorkStepListByWorkStepSortMode:
      return {
        workStepDomList: state.workStepDomList,
        loading: true,
        loaded: true,
      };

    case WHWorkStepMasterDataStoreActionTypes.SortWorkStepListByWorkStepIdList:
      return {
        workStepDomList: state.workStepDomList,
        loading: true,
        loaded: true,
      };

    case WHWorkStepMasterDataStoreActionTypes.ScheduleAllWorkStepForWorkOrder:
      return {
        workStepDomList: state.workStepDomList,
        loading: true,
        loaded: true,
      };

    case WHWorkStepMasterDataStoreActionTypes.SetWHWorkStepDomList:
      return action.payload;

    default:
      return state;
  }
}
