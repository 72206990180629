import {
  updateObject,
  WHEntryDOM,
  IWHWorkingTimEntryDTO,
  WHWorkingTimEntryDOM,
  WHDataService,
} from '@workheld/workheld-shared-lib';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  effect,
} from '@angular/core';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/app-services/ui.service';

@Component({
  selector: 'ng-bee-type2-working-time-entry-static-data',
  templateUrl: './type2-working-time-entry-static-data.component.html',
  styleUrls: ['./type2-working-time-entry-static-data.component.scss'],
})
export class Type2WorkingTimeEntryStaticDataComponent
  implements OnInit, OnDestroy
{
  // DATA VAR
  @Input() public entryDOM: WHEntryDOM;
  public workingTimeEntryDOM: WHWorkingTimEntryDOM = {
    loading: true,
    loaded: false,
  } as any;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];
  currentLocale: string;

  constructor(
    private dataService: WHDataService,
    private dataModelHelperService: DataModelHelperService,
    private changeDetectorRef: ChangeDetectorRef,
    private uiService: UIService,
  ) {
    effect(() => {
      this.currentLocale = uiService.$currentLocale();
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.dataService
        .getByUrl(this.entryDOM.entryDetailURL)
        .subscribe((workingTimeEntryDTO: IWHWorkingTimEntryDTO) => {
          this.workingTimeEntryDOM = updateObject(
            this.workingTimeEntryDOM,
            this.dataModelHelperService.initWorkingTimeEntryDOM(
              workingTimeEntryDTO,
            ),
          );
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
