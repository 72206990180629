// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHChecklistDefinitionMasterDataStore from './w-h-checklist-definition-master-data-store.reducer';
import { WHChecklistDefinitionMasterDataStoreEffects } from './w-h-checklist-definition-master-data-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHChecklistDefinitionMasterDataStore.workheldChecklistDefinitionMasterDataStoreFeatureKey, fromWHChecklistDefinitionMasterDataStore.workheldChecklistDefinitionMasterDataReducer),
    EffectsModule.forFeature([WHChecklistDefinitionMasterDataStoreEffects])
  ]
})
export class WHChecklistDefinitionMasterDataStoreModule { }
