// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHCustomerPreviewStore from './w-h-customer-preview-store.reducer';
import { WHCustomerPreviewStoreEffects } from './w-h-customer-preview-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHCustomerPreviewStore.workheldCustomerPreviewStoreFeatureKey,
    fromWHCustomerPreviewStore.workheldCustomerPreviewReducer), EffectsModule.forFeature([WHCustomerPreviewStoreEffects])
  ]
})
export class WHCustomerPreviewStoreModule { }
