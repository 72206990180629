// HELPERS
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHToolCrudStoreActions, WHToolCrudStoreActionTypes } from './w-h-tool-crud-store.actions';

// MODELS
import { WHToolDOM } from '@workheld/workheld-shared-lib';

export const workheldToolCrudStoreFeatureKey = 'workheldToolCrudStore$';

export const initialState: WHToolDOM = new WHToolDOM();

export function workheldToolCrudReducer(state = initialState, action: WHToolCrudStoreActions): WHToolDOM {
  switch (action.type) {

    case WHToolCrudStoreActionTypes.ClearWHToolCrudSTORE:
      return new WHToolDOM();

    case WHToolCrudStoreActionTypes.GetWHToolDTO:
      return updateObject(state, {
        loading: true,
        loaded: false
      } as any);

    case WHToolCrudStoreActionTypes.SetWHToolDOM:
      return action.payload;

    default:
      return state;
  }
}
