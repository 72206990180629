// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// SERVICES
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHToolPreviewStoreActionTypes,
  WHToolPreviewStoreActions,
  GetWHToolPreviewDtoWithParams,
  SetWHToolPreview,
} from './w-h-tool-preview-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import {
  initHttpParams,
  IWHToolsPreviewDTO,
  WHToolPreviewDOM,
} from '@workheld/workheld-shared-lib';
import { FLOW_GATEWAY } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHToolPreviewStoreEffects {
  getWHToolPreviewDtoWithParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHToolPreviewStoreActionTypes.GetWHToolPreviewDtoWithParams),
      switchMap((action: GetWHToolPreviewDtoWithParams) => {
        const httpParams: HttpParams = initHttpParams(action.payload);
        // console.log(httpParams);
        return this.httpClient.get<IWHToolsPreviewDTO>(
          `${environment.apiUrl + FLOW_GATEWAY}/tools/preview`,
          { params: httpParams }
        );
      }),
      mergeMap((toolPreviewDTO: IWHToolsPreviewDTO) => {
        const toolPreviewDOM: WHToolPreviewDOM =
          this.dataModelHelperService.initToolPreviewDOM(toolPreviewDTO);
        return [new SetWHToolPreview(toolPreviewDOM)];
      })
    );
  });

  constructor(
    // TODO build SERVICE
    private httpClient: HttpClient,
    private actions$: Actions<WHToolPreviewStoreActions>,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
