// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHChecklistDefinitionPageResultDOM } from '@workheld/workheld-shared-lib';

export enum WHChecklistDefinitionPreviewStoreActionTypes {
  GetWHChecklistDefinitionPreviewDtoWithParams = '[WHChecklistDefinitionPreviewStore] GetWHChecklistDefinitionPreviewDtoWithParams',
  SetWHChecklistDefinitionPreview = '[WHChecklistDefinitionPreviewStore] SetWHChecklistDefinitionPreview',
}

export class GetWHChecklistDefinitionPreviewDtoWithParams implements Action {
  readonly type = WHChecklistDefinitionPreviewStoreActionTypes.GetWHChecklistDefinitionPreviewDtoWithParams;

  constructor(public payload: WHHttpParams) {
    // console.log('GetWHChecklistDefinitionPreviewDtoWithParams ' + JSON.stringify(payload));
  }
}

export class SetWHChecklistDefinitionPreview implements Action {
  readonly type = WHChecklistDefinitionPreviewStoreActionTypes.SetWHChecklistDefinitionPreview;

  constructor(public payload: WHChecklistDefinitionPageResultDOM) {
    // console.log('SetWHChecklistDefinitionPreview ' + JSON.stringify(payload));
  }
}


export type WHChecklistDefinitionPreviewStoreActions = GetWHChecklistDefinitionPreviewDtoWithParams | SetWHChecklistDefinitionPreview;
