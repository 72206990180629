// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHEntryPreviewDOM } from '@workheld/workheld-shared-lib';

export enum WHEntryPreviewStoreActionTypes {
  GetWHEntryPreviewDtoWithParams = '[WHEntryPreviewStore] GetWHEntryPreviewDtoWithParams',
  SetWHEntryPreview = '[WHEntryPreviewStore] SetWHEntryPreview',
}

export class GetWHEntryPreviewDtoWithParams implements Action {
  readonly type = WHEntryPreviewStoreActionTypes.GetWHEntryPreviewDtoWithParams;

  constructor(public payload: { params: WHHttpParams, targetParent: string, targetID?: string; workObjectTypeId?: number }) {
    // console.log('GetWHEntryPreviewDtoWithParams ' + JSON.stringify(payload));
  }
}

export class SetWHEntryPreview implements Action {
  readonly type = WHEntryPreviewStoreActionTypes.SetWHEntryPreview;

  constructor(public payload: WHEntryPreviewDOM) {
    // console.log('SetWHEntryPreview ' + JSON.stringify(payload));
  }
}

export type WHEntryPreviewStoreActions = GetWHEntryPreviewDtoWithParams | SetWHEntryPreview;
