// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import {
  FLOW_GATEWAY,
  IWHCustomerEndpointConfigModel,
  WHCustomerDataService,
} from '@workheld/workheld-shared-lib';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHCustomerMasterDataStoreActionTypes,
  WHCustomerMasterDataStoreActions,
  SetCustomerDomList,
} from './w-h-customer-master-data-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import { WHCustomerDOM, IWHCustomerDTO } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHCustomerMasterDataStoreEffects {
  getCustomerDtoList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHCustomerMasterDataStoreActionTypes.GetCustomerDtoList),
      switchMap(() => {
        const endpointConfig: IWHCustomerEndpointConfigModel = {
          apiUrl: environment.apiUrl + FLOW_GATEWAY,
        };
        return this.customerDataService.getCustomerDtoList(endpointConfig);
      }),
      mergeMap((res: IWHCustomerDTO[]) => {
        const customerDomList: WHCustomerDOM[] = [];
        (res as Array<IWHCustomerDTO>).forEach(
          (customerDTO: IWHCustomerDTO) => {
            customerDomList.push(
              this.dataModelHelperService.initCustomerDOM(customerDTO)
            );
          }
        );
        return [
          new SetCustomerDomList({
            customerDomList,
            loading: false,
            loaded: true,
          }),
        ];
      })
    );
  });

  constructor(
    private actions$: Actions<WHCustomerMasterDataStoreActions>,
    private customerDataService: WHCustomerDataService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
