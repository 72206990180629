// ANGULAR
import { Component, Input, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// SERVICES
import { WHDataService, WHIconENUM } from '@workheld/workheld-shared-lib';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// RxJS 6
import { Subscription } from 'rxjs';

// MODELS
import { WHWorkOrderDOM, IWHWorkOrderDTO } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-work-order-static-data',
  templateUrl: './work-order-static-data.component.html',
  styleUrls: ['./work-order-static-data.component.scss']
})
export class WorkOrderStaticDataComponent implements OnChanges, OnDestroy {

  // INPUT
  @Input() public label: string = 'workorder.model.root';
  @Input() public detailURL: string;
  @Input() public withLabel: boolean = false;
  public workOrderDOM: WHWorkOrderDOM;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  constructor(
    private dataService: WHDataService,
    private dataModelHelperService: DataModelHelperService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    if (changes && changes.detailURL && changes.detailURL.currentValue) {
      this.subscriptions.push(this.dataService.getByUrl(changes.detailURL.currentValue)
        .subscribe((workOrderDTO: IWHWorkOrderDTO | any) => {
          this.workOrderDOM = this.dataModelHelperService.initWorkOrderDOM(workOrderDTO);
          this.changeDetectorRef.detectChanges();
        })
      );
    }
  }

  public get workOrderIcon(): string {
    return WHIconENUM.WorkOrderIcon as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
