// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHMaterialDOM } from '@workheld/workheld-shared-lib';

export enum WHMaterialCrudStoreActionTypes {
  GetWHMaterialDTO = '[WHMaterialCrudStore] GetWHMaterialDTO',
  SetWHMaterialDOM = '[WHMaterialCrudStore] SetWHMaterialDOM',
  ClearWHMaterialCrudSTORE = '[WHMaterialCrudStore] ClearWHMaterialCrudSTORE',
}

export class GetWHMaterialDTO implements Action {
  readonly type = WHMaterialCrudStoreActionTypes.GetWHMaterialDTO;

  constructor(public payload: WHHttpParams) {
    // console.log('GetWHMaterialDTO ' + JSON.stringify(payload));
  }
}

export class SetWHMaterialDOM implements Action {
  readonly type = WHMaterialCrudStoreActionTypes.SetWHMaterialDOM;

  constructor(public payload: WHMaterialDOM) {
    // console.log('SetWHMaterialDOM ' + JSON.stringify(payload));
  }
}

export class ClearWHMaterialCrudSTORE implements Action {
  readonly type = WHMaterialCrudStoreActionTypes.ClearWHMaterialCrudSTORE;

  constructor() {
    // console.log('ClearWHMaterialCrudSTORE');
  }
}

export type WHMaterialCrudStoreActions = GetWHMaterialDTO | SetWHMaterialDOM | ClearWHMaterialCrudSTORE;
