// REDUX
import { WHWorkOrderFileMasterDataStoreActions, WHWorkOrderFileMasterDataStoreActionTypes } from './w-h-work-order-file-master-data-store.actions';
import { WHFileDOM } from '@workheld/workheld-shared-lib';

export const workheldWorkOrderFileMasterDataStoreFeatureKey = 'workheldWorkOrderFileMasterDataStore$';

export interface IWHWorkOrderFileMasterDataStore {
  workOrderFileDomList: WHFileDOM[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHWorkOrderFileMasterDataStore = {
  workOrderFileDomList: [],
  loading: false,
  loaded: false,
};

export function workheldWorkOrderFileMasterDataReducer(state = initialState, action: WHWorkOrderFileMasterDataStoreActions): IWHWorkOrderFileMasterDataStore {
  switch (action.type) {

    case WHWorkOrderFileMasterDataStoreActionTypes.GetWHWorkOrderFileDtoList:
      return {
        workOrderFileDomList: [],
        loading: true,
        loaded: false,
      };

    case WHWorkOrderFileMasterDataStoreActionTypes.SetWHWorkOrderFileDomList:
      return action.payload;

    default:
      return state;
  }
}
