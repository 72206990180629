// REDUX
import { WHWorkOrderMasterDataStoreActions, WHWorkOrderMasterDataStoreActionTypes } from './w-h-work-order-master-data-store.actions';

// MODELS
import { WHWorkOrderDOM } from '@workheld/workheld-shared-lib';

export const workheldWorkOrderMasterDataStoreFeatureKey = 'workheldWorkOrderMasterDataStore$';

export interface IWHWorkOrderMasterDataStore {
  workOrderDomList: WHWorkOrderDOM[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHWorkOrderMasterDataStore = {
  workOrderDomList: [],
  loading: false,
  loaded: false,
};

export function workheldWorkOrderMasterDataReducer(state = initialState, action: WHWorkOrderMasterDataStoreActions): IWHWorkOrderMasterDataStore {
  switch (action.type) {

    case WHWorkOrderMasterDataStoreActionTypes.GetWHWorkOrderDtoList:
      return {
        workOrderDomList: [],
        loading: true,
        loaded: false,
      };

    case WHWorkOrderMasterDataStoreActionTypes.SetWHWorkOrderDomList:
      return action.payload;

    default:
      return state;
  }
}
