// ANGULAR
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHChecklistDefinitionDOM } from '@workheld/workheld-shared-lib';

export enum WHChecklistDefinitionMasterDataStoreActionTypes {
  GetWHChecklistDefinitionDtoList = '[WHChecklistDefinitionMasterDataStore] GetWHChecklistDefinitionDtoList',
  SetWHChecklistDefinitionDomList = '[WHChecklistDefinitionMasterDataStore] SetWHChecklistDefinitionDomList',
}

export class GetWHChecklistDefinitionDtoList implements Action {
  readonly type = WHChecklistDefinitionMasterDataStoreActionTypes.GetWHChecklistDefinitionDtoList;

  constructor(public payload?: WHHttpParams) {
    // console.log('GetWHChecklistDefinitionDtoList');
  }
}

export class SetWHChecklistDefinitionDomList implements Action {
  readonly type = WHChecklistDefinitionMasterDataStoreActionTypes.SetWHChecklistDefinitionDomList;

  constructor(public payload: { checklistDefinitionDomList: WHChecklistDefinitionDOM[], loading: boolean, loaded: boolean }) {
    // console.log('SetWHChecklistDefinitionDomList ' + JSON.stringify(payload));
  }
}


export type WHChecklistDefinitionMasterDataStoreActions = GetWHChecklistDefinitionDtoList | SetWHChecklistDefinitionDomList;
