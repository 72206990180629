// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { MaterialStaticDataRoutingModule } from './material-static-data-routing.module';
import { MaterialStaticDataComponent } from './material-static-data.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';

// API
import { WHImgAuthHeaderModule } from '@workheld/workheld-shared-lib';

@NgModule({
  declarations: [
    MaterialStaticDataComponent
  ],
  exports: [
    MaterialStaticDataComponent
  ],
  imports: [
    AppSharedModule,
    WHImgAuthHeaderModule,
    MaterialStaticDataRoutingModule
  ]
})
export class MaterialStaticDataModule { }
