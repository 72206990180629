import { Injectable, signal } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, startWith } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UIService {
  isLargeScreen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  disposeDashboardInfiniteScroll$ = new BehaviorSubject<boolean>(false);
  currentLocale$: BehaviorSubject<string> = new BehaviorSubject<string>('en');
  $currentLocale = signal('en');
  $isLargeScreen = signal(false);
  $isXLScreen = signal(false);
  $isSmallScreen = signal(false);
  isFirefoxBrowser = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService,
  ) {
    this.isFirefoxBrowser = navigator.userAgent
      .toLowerCase()
      .includes('firefox');

    this.breakpointObserver
      .observe([
        Breakpoints.Small,
        Breakpoints.XSmall,
        Breakpoints.Handset,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        const isLargeScreen = Object.values(result.breakpoints).some(
          (value) => value === true && result.breakpoints[Breakpoints.Large],
        );
        const isXLScreen = Object.values(result.breakpoints).some(
          (value) => value === true && result.breakpoints[Breakpoints.XLarge],
        );

        const isSmallScreen = Object.values(result.breakpoints).some(
          (value) =>
            value === true &&
            (result.breakpoints[Breakpoints.Small] ||
              result.breakpoints[Breakpoints.XSmall] ||
              result.breakpoints[Breakpoints.Handset]),
        );

        this.isLargeScreen$.next(isLargeScreen);
        this.$isLargeScreen.set(isLargeScreen);
        this.$isXLScreen.set(isXLScreen);
        this.$isSmallScreen.set(isSmallScreen);
      });

    this.translateService.onLangChange.pipe(startWith('en')).subscribe(() => {
      this.currentLocale$.next(this.translateService.currentLang);
      this.$currentLocale.set(this.translateService.currentLang);
    });
  }
}
