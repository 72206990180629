// ENVIRONMENT
import { environment } from '../../environments/environment';

// HELPER
import { initHttpParams } from '@workheld/workheld-shared-lib';

// ENVIRONMENT

// ANGULAR
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS 6
import { Observable } from 'rxjs';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { IWHChecklistDefinitionDTO } from '@workheld/workheld-shared-lib';
import { FLOW_GATEWAY } from '@workheld/workheld-shared-lib';

@Injectable()
export class ChecklistDefinitionAsyncService {

  constructor(private httpClient: HttpClient) { }

  public initChecklistDefinitionFormGroup() {
    return new FormBuilder().group({
      id: [null],
      description: [null],
      title: [null, [Validators.required]],
      extId: [null],
      subsidiaryId: [null],
      checkDefinitions: this.initCheckDefinitionFormArray(),
      deleteDefinitions: [null],
      modifiedDate: [null],
      childrenModifiedDate: [null]
    });
  }

  public initCheckDefinitionFormGroup() {
    return new FormBuilder().group({
      id: [null],
      unit: [null],
      description: [null, [Validators.required]],
      measurementType: [null, [Validators.required]],
      checkResultTypeId: [null, [Validators.required]],
      mandatory: [null],
      sortPosition: [null]
    });
  }

  public initCheckDefinitionFormArray(): FormArray {
    return new FormBuilder().array([]) as FormArray;
  }

  // ASYNC FUNCTIONS
  public getChecklistDefinitionDtoList(params?: WHHttpParams): Observable<IWHChecklistDefinitionDTO[]> {
    const httpParams: HttpParams = initHttpParams(params);
    return this.httpClient.get<IWHChecklistDefinitionDTO[]>(`${environment.apiUrl + FLOW_GATEWAY}/checklistDefinitions`, { params: httpParams });
  }

  public getChecklistDefinitionDtoByChecklistDefinitionId(checklistDefinitionID: string) {
    return this.httpClient.get<IWHChecklistDefinitionDTO>(`${environment.apiUrl + FLOW_GATEWAY}/checklistDefinitions/${checklistDefinitionID}`);
  }

  public deleteChecklistDefinitionByChecklistDefinitionId(checklistDefinitionID: string) {
    return this.httpClient.delete(`${environment.apiUrl + FLOW_GATEWAY}/checklistDefinitions/${checklistDefinitionID}`);
  }

  public createChecklistDefinitionByChecklistDefinitionDTO(checklistDefinitionDTO: IWHChecklistDefinitionDTO): Observable<IWHChecklistDefinitionDTO> {
    return this.httpClient.post<IWHChecklistDefinitionDTO>(`${environment.apiUrl + FLOW_GATEWAY}/checklistDefinitions`, checklistDefinitionDTO);
  }

  public updateChecklistDefinitionByChecklistDefinitionDTO(checklistDefinitionId: string, checklistDefinitionDTO: IWHChecklistDefinitionDTO): Observable<IWHChecklistDefinitionDTO> {
    return this.httpClient.post<IWHChecklistDefinitionDTO>(`${environment.apiUrl + FLOW_GATEWAY}/checklistDefinitions/${checklistDefinitionId}`, checklistDefinitionDTO);
  }

}
