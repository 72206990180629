<div class="create-work-object-template-mat-dialog">
  <div class="detail-card-header">
    <span whFlex class="w-h-header-icon-span">
      <i
        mat-card-avatar
        class="workheld-icons header-icon"
        [innerHtml]="projectTemplateIcon"
      ></i>
    </span>
    <span whFlex class="w-h-header-span">
      <div class="single-header">
        {{ "template.ui.create.dialogheader.label" | translate }}
      </div>
    </span>
  </div>
  <div class="create-work-object-template-form-wrapper">
    <form [formGroup]="createWorkObjectTemplateFromGroup">
      <div class="flex--row gap--12 flex-1">
        <mat-form-field class="flex-1">
          <mat-label>{{ "template.model.name" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'template.model.name' | translate }}"
            formControlName="templateName"
            required
            autocomplete="off"
          />
        </mat-form-field>
        <mat-form-field class="flex-1">
          <mat-label>{{ "template.model.extid" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'template.model.extid' | translate }}"
            formControlName="extId"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field whFlex>
          <mat-label>{{ "template.model.description" | translate }}</mat-label>
          <textarea
            [rows]="2"
            matInput
            formControlName="templateDescription"
            placeholder="{{ 'template.model.description' | translate }}"
          >
          </textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
  <mat-list class="work-order-list">
    <h3 mat-subheader>
      {{ "template.ui.create.workorderlist.label" | translate }}
    </h3>
    <mat-list-item *ngFor="let workOrderDOM of workOrderDomList">
      <i class="workheld-icons" mat-list-icon [innerHTML]="workOrderIcon"></i>
      <h4 mat-line>{{ workOrderDOM.numberExt }}</h4>
      <p mat-line>{{ workOrderDOM.title }}</p>
    </mat-list-item>
  </mat-list>
  <div class="dialog-action-wrapper" mat-dialog-actions>
    <button
      class="w-h-default-button dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      tabindex="-1"
      (click)="cancel()"
      [disabled]="saving"
    >
      <i class="workheld-icons" [innerHTML]="cancelIcon"></i>
      {{ "app.cancel.label" | translate | uppercase }}
    </button>
    <button
      color="accent"
      class="dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      tabindex="-1"
      [disabled]="isFormGroupInvalid || saving"
      (click)="createWorkObjectTemplate()"
    >
      <mat-spinner style="display: inline-block" *ngIf="saving" [diameter]="18">
      </mat-spinner>
      <i class="workheld-icons" [innerHTML]="saveIcon" *ngIf="!saving"></i>
      {{ "app.save.label" | translate | uppercase }}
    </button>
  </div>
</div>
