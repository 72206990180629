import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JobRrequirementsDataService } from 'src/app/app-services-async/job-requirements-data.service';
import { WHJobRequirementsDataService } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'app-mat-dialog-add-jobprofile',
  templateUrl: './mat-dialog-add-jobprofile.component.html',
  styleUrls: ['./mat-dialog-add-jobprofile.component.scss'],
  providers: [WHJobRequirementsDataService, JobRrequirementsDataService],
})
export class MatDialogAddJobProfileComponent {
  selectedJobRequirement = null;
  constructor(
    public matDialogRef: MatDialogRef<MatDialogAddJobProfileComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      jobProfilesDropdownList$;
      jobProfilesDropdownListLoading$;
    },
  ) {}

  setJobrequirement(event) {
    if (event.type === 'optionSelected') {
      this.selectedJobRequirement = event.payload;
    }
  }

  confirm() {
    this.matDialogRef.close(this.selectedJobRequirement);
  }

  cancel() {
    this.matDialogRef.close(false);
  }
}
