// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { ReportFileAsyncService } from 'src/app/app-services-async/report-file-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHReportFilePreviewStoreActionTypes,
  WHReportFilePreviewStoreActions,
  GetWHReportFilePreviewDtoWithParams,
  SetWHReportFilePreview,
} from './w-h-report-file-preview-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import {
  IWHReportFilePreviewDTO,
  WHReportFilePreviewDOM,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WHReportFilePreviewStoreEffects {
  getWHReportFilePreviewDtoWithParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHReportFilePreviewStoreActionTypes.GetWHReportFilePreviewDtoWithParams
      ),
      switchMap((action: GetWHReportFilePreviewDtoWithParams) => {
        return this.reportFileAsyncService.getReportFilePreview(
          action.payload.params,
          action.payload.targetParent,
          action.payload.targetID,
          action.payload.workObjectTypeId
        );
      }),
      mergeMap((reportFilePreviewDTO: IWHReportFilePreviewDTO) => {
        const reportFilePreviewDOM: WHReportFilePreviewDOM =
          this.dataModelHelperService.initReportFilePreviewDOM(
            reportFilePreviewDTO
          );
        return [new SetWHReportFilePreview(reportFilePreviewDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHReportFilePreviewStoreActions>,
    private reportFileAsyncService: ReportFileAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
