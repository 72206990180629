// ANGULAR
import { Action } from '@ngrx/store';

//
import { WHWorkObjectDOM } from '@workheld/workheld-shared-lib';

export enum WHWorkObjectCrudStoreActionTypes {
  GetWHWorkObjectDTO = '[WHWorkObjectCrudStore] GetWHWorkObjectDTO',
  SetWHWorkObjectDOM = '[WHWorkObjectCrudStore] SetWHWorkObjectDOM',
  ResetWHWorkObjectDOM = '[WHWorkObjectCrudStore] ResetWHWorkObjectDOM',
}

export class GetWHWorkObjectDTO implements Action {
  readonly type = WHWorkObjectCrudStoreActionTypes.GetWHWorkObjectDTO;

  constructor(public payload: { workObjectTypeId: number, workObjectId: string }) {
    // console.log('GetWHWorkObjectDTO ');
  }
}

export class SetWHWorkObjectDOM implements Action {
  readonly type = WHWorkObjectCrudStoreActionTypes.SetWHWorkObjectDOM;

  constructor(public payload: { workObjectTypeId: number, workObjectDOM: WHWorkObjectDOM | any }) {
    // console.log('SetWHWorkObjectDOM ');
  }
}

export class ResetWHWorkObjectDOM implements Action {
  readonly type = WHWorkObjectCrudStoreActionTypes.ResetWHWorkObjectDOM;

  constructor(public payload: { workObjectTypeId: number }) {
    // console.log('ResetWHWorkObjectDOM ');
  }
}


export type WHWorkObjectCrudStoreActions = GetWHWorkObjectDTO | SetWHWorkObjectDOM | ResetWHWorkObjectDOM;
