// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHCustomerMasterDataStore from './w-h-customer-master-data-store.reducer';
import { WHCustomerMasterDataStoreEffects } from './w-h-customer-master-data-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHCustomerMasterDataStore.workheldCustomerMasterDataStoreFeatureKey, fromWHCustomerMasterDataStore.workheldCustomerMasterDataReducer),
    EffectsModule.forFeature([WHCustomerMasterDataStoreEffects])
  ]
})
export class WHCustomerMasterDataStoreModule { }
