// ANGULAR
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// APP
import { WHMatDatePickerUtilRoutingModule } from './w-h-mat-date-picker-util-routing.module';
import { WHMatDatePickerUtilComponent } from './w-h-mat-date-picker-util.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';

@NgModule({
  declarations: [
    WHMatDatePickerUtilComponent
  ],
  exports: [
    WHMatDatePickerUtilComponent
  ],
  imports: [
    ReactiveFormsModule,
    AppSharedModule,
    WHMatDatePickerUtilRoutingModule
  ]
})
export class WHMatDatePickerUtilModule { }
