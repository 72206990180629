// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// SERVICES
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHWorkerTimeReportStoreActionTypes,
  WHWorkerTimeReportStoreActions,
  GetWHWorkerTimeReportDTO,
  SetWHWorkerTimeReportDOM,
} from './w-h-worker-time-report-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import {
  IWHWorkerTimeReportResultDTO,
  WHWorkerTimeReportResultDOM,
} from '@workheld/workheld-shared-lib';
import { FLOW_GATEWAY } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHWorkerTimeReportStoreEffects {
  getWHWorkerTimeReportDTO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHWorkerTimeReportStoreActionTypes.GetWHWorkerTimeReportDTO),
      switchMap((action: GetWHWorkerTimeReportDTO) => {
        const params: WHHttpParams = action.payload as WHHttpParams;
        return this.httpClient.get<IWHWorkerTimeReportResultDTO>(
          `${environment.apiUrl + FLOW_GATEWAY}/workers/${
            params.targetID
          }/timereport/${params.startDate}/${params.endDate}`
        );
      }),
      mergeMap((workerTimeReportResultDTO: IWHWorkerTimeReportResultDTO) => {
        const workerTimerReportResultDOM: WHWorkerTimeReportResultDOM =
          this.dataModelHelperService.initWorkerTimeReportResultDOM(
            workerTimeReportResultDTO
          );
        return [new SetWHWorkerTimeReportDOM(workerTimerReportResultDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHWorkerTimeReportStoreActions>,
    private httpClient: HttpClient,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
