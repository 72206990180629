// HELPER
import { FLOW_GATEWAY, initHttpParams, setWorkObjectTypeIdPath } from '@workheld/workheld-shared-lib';

// ENVIRONMENT
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS 6
import { Observable, of } from 'rxjs';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { IWHReportFilePreviewDTO } from '@workheld/workheld-shared-lib';

@Injectable()
export class ReportFileAsyncService {

  constructor(
    private httpClient: HttpClient
  ) { }

  // ENTRY PREVIEW
  public initReportFilePreviewHttpParamsFormGroup(): FormGroup {
    return new FormBuilder().group({
      page: [0],
      sort: ['createdDate'],
      term: [null],
      sortMode: ['DESC'],
      filter: null,
      entryTypeId: [null]
    });
  }

  public getReportFilePreview(params: WHHttpParams, targetParent: string, targetID: string, workObjectTypeId: number): Observable<IWHReportFilePreviewDTO> {
    const httpParams: HttpParams = initHttpParams(params);
    switch (targetParent) {
      case 'SUBSIDIARY':
        return this.getReportFilePreviewForSubsidiary(httpParams);
      case 'EQUIPMENT':
        return this.getReportFilePreviewForEquipment(targetID, httpParams);
      case 'WORK_OBJECT':
        return this.getReportFilePreviewForWorkObject(targetID, workObjectTypeId, httpParams);
      case 'WORK_ORDER':
        return this.getReportFilePreviewForWorkOrder(targetID, httpParams);
      default:
        return of(null);
    }
  }

  // SUBSIDIARY
  private getReportFilePreviewForSubsidiary(httpParams: HttpParams): Observable<IWHReportFilePreviewDTO> {
    return this.httpClient.get<IWHReportFilePreviewDTO>(`${environment.apiUrl + FLOW_GATEWAY}/subsidiaries/reports/preview`, { params: httpParams });
  }

  // WORK OBJECT LEVEL
  private getReportFilePreviewForWorkObject(workObjectId: string, workObjectTypeId: number, httpParams: HttpParams): Observable<IWHReportFilePreviewDTO> {
    const workObjectTargetEndpoint: string = setWorkObjectTypeIdPath(workObjectTypeId);
    return this.httpClient.get<IWHReportFilePreviewDTO>(`${environment.apiUrl + FLOW_GATEWAY}/${workObjectTargetEndpoint}/${workObjectId}/reports/preview`, { params: httpParams });
  }

  // WORK ORDER LEVEL
  private getReportFilePreviewForWorkOrder(workObjectId: string, httpParams: HttpParams): Observable<IWHReportFilePreviewDTO> {
    return this.httpClient.get<IWHReportFilePreviewDTO>(`${environment.apiUrl + FLOW_GATEWAY}/workOrders/${workObjectId}/reports/preview`, { params: httpParams });
  }

  // EQUIPMENT LEVEL
  private getReportFilePreviewForEquipment(equipmentId: string, httpParams: HttpParams): Observable<IWHReportFilePreviewDTO> {
    return this.httpClient.get<IWHReportFilePreviewDTO>(`${environment.apiUrl + FLOW_GATEWAY}/equipments/${equipmentId}/reports`, { params: httpParams });
  }

}
