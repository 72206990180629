// HELPERS
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHDndAssignmentStoreActions, WHDndAssignmentStoreActionTypes } from './w-h-dnd-assignment-store.actions';

export const workheldDndAssignmentStoreFeatureKey = 'workheldDndAssignmentStore$';

export interface IWHDndAssignmentStore {
  dndAssignmentDtoList: any[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHDndAssignmentStore = {
  dndAssignmentDtoList: [],
  loading: false,
  loaded: false,
};

export function workheldDndAssignmentReducer(state = initialState, action: WHDndAssignmentStoreActions): IWHDndAssignmentStore {
  switch (action.type) {

    case WHDndAssignmentStoreActionTypes.GetWHDndAssignmentDtoListWithParams:
      return updateObject(state, {
        dndAssignmentDtoList: [],
        loading: true,
        loaded: false,
      });

    case WHDndAssignmentStoreActionTypes.SetWHDndAssignmentDtoList:
      return action.payload;

    default:
      return state;
  }
}
