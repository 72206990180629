// ANGULAR
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// SERVICES

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';

// APP
import { WHMaterialPreviewRoutingModule } from './w-h-material-preview-routing.module';
import { WHMaterialPreviewComponent } from './w-h-material-preview.component';
import { WHSearchTermModule } from './w-h-search-term/w-h-search-term.module';
import { WHSearchTermUtilModule } from 'src/app/app-modules-pages/w-h-search-term-util/w-h-search-term-util.module';

// API
import {
  WHDropdownModule,
  WHImgAuthHeaderModule,
  WHLocalDateModule,
} from '@workheld/workheld-shared-lib';

@NgModule({
  declarations: [WHMaterialPreviewComponent],
  exports: [WHMaterialPreviewComponent],
  imports: [
    FormsModule,
    AppSharedModule,
    WHSearchTermModule,
    WHSearchTermUtilModule,
    WHImgAuthHeaderModule,
    WHLocalDateModule,
    WHMaterialPreviewRoutingModule,
    WHDropdownModule,
  ],
  providers: [],
})
export class WHMaterialPreviewModule {}
