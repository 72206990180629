// HELPERS
import { updateObject, WHMaterialPageResultDOM } from '@workheld/workheld-shared-lib';

// REDUX
import { WHMaterialPreviewStoreActions, WHMaterialPreviewStoreActionTypes } from './w-h-material-preview-store.actions';

// MODELS

export const workheldMaterialPreviewStoreFeatureKey = 'workheldMaterialPreviewStore$';

export const initialState: WHMaterialPageResultDOM = new WHMaterialPageResultDOM();

export function workheldMaterialPreviewReducer(state = initialState, action: WHMaterialPreviewStoreActions): WHMaterialPageResultDOM {
  switch (action.type) {

    case WHMaterialPreviewStoreActionTypes.GetWHMaterialPreviewDtoWithParams:
     // const payload: WHHttpParams = action.payload;
      return updateObject<WHMaterialPageResultDOM>(state, {
        loading: true,
        loaded: false
      } as any);

    case WHMaterialPreviewStoreActionTypes.GetWHMaterialCatalogPreviewDtoWithParams:
      // const payload: WHHttpParams = action.payload.params;
      return updateObject<WHMaterialPageResultDOM>(state, {
        loading: true,
        loaded: false
      } as any);


    case WHMaterialPreviewStoreActionTypes.SetWHMaterialPreview:
      return action.payload;

    default:
      return state;
  }
}
