// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHChecklistDefinitionDOM } from '@workheld/workheld-shared-lib';

export enum WHChecklistDefinitionCrudStoreActionTypes {
  GetWHChecklistDefinitionDTO = '[WHChecklistDefinitionCrudStore] GetWHChecklistDefinitionDTO',
  SetWHChecklistDefinitionDOM = '[WHChecklistDefinitionCrudStore] SetWHChecklistDefinitionDOM',
  ClearWHChecklistDefinitionCrudSTORE = '[WHChecklistDefinitionCrudStore] ClearWHChecklistDefinitionCrudSTORE',
}

export class GetWHChecklistDefinitionDTO implements Action {
  readonly type = WHChecklistDefinitionCrudStoreActionTypes.GetWHChecklistDefinitionDTO;

  constructor(public payload: WHHttpParams) {
    // console.log('GetWHChecklistDefinitionDTO ' + JSON.stringify(payload));
  }
}

export class SetWHChecklistDefinitionDOM implements Action {
  readonly type = WHChecklistDefinitionCrudStoreActionTypes.SetWHChecklistDefinitionDOM;

  constructor(public payload: WHChecklistDefinitionDOM) {
    // console.log('SetWHChecklistDefinitionDOM ' + JSON.stringify(payload));
  }
}

export class ClearWHChecklistDefinitionCrudSTORE implements Action {
  readonly type = WHChecklistDefinitionCrudStoreActionTypes.ClearWHChecklistDefinitionCrudSTORE;

  constructor() {
    // console.log('ClearWHMaterialCrudSTORE');
  }
}


export type WHChecklistDefinitionCrudStoreActions = GetWHChecklistDefinitionDTO | SetWHChecklistDefinitionDOM | ClearWHChecklistDefinitionCrudSTORE;
