// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { WHDateStaticDataRoutingModule } from './w-h-date-static-data-routing.module';
import { WHDateStaticDataComponent } from './w-h-date-static-data.component';

// MDOULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';

@NgModule({
  declarations: [
    WHDateStaticDataComponent
  ],
  exports: [
    WHDateStaticDataComponent
  ],
  imports: [
    AppSharedModule,
    WHDateStaticDataRoutingModule
  ]
})
export class WHDateStaticDataModule { }
