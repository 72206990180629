// ANGULAR
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHWorkStepDOM } from '@workheld/workheld-shared-lib';
import { WHScheduleAllWorkStepPayload } from '@workheld/workheld-shared-lib';

export enum WHWorkStepMasterDataStoreActionTypes {
  GetWHWorkStepDtoList = '[WHWorkStepMasterDataStore] GetWHWorkStepDtoList',
  GetAfterDeleteWHWorkStepDtoList = '[WHWorkStepMasterDataStore] GetAfterDeleteWHWorkStepDtoList',
  SortWorkStepListByWorkStepSortMode = '[WHWorkStepMasterDataStore] SortWorkStepListByWorkStepSortMode',
  SortWorkStepListByWorkStepIdList = '[WHWorkStepMasterDataStore] SortWorkStepListByWorkStepIdList',
  ScheduleAllWorkStepForWorkOrder = '[WHWorkStepMasterDataStore] ScheduleAllWorkStepForWorkOrder',
  SetWHWorkStepDomList = '[WHWorkStepMasterDataStore] SetWHWorkStepDomList',
}

export class GetWHWorkStepDtoList implements Action {
  readonly type = WHWorkStepMasterDataStoreActionTypes.GetWHWorkStepDtoList;

  constructor(public payload: { workOrderId: string, httpParams?: WHHttpParams }) {
    // console.log('GetWHWorkStepDtoList ' + JSON.stringify(payload));
  }
}

export class GetAfterDeleteWHWorkStepDtoList implements Action {
  readonly type = WHWorkStepMasterDataStoreActionTypes.GetAfterDeleteWHWorkStepDtoList;

  constructor(public payload: { workOrderId: string, httpParams?: WHHttpParams }) {
    // console.log('GetAfterDeleteWHWorkStepDtoList ' + JSON.stringify(payload));
  }
}

export class SortWorkStepListByWorkStepSortMode implements Action {
  readonly type = WHWorkStepMasterDataStoreActionTypes.SortWorkStepListByWorkStepSortMode;

  constructor(public payload: WHHttpParams) {
    // console.log('SortWorkStepListByWorkStepSortMode ' + JSON.stringify(payload));
  }
}

export class SortWorkStepListByWorkStepIdList implements Action {
  readonly type = WHWorkStepMasterDataStoreActionTypes.SortWorkStepListByWorkStepIdList;

  constructor(public payload: WHHttpParams) {
    // console.log('SortWorkStepListByWorkStepIdList ' + JSON.stringify(payload));
  }
}

export class SetWHWorkStepDomList implements Action {
  readonly type = WHWorkStepMasterDataStoreActionTypes.SetWHWorkStepDomList;

  // TODO MODEL
  constructor(public payload: { workStepDomList: WHWorkStepDOM[], loading: boolean, loaded: boolean }) {
    // console.log('SetWHWorkStepDomList ' + JSON.stringify(payload));
  }
}

export class ScheduleAllWorkStepForWorkOrder implements Action {
  readonly type = WHWorkStepMasterDataStoreActionTypes.ScheduleAllWorkStepForWorkOrder;

  constructor(public payload: WHScheduleAllWorkStepPayload) {
    // console.log('ScheduleAllWorkStepForWorkOrder ' + JSON.stringify(payload));
  }
}

export type WHWorkStepMasterDataStoreActions = GetWHWorkStepDtoList | GetAfterDeleteWHWorkStepDtoList | SortWorkStepListByWorkStepSortMode | SortWorkStepListByWorkStepIdList | SetWHWorkStepDomList | ScheduleAllWorkStepForWorkOrder;
