// ANGULAR
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

// SERVICES
import { DateTimeHelperService } from './date-time-helper.service';
import { noWhitespaceValidator } from '@workheld/workheld-shared-lib';

@Injectable()
export class NgFormBuilderHelperService {
  constructor(private dateTimeHelperService: DateTimeHelperService) {}

  // USED in weekly time report - TODO - remove or improve
  public initWorkItemDateFormGroup(
    startDate: string,
    endDate: string
  ): FormGroup {
    return new FormBuilder().group({
      startDate: this.dateTimeHelperService.initDateISO(startDate),
      endDate: this.dateTimeHelperService.initDateISO(endDate),
      startDateTime: [
        this.dateTimeHelperService.parseDateToTimeDigits(startDate),
        [Validators.pattern('^[0-2]?[0-9]:[0-5]?[0-9]$')],
      ],
      endDateTime: [
        this.dateTimeHelperService.parseDateToTimeDigits(endDate),
        [Validators.pattern('^[0-2]?[0-9]:[0-5]?[0-9]$')],
      ],
    }) as FormGroup;
  }

  // Schedule Form Group
  public initScheduleDateFormGroup() {
    return new FormBuilder().group({
      workOrderId: null,
      startDate: null,
      endDate: null,
      mode: 'ONLY_UNSCHEDULED',
    }) as FormGroup;
  }

  // LOGIN
  public initLoginFormGroup(): FormGroup {
    return new FormBuilder().group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
      organization: [null, [Validators.required]],
    }) as FormGroup;
  }

  // CHANGE PASSWORD
  public initChangePasswordFormGroup() {
    return new FormBuilder().group({
      oldPassword: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/
          ),
          Validators.maxLength(60),
        ],
      ],
      newPassword: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/
          ),
          Validators.maxLength(60),
        ],
      ],
      repeatPassword: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/
          ),
          Validators.maxLength(60),
        ],
      ],
    }) as FormGroup;
  }

  // LOCATION
  public initLocationFormGroup(required = false) {
    return new FormBuilder().group({
      id: null,
      name: null,
      countryCodeISO: null,
      latitude: null,
      longitude: null,
      formattedAddress: [null, required ? [Validators.required, noWhitespaceValidator] : null],
      locationSupplement: null,
      exact: null,
    }) as FormGroup;
  }
}
