// HELPERS
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHEquipmentCrudStoreActions, WHEquipmentCrudStoreActionTypes } from './w-h-equipment-crud-store.actions';

// MODELS
import { WHEquipmentDOM } from '@workheld/workheld-shared-lib';

// CONST
export const workheldEquipmentCrudStoreFeatureKey = 'workheldEquipmentCrudStore$';

export const initialState: WHEquipmentDOM = new WHEquipmentDOM();

export function workheldEquipmentCrudReducer(state = initialState, action: WHEquipmentCrudStoreActions): WHEquipmentDOM {
  switch (action.type) {

    case WHEquipmentCrudStoreActionTypes.GetWHEquipmentDTO:
      return updateObject<WHEquipmentDOM>(new WHEquipmentDOM(), { loading: true, loaded: false } as any);

    case WHEquipmentCrudStoreActionTypes.SetWHEquipmentDOM:
      return updateObject<WHEquipmentDOM>(action.payload, {
        loading: false,
        loaded: true
      } as any);

    case WHEquipmentCrudStoreActionTypes.ClearWHEquipmentCrudSTORE:
      return updateObject<WHEquipmentDOM>(new WHEquipmentDOM(), {} as any);

    default:
      return state;
  }
}
