// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHEntryFilePreviewDOM } from '@workheld/workheld-shared-lib';

export enum WHEntryFilePreviewStoreActionTypes {
  GetWHEntryFilePreviewDtoWithParams = '[WHEntryFilePreviewStore] GetWHEntryFilePreviewDtoWithParams',
  SetWHEntryFilePreview = '[WHEntryPreviewStore] SetWHEntryFilePreview',
}

export class GetWHEntryFilePreviewDtoWithParams implements Action {
  readonly type = WHEntryFilePreviewStoreActionTypes.GetWHEntryFilePreviewDtoWithParams;

  constructor(public payload: { params: WHHttpParams, targetParent: string, targetID: string; workObjectTypeId: number }) {
    // console.log('GetWHEntryFilePreviewDtoWithParams ' + JSON.stringify(payload));
  }
}

export class SetWHEntryFilePreview implements Action {
  readonly type = WHEntryFilePreviewStoreActionTypes.SetWHEntryFilePreview;

  constructor(public payload: WHEntryFilePreviewDOM) {
    // console.log('SetWHEntryFilePreview ' + JSON.stringify(payload));
  }
}

export type WHEntryFilePreviewStoreActions = GetWHEntryFilePreviewDtoWithParams | SetWHEntryFilePreview;
