// API
import { updateObject, WHWorkObjectTemplatePreviewDOM } from '@workheld/workheld-shared-lib';

// REDUX
import { WHWorkObjectTemplatePreviewStoreActions, WHWorkObjectTemplatePreviewStoreActionTypes } from './w-h-work-object-template-preview-store.actions';

export const workheldWorkObjectTemplatePreviewStoreFeatureKey = 'workheldWorkObjectTemplatePreviewStore$';

// MODELS

export const initialState: WHWorkObjectTemplatePreviewDOM = new WHWorkObjectTemplatePreviewDOM();

export function workheldWorkObjectTemplatePreviewReducer(state = initialState, action: WHWorkObjectTemplatePreviewStoreActions): WHWorkObjectTemplatePreviewDOM {
  switch (action.type) {

    case WHWorkObjectTemplatePreviewStoreActionTypes.GetWHWorkObjectTemplatePreviewDtoWithParams:
      //const payload: WHHttpParams = action.payload;
      return updateObject<WHWorkObjectTemplatePreviewDOM>(state, {
        loading: true,
        loaded: false
      } as any);

    case WHWorkObjectTemplatePreviewStoreActionTypes.SetWHWorkObjectTemplatePreview:
      return action.payload;

    default:
      return state;
  }
}
