// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { WorkObjectStaticDataRoutingModule } from './work-object-static-data-routing.module';
import { WorkObjectStaticDataComponent } from './work-object-static-data.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';
import { CustomerStaticDataModule } from '../../customer/customer-static-data/customer-static-data.module';
import { WHDateStaticDataModule } from '../../w-h-date-util/w-h-date-static-data/w-h-date-static-data.module';
import { WHAgmCoreLocationFormattedAddressModule } from '../../w-h-agm-core-util/w-h-agm-core-location-formatted-address/w-h-agm-core-location-formatted-address.module';

@NgModule({
  declarations: [
    WorkObjectStaticDataComponent
  ],
  exports: [
    WorkObjectStaticDataComponent
  ],
  imports: [
    AppSharedModule,
    CustomerStaticDataModule,
    WHDateStaticDataModule,
    WHAgmCoreLocationFormattedAddressModule,
    WorkObjectStaticDataRoutingModule
  ]
})
export class WorkObjectStaticDataModule { }
