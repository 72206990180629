// ANGULAR
import { Injectable } from '@angular/core';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHToolCrudStoreActionTypes,
  WHToolCrudStoreActions,
  GetWHToolDTO,
  SetWHToolDOM,
} from './w-h-tool-crud-store.actions';

// SERVICES
import { ToolAsyncService } from 'src/app/app-services-async/tool-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import { IWHToolDTO, WHToolDOM } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHToolCrudStoreEffects {
  getWHToolDTO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHToolCrudStoreActionTypes.GetWHToolDTO),
      switchMap((action: GetWHToolDTO) => {
        return this.toolAsyncService.getToolDtoByToolId(
          action.payload.targetID
        );
      }),
      mergeMap((toolDTO: IWHToolDTO) => {
        const toolDOM: WHToolDOM =
          this.dataModelHelperService.initToolDOM(toolDTO);
        return [new SetWHToolDOM(toolDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHToolCrudStoreActions>,
    private toolAsyncService: ToolAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
