// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { WorkOrderAsyncService } from 'src/app/app-services-async/work-order-async.service';
import { WorkerAsyncService } from 'src/app/app-services-async/worker-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHWorkerMasterDataStoreActionTypes,
  WHWorkerMasterDataStoreActions,
  GetWHWorkerDtoList,
  SetWHWorkerDomList,
  GetWHWorkerDtoListForWorkOrderAssignment,
  GetWHWorkerWorkOrderAssignment,
  SetWHWorkerWorkOrderAssignment,
} from './w-h-worker-master-data-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import { WHWorkerDOM, IWHWorkerDTO } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHWorkerMasterDataStoreEffects {
  // STATE HELPER
  private workOrderId: string;

  getWHWorkerDtoList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHWorkerMasterDataStoreActionTypes.GetWHWorkerDtoList),
      /** An EMPTY observable only emits completion. Replace with your own observable API request */
      switchMap((action: GetWHWorkerDtoList) => {
        return this.workerAsyncService.getWorkerDtoList(action.payload);
      }),
      mergeMap((workers: any[]) => {
        const workerDomList: WHWorkerDOM[] = [];
        workers.forEach((workerDTO: IWHWorkerDTO) => {
          const workerDOM: WHWorkerDOM =
            this.dataModelHelperService.initWorkerDOM(workerDTO);
          workerDomList.push(workerDOM);
        });
        // SORT WORKERS
        const workerDomListSort: WHWorkerDOM[] = workerDomList.sort(
          (a: WHWorkerDOM, b: WHWorkerDOM) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          }
        );
        return [
          new SetWHWorkerDomList({
            workerDomList: workerDomListSort,
            loading: false,
            loaded: true,
          }),
        ];
      })
    );
  });

  getWHWorkerDtoListForWorkOrderAssignment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHWorkerMasterDataStoreActionTypes.GetWHWorkerDtoListForWorkOrderAssignment
      ),
      /** An EMPTY observable only emits completion. Replace with your own observable API request */
      switchMap((action: GetWHWorkerDtoListForWorkOrderAssignment) => {
        this.workOrderId = action.payload.workOrderId;
        return this.workerAsyncService.getWorkerDtoList(
          action.payload.httpParams
        );
      }),
      mergeMap((workers: any[]) => {
        console.log(this.workOrderId);
        const workerDomList: WHWorkerDOM[] = [];
        workers.forEach((workerDTO: IWHWorkerDTO) => {
          const workerDOM: WHWorkerDOM =
            this.dataModelHelperService.initWorkerDOM(workerDTO);
          workerDomList.push(workerDOM);
        });
        return [
          new SetWHWorkerDomList({
            workerDomList,
            loading: false,
            loaded: true,
          }),
          new GetWHWorkerWorkOrderAssignment({ workOrderId: this.workOrderId }),
        ];
      })
    );
  });

  getWHWorkerWorkOrderAssignment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHWorkerMasterDataStoreActionTypes.GetWHWorkerWorkOrderAssignment),
      /** An EMPTY observable only emits completion. Replace with your own observable API request */
      switchMap((action: GetWHWorkerWorkOrderAssignment) => {
        return this.workOrderAsyncService.getWorkOrderWorkerAssignmentDtoList(
          action.payload.workOrderId,
          action.payload.httpParams
        );
      }),
      mergeMap((workers: any[]) => {
        console.log(workers);
        const workerDomList: WHWorkerDOM[] = [];
        workers.forEach((workerDTO: IWHWorkerDTO) => {
          const workerDOM: WHWorkerDOM =
            this.dataModelHelperService.initWorkerDOM(workerDTO);
          workerDomList.push(workerDOM);
        });
        return [new SetWHWorkerWorkOrderAssignment(workerDomList)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHWorkerMasterDataStoreActions>,
    private workerAsyncService: WorkerAsyncService,
    private workOrderAsyncService: WorkOrderAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
