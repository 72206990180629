// REDUX
import { Action } from '@ngrx/store';
import { WHHttpParams } from '@workheld/workheld-shared-lib';

// MODELS
import { WHEquipmentDOM } from '@workheld/workheld-shared-lib';

export enum WHEquipmentCrudStoreActionTypes {
  GetWHEquipmentDTO = '[WHEquipmentCrudStore] GetWHEquipmentDTO',
  SetWHEquipmentDOM = '[WHEquipmentCrudStore] SetWHEquipmentDOM',
  ClearWHEquipmentCrudSTORE = '[WHEquipmentCrudStore] ClearWHEquipmentCrudSTORE',
}

export class GetWHEquipmentDTO implements Action {
  readonly type = WHEquipmentCrudStoreActionTypes.GetWHEquipmentDTO;

  constructor(public payload: WHHttpParams) {
    // console.log('GetWHEquipmentDTO ' + JSON.stringify(payload));
  }
}

export class SetWHEquipmentDOM implements Action {
  readonly type = WHEquipmentCrudStoreActionTypes.SetWHEquipmentDOM;

  constructor(public payload: WHEquipmentDOM) {
    // console.log('SetWHEquipmentDOM ' + JSON.stringify(payload));
  }
}

export class ClearWHEquipmentCrudSTORE implements Action {
  readonly type = WHEquipmentCrudStoreActionTypes.ClearWHEquipmentCrudSTORE;

  constructor() {
    // console.log('ClearWHEquipmentCrudSTORE');
  }
}

export type WHEquipmentCrudStoreActions =
  GetWHEquipmentDTO
  | SetWHEquipmentDOM
  | ClearWHEquipmentCrudSTORE
  ;
