// REGISTER ALL HELPER SERVICES

// HELPER SERVICES
import { DataModelHelperService } from './data-model-helper.service';
import { DateTimeHelperService } from './date-time-helper.service';
import { NgFormBuilderHelperService } from './ng-form-builder-helper.service';
import { WHDndBehaviorSubjectHelperService } from './w-h-dnd-behavior-subject-helper.service';
import { WHRouteBehaviorSubjectService } from './w-h-route-behavior-subject.service';

export const SERVICES_HELPER: any[] = [
  DataModelHelperService,
  DateTimeHelperService,
  NgFormBuilderHelperService,
  WHDndBehaviorSubjectHelperService,
  WHRouteBehaviorSubjectService,
];
