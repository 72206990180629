// ANGULAR
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (value === 0) {
      return value;
    }

    if (!!args && (args === 'time-report')) {
      return this.formatTimeReportTime(value);
    }

    if (!!args && (args === 'time-report-simple')) {
      return this.formatTimeReportSimpleTime(value);
    }

    if (!value || (value === 0)) {
      return '-';
    }

    // set minutes to seconds
    let seconds = value * 60;

    // calculate (and subtract) whole days
    const days = Math.floor(seconds / 86400);
    seconds -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(seconds / 3600) % 24;
    seconds -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(seconds / 60) % 60;

    return (days > 0 ? days + 'd ' : '') + ((hours < 10) ? ('0' + hours + 'h ') : (hours + 'h ')) + ((minutes < 10) ? ('0' + minutes + 'm ') : (minutes + 'm '));
  }

  private formatTimeReportTime(timeInMinutes: any): string {
    if (timeInMinutes === undefined || timeInMinutes === null || timeInMinutes === 0) {
      return '-';
    }
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    // put in format hh:mm
    return (`${(hours < 10) ? ('0' + hours + 'h') : (hours + 'h')}:${(minutes < 10) ? ('0' + minutes + 'm') : (minutes + 'm')}`);
  }

  private formatTimeReportSimpleTime(timeInMinutes: any): string {
    if (timeInMinutes === undefined || timeInMinutes === null || timeInMinutes === 0) {
      return '-';
    }
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    // put in format hh:mm
    return (`${(hours < 10) ? ('0' + hours) : (hours)}:${(minutes < 10) ? ('0' + minutes) : (minutes)}`);
  }

}
