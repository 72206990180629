// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHWorkObjectTemplatePreviewStore from './w-h-work-object-template-preview-store.reducer';
import { WHWorkObjectTemplatePreviewStoreEffects } from './w-h-work-object-template-preview-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHWorkObjectTemplatePreviewStore.workheldWorkObjectTemplatePreviewStoreFeatureKey, fromWHWorkObjectTemplatePreviewStore.workheldWorkObjectTemplatePreviewReducer),
    EffectsModule.forFeature([WHWorkObjectTemplatePreviewStoreEffects])
  ]
})
export class WHWorkObjectTemplatePreviewStoreModule { }
