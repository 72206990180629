// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHWorkObjectTemplatePreviewDOM } from '@workheld/workheld-shared-lib';

export enum WHWorkObjectTemplatePreviewStoreActionTypes {
  GetWHWorkObjectTemplatePreviewDtoWithParams = '[WHWorkObjectTemplatePreviewStore] GetWHWorkObjectTemplatePreviewDtoWithParams',
  SetWHWorkObjectTemplatePreview = '[WHWorkObjectTemplatePreviewStore] SetWHWorkObjectTemplatePreview',
}

export class GetWHWorkObjectTemplatePreviewDtoWithParams implements Action {
  readonly type = WHWorkObjectTemplatePreviewStoreActionTypes.GetWHWorkObjectTemplatePreviewDtoWithParams;

  constructor(public payload: WHHttpParams) {
    // console.log('GetWHWorkObjectTemplatePreviewDtoWithParams ' + JSON.stringify(payload));
  }
}

export class SetWHWorkObjectTemplatePreview implements Action {
  readonly type = WHWorkObjectTemplatePreviewStoreActionTypes.SetWHWorkObjectTemplatePreview;

  constructor(public payload: WHWorkObjectTemplatePreviewDOM) {
    // console.log('SetWHChecklistDefinitionPreview ' + JSON.stringify(payload));
  }
}

export type WHWorkObjectTemplatePreviewStoreActions = GetWHWorkObjectTemplatePreviewDtoWithParams | SetWHWorkObjectTemplatePreview;
