import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

export interface WHChipMenuItem {
  translateKey: string;
  actionKey: string;
  disableByObjectKey?: string;
}

@Component({
  selector: 'app-w-h-chip-list',
  templateUrl: './w-h-chip-list.component.html',
  styleUrls: ['./w-h-chip-list.component.scss'],
})
export class WHChipListComponent {
  @Input() data: any[];
  @Input() navigateToKey = '';
  @Input() imageKey: string;
  @Input() placeholderImg: string;
  @Input() titleKey: string;
  @Input() badgeKey: string;
  @Input() isDisabled: boolean;
  @Input() maxHeight: string = '100%';
  @Input() isRemoveButtonVisible: boolean;
  // Give a translation key and the actionkey should be implemented in the parent component
  @Input() menuItems: WHChipMenuItem[] = [];
  @Input() tooltipKeys: string[];
  @Input() hasDropdown: boolean;
  @Input() icon: string;
  @Input() matIcon: string;
  @Input() isStacked = true;
  @Input() isLoading = false;
  @Input() showScrollBackground = false;
  @Output() handleRemoveChip: EventEmitter<any> = new EventEmitter();
  // The call back from triggering the menu item from input menuItems
  @Output() handleMenuTrigger: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) {}

  getTooltip(item: any) {
    let tooltip = '';
    if (!this.tooltipKeys || !this.tooltipKeys.length) return '';
    this.tooltipKeys.forEach((key) => {
      if (key?.length && key in item) {
        tooltip += `${item[key]} `;
      }
    });
    return tooltip;
  }

  getBadge(item: any) {
    return this.badgeKey ? item[this.badgeKey] : null;
  }

  handleNavigate(item: any) {
    if (this.navigateToKey) this.router.navigate(item[this.navigateToKey]);
  }
  onRemoveChip(event, item) {
    event.stopPropagation();
    this.handleRemoveChip.emit(item);
  }

  onMenuItemClick(payload, actionKey) {
    // This should be implemented in the parent component
    // Switch case on actionKey
    this.handleMenuTrigger.emit({ actionKey, payload });
  }

  //Disable when a key is not present in the object
  isMenuItemDisabled(item, chipMenuItem: WHChipMenuItem) {
    if (chipMenuItem.disableByObjectKey) {
      const keyValue = item[chipMenuItem.disableByObjectKey];
      return !keyValue;
    } else {
      return false;
    }
  }
}
