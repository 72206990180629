// HELPERS
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHWorkerTimeReportStoreActions, WHWorkerTimeReportStoreActionTypes } from './w-h-worker-time-report-store.actions';
import {
  WHWorkerTimeReportResultDOM
} from '@workheld/workheld-shared-lib';

export const workheldWorkerTimeReportStoreFeatureKey = 'workheldWorkerTimeReportStore$';

export const initialState: WHWorkerTimeReportResultDOM = {
  workObjectLines: [],
  unassignedLine: null,
  daySums: [],
  sum: 0,
  workingTimeSum: 0,
  activeTravelTimeSum: 0,
  passiveTravelTimeSum: 0,

  // CUSTOM
  loading: false,
  loaded: false
};

export function workheldWorkerTimeReportReducer(state = initialState, action: WHWorkerTimeReportStoreActions): WHWorkerTimeReportResultDOM {
  switch (action.type) {

    case WHWorkerTimeReportStoreActionTypes.GetWHWorkerTimeReportDTO:
      return updateObject(state, {
        workObjectLines: [],
        unassignedLine: null,
        daySums: [],
        sum: 0,
        workingTimeSum: 0,
        activeTravelTimeSum: 0,
        passiveTravelTimeSum: 0,

        // CUSTOM
        loading: true,
        loaded: false
      });

    case WHWorkerTimeReportStoreActionTypes.SetWHWorkerTimeReportDOM:
      return action.payload;

    default:
      return state;
  }
}
