<app-w-h-chip-list
  *ngIf="chipData"
  [data]="[chipData]"
  [imageKey]="'avatar'"
  [icon]="true"
  [tooltipKeys]="['extId', 'name']"
  [titleKey]="'name'"
  [isDisabled]="isDisabled"
  [isRemoveButtonVisible]="true"
  (handleRemoveChip)="unassign()"
></app-w-h-chip-list>
