import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  catchError,
  first,
  map,
  throwError,
} from 'rxjs';
import {
  WHAbsenceRequestsDataService,
  ABSENCE_REQUEST_STATUS,
  IWHFlowMetadataTranslationModel,
  PageResultAbsenceRequestDto, IWHAbsenceRequestDetailDto, IWHAbsenceApprovalPayload,
  AbsenceRequestParamsModel,
  IWHAbsenceRequestsTeamScheduleDto
} from '@workheld/workheld-shared-lib';
import { environment } from '../../environments/environment';
import { FLOW_GATEWAY } from '@workheld/workheld-shared-lib';
import { IWHSelectObject } from './hr-worker-data.service';
import { sortEnumObject } from '../app-shared/helpers/enumSort.helper';

export enum RequestResponse {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

class AbsenceRequestEntity {
  _absenceRequests: BehaviorSubject<PageResultAbsenceRequestDto>;
  _absenceRequestsTeamPlanner: BehaviorSubject<
    IWHAbsenceRequestsTeamScheduleDto[]
  >;
  absenceRequests$: Observable<any>;
  absenceRequestsTeamPlanner$: Observable<IWHAbsenceRequestsTeamScheduleDto[]>;
  _absenceRequest: BehaviorSubject<IWHAbsenceRequestDetailDto>;
  absenceRequest$: Observable<IWHAbsenceRequestDetailDto>;
  _loading: BehaviorSubject<boolean>;
  loading$: Observable<boolean>;
  constructor() {
    this._absenceRequests = new BehaviorSubject<PageResultAbsenceRequestDto>(
      null
    );
    this._absenceRequestsTeamPlanner = new BehaviorSubject<
      IWHAbsenceRequestsTeamScheduleDto[]
    >([]);
    this.absenceRequests$ = this._absenceRequests.asObservable();
    this.absenceRequestsTeamPlanner$ =
      this._absenceRequestsTeamPlanner.asObservable();
    this._absenceRequest = new BehaviorSubject<IWHAbsenceRequestDetailDto>(
      null
    );
    this.absenceRequest$ = this._absenceRequest.asObservable();
    this._loading = new BehaviorSubject<boolean>(false);
    this.loading$ = this._loading.asObservable();
  }
}

@Injectable({
  providedIn: 'root',
})
export class AbsenceRequestDataService {
  public data = new AbsenceRequestEntity();

  constructor(
    private service: WHAbsenceRequestsDataService,
  ) {}

  initParamsFormGroup(): FormGroup<any> {
    return new FormBuilder().group({
      page: [0],
      pageSize: [20],
      sort: 'status',
      sortMode: 'ASC',
      term: [null],
      onlyRelated: [null],
      withApprovals: [null],
    });
  }

  /**
   * Function to get the all absence requests
   * @param params
   */
  async getAbsenceRequests(params?: AbsenceRequestParamsModel) {
    this.data._loading.next(true);

    this.service
      .getAbsenceRequests({ apiUrl: environment.apiUrl + FLOW_GATEWAY }, params)
      .pipe(first())
      .subscribe({
        next: (data: PageResultAbsenceRequestDto) => {
          this.data._absenceRequests.next(data);
          console.log('fetch absence requests');
          console.log(data);
          this.data._loading.next(false);
        },
        error: (err) => {
          this.data._loading.next(false);
          return throwError(() => err);
        },
      });
  }

  getAbsenceRequestsForRange(
    dates: { start: string; end: string },
    params?: AbsenceRequestParamsModel
  ) {
    this.data._loading.next(true);

    this.service
      .getAbsenceRequestesForDateRange(
        {
          apiUrl: environment.apiUrl + FLOW_GATEWAY,
          start: dates.start,
          end: dates.end,
        },
        params
      )
      .pipe(
        map((data: any) => {
          this.data._absenceRequestsTeamPlanner.next(data);
          this.data._loading.next(false);
        }),
        catchError((err) => {
          return throwError(err);
        })
      )
      .subscribe();
  }

  getAbsenceRequest(id: string) {
    this.data._loading.next(true);

    console.log('fetch absence request', id);
    this.service
      .getAbsenceRequestById({
        apiUrl: environment.apiUrl + FLOW_GATEWAY,
        id: id,
      })
      .pipe(first())
      .subscribe({
        next: (data: IWHAbsenceRequestDetailDto) => {
          console.log('fetch absence request', data);
          this.data._absenceRequest.next(data);
          this.data._loading.next(false);
        },
        error: (err) => {
          this.data._loading.next(false);
          return throwError(() => err);
        },
      });
  }

  // Approve or reject absence request
  approveAbsenceRequest(
    payload: IWHAbsenceApprovalPayload,
    absenceRequest: IWHAbsenceRequestDetailDto
  ) {
    this.data._loading.next(true);

    return this.service.createAbsenceApproval(
      {
        apiUrl: environment.apiUrl + FLOW_GATEWAY,
      },
      payload
    );
  }
}

export function getAbsenceTypeArray(
  metadataTranslation: IWHFlowMetadataTranslationModel
): IWHSelectObject[] {
  const absenceTypes = Object.entries(sortEnumObject(metadataTranslation.ABSENCE_TYPE));
  const absenceTypeArray: IWHSelectObject[] = absenceTypes.map(
    (state: any[]) => {
      return {
        id: state[0],
        name: state[1],
      };
    }
  );
  absenceTypeArray.sort((a, b) => a.name.localeCompare(b.name));
  return absenceTypeArray;
}

export function extractStatusTitle(status: number): string {
  switch (status) {
    case ABSENCE_REQUEST_STATUS.Approved:
      return 'app.status.approved';
    case ABSENCE_REQUEST_STATUS.Rejected:
      return 'app.status.rejected';
    case ABSENCE_REQUEST_STATUS.Cancelled:
      return 'app.status.cancelled';
    case ABSENCE_REQUEST_STATUS.Requested:
      return 'app.status.requested';
    default:
      return '';
  }
}
