import { CommonModule } from '@angular/common';
import { Component, effect, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WorkerStaticDataModule } from '../../worker/worker-static-data/worker-static-data.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';
import { UIService } from 'src/app/app-services/ui.service';

@Component({
  selector: 'entry-header',
  standalone: true,
  providers: [UIService],
  imports: [
    CommonModule,
    TranslateModule,
    WorkerStaticDataModule,
    MatTooltipModule,
    AppSharedModule,
  ],
  template: `
    <div
      style="display: grid;
      width: 100%;
      gap: 16px;
      grid-template-columns: 24% 24% 24% 24%;"
    >
      <span>
        <div class="w-h-label entry-detail-label">
          {{ 'entry.model.description' | translate }}
        </div>
        <div class="html-overflow" matTooltip="{{ entryDOM?.title }}">
          {{ entryDOM?.title ? entryDOM?.title : '-' }}
        </div>
      </span>
      <span>
        <div class="w-h-label entry-detail-label">
          {{ 'entry.model.createddate' | translate }}
        </div>
        <div>
          {{ entryDOM?.createdDate | date: 'mediumDate' : '' : currentLocale }}
        </div>
      </span>
      <span>
        <ng-bee-worker-static-data
          [withDetailPageNav]="false"
          [nameLabel]="'entry.model.createdbyworker'"
          [withNameLabel]="true"
          [detailURL]="entryDOM?.creatorWorkerDetailURL"
        >
        </ng-bee-worker-static-data>
      </span>
      <ng-bee-worker-static-data
        *ngIf="showCreatedFor && entryDOM?.assignedEmployeeDetailURL"
        [withDetailPageNav]="false"
        [nameLabel]="'entry.model.assignedworker'"
        [withNameLabel]="true"
        [detailURL]="entryDOM?.assignedEmployeeDetailURL"
      >
      </ng-bee-worker-static-data>
    </div>
  `,
})
export class StaticHeaderStandaloneComponent {
  @Input() entryDOM: any;
  @Input() showCreatedFor: boolean;
  currentLocale: string;

  constructor(private uiService: UIService) {
    effect(() => {
      this.currentLocale = uiService.$currentLocale();
    });
  }
}
