// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHReportFilePreviewStore from './w-h-report-file-preview-store.reducer';
import { WHReportFilePreviewStoreEffects } from './w-h-report-file-preview-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHReportFilePreviewStore.workheldReportFilePreviewStoreFeatureKey, fromWHReportFilePreviewStore.workheldReportFilePreviewReducer),
    EffectsModule.forFeature([WHReportFilePreviewStoreEffects])
  ]
})
export class WHReportFilePreviewStoreModule { }
