// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHChecklistDefinitionPreviewStore from './w-h-checklist-definition-preview-store.reducer';
import { WHChecklistDefinitionPreviewStoreEffects } from './w-h-checklist-definition-preview-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHChecklistDefinitionPreviewStore.workheldChecklistDefinitionPreviewStoreFeatureKey, fromWHChecklistDefinitionPreviewStore.workheldChecklistDefinitionPreviewReducer),
    EffectsModule.forFeature([WHChecklistDefinitionPreviewStoreEffects])
  ]
})
export class WHChecklistDefinitionPreviewStoreModule { }
