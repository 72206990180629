// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHCustomerPreviewDOM } from '@workheld/workheld-shared-lib';
import { WHHttpParams } from '@workheld/workheld-shared-lib';

export enum WHCustomerPreviewStoreActionTypes {
  GetWHCustomerPreviewDtoWithParams = '[WHCustomerPreviewStore] Get WHCustomerPreviewDtoWithParams',
  SetWHCustomerPreview = '[WHCustomerPreviewStore] Set WHCustomerPreview',
}

export class GetWHCustomerPreviewDtoWithParams implements Action {
  readonly type = WHCustomerPreviewStoreActionTypes.GetWHCustomerPreviewDtoWithParams;

  constructor(public payload: WHHttpParams) {
    // console.log('GetWHCustomerPreviewDtoWithParams ' + JSON.stringify(payload));
  }
}

export class SetWHCustomerPreview implements Action {
  readonly type = WHCustomerPreviewStoreActionTypes.SetWHCustomerPreview;

  constructor(public payload: WHCustomerPreviewDOM) {
    // console.log('SetWHCustomerPreview ' + JSON.stringify(payload));
  }
}

export type WHCustomerPreviewStoreActions = GetWHCustomerPreviewDtoWithParams | SetWHCustomerPreview;

