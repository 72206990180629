// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { WHAgmCoreLocationFormattedAddressRoutingModule } from './w-h-agm-core-location-formatted-address-routing.module';
import { WHAgmCoreLocationFormattedAddressComponent } from './w-h-agm-core-location-formatted-address.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';

@NgModule({
  declarations: [
    WHAgmCoreLocationFormattedAddressComponent
  ],
  exports: [
    WHAgmCoreLocationFormattedAddressComponent
  ],
  imports: [
    AppSharedModule,
    WHAgmCoreLocationFormattedAddressRoutingModule
  ]
})
export class WHAgmCoreLocationFormattedAddressModule { }
