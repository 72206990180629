import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivateFn } from '@angular/router';
import { environment } from 'src/environments/environment';

export const DevelopmentGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
) => {
  const router = inject(Router);
  const canActivate = environment.stage === 'dev';

  if (!canActivate) {
    router.navigate(['/']);
  }
  return canActivate;
};
