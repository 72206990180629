// ANGULAR
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// APP
import { WHDatePickerRoutingModule } from './w-h-date-picker-routing.module';
import { WHDatePickerComponent } from './w-h-date-picker.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';

@NgModule({
  declarations: [
    WHDatePickerComponent
  ],
  exports: [
    WHDatePickerComponent
  ],
  imports: [
    ReactiveFormsModule,
    AppSharedModule,
    WHDatePickerRoutingModule,
    FormsModule
  ]
})
export class WHMatDatePickerModule { }
