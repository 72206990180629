// REDUX
import { ActionReducerMap, MetaReducer } from '@ngrx/store';

// API

// CUSTOMER
import { workheldCustomerCrudReducer } from './w-h-customer-stores/w-h-customer-crud-store/w-h-customer-crud-store.reducer';
import { workheldCustomerPreviewReducer } from './w-h-customer-stores/w-h-customer-preview-store/w-h-customer-preview-store.reducer';
import {
  IWHCustomerMasterDataStore,
  workheldCustomerMasterDataReducer,
} from './w-h-customer-stores/w-h-customer-master-data-store/w-h-customer-master-data-store.reducer';

// WORKER
import {
  IWHWorkerMasterDataStore,
  workheldWorkerMasterDataReducer,
} from './w-h-worker-stores/w-h-worker-master-data-store/w-h-worker-master-data-store.reducer';
// TIME REPORT
import { workheldWorkerTimeReportReducer } from './w-h-worker-stores/w-h-worker-time-report-store/w-h-worker-time-report-store.reducer';

// EQUIPMENT
import { workheldEquipmentCrudReducer } from './w-h-equipment-stores/w-h-equipment-crud-store/w-h-equipment-crud-store.reducer';
import { workheldEquipmentPreviewReducer } from './w-h-equipment-stores/w-h-equipment-preview-store/w-h-equipment-preview-store.reducer';
import {
  IWHEquipmentMasterDataStore,
  workheldEquipmentMasterDataReducer,
} from './w-h-equipment-stores/w-h-equipment-master-data-store/w-h-equipment-master-data-store.reducer';

// MATERIAL
import {
  WHWorkerTimeReportResultDOM,
  WHMaterialDOM,
  WHCustomerDOM,
  WHCustomerPreviewDOM,
  WHEquipmentPreviewDOM,
  WHToolPreviewDOM,
  WHWorkObjectPreviewDOM,
  WHWorkOrderDOM,
  WHEntryFilePreviewDOM,
  WHEntryPreviewDOM,
  WHReportFilePreviewDOM,
  WHMaterialPageResultDOM,
  WHChecklistDefinitionDOM,
  WHWorkObjectTemplateDOM,
  WHWorkObjectTemplatePreviewDOM,
  WHToolDOM,
  WHEquipmentDOM,
  WHChecklistInstancePreviewDOM,
} from '@workheld/workheld-shared-lib';
import { workheldMaterialCrudReducer } from './w-h-material-stores/w-h-material-crud-store/w-h-material-crud-store.reducer';
import { workheldMaterialPreviewReducer } from './w-h-material-stores/w-h-material-preview-store/w-h-material-preview-store.reducer';

// TOOL
import { workheldToolCrudReducer } from './w-h-tool-stores/w-h-tool-crud-store/w-h-tool-crud-store.reducer';
import { workheldToolPreviewReducer } from './w-h-tool-stores/w-h-tool-preview-store/w-h-tool-preview-store.reducer';
import {
  IWHToolMasterDataStore,
  workheldToolMasterDataReducer,
} from './w-h-tool-stores/w-h-tool-master-data-store/w-h-tool-master-data-store.reducer';

// TEMPLATE
import { workheldWorkObjectTemplatePreviewReducer } from './w-h-template-stores/w-h-work-object-template-preview-store/w-h-work-object-template-preview-store.reducer';
import { workheldWorkObjectTemplateCrudReducer } from './w-h-template-stores/w-h-work-object-template-crud-store/w-h-work-object-template-crud-store.reducer';

// C-H DEFINITION
import { workheldChecklistDefinitionCrudReducer } from './w-h-checklist-definition-stores/w-h-checklist-definition-crud-store/w-h-checklist-definition-crud-store.reducer';
import {
  IWHChecklistDefinitionPreviewStore,
  workheldChecklistDefinitionPreviewReducer,
} from './w-h-checklist-definition-stores/w-h-checklist-definition-preview-store/w-h-checklist-definition-preview-store.reducer';
import {
  IWHChecklistDefinitionMasterDataStore,
  workheldChecklistDefinitionMasterDataReducer,
} from './w-h-checklist-definition-stores/w-h-checklist-definition-master-data-store/w-h-checklist-definition-master-data-store.reducer';

// C-H INSTANCE
import { workheldChecklistInstancePreviewReducer } from './w-h-checklist-instance-stores/w-h-checklist-instance-preview-store/w-h-checklist-instance-preview-store.reducer';

// WORK OBJECT
import { workheldWorkObjectPreviewReducer } from './w-h-work-object-stores/w-h-work-object-preview-store/w-h-work-object-preview-store.reducer';
import {
  IWHWorkObjectCrudStore,
  workheldWorkObjectCrudReducer,
} from './w-h-work-object-stores/w-h-work-object-crud-store/w-h-work-object-crud-store.reducer';

// WORK ORDER
import {
  IWHWorkOrderMasterDataStore,
  workheldWorkOrderMasterDataReducer,
} from './w-h-work-order-stores/w-h-work-order-master-data-store/w-h-work-order-master-data-store.reducer';
import { workheldWorkOrderCrudReducer } from './w-h-work-order-stores/w-h-work-order-crud-store/w-h-work-order-crud-store.reducer';

// WORK ORDER FILE
import {
  workheldWorkOrderFileMasterDataReducer,
  IWHWorkOrderFileMasterDataStore,
} from './w-h-work-order-file-stores/w-h-work-order-file-master-data-store/w-h-work-order-file-master-data-store.reducer';

// WORK STEP
import {
  IWHWorkStepMasterDataStore,
  workheldWorkStepMasterDataReducer,
} from './w-h-work-step-stores/w-h-work-step-master-data-store/w-h-work-step-master-data-store.reducer';

// ENTRY
import { workheldEntryPreviewReducer } from './w-h-entry-stores/w-h-entry-preview-store/w-h-entry-preview-store.reducer';

// ENTRY FILE
import { workheldEntryFilePreviewReducer } from './w-h-entry-file-stores/w-h-entry-file-preview-store/w-h-entry-file-preview-store.reducer';

// REPORT FILE
import { workheldReportFilePreviewReducer } from './w-h-report-file-stores/w-h-report-file-preview-store/w-h-report-file-preview-store.reducer';

// DND
import {
  IWHDndAssignmentStore,
  workheldDndAssignmentReducer,
} from './w-h-dnd-assignment-store/w-h-dnd-assignment-store.reducer';

// ALL ASSIGNMENT
import {
  IWHAllAssignmentStore,
  workheldAllAssignmentReducer,
} from './w-h-all-assignment-store/w-h-all-assignment-store.reducer';

// EFFORT REPORT
import {
  IWHWorkObjectEffortReportStore,
  workheldWorkObjectEffortReportReducer,
} from './w-h-work-object-stores/w-h-work-object-effort-report/w-h-work-object-effort-report.reducer';

// MATERIAL USAGE REPORT
import {
  IWHWorkObjectMaterialUsageReportStore,
  workheldWorkObjectMaterialUsageReportReducer,
} from './w-h-work-object-stores/w-h-work-object-material-usage-report/w-h-work-object-material-usage-report.reducer';

// BRYNTUM
import {
  IWHBryEventStore,
  workheldBryEventReducer,
} from './w-h-bry-event-store/w-h-bry-event-store.reducer';
import {
  IWHBryResourceStore,
  workheldBryResourceReducer,
} from './w-h-bry-resource-store/w-h-bry-resource-store.reducer';

export interface IAppStore {
  // WH CUSTOMERS
  workheldCustomerCrudStore$: WHCustomerDOM;
  workheldCustomerMasterDataStore$: IWHCustomerMasterDataStore;
  workheldCustomerPreviewStore$: WHCustomerPreviewDOM;

  // WH WORKER
  workheldWorkerMasterDataStore$: IWHWorkerMasterDataStore;
  workheldWorkerTimeReportStore$: WHWorkerTimeReportResultDOM;

  // WH EQUIPMENT
  workheldEquipmentPreviewStore$: WHEquipmentPreviewDOM;
  workheldEquipmentMasterDataStore$: IWHEquipmentMasterDataStore;
  workheldEquipmentCrudStore$: WHEquipmentDOM;

  // MATERIAL
  workheldMaterialCrudStore$: WHMaterialDOM;
  workheldMaterialPreviewStore$: WHMaterialPageResultDOM;

  // TOOL
  workheldToolCrudStore$: WHToolDOM;
  workheldToolMasterDataStore$: IWHToolMasterDataStore;
  workheldToolPreviewStore$: WHToolPreviewDOM;

  // C-H DEFINITION
  workheldChecklistDefinitionCrudStore$: WHChecklistDefinitionDOM;
  workheldChecklistDefinitionMasterDataStore$: IWHChecklistDefinitionMasterDataStore;
  workheldChecklistDefinitionPreviewStore$: IWHChecklistDefinitionPreviewStore;

  // C-H INSTANCE
  workheldChecklistInstancePreviewStore$: WHChecklistInstancePreviewDOM;

  // TEMPLATE
  workheldWorkObjectTemplateCrudStore$: WHWorkObjectTemplateDOM;
  workheldWorkObjectTemplatePreviewStore$: WHWorkObjectTemplatePreviewDOM;

  // WORK OBJECT
  workheldWorkObjectPreviewStore$: WHWorkObjectPreviewDOM;
  workheldWorkObjectCrudStore$: IWHWorkObjectCrudStore;
  workheldWorkObjectEffortReportStore$: IWHWorkObjectEffortReportStore;
  workheldWorkObjectMaterialUsageReportStore$: IWHWorkObjectMaterialUsageReportStore;

  // WORK ORDER
  workheldWorkOrderMasterDataStore$: IWHWorkOrderMasterDataStore;
  workheldWorkOrderCrudStore$: WHWorkOrderDOM;

  // ALL ASSIGNMENT
  workheldAllAssignmentStore$: IWHAllAssignmentStore;

  // WORK ORDER FILE
  workheldWorkOrderFileMasterDataStore$: IWHWorkOrderFileMasterDataStore;

  // WORK STEP
  workheldWorkStepMasterDataStore$: IWHWorkStepMasterDataStore;

  // ENTRY
  workheldEntryPreviewStore$: WHEntryPreviewDOM;

  // ENTRY FILE
  workheldEntryFilePreviewStore$: WHEntryFilePreviewDOM;

  // REPORT FILE
  workheldReportFilePreviewStore$: WHReportFilePreviewDOM;

  // DND
  workheldDndAssignmentStore$: IWHDndAssignmentStore;

  // BRYNTUM
  workheldBryResourceStore$: IWHBryResourceStore;
  workheldBryEventStore$: IWHBryEventStore;
  // workheldWorkStepBrySchStore$: IWHWorkStepBrySchStore;
}

export const reducers: ActionReducerMap<IAppStore> = {
  // WH CUSTOMERS
  workheldCustomerCrudStore$: workheldCustomerCrudReducer,
  workheldCustomerMasterDataStore$: workheldCustomerMasterDataReducer,
  workheldCustomerPreviewStore$: workheldCustomerPreviewReducer,

  // WH WORKER
  workheldWorkerMasterDataStore$: workheldWorkerMasterDataReducer,
  workheldWorkerTimeReportStore$: workheldWorkerTimeReportReducer,

  // EQUIPMENT
  workheldEquipmentPreviewStore$: workheldEquipmentPreviewReducer,
  workheldEquipmentMasterDataStore$: workheldEquipmentMasterDataReducer,
  workheldEquipmentCrudStore$: workheldEquipmentCrudReducer,

  // MATERIAL
  workheldMaterialCrudStore$: workheldMaterialCrudReducer,
  workheldMaterialPreviewStore$: workheldMaterialPreviewReducer,

  // TOOL
  workheldToolCrudStore$: workheldToolCrudReducer,
  workheldToolMasterDataStore$: workheldToolMasterDataReducer,
  workheldToolPreviewStore$: workheldToolPreviewReducer,

  // C-H DEFINITION
  workheldChecklistDefinitionCrudStore$: workheldChecklistDefinitionCrudReducer,
  workheldChecklistDefinitionPreviewStore$:
    workheldChecklistDefinitionPreviewReducer,
  workheldChecklistDefinitionMasterDataStore$:
    workheldChecklistDefinitionMasterDataReducer,

  // C-H INSTANCE
  workheldChecklistInstancePreviewStore$:
    workheldChecklistInstancePreviewReducer,

  // TEMPLATE
  workheldWorkObjectTemplateCrudStore$: workheldWorkObjectTemplateCrudReducer,
  workheldWorkObjectTemplatePreviewStore$:
    workheldWorkObjectTemplatePreviewReducer,

  // WORK OBJECT
  workheldWorkObjectPreviewStore$: workheldWorkObjectPreviewReducer,
  workheldWorkObjectCrudStore$: workheldWorkObjectCrudReducer,
  workheldWorkObjectEffortReportStore$: workheldWorkObjectEffortReportReducer,
  workheldWorkObjectMaterialUsageReportStore$:
    workheldWorkObjectMaterialUsageReportReducer,

  // WORK ORDER
  workheldWorkOrderMasterDataStore$: workheldWorkOrderMasterDataReducer,
  workheldWorkOrderCrudStore$: workheldWorkOrderCrudReducer,

  // ALL ASSIGNMENT
  workheldAllAssignmentStore$: workheldAllAssignmentReducer,

  // WORK ORDER FILE
  workheldWorkOrderFileMasterDataStore$: workheldWorkOrderFileMasterDataReducer,

  // WORK STEP
  workheldWorkStepMasterDataStore$: workheldWorkStepMasterDataReducer,

  // ENTRY
  workheldEntryPreviewStore$: workheldEntryPreviewReducer,

  // ENTRY FILE
  workheldEntryFilePreviewStore$: workheldEntryFilePreviewReducer,

  // REPORT FILE
  workheldReportFilePreviewStore$: workheldReportFilePreviewReducer,

  // DND
  workheldDndAssignmentStore$: workheldDndAssignmentReducer,

  // BRYNTUM
  workheldBryResourceStore$: workheldBryResourceReducer,
  workheldBryEventStore$: workheldBryEventReducer,
  // workheldWorkStepBrySchStore$: workheldWorkStepBrySchReducer
};

export const metaReducers: MetaReducer<IAppStore>[] = [];
