// ANGULAR
import { Injectable } from '@angular/core';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, mergeMap } from 'rxjs/operators';
import * as WHWorkObjectEffortReportActions from './w-h-work-object-effort-report.actions';

// API
import {
  WHWorkObjectDataService,
  IWHEffortReportDTO,
  IWHWorkObjectEndpointConfigModel,
  FLOW_GATEWAY,
} from '@workheld/workheld-shared-lib';

// RxJS 6
import { environment } from 'src/environments/environment';

@Injectable()
export class WHWorkObjectEffortReportEffects {
  getWHWorkObjectEffortReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHWorkObjectEffortReportActions.WHWorkObjectEffortReportStoreActionTypes
          .GetWHWorkObjectEffortReportDTO
      ),
      switchMap(
        (
          action: WHWorkObjectEffortReportActions.GetWHWorkObjectEffortReportDTO
        ) => {
          const endpointConfig: IWHWorkObjectEndpointConfigModel = {
            apiUrl: environment.apiUrl + FLOW_GATEWAY,
            startDate: action.payload.startDate,
            endDate: action.payload.endDate,
          };
          return this.workObjectAsyncService.getEffortReport(endpointConfig);
        }
      ),
      mergeMap((effortReportDTO: IWHEffortReportDTO) => {
        return [
          new WHWorkObjectEffortReportActions.SetWHWorkObjectEffortReportDOM({
            effortReportDTO,
          }),
        ];
      })
    );
  });

  constructor(
    private actions$: Actions,
    private workObjectAsyncService: WHWorkObjectDataService
  ) {}
}
