// ANGULAR
import { NgModule } from '@angular/core';

// ANGULAR
import { WHSearchTermUtilRoutingModule } from './w-h-search-term-util-routing.module';
import { WHSearchTermUtilComponent } from './w-h-search-term-util.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';

@NgModule({
  declarations: [
    WHSearchTermUtilComponent
  ],
  exports: [
    WHSearchTermUtilComponent
  ],
  imports: [
    AppSharedModule,
    WHSearchTermUtilRoutingModule
  ]
})
export class WHSearchTermUtilModule { }
