// HELPER
import { updateObject, WHEntryPreviewDOM } from '@workheld/workheld-shared-lib';

// REDUX
import { WHEntryPreviewStoreActions, WHEntryPreviewStoreActionTypes } from './w-h-entry-preview-store.actions';

// MODELS

export const workheldEntryPreviewStoreFeatureKey = 'workheldEntryPreviewStore$';

export const initialState: WHEntryPreviewDOM = new WHEntryPreviewDOM();

export function workheldEntryPreviewReducer(state = initialState, action: WHEntryPreviewStoreActions): WHEntryPreviewDOM {
  switch (action.type) {

    case WHEntryPreviewStoreActionTypes.GetWHEntryPreviewDtoWithParams:
      //const payload: WHHttpParams = action.payload;
      return updateObject<WHEntryPreviewDOM>(state, {
        loading: true,
        loaded: false
      } as any);

    case WHEntryPreviewStoreActionTypes.SetWHEntryPreview:
      return action.payload;

    default:
      return state;
  }
}
