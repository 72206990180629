<div class="work-step-scheduler-mat-dialog">
    <div class="detail-card-header">
        <span whFlex class="w-h-header-icon-span">
            <i mat-card-avatar class="workheld-icons header-icon" [innerHtml]="calendarIcon"></i>
        </span>
        <span whFlex class="w-h-header-span">
            <div class="single-header">
                {{ 'workorder.ui.scheduleinbulk.header.label' | translate }}
            </div>
        </span>
    </div>
    <div class="work-step-scheduler-mat-dialog-body">
        <p>
            {{ 'workorder.ui.scheduleinbulk.helpertext' | translate }}
        </p>
        <div class="default-max-width">
            <lib-w-h-datetime-picker
                [parentFormGroup]="tempFormGroup"
                startDateForm="dateSlotForm"
                startTimeForm="timeSlotForm"
                endDateForm="enddateSlotForm"
                endTimeForm="endtimeSlotForm"
                [hasEndDate]="true"
                [futureDate]="true"
                [hasTimer]="true"
                [startDateLabel]="'workstep.model.startdate'"
                [endDateLabel]="'workstep.model.enddate'"
                (onResetDate)="resetDateField($event)"
            ></lib-w-h-datetime-picker>
        </div>
        <div class="m-t--12">
            <mat-slide-toggle
                #slideToggle
                [checked]="true"
                (change)="toggleScheduleMode($event)"
                color="accent">
                {{ 'workorder.ui.scheduleinbulk.applytounscheduled.label' | translate }}
            </mat-slide-toggle>
        </div>
    </div>
    <div class="dialog-action-wrapper" mat-dialog-actions>
        
        <button
            class="w-h-default-button dialog-button mat-elevation-z2"
            mat-flat-button
            type="button"
            tabindex="-1"
            (click)="closeDialog()">
            <i class="workheld-icons" [innerHTML]="cancelIcon"></i> 
            {{ ('app.cancel.label') | translate | uppercase }}
        </button>
        <button
            color="accent"
            class="dialog-button mat-elevation-z2"
            mat-flat-button
            type="button"
            [disabled]="!dateSet"
            tabindex="-1"
            (click)="handleSubmit()">
            <i class="workheld-icons" [innerHTML]="saveIcon"></i> 
            <span>
                {{ ('app.save.label') | translate | uppercase }}
            </span>
        </button>
    </div>
</div>
