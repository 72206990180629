// ENVIRONMENT
import { environment } from '../../environments/environment';

// ANGULAR
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS 6
import { Observable } from 'rxjs';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { getWorkObjectIconByWorkObjectTypeId, IWHWorkerDTO, WHIconENUM } from '@workheld/workheld-shared-lib';
import { IWHStatusOverviewSearchDTO, IWHSearchResultDTO } from '@workheld/workheld-shared-lib';
import { FLOW_GATEWAY, IWHCustomerDTO } from '@workheld/workheld-shared-lib';
import { IWHWorkObjectDTO } from '@workheld/workheld-shared-lib';

@Injectable()
export class SearchAsyncService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  public searchStatusOverview(params: WHHttpParams): Observable<IWHStatusOverviewSearchDTO> {
    const httpParams: HttpParams = new HttpParams()
      .set('searchTerm', params.term);
    return this.httpClient.get<IWHStatusOverviewSearchDTO>(`${environment.apiUrl + FLOW_GATEWAY}/search/statusOverview`, { params: httpParams });
  }

  public processStatusOverviewSearchResult(statusOverviewSearchDTO: IWHStatusOverviewSearchDTO): IWHSearchResultDTO[] {
    const statusOverviewSearchResults: IWHSearchResultDTO[] = [];

    // WORKERS
    statusOverviewSearchDTO.workers.forEach((workerDTO: IWHWorkerDTO) => {
      statusOverviewSearchResults.push({
        id: workerDTO.id,
        extId: workerDTO.numberExt,
        name: workerDTO.name,
        icon: WHIconENUM.UserIcon as string,
        resultType: 'WORKER'
      });
    });

    // CUSTOMERS
    statusOverviewSearchDTO.customers.forEach((customerDTO: IWHCustomerDTO) => {
      statusOverviewSearchResults.push({
        id: customerDTO.id,
        extId: customerDTO.numberExt,
        name: customerDTO.name,
        icon: WHIconENUM.CustomerIcon as string,
        resultType: 'CUSTOMER'
      });
    });

    statusOverviewSearchDTO.workObjects.forEach((workObjectDTO: IWHWorkObjectDTO) => {
      statusOverviewSearchResults.push({
        id: workObjectDTO.id,
        extId: workObjectDTO.numberExt,
        name: workObjectDTO.title,
        icon: getWorkObjectIconByWorkObjectTypeId(this.initWorkObjectTypeId((workObjectDTO.workObjectType as any))),
        resultType: 'WORKOBJECT',
        workObjectTypeId: this.initWorkObjectTypeId((workObjectDTO.workObjectType as any))
      });
    });

    return statusOverviewSearchResults;
  }

  private initWorkObjectTypeId(workObjectType: string) {
    switch (workObjectType) {
      case 'PROJECT':
        return 1;
      case 'CASE':
        return 2;
      case 'STANDING_ORDER':
        return 3;
      default:
        return undefined;
    }
  }

}
