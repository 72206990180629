// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHFileDOM } from '@workheld/workheld-shared-lib';

export enum WHWorkOrderFileMasterDataStoreActionTypes {
  GetWHWorkOrderFileDtoList = '[WHWorkOrderFileMasterDataStore] GetWHWorkOrderFileDtoList',
  SetWHWorkOrderFileDomList = '[WHWorkOrderFileMasterDataStore] SetWHWorkOrderFileDomList',
}

export class GetWHWorkOrderFileDtoList implements Action {
  readonly type = WHWorkOrderFileMasterDataStoreActionTypes.GetWHWorkOrderFileDtoList;

  constructor(public payload: { workOrderId: string }) {
    // console.log('GetWHWorkOrderFileDtoList ' + JSON.stringify(payload));
  }
}

export class SetWHWorkOrderFileDomList implements Action {
  readonly type = WHWorkOrderFileMasterDataStoreActionTypes.SetWHWorkOrderFileDomList;

  constructor(public payload: { workOrderFileDomList: WHFileDOM[], loading: boolean, loaded: boolean }) {
    // console.log('SetWHWorkOrderFileDomList ' + JSON.stringify(payload));
  }
}


export type WHWorkOrderFileMasterDataStoreActions = GetWHWorkOrderFileDtoList | SetWHWorkOrderFileDomList;
