import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UIService } from 'src/app/app-services/ui.service';

@Component({
  selector: 'w-h-back-button',
  templateUrl: './w-h-back-button.component.html',
  styleUrl: './w-h-back-button.component.scss',
})
export class WHBackButtonComponent implements OnInit {
  @Input() public labelTranslateKey: string = 'app.back.label';
  @Input() public hideShowLabel: boolean = false;
  @Output() public click: EventEmitter<any> = new EventEmitter();

  isLargeScreen$: any;

  constructor(private uiService: UIService) {}

  ngOnInit(): void {
    this.isLargeScreen$ = this.uiService.isLargeScreen$;
  }

  onBackButtonClick() {
    this.click.emit();
  }
}
