import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WHDepartmentDOM } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'app-department-list',
  templateUrl: './department-list.component.html',
  styleUrls: ['./department-list.component.scss'],
})
export class DepartmentListComponent {
  @Output() departmentSelected = new EventEmitter<WHDepartmentDOM>();
  @Output() departmentRemoved = new EventEmitter<boolean>();
  @Input() canRemoveFilter = false;
  @Input('selectedDepartment') set _selectedDepartment(
    department: WHDepartmentDOM
  ) {
    this.selectedDepartmentDOM = department;
  }
  @Input('departmentList') set _departmentList(list: WHDepartmentDOM[]) {
    this.filteredDepartmentList = list;
    this.departmentList = list;
  }
  selectedDepartmentDOM = null;
  departmentList: WHDepartmentDOM[] = [];
  filteredDepartmentList: WHDepartmentDOM[] = [];
  onSelect(department: WHDepartmentDOM) {
    this.selectedDepartmentDOM = department;
    this.departmentSelected.emit(department);
  }

  onRemove() {
    this.departmentRemoved.emit(true);
    this.selectedDepartmentDOM = null;
  }
  onSearchTerm(term: string) {
    if (term) {
      this.filteredDepartmentList = this.departmentList.filter((department) => {
        return department.name.toLowerCase().includes(term.toLowerCase());
      });
    } else {
      this.filteredDepartmentList = this.departmentList;
    }
  }
}
