// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { WorkOrderStaticDataRoutingModule } from './work-order-static-data-routing.module';
import { WorkOrderStaticDataComponent } from './work-order-static-data.component';
import { Type1WorkOrderDefaultStaticDataComponent } from './type1-work-order-default-static-data/type1-work-order-default-static-data.component';
import { Type2WorkOrderIncidentStaticDataComponent } from './type2-work-order-incident-static-data/type2-work-order-incident-static-data.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';
import { CustomerStaticDataModule } from '../../customer/customer-static-data/customer-static-data.module';
import { WorkObjectStaticDataModule } from '../../work-object/work-object-static-data/work-object-static-data.module';
import { WHDateStaticDataModule } from '../../w-h-date-util/w-h-date-static-data/w-h-date-static-data.module';
import { WHAgmCoreLocationFormattedAddressModule } from '../../w-h-agm-core-util/w-h-agm-core-location-formatted-address/w-h-agm-core-location-formatted-address.module';

@NgModule({
  declarations: [
    WorkOrderStaticDataComponent,
    Type1WorkOrderDefaultStaticDataComponent,
    Type2WorkOrderIncidentStaticDataComponent
  ],
  exports: [
    WorkOrderStaticDataComponent,
    Type1WorkOrderDefaultStaticDataComponent,
    Type2WorkOrderIncidentStaticDataComponent
  ],
  imports: [
    AppSharedModule,
    CustomerStaticDataModule,
    WorkObjectStaticDataModule,
    WHAgmCoreLocationFormattedAddressModule,
    WHDateStaticDataModule,
    WorkOrderStaticDataRoutingModule
  ]
})
export class WorkOrderStaticDataModule { }
