// REDUX
import { Action } from '@ngrx/store';

// MODEL
import { WHBryResourceDOM } from '@workheld/workheld-shared-lib';

export enum WHBryResourceStoreActionTypes {
  GetWHBryResourceDTOList = '[WHBryResourceStore] GetWHBryResourceDTOList',
  SetWHBryResourceDOMList = '[WHBryResourceStore] SetWHBryResourceDOMList'
}

export class GetWHBryResourceDTOList implements Action {
  readonly type = WHBryResourceStoreActionTypes.GetWHBryResourceDTOList;

  constructor(
    public payload?: { params?: any }
  ) {
    // console.log('GetWHBryResourceDTOList ');
  }
}

export class SetWHBryResourceDOMList implements Action {
  readonly type = WHBryResourceStoreActionTypes.SetWHBryResourceDOMList;

  constructor(public payload: { resources: WHBryResourceDOM[], loading: boolean, loaded: boolean }) {
    // console.log('SetWHBryResourceDOMList ');
  }
}

export type WHBryResourceStoreActions = GetWHBryResourceDTOList | SetWHBryResourceDOMList;
