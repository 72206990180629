// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHAllAssignmentStore from './w-h-all-assignment-store.reducer';
import { WHAllAssignmentStoreEffects } from './w-h-all-assignment-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHAllAssignmentStore.workheldAllAssignmentStoreFeatureKey, fromWHAllAssignmentStore.workheldAllAssignmentReducer),
    EffectsModule.forFeature([WHAllAssignmentStoreEffects])
  ]
})
export class WHAllAssignmentStoreModule { }
