// REDUX
import { WHChecklistDefinitionMasterDataStoreActions, WHChecklistDefinitionMasterDataStoreActionTypes } from './w-h-checklist-definition-master-data-store.actions';

// CONST
export const workheldChecklistDefinitionMasterDataStoreFeatureKey = 'workheldChecklistDefinitionMasterDataStore$';

// MODELS
import { WHChecklistDefinitionDOM } from '@workheld/workheld-shared-lib';

export interface IWHChecklistDefinitionMasterDataStore {
  checklistDefinitionDomList: WHChecklistDefinitionDOM[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHChecklistDefinitionMasterDataStore = {
  checklistDefinitionDomList: [],
  loading: false,
  loaded: false,
};

export function workheldChecklistDefinitionMasterDataReducer(state = initialState, action: WHChecklistDefinitionMasterDataStoreActions): IWHChecklistDefinitionMasterDataStore {
  switch (action.type) {

    case WHChecklistDefinitionMasterDataStoreActionTypes.GetWHChecklistDefinitionDtoList:
      return {
        checklistDefinitionDomList: [],
        loading: true,
        loaded: false,
      };

    case WHChecklistDefinitionMasterDataStoreActionTypes.SetWHChecklistDefinitionDomList:
      return action.payload;

    default:
      return state;
  }
}
