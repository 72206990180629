// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR
import { Injectable } from '@angular/core';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHBryResourceStoreActionTypes,
  WHBryResourceStoreActions,
  GetWHBryResourceDTOList,
  SetWHBryResourceDOMList,
} from './w-h-bry-resource-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import {
  FLOW_GATEWAY,
  IWHWorkerEndpointConfigModel,
  WHBryResourceDOM,
  WHWorkerDataService,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WHBryResourceStoreEffects {
  getWHBryResourceDtoList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHBryResourceStoreActionTypes.GetWHBryResourceDTOList),
      switchMap((action: GetWHBryResourceDTOList) => {
        const endpointConfig: IWHWorkerEndpointConfigModel = {
          apiUrl: environment.apiUrl + FLOW_GATEWAY,
        };
        return this.workerDataService.getTeamScheduleWorkerDtoList(
          endpointConfig,
          action.payload.params
        );
      }),
      mergeMap((resources: WHBryResourceDOM[]) => {
        return [
          new SetWHBryResourceDOMList({
            resources,
            loading: false,
            loaded: true,
          }),
        ];
      })
    );
  });

  constructor(
    private actions$: Actions<WHBryResourceStoreActions>,
    private workerDataService: WHWorkerDataService
  ) {}
}
