import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Directive({
  selector: '[whFlex]',
})
export class FxFlexDirective implements AfterViewInit {
  // this directive is mainly created because whFlex lib is deprecated
  // DO NOT USE IN FURTHER DEVELOPMENT
  @Input('whFlex') set flexValue(value: string) {
    if (!value) {
      this.flex = '1 1 100%';
    } else if (value.includes(' ') && !value.includes('calc')) {
      const splitValues = value.split(' ');
      this.flex = splitValues[0] + ' ' + splitValues[1] + ' ' + splitValues[2];
    } else {
      if (
        !value.includes('px') &&
        !value.includes('%') &&
        !value.includes('calc')
      ) {
        value = value + '%';
      }
      this.flex = value;
    }
  }
  flex: string;
  @HostBinding('style') style: SafeStyle;
  constructor(private sanitizer: DomSanitizer, private elRef: ElementRef) {}
  ngAfterViewInit(): void {
    this.elRef.nativeElement.parentElement.style.display = 'flex';
    this.style = this.sanitizer.bypassSecurityTrustStyle(
      'flex: ' + this.flex + ';'
    );
  }
}
