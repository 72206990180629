// HELPERS
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHMaterialCrudStoreActions, WHMaterialCrudStoreActionTypes } from './w-h-material-crud-store.actions';

// MODELS
import { WHMaterialDOM } from '@workheld/workheld-shared-lib';

export const workheldMaterialCrudStoreFeatureKey = 'workheldMaterialCrudStore$';

export const initialState: WHMaterialDOM = new WHMaterialDOM();

export function workheldMaterialCrudReducer(state = initialState, action: WHMaterialCrudStoreActions): WHMaterialDOM {
  switch (action.type) {

    case WHMaterialCrudStoreActionTypes.ClearWHMaterialCrudSTORE:
      return updateObject(new WHMaterialDOM(), { loading: false, loaded: false } as any);
    case WHMaterialCrudStoreActionTypes.GetWHMaterialDTO:
      return updateObject(new WHMaterialDOM(), { loading: true, loaded: false } as any);

    case WHMaterialCrudStoreActionTypes.SetWHMaterialDOM:
      return updateObject(action.payload, {
        loading: false,
        loaded: true,
      } as any);

    default:
      return state;
  }
}
