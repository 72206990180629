// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { ChecklistDefinitionAsyncService } from 'src/app/app-services-async/checklist-definition-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHChecklistDefinitionCrudStoreActionTypes,
  WHChecklistDefinitionCrudStoreActions,
  GetWHChecklistDefinitionDTO,
  SetWHChecklistDefinitionDOM,
} from './w-h-checklist-definition-crud-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import {
  WHChecklistDefinitionDOM,
  IWHChecklistDefinitionDTO,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WHChecklistDefinitionCrudStoreEffects {
  getWHChecklistDefinitionDTO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHChecklistDefinitionCrudStoreActionTypes.GetWHChecklistDefinitionDTO
      ),
      switchMap((action: GetWHChecklistDefinitionDTO) => {
        return this.checklistDefinitionAsyncService.getChecklistDefinitionDtoByChecklistDefinitionId(
          action.payload.targetID
        );
      }),
      mergeMap((checklistDefinitionDTO: IWHChecklistDefinitionDTO) => {
        const checklistDefinitionDOM: WHChecklistDefinitionDOM =
          this.dataModelHelperService.initChecklistDefinitionDOM(
            checklistDefinitionDTO
          );
        return [new SetWHChecklistDefinitionDOM(checklistDefinitionDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHChecklistDefinitionCrudStoreActions>,
    private checklistDefinitionAsyncService: ChecklistDefinitionAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
