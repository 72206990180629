// ENVIRONMENT
import { environment } from '../../environments/environment';

// ANGULAR
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS 6
import { Observable } from 'rxjs';

// MODELS
import { initHttpParams, WHHttpParams } from '@workheld/workheld-shared-lib';
import { IWHWorkerDTO } from '@workheld/workheld-shared-lib';
import { IWHMaterialInventoryDTO } from '@workheld/workheld-shared-lib';
import { FLOW_GATEWAY } from '@workheld/workheld-shared-lib';

@Injectable()
export class WorkerAsyncService {

  constructor(
    private httpClient: HttpClient
  ) { }

  // GET ALL
  public getWorkerDtoList(params?: WHHttpParams): Observable<IWHWorkerDTO[]> {
    const httpParams: HttpParams = initHttpParams(params);
    return this.httpClient.get<IWHWorkerDTO[]>(`${environment.apiUrl + FLOW_GATEWAY}/workers`, { params: httpParams });
  }

  // TIME REPORT
  public getWorkerWorkObjectWeeklyTimeReportCSV(workerId: string, startDate: string, endDate: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(`${environment.apiUrl + FLOW_GATEWAY}/workers/${workerId}/timereportcsv/${startDate}/${endDate}`, {
      // headers: { 'Content-Type': 'application/csv' },
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  // MATERIAL INVENTORY
  public getWorkerMaterialInventory(workerId: string): Observable<IWHMaterialInventoryDTO> {
    return this.httpClient.get<IWHMaterialInventoryDTO>(`${environment.apiUrl + FLOW_GATEWAY}/workers/${workerId}/inventory`);
  }

}
