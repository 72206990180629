<div *ngIf="isSimple">
  <div *ngIf="withNameLabel" class="w-h-label html-overflow">
    {{ nameLabel | translate }}
  </div>
  <div class="html-overflow default-value">
    {{ workerDOM?.name }}
  </div>
</div>

<div *ngIf="!isSimple && !withDetailPageNav && !isAvatar">
  <div *ngIf="withNameLabel" class="w-h-label html-overflow">
    {{ nameLabel | translate }}
  </div>
  <div class="worker-chip">
    <div>
      <app-w-h-chip-list
        *ngIf="workerDOM"
        [data]="[workerDOM]"
        [imageKey]="'smallerAvatarURL'"
        [tooltipKeys]="[
          workerDOM?.extId ? 'extId' : '',
          workerDOM?.name ? 'name' : ''
        ]"
        [isRemoveButtonVisible]="isRemoveable"
        [titleKey]="'name'"
        [isStacked]="false"
        [placeholderImg]="personImgJPG"
        (handleRemoveChip)="removeChip()"
      ></app-w-h-chip-list>
    </div>
  </div>
</div>
<div *ngIf="!isSimple && withDetailPageNav && !isAvatar">
  <div *ngIf="withNameLabel" class="w-h-label html-overflow">
    {{ nameLabel | translate | uppercase }}
  </div>
  <div class="worker-chip">
    <div>
      <app-w-h-chip-list
        *ngIf="workerDOM"
        [data]="[workerDOM]"
        [imageKey]="'smallerAvatarURL'"
        [navigateToKey]="'workerDetailRouterLink'"
        [tooltipKeys]="[
          workerDOM?.extId ? 'extId' : '',
          workerDOM?.name ? 'name' : ''
        ]"
        [isRemoveButtonVisible]="isRemoveable"
        [titleKey]="'name'"
        [placeholderImg]="personImgJPG"
        (handleRemoveChip)="removeChip()"
      ></app-w-h-chip-list>
    </div>
  </div>
</div>

<span
  *ngIf="!isSimple && !withDetailPageNav && isAvatar"
  style="display: flex; align-self: center"
>
  <img
    *ngIf="
      workerDOM?.smallerAvatarURL &&
        (workerDOM?.smallerAvatarURL
          | WHImgAuthHeader : personImgJPG
          | async) as image;
      else loading
    "
    alt="logoSmall"
    [src]="image"
    mat-card-avatar
    class="small-avatar"
    matTooltip="{{
      (workerDOM?.extId ? workerDOM?.extId : '') + ' ' + workerDOM?.name
    }}"
  />
  <ng-template #loading>
    <!-- Loading... -->
    <img
      [src]="personImgJPG"
      mat-card-avatar
      alt="personImg"
      class="small-avatar"
      matTooltip="{{
        (workerDOM?.extId ? workerDOM?.extId : '') + ' ' + workerDOM?.name
      }}"
    />
  </ng-template>
</span>
