// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHBryResourceStore from './w-h-bry-resource-store.reducer';
import { WHBryResourceStoreEffects } from './w-h-bry-resource-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHBryResourceStore.workheldBryResourceStoreFeatureKey, fromWHBryResourceStore.workheldBryResourceReducer),
    EffectsModule.forFeature([WHBryResourceStoreEffects])
  ]
})
export class WHBryResourceStoreModule { }
