// ANGULAR
import {Action} from '@ngrx/store';

// MODELS
import {WHHttpParams} from '@workheld/workheld-shared-lib';
import {WHMaterialPageResultDOM} from '@workheld/workheld-shared-lib';

export enum WHMaterialPreviewStoreActionTypes {
  GetWHMaterialPreviewDtoWithParams = '[WHMaterialPreviewStore] GetWHMaterialPreviewDtoWithParams',
  GetWHMaterialCatalogPreviewDtoWithParams = '[WHMaterialPreviewStore] GetWHMaterialCatalogPreviewDtoWithParams',
  SetWHMaterialPreview = '[WHMaterialPreviewStore] SetWHMaterialPreview',
}

export class GetWHMaterialPreviewDtoWithParams implements Action {
  readonly type = WHMaterialPreviewStoreActionTypes.GetWHMaterialPreviewDtoWithParams;

  constructor(public payload: WHHttpParams) {
  }
}

export class GetWHMaterialCatalogPreviewDtoWithParams implements Action {
  readonly type = WHMaterialPreviewStoreActionTypes.GetWHMaterialCatalogPreviewDtoWithParams;

  constructor(public payload: { workStepId: string, params: WHHttpParams }) {
  }
}

export class SetWHMaterialPreview implements Action {
  readonly type = WHMaterialPreviewStoreActionTypes.SetWHMaterialPreview;

  constructor(public payload: WHMaterialPageResultDOM) {
  }
}


export type WHMaterialPreviewStoreActions =
  GetWHMaterialPreviewDtoWithParams
  | GetWHMaterialCatalogPreviewDtoWithParams
  | SetWHMaterialPreview;
