// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHWorkOrderFileMasterDataStore from './w-h-work-order-file-master-data-store.reducer';
import { WHWorkOrderFileMasterDataStoreEffects } from './w-h-work-order-file-master-data-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHWorkOrderFileMasterDataStore.workheldWorkOrderFileMasterDataStoreFeatureKey, fromWHWorkOrderFileMasterDataStore.workheldWorkOrderFileMasterDataReducer),
    EffectsModule.forFeature([WHWorkOrderFileMasterDataStoreEffects])
  ]
})
export class WHWorkOrderFileMasterDataStoreModule { }
