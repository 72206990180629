// ANGULAR
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// SERVICES
import { EquipmentAsyncService } from 'src/app/app-services-async/equipment-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHEquipmentPreviewStoreActionTypes,
  WHEquipmentPreviewStoreActions,
  GetWHEquipmentPreviewDtoWithParams,
  SetWHEquipmentPreview,
} from './w-h-equipment-preview-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import {
  initHttpParams,
  IWHEquipmentPreviewDTO,
  WHEquipmentPreviewDOM,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WHEquipmentPreviewStoreEffects {
  getWHEquipmentPreviewDTO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHEquipmentPreviewStoreActionTypes.GetWHEquipmentPreviewDtoWithParams
      ),
      switchMap((action: GetWHEquipmentPreviewDtoWithParams) => {
        const httpParams: HttpParams = initHttpParams(action.payload);
        // console.log(httpParams);
        return this.equipmentAsyncService.getEquipmentsPreview(httpParams);
      }),
      mergeMap((equipmentPreviewDTO: IWHEquipmentPreviewDTO) => {
        const equipmentPreviewDOM: WHEquipmentPreviewDOM =
          this.dataModelHelperService.initEquipmentPreviewDOM(
            equipmentPreviewDTO
          );
        return [new SetWHEquipmentPreview(equipmentPreviewDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHEquipmentPreviewStoreActions>,
    private equipmentAsyncService: EquipmentAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
