// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { ToolAsyncService } from 'src/app/app-services-async/tool-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHToolMasterDataStoreActionTypes,
  WHToolMasterDataStoreActions,
  GetWHToolDtoList,
  SetWHToolDtoList,
} from './w-h-tool-master-data-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import { IWHToolDTO, WHToolDOM } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHToolMasterDataStoreEffects {
  getWHToolDtoList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHToolMasterDataStoreActionTypes.GetWHToolDtoList),
      switchMap((action: GetWHToolDtoList) => {
        return this.toolAsyncService.getToolDtoList();
      }),
      mergeMap((toolDtoList: IWHToolDTO[]) => {
        let toolDomList: WHToolDOM[] = [];
        // console.log(toolDtoList);
        if (toolDtoList && toolDtoList.length > 0) {
          toolDtoList.forEach((toolDTO: IWHToolDTO) => {
            const toolDOM: WHToolDOM =
              this.dataModelHelperService.initToolDOM(toolDTO);
            toolDomList.push(toolDOM);
          });
          // SORT WORKERS
          toolDomList = toolDomList.sort((a: WHToolDOM, b: WHToolDOM) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
        }
        return [
          new SetWHToolDtoList({ toolDomList, loading: false, loaded: true }),
        ];
      })
    );
  });

  constructor(
    private actions$: Actions<WHToolMasterDataStoreActions>,
    private toolAsyncService: ToolAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
