import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import {
  AssignedSkill,
  IWHBomNodeSkillDTO,
  IWHDTColumnModel,
  IWHSkillDTO,
  IWHSkillsEndpointConfigModel,
  SkillPayload,
  WHSkillsDataService,
  noWhitespaceValidator,
} from '@workheld/workheld-shared-lib';
import { environment } from '../../environments/environment';
import { FLOW_GATEWAY } from '@workheld/workheld-shared-lib';
import { of } from 'rxjs';

class SkillsEntity {
  _skills: BehaviorSubject<IWHSkillDTO[]>;
  skills$: Observable<IWHSkillDTO[]>;
  _skill: BehaviorSubject<IWHSkillDTO>;
  skill$: Observable<IWHSkillDTO>;
  _assignedSkills: BehaviorSubject<AssignedSkill[]>;
  assignedSkills$: Observable<AssignedSkill[]>;
  _loading: BehaviorSubject<boolean>;
  loading$: Observable<boolean>;
  constructor() {
    this._skills = new BehaviorSubject<IWHSkillDTO[]>([]);
    this._skill = new BehaviorSubject<IWHSkillDTO>(null);
    this._loading = new BehaviorSubject<boolean>(false);
    this.skills$ = this._skills.asObservable();
    this.skill$ = this._skill.asObservable();
    this._assignedSkills = new BehaviorSubject<AssignedSkill[]>([]);
    this.assignedSkills$ = this._assignedSkills.asObservable();
    this.loading$ = this._loading.asObservable();
  }
}

@Injectable({
  providedIn: 'root',
})
export class SkillDataService {
  public skillsData = new SkillsEntity();
  public numOfSkills: number = 0;

  constructor(private service: WHSkillsDataService) {
    // whSkillsDataService = new WHSkillsDataService();
  }

  public initDataTableColumn(): IWHDTColumnModel[] {
    return [
      { property: 'id', visible: true, headerLabelI18nKey: '' },
      {
        property: 'name',
        visible: true,
        headerLabelI18nKey: 'skills.model.name',
      },
      {
        property: 'description',
        visible: true,
        headerLabelI18nKey: 'skills.model.description',
      },
      {
        property: 'category',
        visible: true,
        headerLabelI18nKey: 'skills.model.category',
      },
      {
        property: 'minimumStaffNumber',
        visible: true,
        headerLabelI18nKey: 'skills.model.minimumStaffNumber',
      },
      {
        property: 'status',
        visible: true,
        headerLabelI18nKey: 'skills.model.status',
      },
    ];
  }

  public initMaterialFormGroup(isCreateOrEdit: boolean): FormGroup {
    return new FormBuilder().group({
      id: [null],
      extId: [{ value: null, disabled: isCreateOrEdit }],
      name: [null, [Validators.required, noWhitespaceValidator]],
      description: [null],
      category: [null],
      minimumStaffNumber: [
        0,
        [
          Validators.pattern('^[0-9]*$'),
          Validators.min(0),
          Validators.maxLength(5),
        ],
      ],
      status: ['ACTIVE'],
    });
  }

  getSkillsWithStatus(status?: 'ACTIVE' | 'INACTIVE') {
    this.skillsData._loading.next(true);
    this.service
      .getAllSkills(this.getSkillEndpointConfigModel(), status)
      .pipe(
        map((skills: IWHSkillDTO[]) => {
          this.skillsData._skills.next(skills);
          this.skillsData._loading.next(false);
        }),
        catchError((err) => {
          this.skillsData._loading.next(false);
          return of(err);
        })
      )
      .subscribe();
  }

  getSkills(status?: 'ACTIVE' | 'INACTIVE'): Observable<Array<IWHSkillDTO>> {
    this.skillsData._loading.next(true);
    return this.service.getAllSkills(
      this.getSkillEndpointConfigModel(),
      status
    );
  }
  public getSkillById(skillId: string) {
    this.skillsData._loading.next(true);
    this.service
      .getSkillByID(this.getSkillEndpointConfigModel(skillId))
      .pipe(
        map((skill: IWHSkillDTO) => {
          this.skillsData._skill.next(skill);
          this.skillsData._loading.next(false);
        }),
        catchError((err) => {
          this.skillsData._loading.next(false);
          return of(err);
        })
      )
      .subscribe();
  }

  deleteSkill(id: string) {
    this.skillsData._loading.next(true);
    return this.service.deleteSkill({
      apiUrl: environment.apiUrl + FLOW_GATEWAY,
      skillID: id,
    });
  }

  createOrUpdateSkill(payload: SkillPayload) {
    this.skillsData._loading.next(true);
    return this.service.createOrUpdateSkill(
      this.getSkillEndpointConfigModel(),
      payload
    );
  }

  private getSkillEndpointConfigModel(
    skillID?: string
  ): IWHSkillsEndpointConfigModel {
    return {
      apiUrl: environment.apiUrl + FLOW_GATEWAY,
      skillID: skillID,
    } as IWHSkillsEndpointConfigModel;
  }

  addSkillCount(skills: IWHSkillDTO[]) {
    const assignedSkills: AssignedSkill[] =
      this.skillsData._assignedSkills.value;

    skills.forEach((skill: IWHSkillDTO) => {
      const assignedSkill: AssignedSkill = assignedSkills.find(
        (assignedSkill: AssignedSkill) => {
          return assignedSkill.id === skill.id;
        }
      );
      if (assignedSkill) {
        assignedSkill.count++;
      } else {
        const assignedSkill: AssignedSkill = {
          id: skill.id,
          name: skill.name,
          count: 1,
        };
        assignedSkills.push(assignedSkill);
      }
    });

    this.skillsData._assignedSkills.next(assignedSkills);
  }

  addSkillCountFromBomNode(skills: IWHBomNodeSkillDTO[]) {
    this.skillsData._assignedSkills.next([]);
    const assignedSkills: AssignedSkill[] =
      this.skillsData._assignedSkills.value;

    skills?.forEach((skill: IWHBomNodeSkillDTO) => {
      const assignedSkill: AssignedSkill = assignedSkills.find(
        (assignedSkill: AssignedSkill) => {
          return assignedSkill.id === skill.skillId;
        }
      );
      if (assignedSkill) {
        assignedSkill.count++;
      } else {
        const assignedSkill: AssignedSkill = {
          id: skill.skillId,
          name: skill.skillName,
          count: skill.count,
        };
        assignedSkills.push(assignedSkill);
      }
    });

    this.skillsData._assignedSkills.next(assignedSkills);
  }

  removeAssignedSkills(skillIds: string[]) {
    const assignedSkills: AssignedSkill[] = structuredClone(
      this.skillsData._assignedSkills.getValue()
    );

    skillIds.forEach((skillId: string) => {
      const assignedSkill: AssignedSkill = assignedSkills.find(
        (assignedSkill: AssignedSkill) => {
          return assignedSkill.id === skillId;
        }
      );
      if (assignedSkill) {
        assignedSkill.count--;

        const index = assignedSkills.findIndex(
          (assignedSkill: AssignedSkill) => {
            return assignedSkill.id === skillId;
          }
        );
        if (assignedSkill.count <= 0) {
          //remove the assigned skill with skillId
          assignedSkills.splice(index, 1);
        } else {
          //replace the assigned skill with the updated one
          assignedSkills.splice(index, 1, assignedSkill);
        }
      }
    });
    this.skillsData._assignedSkills.next(assignedSkills);
  }

  cleanAssignedSkill(skillId: string) {
    const assignedSkills: AssignedSkill[] = structuredClone(
      this.skillsData._assignedSkills.getValue()
    );

    //remove the assigned skill with skillId
    const index = assignedSkills.findIndex((assignedSkill: AssignedSkill) => {
      return assignedSkill.id === skillId;
    });
    assignedSkills.splice(index, 1);

    this.skillsData._assignedSkills.next(assignedSkills);
  }

  populateAssignedSkill(skillId: string) {
    const assignedSkills: AssignedSkill[] = structuredClone(
      this.skillsData._assignedSkills.getValue()
    );

    const allSkills: IWHSkillDTO[] = structuredClone(
      this.skillsData._skills.getValue()
    );

    //find Skill
    const skill: IWHSkillDTO = allSkills.find((skill: IWHSkillDTO) => {
      return skill.id === skillId;
    });

    const assignedSkill: AssignedSkill = {
      id: skill.id,
      name: skill.name,
      count: this.numOfSkills,
    };

    //replace the assigned skill with the updated one
    const index = assignedSkills.findIndex((assignedSkill: AssignedSkill) => {
      return assignedSkill.id === skillId;
    });

    if (index === -1) {
      assignedSkills.push(assignedSkill);
    } else {
      assignedSkills.splice(index, 1, assignedSkill);
    }

    this.skillsData._assignedSkills.next(assignedSkills);
  }

  findSkillById(skillId: string): IWHSkillDTO {
    const skills = structuredClone(this.skillsData._skills.getValue());

    return skills.find((skill: IWHSkillDTO) => {
      return skill.id === skillId;
    });
  }
}
