// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { EntryFileImgGalleryRoutingModule } from './entry-file-img-gallery-routing.module';
import { EntryFileImgGalleryComponent } from './entry-file-img-gallery.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';
import { WHImgAuthHeaderModule } from '@workheld/workheld-shared-lib';

@NgModule({
  declarations: [
    EntryFileImgGalleryComponent
  ],
  exports: [
    EntryFileImgGalleryComponent
  ],
  imports: [
    AppSharedModule,
    WHImgAuthHeaderModule,
    EntryFileImgGalleryRoutingModule
  ]
})
export class EntryFileImgGalleryModule { }
