// HELPER
import { updateObject, WHWorkObjectTypeENUM } from '@workheld/workheld-shared-lib';

// REDUX
import { WHWorkObjectCrudStoreActions, WHWorkObjectCrudStoreActionTypes } from './w-h-work-object-crud-store.actions';

// MODELS
import { WHProjectWorkObjectDOM, WHStandingOrderWorkObjectDOM, WHCaseWorkObjectDOM } from '@workheld/workheld-shared-lib';

export const workheldWorkObjectCrudStoreFeatureKey = 'workheldWorkObjectCrudStore$';

export interface IWHWorkObjectCrudStore {
  workObjectTypeId: number;
  projectDOM: WHProjectWorkObjectDOM;
  caseDOM: WHCaseWorkObjectDOM;
  standingOrderDOM: WHStandingOrderWorkObjectDOM;
  jobDOM: any;
}

export const initialState: IWHWorkObjectCrudStore = {
  workObjectTypeId: 0,
  projectDOM: new WHProjectWorkObjectDOM(),
  caseDOM: new WHCaseWorkObjectDOM(),
  standingOrderDOM: new WHStandingOrderWorkObjectDOM(),
  jobDOM: {

  } as any
};

export function workheldWorkObjectCrudReducer(state = initialState, action: WHWorkObjectCrudStoreActions): IWHWorkObjectCrudStore {
  switch (action.type) {

    case WHWorkObjectCrudStoreActionTypes.GetWHWorkObjectDTO:
      return updateObject(state, getWorkObjectDomType(action.payload.workObjectTypeId));

    case WHWorkObjectCrudStoreActionTypes.SetWHWorkObjectDOM:
      return updateObject(state, updateWorkObjectDomType(action.payload.workObjectTypeId, action.payload.workObjectDOM));

    case WHWorkObjectCrudStoreActionTypes.ResetWHWorkObjectDOM:
      return resetWorkObjectDOM(state, action.payload.workObjectTypeId);

    default:
      return state;
  }
}

function resetWorkObjectDOM(state, workObjectTypeId) {
  switch (workObjectTypeId) {
    case WHWorkObjectTypeENUM.PROJECT:
      state.projectDOM = updateObject(new WHProjectWorkObjectDOM(), { loading: false, loaded: false } as any)
      return state;
    case WHWorkObjectTypeENUM.CASE:
      state.caseDOM = updateObject(new WHCaseWorkObjectDOM(), { loading: false, loaded: false } as any)
      return state;
    case WHWorkObjectTypeENUM.STANDING_ORDER:
      state.standingOrderDOM = updateObject(new WHStandingOrderWorkObjectDOM(), { loading: false, loaded: false } as any)
      return state;
  }
}

function getWorkObjectDomType(workObjectTypeId: number): IWHWorkObjectCrudStore | any {
  switch (workObjectTypeId) {
    case WHWorkObjectTypeENUM.PROJECT:
      return {
        workObjectTypeId: WHWorkObjectTypeENUM.PROJECT,
        projectDOM: updateObject(new WHProjectWorkObjectDOM(), { loading: true, loaded: false } as any)
      } as any;
    case WHWorkObjectTypeENUM.CASE:
      return {
        workObjectTypeId: WHWorkObjectTypeENUM.CASE,
        caseDOM: updateObject(new WHCaseWorkObjectDOM(), { loading: true, loaded: false } as any),
      } as any;
    case WHWorkObjectTypeENUM.STANDING_ORDER:
      return {
        workObjectTypeId: WHWorkObjectTypeENUM.STANDING_ORDER,
        standingOrderDOM: updateObject(new WHStandingOrderWorkObjectDOM(), { loading: true, loaded: false } as any)
      } as any;
    case WHWorkObjectTypeENUM.JOB:
      return {
        workObjectTypeId: WHWorkObjectTypeENUM.JOB,
        jobDOM: {
          loading: true,
          loaded: false
        }
      } as any;
  }
}

function updateWorkObjectDomType(workObjectTypeId: number, workObjectDom: any): IWHWorkObjectCrudStore | any {
  switch (workObjectTypeId) {
    case WHWorkObjectTypeENUM.PROJECT:
      return {
        projectDOM: workObjectDom
      } as any;
    case WHWorkObjectTypeENUM.CASE:
      return {
        caseDOM: workObjectDom,
      } as any;
    case WHWorkObjectTypeENUM.STANDING_ORDER:
      return {
        standingOrderDOM: workObjectDom
      } as any;
    case WHWorkObjectTypeENUM.JOB:
      return {
        jobDOM: workObjectDom
      } as any;
  }
}
