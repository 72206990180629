// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import * as fromWHWorkObjectEffortReport from './w-h-work-object-effort-report.reducer';
import { EffectsModule } from '@ngrx/effects';
import { WHWorkObjectEffortReportEffects } from './w-h-work-object-effort-report.effects';



@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHWorkObjectEffortReport.workheldWorkObjectEffortReportFeatureKey, fromWHWorkObjectEffortReport.workheldWorkObjectEffortReportReducer),
    EffectsModule.forFeature([WHWorkObjectEffortReportEffects])
  ]
})
export class WHWorkObjectEffortReportModule { }
