// HELPERS
import { updateObject, WHEquipmentPreviewDOM } from '@workheld/workheld-shared-lib';

// REDUX
import { WHEquipmentPreviewStoreActions, WHEquipmentPreviewStoreActionTypes } from './w-h-equipment-preview-store.actions';

// MODELS

// CONST
export const workheldEquipmentPreviewStoreFeatureKey = 'workheldEquipmentPreviewStore$';

export const initialState: WHEquipmentPreviewDOM = new WHEquipmentPreviewDOM();

export function workheldEquipmentPreviewReducer(state = initialState, action: WHEquipmentPreviewStoreActions): WHEquipmentPreviewDOM {
  switch (action.type) {

    case WHEquipmentPreviewStoreActionTypes.GetWHEquipmentPreviewDtoWithParams:
      //const payload: WHHttpParams = action.payload;
      return updateObject<WHEquipmentPreviewDOM>(state, {
        loading: true,
        loaded: false
      } as any);

    case WHEquipmentPreviewStoreActionTypes.SetWHEquipmentPreview:
      return action.payload;

    default:
      return state;
  }
}
