// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';

// MODELS
import { WHWorkerDOM } from '@workheld/workheld-shared-lib';

export enum WHWorkerMasterDataStoreActionTypes {
  GetWHWorkerDtoList = '[WHWorkerMasterDataStore] GetWHWorkerDtoList',
  SetWHWorkerDomList = '[WHWorkerMasterDataStore] SetWHWorkerDomList',
  GetWHWorkerDtoListForWorkOrderAssignment = '[WHWorkerMasterDataStore] GetWHWorkerDtoListForWorkOrderAssignment',
  GetWHWorkerWorkOrderAssignment = '[WHWorkerMasterDataStore] GetWHWorkerWorkOrderAssignment',
  SetWHWorkerWorkOrderAssignment = '[WHWorkerMasterDataStore] SetWHWorkerWorkOrderAssignment',
}

export class GetWHWorkerDtoList implements Action {
  readonly type = WHWorkerMasterDataStoreActionTypes.GetWHWorkerDtoList;

  constructor(public payload?: WHHttpParams) {
    // console.log('GetWHWorkerDtoList' + JSON.stringify(payload));
  }
}

export class SetWHWorkerDomList implements Action {
  readonly type = WHWorkerMasterDataStoreActionTypes.SetWHWorkerDomList;

  constructor(public payload?: { workerDomList: WHWorkerDOM[], loading: boolean, loaded: boolean }) {
    // console.log('SetWHWorkerDomList' + JSON.stringify(payload));
  }
}

export class GetWHWorkerDtoListForWorkOrderAssignment implements Action {
  readonly type = WHWorkerMasterDataStoreActionTypes.GetWHWorkerDtoListForWorkOrderAssignment;

  constructor(public payload?: { workOrderId: string, httpParams?: WHHttpParams }) {
    // console.log('GetWHWorkerDtoListForWorkOrderAssignment' + JSON.stringify(payload));
  }
}

export class GetWHWorkerWorkOrderAssignment implements Action {
  readonly type = WHWorkerMasterDataStoreActionTypes.GetWHWorkerWorkOrderAssignment;

  constructor(public payload?: { workOrderId: string, httpParams?: WHHttpParams }) {
    // console.log('GetWHWorkerWorkOrderAssignment' + JSON.stringify(payload));
  }
}

export class SetWHWorkerWorkOrderAssignment implements Action {
  readonly type = WHWorkerMasterDataStoreActionTypes.SetWHWorkerWorkOrderAssignment;

  constructor(public payload?: WHWorkerDOM[]) {
    // console.log('SetWHWorkerWorkOrderAssignment' + JSON.stringify(payload));
  }
}

export type WHWorkerMasterDataStoreActions =
  GetWHWorkerDtoList |
  SetWHWorkerDomList |
  GetWHWorkerDtoListForWorkOrderAssignment |
  GetWHWorkerWorkOrderAssignment |
  SetWHWorkerWorkOrderAssignment;
