// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHEntryFilePreviewStore from './w-h-entry-file-preview-store.reducer';
import { WHEntryFilePreviewStoreEffects } from './w-h-entry-file-preview-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHEntryFilePreviewStore.workheldEntryFilePreviewStoreFeatureKey, fromWHEntryFilePreviewStore.workheldEntryFilePreviewReducer),
    EffectsModule.forFeature([WHEntryFilePreviewStoreEffects])
  ]
})
export class WHEntryFilePreviewStoreModule { }
