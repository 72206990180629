// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHEntryPreviewStore from './w-h-entry-preview-store.reducer';
import { WHEntryPreviewStoreEffects } from './w-h-entry-preview-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHEntryPreviewStore.workheldEntryPreviewStoreFeatureKey, fromWHEntryPreviewStore.workheldEntryPreviewReducer),
    EffectsModule.forFeature([WHEntryPreviewStoreEffects])
  ]
})
export class WHEntryPreviewStoreModule { }
