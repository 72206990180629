// DEP
import { saveAs } from 'file-saver';

// ENVIRONMENT
import { environment } from '../../environments/environment';

// ANGULAR
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS 6
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

// MODELS
import { initHttpParams, WHHttpParams } from '@workheld/workheld-shared-lib';
import {
  IWHEquipmentDTO,
  WHEquipmentDOM,
  IWHEquipmentPreviewDTO,
} from '@workheld/workheld-shared-lib';
import { FLOW_GATEWAY } from '@workheld/workheld-shared-lib';

class DecommissionEntity {
  _showDecommissioned: BehaviorSubject<boolean>;
  showDecommissioned$: Observable<boolean>;

  constructor() {
    this._showDecommissioned = new BehaviorSubject<boolean>(false);
    this.showDecommissioned$ = this._showDecommissioned.asObservable();
  }
}

@Injectable()
export class EquipmentAsyncService {
  public equipmentData = new DecommissionEntity();

  private _equipmentDropdownList = new BehaviorSubject<IWHEquipmentPreviewDTO>(
    null,
  );
  equipmentDropdown = {
    equipmentDropdownList$: this._equipmentDropdownList.asObservable(),
    equipmentDropdownloading$: new BehaviorSubject(true),
  };

  constructor(private httpClient: HttpClient) {}

  public removeImageFile(equipmentId: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/equipments/${equipmentId}/equipmentPicture`,
    );
  }

  public caseQuickCreate(payload: {
    equipmentId: string;
    category: string;
  }): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl + FLOW_GATEWAY}/cases/category`,
      payload,
    );
  }

  public getEquipmentDtoByEquimentId(
    equipmentID: string,
  ): Observable<IWHEquipmentDTO> {
    return this.httpClient.get<IWHEquipmentDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/equipments/${equipmentID}`,
    );
  }

  public createEquipmentByEquipmentDTO(
    equipmentDTO: IWHEquipmentDTO,
  ): Observable<IWHEquipmentDTO> {
    return this.httpClient.post<IWHEquipmentDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/equipments`,
      equipmentDTO,
    );
  }

  public updateEquipmentByEquipmentDTO(
    equipmentID: string,
    equipmentDTO: IWHEquipmentDTO,
  ): Observable<IWHEquipmentDTO> {
    return this.httpClient.post<IWHEquipmentDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/equipments/${equipmentID}`,
      equipmentDTO,
    );
  }

  public postEquipmentImageFile(
    equipmentID: string,
    fileBlob: Blob,
  ): Observable<IWHEquipmentDTO> {
    const formData: FormData = new FormData();
    let mimeType = 'undefined';
    let extension = '.png';
    if (fileBlob.type) {
      mimeType = fileBlob.type;
      extension = '.' + mimeType.split('/')[1];
    }
    formData.append('file', fileBlob, 'avatar' + extension);

    const headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', mimeType);

    return this.httpClient.post<IWHEquipmentDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/equipments/${equipmentID}/files`,
      formData,
      { headers },
    );
  }

  // // TODO - check - with caching
  public getEquipmentQrCodeImage(equipmentQrCodeUrl: string): Observable<Blob> {
    return this.httpClient.get(equipmentQrCodeUrl, { responseType: 'blob' });
  }

  // TODO - check - without caching
  public loadQRCode(equipmentQrCodeUrl: string): Observable<Blob> {
    return this.httpClient.get(equipmentQrCodeUrl, {
      headers: { Accept: 'image/png' },
      responseType: 'blob',
    });
  }

  // QR CODE
  public saveQRCode(equipmentDOM: WHEquipmentDOM, qrBlob: Blob) {
    // console.log(equipment.equipmentNumber);
    saveAs(
      qrBlob,
      'qr-' + equipmentDOM.name + '_' + equipmentDOM.equipmentNumber + '.png',
    );
  }

  public getEquipmentsPreview(
    httpParams: HttpParams,
  ): Observable<IWHEquipmentPreviewDTO> {
    return this.httpClient.get<IWHEquipmentPreviewDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/equipments/preview`,
      { params: httpParams },
    );
  }

  public equipmentPlannerWorkObjects$ = new BehaviorSubject(null);
  public equipmentPlannerWorkObjectsLoading$ = new BehaviorSubject(null);

  // TODO - improve adding start and end date for performance
  public getEquipmentWorkObjects(id: string, params?: { startDate?: Date; endDate?: Date, includeBomNodes?: boolean }) {
    this.equipmentPlannerWorkObjectsLoading$.next(true);
    const httpParams: HttpParams = initHttpParams(params);
    return this.httpClient
      .get(environment.apiUrl + FLOW_GATEWAY + `/equipments/${id}/workobjects`, { params: httpParams})
      .subscribe((res: any) => {
        this.equipmentPlannerWorkObjects$.next(res);
        this.equipmentPlannerWorkObjectsLoading$.next(false);
      });
  }

  public getEquipmentDtoList(targetParent: string, customerId: string) {
    switch (targetParent) {
      case 'CUSTOMER':
        return this.getCustomerEquipmentDtoList(customerId);
      default:
        return of(undefined);
    }
  }

  private getCustomerEquipmentDtoList(
    customerId: string,
  ): Observable<IWHEquipmentDTO[]> {
    let params: WHHttpParams = { page: 0 };
    let httpParams: HttpParams = initHttpParams(params);
    return this.getCustomerEquipmentPreview(customerId, httpParams).pipe(
      switchMap((res: IWHEquipmentPreviewDTO) => {
        const requestArray: Array<Observable<IWHEquipmentPreviewDTO>> =
          new Array();
        requestArray.push(of(res));
        for (let index = 0; index < res.numPages; index++) {
          if (index === 0) {
            continue;
          }
          params = { page: index };
          httpParams = initHttpParams(params);
          const request = this.getCustomerEquipmentPreview(
            customerId,
            httpParams,
          );
          requestArray.push(request);
        }
        return forkJoin(requestArray).pipe(
          map((results: IWHEquipmentPreviewDTO[]) => {
            let equipmentDtoList: IWHEquipmentDTO[] = [];
            results.forEach((resStored: IWHEquipmentPreviewDTO) => {
              equipmentDtoList = resStored.equipments
                ? equipmentDtoList.concat(resStored.equipments)
                : [];
            });
            return equipmentDtoList;
          }),
        );
      }),
    );
  }

  public getEquipmentList(
    customerId: string,
    queryParams: { page: number; pageSize: number; term: string },
  ): Observable<IWHEquipmentPreviewDTO> {
    let httpParams: HttpParams = initHttpParams(queryParams);
    return this.getCustomerEquipmentPreview(customerId, httpParams);
  }

  public getCustomerEquipmentPreview(
    customerId: string,
    httpParams: HttpParams,
  ): Observable<IWHEquipmentPreviewDTO> {
    return this.httpClient.get<IWHEquipmentPreviewDTO>(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/customers/${customerId}/equipments/preview`,
      { params: httpParams },
    );
  }

  public getCircleColor(operatingStateId: number, isDecommissioned: boolean) {
    if (isDecommissioned) {
      return 'grey';
    }
    switch (operatingStateId) {
      case 0:
        return 'green';
      case 1:
        return 'orange';
      case 2:
        return 'red';
      default:
        return '';
    }
  }

  public getEquipmentDropdownList(httpParams) {
    let params = initHttpParams(httpParams);
    this.equipmentDropdown.equipmentDropdownloading$.next(true);
    return this.httpClient
      .get<IWHEquipmentPreviewDTO>(
        `${environment.apiUrl + FLOW_GATEWAY}/equipments/list`,
        { params },
      )
      .pipe(
        map((resp) => {
          const modifiedResp = { ...resp, data: resp?.equipments || [] };
          this.equipmentDropdown.equipmentDropdownloading$.next(false);
          this._equipmentDropdownList.next(modifiedResp);
        }),
        catchError((error) => {
          this.equipmentDropdown.equipmentDropdownloading$.next(false);
          return of(error);
        }),
      )
      .subscribe();
  }
}
