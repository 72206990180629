// REDUX
import { WHCustomerPreviewStoreActions, WHCustomerPreviewStoreActionTypes } from './w-h-customer-preview-store.actions';

export const workheldCustomerPreviewStoreFeatureKey = 'workheldCustomerPreviewStore$';

// MODELS
import { updateObject, WHCustomerPreviewDOM } from '@workheld/workheld-shared-lib';

export const initialState: WHCustomerPreviewDOM = new WHCustomerPreviewDOM();

export function workheldCustomerPreviewReducer(state = initialState, action: WHCustomerPreviewStoreActions): WHCustomerPreviewDOM {
  switch (action.type) {

    case WHCustomerPreviewStoreActionTypes.GetWHCustomerPreviewDtoWithParams:
      // const payload: WHHttpParams = action.payload;
      return updateObject<WHCustomerPreviewDOM>(state, {
        loading: true,
        loaded: false
      } as any);

    case WHCustomerPreviewStoreActionTypes.SetWHCustomerPreview:
      return action.payload;

    default:
      return state;
  }
}
