// ANGULAR
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  UrlSegment,
} from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

// API
import {
  IWHBreadcrumbModel,
  WHBreadcrumbService,
  WHRouteZeroENUM,
  WHLoginDataService,
  WHDepartmentDataService,
  WHDepartmentDOM,
  WHDomEventService,
  WHActiveUserDOM,
  WHPermissionKeyENUM,
  WHIconENUM,
  WHDomEvent,
} from '@workheld/workheld-shared-lib';

// RxJS 6
import { of, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { WHRouteBehaviorSubjectService } from 'src/app/app-services-helper/w-h-route-behavior-subject.service';
import { CheckUnsavedComponent } from 'src/app/app-shared/guards/check-unsaved.guard';
import { FormReferenceService } from 'src/app/app-services-helper/form-reference.service';
import { debounceTime, map, switchMap } from 'rxjs/operators';

// GA
declare const gtag: Function;

@Component({
  selector: 'app-w-h-content-page',
  templateUrl: './w-h-content-page.component.html',
})
export class WHContentPageComponent
  implements OnInit, OnDestroy, CheckUnsavedComponent
{
  // VAR
  public permissions: string[];
  public basePath: string = '';
  public routeWhitelist: string[] = [WHRouteZeroENUM.PROFILE];
  public breadcrumbs: IWHBreadcrumbModel[] = [];

  // DEPARTMENTS
  public displayFilter: boolean = false;
  public departmentFilterWhitelist: string[] = [
    WHRouteZeroENUM.PROJECTS,
    WHRouteZeroENUM.PROJECT_OVERVIEW,
    WHRouteZeroENUM.CASES,
    WHRouteZeroENUM.STANDING_ORDER,
    WHRouteZeroENUM.HOME,
    WHRouteZeroENUM.TEAM_PLANNER,
    WHRouteZeroENUM.HR_WORKERS,
    WHRouteZeroENUM.JOB_REQUIREMENTS,
    WHRouteZeroENUM.SHIFT_DEFINITIONS,
    WHRouteZeroENUM.SHIFT_CALENDAR,
    WHRouteZeroENUM.SHIFT_PLANNER,
    WHRouteZeroENUM.EQUIPMENT_PLANNER,
  ];
  public isOpen: boolean = false;
  public selectedDepartmentDOM: WHDepartmentDOM = undefined;
  public cssPrefix = 'department-dropdown';
  public departmentDOMList: WHDepartmentDOM[] = [];
  public filteredDepartmentDOMList: WHDepartmentDOM[] = [];
  // SEARCH CONTROL
  public searchCtrl: FormControl = new FormControl();

  // SUBSCRIPTION
  private subscription: Subscription = new Subscription();

  // STATE
  private activeUser: WHActiveUserDOM = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loginDataService: WHLoginDataService,
    private departmentDataService: WHDepartmentDataService,
    private domEventService: WHDomEventService,
    private breadcrumbService: WHBreadcrumbService,
    private workheldRouteBehaviorSubjectService: WHRouteBehaviorSubjectService,
    private formReferenceService: FormReferenceService,
    private translateService: TranslateService,
    private titleService: Title
  ) {}

  checkUnsaved() {
    return this.formReferenceService.unsavedChanges$.pipe(
      switchMap((isDirty) => {
        if (isDirty) {
          const dialog = this.formReferenceService.createDialog();
          return dialog.pipe((discard) => {
            return discard;
          });
        } else {
          return of(true);
        }
      })
    );
  }

  public onClickOutside() {
    this.isOpen = false;
  }

  public selectionChange(departmentDOM: WHDepartmentDOM) {
    if (
      this.selectedDepartmentDOM &&
      this.selectedDepartmentDOM.id === departmentDOM.id
    ) {
      this.selectedDepartmentDOM = null;
      this.workheldRouteBehaviorSubjectService.departmentId = null;
      this.domEventService.domEvents$.next({
        type: 'departmentFilterChange',
        payload: this.selectedDepartmentDOM,
      });
      this.isOpen = false;
      return;
    }

    this.selectedDepartmentDOM = departmentDOM;
    this.workheldRouteBehaviorSubjectService.departmentId = departmentDOM.id;
    this.domEventService.domEvents$.next({
      type: 'departmentFilterChange',
      payload: departmentDOM,
    });
    this.isOpen = false;
  }

  ngOnInit(): void {
    this.formReferenceService.unsavedChanges = false;
    // DEPARTMENTS
    this.subscription.add(
      this.departmentDataService.departments$.subscribe(
        (departments: WHDepartmentDOM[]) => {
          this.departmentDOMList = departments.sort(
            (a: WHDepartmentDOM, b: WHDepartmentDOM) => {
              if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              }
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              }
              return 0;
            }
          );
          this.filteredDepartmentDOMList = this.departmentDOMList;
          // if (backButtonValue && backButtonValue.departmentId) {
          //   this.selectedDepartmentDOM = this.departmentDOMList.find((department: WHDepartmentDOM) => {
          //     return department.id = backButtonValue.departmentId;
          //   });
          // }
          if (this.workheldRouteBehaviorSubjectService.departmentId) {
            const department = this.departmentDOMList.find(
              (department: WHDepartmentDOM) => {
                return (
                  department.id ===
                  this.workheldRouteBehaviorSubjectService.departmentId
                );
              }
            );

            this.selectedDepartmentDOM = department;
          }

          if (this.departmentDOMList?.length == 1) {
            this.selectedDepartmentDOM = this.departmentDOMList[0];
          }
        }
      )
    );

    this.subscription.add(
      this.loginDataService.permissions$.subscribe((permissions: string[]) => {
        this.permissions = permissions;
      })
    );

    this.subscription.add(
      this.domEventService.domEvents$.subscribe((domEvent: WHDomEvent) => {
        if (
          domEvent &&
          domEvent.type === 'departmentFilterChange' &&
          !this.selectedDepartmentDOM
        ) {
          this.selectedDepartmentDOM = domEvent.payload;
        }
      })
    );

    this.subscription.add(
      this.loginDataService.activeUserDOM$.subscribe(
        (user: WHActiveUserDOM) => {
          this.activeUser = user;
        }
      )
    );

    this.subscription.add(
      this.router.events.subscribe((event: any) => {
        if (event.routerEvent && event.routerEvent instanceof NavigationEnd) {
          const urlSegment: UrlSegment[] = event.routerEvent.url
            .split('/')
            // Filters status overview and and url parameters
            .filter((element) => element && element.split('?')[0] !== '')
            .map((e) => {
              const url = e.split('?');
              const parameters = url[1] ? url[1].split('&') : [];
              return {
                path: url[0],
                parameters: parameters,
              } as UrlSegment;
            });
          this._fillCrumbs(urlSegment);
        }
      })
    );

    this.subscription.add(
      this.activatedRoute.url.subscribe((urlSegment: UrlSegment[]) => {
        this._fillCrumbs(urlSegment);
      })
    );

    this.subscription.add(
      this.breadcrumbService.breadcrumbs$
        .pipe(
          debounceTime(300),
          map((breadcrumbs: any) => {
            this.breadcrumbs = breadcrumbs;
            let title =
              breadcrumbs.length > 1 ? 'Workheld Flow | ' : 'Workheld Flow';
            breadcrumbs.map((b, index) => {
              title += b.nameI18nKey.includes('.')
                ? this.translateService.instant(b.nameI18nKey)
                : b.nameI18nKey;
              if (index + 1 < breadcrumbs.length) title += ' | ';
            });
            this.titleService.setTitle(title);
            if (environment.googleAnalyticsKey) {
              gtag('config', environment.googleAnalyticsKey, {
                page_view: title,
              });
            }
          })
        )
        .subscribe()
    );
  }

  private _fillCrumbs(urlSegment: UrlSegment[]) {
    if (urlSegment[0]?.path) {
      this.basePath = urlSegment[0].path;
    }

    const crumbs: IWHBreadcrumbModel[] = this.breadcrumbService
      .initCrumbDOMList(urlSegment)
      .filter((crumb: IWHBreadcrumbModel) => {
        return crumb.enabled;
      });
    const baseCrumbs: IWHBreadcrumbModel[] = this.setBaseCrumbs(crumbs[0]);
    const flowCrumbs: IWHBreadcrumbModel[] = baseCrumbs.concat(crumbs);

    this.showDepartmentFilter(flowCrumbs);

    this.breadcrumbService.breadcrumbs$.next(flowCrumbs);
  }

  onSearchTerm(value: string) {
    if (!value || value.length === 0) {
      this.filteredDepartmentDOMList = this.departmentDOMList;
    } else {
      this.filteredDepartmentDOMList = this.departmentDOMList.filter(
        (departmentDOM: WHDepartmentDOM) => {
          return departmentDOM.name.toLowerCase().includes(value.toLowerCase());
        }
      );
    }
  }
  public showDepartmentFilter(flowCrumbs: IWHBreadcrumbModel[]) {
    if (flowCrumbs[1] && flowCrumbs.length === 2) {
      if (
        this.departmentFilterWhitelist.includes(flowCrumbs[1].urlSegmentPath)
      ) {
        this.displayFilter = true;
      } else {
        this.displayFilter = false;
      }
    } else {
      this.displayFilter = false;
    }
  }

  public get routeWhitelisted() {
    return this.routeWhitelist.includes(this.basePath);
  }

  public get coordinatorAccess() {
    return this.permissions.includes(
      WHPermissionKeyENUM.FLOW_COORDINATOR_ACCESS
    );
  }

  public navigate(crumb: IWHBreadcrumbModel): void {
    if (!crumb.routeUrl) {
      return;
    }
    if (typeof crumb.routeUrl === 'function') {
      (crumb as any).routeUrl();
      return;
    }
    this.router.navigateByUrl(crumb.routeUrl);
  }

  private setBaseCrumbs(crumb: IWHBreadcrumbModel): IWHBreadcrumbModel[] {
    switch (crumb ? crumb.urlSegmentPath : null) {
      case WHRouteZeroENUM.HOME:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.overview.root',
            routeUrl: WHRouteZeroENUM.STATUS_OVERVIEW,
            urlSegmentPath: WHRouteZeroENUM.STATUS_OVERVIEW,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.PROJECT_OVERVIEW:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.overview.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.PROJECT_OVERVIEW,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.QUALIFICATIONS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.humanresources.root',
            routeUrl: WHRouteZeroENUM.QUALIFICATIONS,
            urlSegmentPath: WHRouteZeroENUM.QUALIFICATIONS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.SUBCONTACTOR_BOARD:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.overview.root',
            routeUrl: WHRouteZeroENUM.STATUS_OVERVIEW,
            urlSegmentPath: WHRouteZeroENUM.STATUS_OVERVIEW,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.ENTRIES_UNASSIGNED:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.overview.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.ENTRIES_UNASSIGNED,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];

      case WHRouteZeroENUM.MAINTENANCE_NOTIFICATIONS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.overview.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.MAINTENANCE_NOTIFICATIONS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.WORK_OBJECT_EFFORT_REPORT:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.overview.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.WORK_OBJECT_EFFORT_REPORT,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.TEAM_PLANNER:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.overview.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.TEAM_PLANNER,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.TIME_REPORT:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.overview.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.TIME_REPORT,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.PROJECTS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.work.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.PROJECTS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.CASES:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.work.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.CASES,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.CASES_CATEGORY:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.work.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.CASES_CATEGORY,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.STANDING_ORDER:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.work.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.STANDING_ORDER,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.EQUIPMENTS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.equipment.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.EQUIPMENTS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.EQUIPMENT_PLANNER:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.equipment.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.EQUIPMENTS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.SHIFT_PLANNER:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.overview.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.SHIFT_PLANNER,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.DAILY_DASHBOARD:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.overview.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.DAILY_DASHBOARD,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.SKILLS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.humanresources.root',
            routeUrl: WHRouteZeroENUM.SKILLS,
            urlSegmentPath: WHRouteZeroENUM.SKILLS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.JOB_REQUIREMENTS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.humanresources.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.JOB_REQUIREMENTS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.SHIFT_DEFINITIONS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.humanresources.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.SHIFT_DEFINITIONS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.HR_WORKERS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.humanresources.root',
            routeUrl: WHRouteZeroENUM.HR_WORKERS,
            urlSegmentPath: WHRouteZeroENUM.HR_WORKERS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.SHIFT_GROUPS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.humanresources.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.SHIFT_GROUPS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.SHIFT_CALENDAR:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.humanresources.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.SHIFT_CALENDAR,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.ABSENCE_REQUESTS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.humanresources.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.ABSENCE_REQUESTS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.MAINTENANCE_PLAN:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.equipment.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.MAINTENANCE_PLAN,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.MATERIALS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.material.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.MATERIALS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.MATERIALINVENTORY:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.material.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.MATERIALINVENTORY,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.CUSTOMERS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.masterdata.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.CUSTOMERS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.TOOLS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.masterdata.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.TOOLS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.WORKERS:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.masterdata.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.WORKERS,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.CHECKLIST_DEFINITION:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.masterdata.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.CHECKLIST_DEFINITION,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.WORK_OBJECT_TEMPLATES:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.masterdata.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.WORK_OBJECT_TEMPLATES,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.WORK_OBJECT_MATERIALUSAGE_REPORT:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.overview.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.WORK_OBJECT_MATERIALUSAGE_REPORT,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case '/equipmentreport':
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.overview.root',
            routeUrl: '/',
            urlSegmentPath: '/equipmentreport',
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      case WHRouteZeroENUM.WORK_OBJECT_REPORTS_OVERVIEW:
        return [
          {
            enabled: true,
            nameI18nKey: 'nav.overview.root',
            routeUrl: '/',
            urlSegmentPath: WHRouteZeroENUM.WORK_OBJECT_REPORTS_OVERVIEW,
            icon: WHIconENUM.WorkheldIcon,
          },
        ];
      default:
        return [];
    }
  }

  removeFilter() {
    if (!this.canRemoveFilter) return;

    this.selectedDepartmentDOM = null;
    this.workheldRouteBehaviorSubjectService.departmentId = null;
    this.domEventService.domEvents$.next({
      type: 'departmentFilterChange',
      payload: this.selectedDepartmentDOM,
    });
  }

  public get filterIcon(): string {
    return WHIconENUM.FilterIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  public get canRemoveFilter(): boolean {
    return this.activeUser?.department
      ? !this.activeUser.department.subcontractor
      : true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
