import { WHRegisterLocaleDataENUM } from '@workheld/workheld-shared-lib'
import { LocaleManager, LocaleHelper } from '@bryntum/schedulerpro'
import '@bryntum/schedulerpro/locales/schedulerpro.locale.En'
import '@bryntum/schedulerpro/locales/schedulerpro.locale.De'
import '@bryntum/schedulerpro/locales/schedulerpro.locale.FrFr'
import '@bryntum/schedulerpro/locales/schedulerpro.locale.Es'
import '@bryntum/schedulerpro/locales/schedulerpro.locale.Pt'

// Map Workheld locales to Bryntum locales
const BRYNTUM_LOCALES = {
  [WHRegisterLocaleDataENUM.EN]: 'En',
  [WHRegisterLocaleDataENUM.DE]: 'De',
  [WHRegisterLocaleDataENUM.FR]: 'FrFr',
  // [WHRegisterLocaleDataENUM.ES]: 'Es',
  // [WHRegisterLocaleDataENUM.PT]: 'Pt',
  'es': 'Es',
  'pt': 'Pt',
}

export const applyBryntumLocale = (lang: string): void => {
  if (BRYNTUM_LOCALES[lang]) {
    LocaleManager.applyLocale(BRYNTUM_LOCALES[lang]);
  } else {
    LocaleManager.applyLocale(BRYNTUM_LOCALES[WHRegisterLocaleDataENUM.EN]);
  }
}

export const extendBryntumLocale = (lang: string, translation: object): void => {
  if (BRYNTUM_LOCALES[lang]) {
    const localeConfig = {
      // This is required so in scheduler configs you can use the following syntax:
      // ```
      // {
      //   type: 'button',
      //   text: 'L{TranslateService.app.cancel.label}',
      // }
      // ```
      // where "app.cancel.label" is a translation key
      TranslateService: { ...translation },
      // Override weekStartDay for all locales to start on Monday
      DateHelper : {
        weekStartDay: 1,
      }
    }

    LocaleHelper.publishLocale(BRYNTUM_LOCALES[lang], localeConfig);
  }
}
