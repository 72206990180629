// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHWorkerMasterDataStore from './w-h-worker-master-data-store.reducer';
import { WHWorkerMasterDataStoreEffects } from './w-h-worker-master-data-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(
      fromWHWorkerMasterDataStore.workheldWorkerMasterDataStoreFeatureKey,
      fromWHWorkerMasterDataStore.workheldWorkerMasterDataReducer,
    ),
    EffectsModule.forFeature([WHWorkerMasterDataStoreEffects])
  ]
})
export class WHWorkerMasterDataStoreModule { }
