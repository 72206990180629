import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mat-dialog-confirm-unsaved-changes',
  templateUrl: './mat-dialog-confirm-unsaved-changes.component.html',
  styleUrls: ['./mat-dialog-confirm-unsaved-changes.component.scss'],
})
export class MatDialogConfirmUnsavedChangesComponent {
  constructor(
    public dialogRef: MatDialogRef<MatDialogConfirmUnsavedChangesComponent>
  ) {}

  closeDialog(discard: boolean) {
    this.dialogRef.close(discard);
  }
}
