// ANGULAR
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHEquipmentPreviewDOM } from '@workheld/workheld-shared-lib';

export enum WHEquipmentPreviewStoreActionTypes {
  GetWHEquipmentPreviewDtoWithParams = '[WHEquipmentPreviewStore] GetWHEquipmentPreviewDtoWithParams',
  SetWHEquipmentPreview = '[WHEquipmentPreviewStore] SetWHEquipmentPreview'
}

export class GetWHEquipmentPreviewDtoWithParams implements Action {
  readonly type = WHEquipmentPreviewStoreActionTypes.GetWHEquipmentPreviewDtoWithParams;

  constructor(public payload: WHHttpParams) {
    // console.log('GetWHEquipmentPreviewDtoWithParams ' + JSON.stringify(payload));
  }
}

export class SetWHEquipmentPreview implements Action {
  readonly type = WHEquipmentPreviewStoreActionTypes.SetWHEquipmentPreview;

  constructor(public payload: WHEquipmentPreviewDOM) {
    // console.log('SetWHEquipmentPreview ' + JSON.stringify(payload));
  }
}

export type WHEquipmentPreviewStoreActions = GetWHEquipmentPreviewDtoWithParams | SetWHEquipmentPreview;
