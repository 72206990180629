// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHReportFilePreviewDOM } from '@workheld/workheld-shared-lib';

export enum WHReportFilePreviewStoreActionTypes {
  GetWHReportFilePreviewDtoWithParams = '[WHReportFilePreviewStore] GetWHReportFilePreviewDtoWithParams',
  SetWHReportFilePreview = '[WHReportFilePreviewStore] SetWHReportFilePreview',
}

export class GetWHReportFilePreviewDtoWithParams implements Action {
  readonly type = WHReportFilePreviewStoreActionTypes.GetWHReportFilePreviewDtoWithParams;

  constructor(public payload: { params: WHHttpParams, targetParent: string, targetID: string; workObjectTypeId: number }) {
    // console.log('GetWHReportFilePreviewDtoWithParams ' + JSON.stringify(payload));
  }
}

export class SetWHReportFilePreview implements Action {
  readonly type = WHReportFilePreviewStoreActionTypes.SetWHReportFilePreview;

  constructor(public payload: WHReportFilePreviewDOM) {
    // console.log('SetWHEntryFilePreview ' + JSON.stringify(payload));
  }
}


export type WHReportFilePreviewStoreActions = GetWHReportFilePreviewDtoWithParams | SetWHReportFilePreview;
