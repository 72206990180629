// HELPERS
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHWorkObjectTemplateCrudStoreActions, WHWorkObjectTemplateCrudStoreActionTypes } from './w-h-work-object-template-crud-store.actions';

// MODELS
import { WHWorkObjectTemplateDOM } from '@workheld/workheld-shared-lib';

// CONST
export const workheldWorkObjectTemplateCrudStoreFeatureKey = 'workheldWorkObjectTemplateCrudStore$';

export const initialState: WHWorkObjectTemplateDOM = new WHWorkObjectTemplateDOM();

export function workheldWorkObjectTemplateCrudReducer(state = initialState, action: WHWorkObjectTemplateCrudStoreActions): WHWorkObjectTemplateDOM {
  switch (action.type) {

    case WHWorkObjectTemplateCrudStoreActionTypes.ClearWHWorkObjectTemplateCrudSTORE:
      return updateObject(new WHWorkObjectTemplateDOM(), { loading: false, loaded: false } as any);
    case WHWorkObjectTemplateCrudStoreActionTypes.GetWHWorkObjectTemplateDTO:
      return updateObject(new WHWorkObjectTemplateDOM(), { loading: true, loaded: false } as any);

    case WHWorkObjectTemplateCrudStoreActionTypes.SetWHWorkObjectTemplateDOM:
      return updateObject(action.payload, {
        loading: false,
        loaded: true,
      } as any);

    default:
      return state;
  }
}
