// REGISTER ALL ASYNC SERVICES

// ASYNC SERVICES
import { EquipmentAsyncService } from './equipment-async.service';
import { ToolAsyncService } from './tool-async.service';
import { MaterialAsyncService } from './material-async.service';
import { WorkObjectTemplateAsyncService } from './work-object-template-async.service';
import { ChecklistDefinitionAsyncService } from './checklist-definition-async.service';
import { ChecklistInstanceAsyncService } from './checklist-instance-async.service';
import { CheckInstanceFileAsyncService } from './check-instance-file-async.service';
import { EntryAsyncService } from './entry-async.service';
import { WHFileAsyncService } from './w-h-file-async.service';
import { EntryFileAsyncService } from './entry-file-async.service';
import { ReportFileAsyncService } from './report-file-async.service';
import { WorkOrderAsyncService } from './work-order-async.service';
import { WorkOrderFileAsyncService } from './work-order-file-async.service';
import { WorkStepAsyncService } from './work-step-async.service';
import { WorkerAsyncService } from './worker-async.service';
import { SearchAsyncService } from './search-async.service';

export const SERVICES_ASYNC: any[] = [
    EquipmentAsyncService,
    ToolAsyncService,
    MaterialAsyncService,
    WorkObjectTemplateAsyncService,
    ChecklistDefinitionAsyncService,
    ChecklistInstanceAsyncService,
    CheckInstanceFileAsyncService,
    EntryAsyncService,
    WHFileAsyncService,
    EntryFileAsyncService,
    ReportFileAsyncService,
    WorkOrderAsyncService,
    WorkOrderFileAsyncService,
    WorkStepAsyncService,
    WorkerAsyncService,
    SearchAsyncService
];
