// ANGULAR
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// APP
import { CheckDefinitionCreateEditFormRoutingModule } from './check-definition-create-edit-form-routing.module';
import { CheckDefinitionCreateEditFormComponent } from './check-definition-create-edit-form.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [
    CheckDefinitionCreateEditFormComponent
  ],
  exports: [
    CheckDefinitionCreateEditFormComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    AppSharedModule,
    CheckDefinitionCreateEditFormRoutingModule
  ]
})
export class CheckDefinitionCreateEditFormModule { }
