// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHToolDOM } from '@workheld/workheld-shared-lib';

export enum WHToolMasterDataStoreActionTypes {
  GetWHToolDtoList = '[WHToolMasterDataStore] GetWHToolDtoList',
  SetWHToolDtoList = '[WHToolMasterDataStore] SetWHToolDtoList',
}

export class GetWHToolDtoList implements Action {
  readonly type = WHToolMasterDataStoreActionTypes.GetWHToolDtoList;

  constructor() {
    // console.log('GetWHToolDtoList ');
  }
}

export class SetWHToolDtoList implements Action {
  readonly type = WHToolMasterDataStoreActionTypes.SetWHToolDtoList;

  constructor(public payload: { toolDomList: WHToolDOM[], loading: boolean, loaded: boolean }) {
    // console.log('SetWHToolDtoList ' + JSON.stringify(payload));
  }
}

export type WHToolMasterDataStoreActions = GetWHToolDtoList | SetWHToolDtoList;
