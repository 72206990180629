// 3 LIBS
import * as moment from 'moment';

// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';

// ANGULAR MATERIAL
import { MatIconRegistry } from '@angular/material/icon';
import { DateAdapter } from '@angular/material/core';

// AUTH0
import { AuthClientConfig } from '@auth0/auth0-angular';

// @ngx-translate/core
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

// RxJS 6
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

// API
import {
  IWHEnumWithTranslationsDTO,
  IWHMetadataEndpointConfigModel,
  WHMetadataDataService,
  WHThemeConfigService,
  diagnostics$,
  WHLayoutNavItemDOM,
  WHDomEventService,
  WHLoginDataService,
  WHAssetsENUM,
  IWHEndpointConfigModel,
  getAccessTokenFromLocalStorage,
  MGMT_v1_GATEWAY,
} from '@workheld/workheld-shared-lib';

// SCHEDULER LOCALE
import { applyBryntumLocale } from 'src/app/app-pages/w-h-team-planner-page/team-planner-configs/team-planner-locale.config';

// ENV CONFIG
import { localDiagnosticsNavItems } from 'src/environments/env.config';
import { FormReferenceService } from './app-services-helper/form-reference.service';
import { WHFileAsyncService } from './app-services-async/w-h-file-async.service';
import { MatSidenavContent } from '@angular/material/sidenav';
// GA
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  // VAR
  accessToken: string;
  public callEndpointConfig: IWHEndpointConfigModel = {
    apiUrl: 'https://workheld-callservice-dev.azurewebsites.net/api',
  };
  private favIcon: HTMLLinkElement;

  // MANAGE SUBSCRIPTIONS
  private subscription: Subscription = new Subscription();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private renderer2: Renderer2,
    private translateService: TranslateService,
    private metadataDataService: WHMetadataDataService,
    private themeConfigService: WHThemeConfigService,
    private loginDataService: WHLoginDataService,
    authClientConfig: AuthClientConfig,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private router: Router,
    private dateAdapter: DateAdapter<any>,
    private domEventService: WHDomEventService,
    private formReferenceService: FormReferenceService,
    private fileService: WHFileAsyncService,
    // private whCustomErrorService: WHCustomMonitoringService,
  ) {
    if (environment.instrumentationKey) {
      // this.whCustomErrorService.startMonitoring();
    }
    const token = getAccessTokenFromLocalStorage(
      authClientConfig.get().clientId,
    );

    if (token && token.body) {
      this.accessToken = token.body.access_token;
    }
    iconRegistry.addSvgIcon(
      'activate',
      sanitizer.bypassSecurityTrustResourceUrl(WHAssetsENUM.ACTIVATE_ICON),
    );
    iconRegistry.addSvgIcon(
      'deactivate',
      sanitizer.bypassSecurityTrustResourceUrl(WHAssetsENUM.DEACTIVATE_ICON),
    );
    iconRegistry.addSvgIcon(
      'workheld-icon',
      sanitizer.bypassSecurityTrustResourceUrl(WHAssetsENUM.WORKHELD_ICON),
    );
    iconRegistry.addSvgIcon(
      'workheld-call-icon',
      sanitizer.bypassSecurityTrustResourceUrl(WHAssetsENUM.WORKHELD_CALL_ICON),
    );
    iconRegistry.addSvgIcon(
      'workheld-sense-icon',
      sanitizer.bypassSecurityTrustResourceUrl(
        WHAssetsENUM.WORKHELD_SENSE_ICON,
      ),
    );

    // Google Analytics
    if (environment.googleAnalyticsKey) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsKey}`;
      document.head.appendChild(gTagManagerScript);

      // register Google Analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${environment.googleAnalyticsKey}');
      `;
      document.head.appendChild(gaScript);
    }
    // End of Google Analytics
  }
  public throwTestError(): void {
    throw new Error('Sentry Test Error');
  }
  ngOnInit(): void {
    // DARK MODE
    this.subscription.add(
      this.themeConfigService.dark$.subscribe((dark: boolean) => {
        dark
          ? this.renderer2.addClass(this.document.body, 'dark')
          : this.renderer2.removeClass(this.document.body, 'dark');
      }),
    );

    // hack for WHP-7864
    setTimeout(() => {
      const sidenavContent = document.querySelector('mat-sidenav-content');

      if (sidenavContent) {
        this.renderer2.removeClass(sidenavContent, 'mat-drawer-content');
      }
    }, 1000);
    // end of hack

    // SET LOCAL DIAGNOSTICS PAGES
    const diagnostics: WHLayoutNavItemDOM[] = diagnostics$.value;
    diagnostics$.next(diagnostics.concat(localDiagnosticsNavItems));

    // LANGUAGE CHANGES
    this.subscription.add(
      this.translateService.onDefaultLangChange
        .pipe(
          switchMap((langChangeEvent: LangChangeEvent) => {
            // Activate Bryntum locale
            applyBryntumLocale(langChangeEvent.lang);

            const endpointConfigMetadata: IWHMetadataEndpointConfigModel = {
              apiUrl: environment.apiUrl + MGMT_v1_GATEWAY,
            };
            moment.locale(langChangeEvent.lang);
            this.dateAdapter.setLocale(langChangeEvent.lang);
            this.document.documentElement.lang = langChangeEvent.lang;
            const tenantToken: string =
              this.loginDataService.activeTenantDOM$.value.token;
            const lngKey: string = this.translateService.langs?.includes(
              langChangeEvent.lang + '_' + tenantToken,
            )
              ? langChangeEvent.lang + '_' + tenantToken
              : langChangeEvent.lang;
            this.translateService.use(lngKey);
            return this.metadataDataService.getFlowEnumTranslations(
              endpointConfigMetadata,
              {
                language: langChangeEvent.lang,
                type: undefined,
                tenantToken: tenantToken,
              },
            );
          }),
        )
        .subscribe((enumTranslations: IWHEnumWithTranslationsDTO[]) => {
          // console.log(enumTranslations);
          this.metadataDataService.setMetadataTranslation(enumTranslations);
        }),
    );

    if (this.translateService?.defaultLang) {
      this.dateAdapter.setLocale(this.translateService.defaultLang);
    } else {
      this.dateAdapter.setLocale('de');
    }

    // NAVIGATION LISTENER
    this.subscription.add(
      this.router.events.subscribe((event: any) => {
        // console.log(event);
        if (event instanceof NavigationEnd) {
          this.formReferenceService.unsavedChanges = false;
          this.domEventService.lastNavigationId = event.id;
          this.domEventService.routeEventsMap.set(event.id, event);
          if (environment.instrumentationKey) {
            // this.whCustomErrorService.logPageView(event.urlAfterRedirects);
          }
          if (environment.googleAnalyticsKey) {
            gtag('config', environment.googleAnalyticsKey, {
              page_path: event.urlAfterRedirects,
            });
          }

          this._setLeftOffPath(event);
        }
      }),
    );

    // SET FAVICON
    this.favIcon = document.querySelector('#appFavicon');
    const tenantFavicon =
      this.loginDataService.activeTenantDOM$?.value?.customization?.favicon;
    if (tenantFavicon) {
      this.subscription.add(
        this.fileService
          .downloadWHFileBlob(tenantFavicon)
          .pipe(
            tap((blob) => {
              this.favIcon.href = window.URL.createObjectURL(blob);
            }),
          )
          .subscribe(),
      );
    } else {
      this.favIcon.href = './assets/img/workheld-icon.svg';
    }

    // Load GMaps

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?v=quarterly&key=${environment.googleMapsApiKey}&libraries=places&language=${this.translateService.currentLang}`;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  // used for users that does not have permission
  // to view any work objects
  // displays a message with a links to pages user
  // visited before
  private _setLeftOffPath(event) {
    const path = event?.url.split('/').filter((part) => part !== '');
    const localStorageItem = localStorage.getItem('leftOffPath');
    const currentPath = localStorageItem ? JSON.parse(localStorageItem) : [];

    if (path[0] && !currentPath.find((p) => p === path[0])) {
      if (currentPath.length > 2) {
        currentPath.shift();
      }
      currentPath.push(path[0] as string);
    }
    localStorage.setItem('leftOffPath', JSON.stringify(currentPath));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
