// ANGULAR
import { Component, Input } from '@angular/core';

// MODELS
import { IWHLocationDTO } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-w-h-agm-core-location-formatted-address',
  templateUrl: './w-h-agm-core-location-formatted-address.component.html',
  styleUrls: ['./w-h-agm-core-location-formatted-address.component.scss']
})
export class WHAgmCoreLocationFormattedAddressComponent {

  // INPUT
  @Input() public withLabel: boolean = false;
  @Input() public formattedAddressLabel: string = 'location.model.root';

  // DATA INPUT
  @Input() public locationDTO: IWHLocationDTO;

}
