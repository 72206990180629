import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, map, startWith } from 'rxjs';

@Component({
  selector: 'w-h-autocomplete-static',
  templateUrl: './w-h-autocomplete-static.component.html',
  styleUrls: ['./w-h-autocomplete-static.component.scss'],
})
export class WHAutocompleteStaticComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() valueFormControl: FormControl = new FormControl(null);
  @Input() optionsList: any[];
  @Input() optionLabel: string;
  @Input() optionValue: string;
  @Input() optionIcon: string; //Only for fi icon
  @Input() placeholder: string;

  filteredItems: any = [];
  private subscription = new Subscription();
  value: string = '';
  ngOnInit() {
    this.subscription.add(
      this.valueFormControl.valueChanges
        .pipe(
          startWith(''),
          map((value) => {
            this.filteredItems = this._filter(value || '');
          })
        )
        .subscribe()
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    //used for testing
    this.value = value;
    return this.optionsList.filter((option) => {
      if (this.optionLabel) {
        return option[this.optionLabel].toLowerCase().includes(filterValue);
      } else {
        return option.toLowerCase().includes(filterValue);
      }
    });
  }
  displayFn(option) {
    let displayValue;
    if (this.optionValue) {
      displayValue = option;
    } else if (this.optionLabel) {
      displayValue = option[this.optionLabel];
    } else {
      displayValue = option;
    }
    return displayValue;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
