// ANGULAR
import { Component, Input } from '@angular/core';

// MODELS
import { WHEntryDOM } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-entry-static-data',
  templateUrl: './entry-static-data.component.html',
  styleUrls: ['./entry-static-data.component.scss'],
})
export class EntryStaticDataComponent {
  @Input() public entryDOM: WHEntryDOM & {
    workObjectStatusId: number;
    workObjectDetails: any;
  };
}
