// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHToolMasterDataStore from './w-h-tool-master-data-store.reducer';
import { WHToolMasterDataStoreEffects } from './w-h-tool-master-data-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHToolMasterDataStore.workheldToolMasterDataStoreFeatureKey, fromWHToolMasterDataStore.workheldToolMasterDataReducer),
    EffectsModule.forFeature([WHToolMasterDataStoreEffects])
  ]
})
export class WHToolMasterDataStoreModule { }
