// ANGULAR
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// APP
import { ListComponent } from './list.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';
import { WHSearchTermUtilModule } from 'src/app/app-modules-pages/w-h-search-term-util/w-h-search-term-util.module';

@NgModule({
  declarations: [
    ListComponent
  ],
  exports: [
    ListComponent
  ],
  imports: [
    FormsModule,
    AppSharedModule,
    WHSearchTermUtilModule
  ]
})
export class ListModule { }
