// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHCustomerDOM } from '@workheld/workheld-shared-lib';

export enum WHCustomerMasterDataStoreActionTypes {
  GetCustomerDtoList = '[WHCustomerMasterDataStore] GetCustomerDtoList',
  SetCustomerDomList = '[WHCustomerMasterDataStore] SetCustomerDomList',
}

export class GetCustomerDtoList implements Action {
  readonly type = WHCustomerMasterDataStoreActionTypes.GetCustomerDtoList;

  constructor() {
    // console.log('GetCustomerDtoList');
  }
}

export class SetCustomerDomList implements Action {
  readonly type = WHCustomerMasterDataStoreActionTypes.SetCustomerDomList;

  constructor(public payload: { customerDomList: WHCustomerDOM[], loading: boolean, loaded: boolean }) {
    // console.log('SetCustomerDomList ' + JSON.stringify(payload));
  }
}

export type WHCustomerMasterDataStoreActions =
  GetCustomerDtoList |
  SetCustomerDomList
  ;
