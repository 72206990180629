// HELPER
import { updateObject, WHWorkObjectPreviewDOM } from '@workheld/workheld-shared-lib';

// REDUX
import { WHWorkObjectPreviewStoreActions, WHWorkObjectPreviewStoreActionTypes } from './w-h-work-object-preview-store.actions';

// MODELS

export const workheldWorkObjectPreviewStoreFeatureKey = 'workheldWorkObjectPreviewStore$';

export const initialState: WHWorkObjectPreviewDOM = new WHWorkObjectPreviewDOM();

export function workheldWorkObjectPreviewReducer(state = initialState, action: WHWorkObjectPreviewStoreActions): WHWorkObjectPreviewDOM {
  switch (action.type) {

    case WHWorkObjectPreviewStoreActionTypes.GetWHWorkObjectPreviewDtoWithParams:
      //const payload: WHHttpParams = action.payload;
      return updateObject<WHWorkObjectPreviewDOM>(state, {
        loading: true,
        loaded: false
      } as any);

    case WHWorkObjectPreviewStoreActionTypes.SetWHWorkObjectPreview:
      return action.payload;

    default:
      return state;
  }
}
