import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

export interface CheckUnsavedComponent {
  checkUnsaved: () => Observable<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CheckUnsavedCanDeactivateGuard
  
{
  canDeactivate(
    component: CheckUnsavedComponent
  ): Observable<boolean> | boolean {
    return component.checkUnsaved?.();
  }
}
