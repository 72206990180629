// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHWorkOrderCrudStore from './w-h-work-order-crud-store.reducer';
import { WHWorkOrderCrudStoreEffects } from './w-h-work-order-crud-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHWorkOrderCrudStore.workheldWorkOrderCrudStoreFeatureKey, fromWHWorkOrderCrudStore.workheldWorkOrderCrudReducer),
    EffectsModule.forFeature([WHWorkOrderCrudStoreEffects])
  ]
})
export class WHWorkOrderCrudStoreModule { }
