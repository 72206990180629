// ANGULAR
import {
  Component,
  AfterViewChecked,
  OnChanges,
  SimpleChanges,
  ElementRef,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';

// SERVICES
import { WHIconENUM } from '@workheld/workheld-shared-lib';
import { Observable, debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'ng-bee-w-h-search-term-util',
  templateUrl: './w-h-search-term-util.component.html',
  styleUrls: ['./w-h-search-term-util.component.scss'],
})
export class WHSearchTermUtilComponent
  implements OnInit, OnChanges, AfterViewChecked, OnDestroy
{
  // INPUT STATE
  @Input() public focusElement: boolean = false;
  @Input() public initialValue: string;
  @Input() public isWithSearchHeaderIcon: boolean = false;
  @Input() public icon: string;
  @Input() public isMaterialIcon: boolean = false;
  @Input() public isCharTriggerLimited: boolean = false;
  @Input('clear') set _clearValue(value: boolean) {
    if (value) {
      this.inputField.nativeElement.value = '';
    }
  }

  private initializing: boolean = true;
  // DOM
  @ViewChild('inputField', { read: ElementRef, static: true })
  public inputField: ElementRef<HTMLInputElement>;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onSearchTermInput: EventEmitter<string> = new EventEmitter(
    false
  );

  get searchTerm() {
    return this.inputField.nativeElement.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialValue && changes.initialValue.currentValue) {
      this.inputField.nativeElement.value = changes.initialValue.currentValue;
    }
    // throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.inputField.nativeElement.value = this.initialValue
      ? this.initialValue
      : '';

    const inputObservable: Observable<Event> = fromEvent(
      this.inputField.nativeElement,
      'keyup'
    );

    inputObservable.pipe(debounceTime(300)).subscribe((event: Event) => {
      this.onKey(event);
    });
  }

  ngAfterViewChecked() {
    if (this.focusElement && this.initializing) {
      this.inputField.nativeElement.focus();
      this.initializing = false;
    }
  }

  public onKey($event: KeyboardEvent | any): void {
    // console.log($event);
    if (this.isBannedKey($event)) {
      return;
    }

    if (!this.isCharTriggerLimited) {
      this.onSearchTermInput.emit($event.target.value);
      return;
    }
    if ($event.target.value.length > 3) {
      this.onSearchTermInput.emit($event.target.value);
    } else {
      this.onSearchTermInput.emit(null);
    }
  }

  public clearSearchInput(): void {
    this.inputField.nativeElement.value = '';
    this.onSearchTermInput.emit(null);
    //Does not trigger first time.
    this.onSearchTermInput.emit(null);
  }

  public get displayIcon() {
    return this.icon ? this.icon : (WHIconENUM.ServerIcon as string);
  }

  public ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
  }

  private isBannedKey($event: KeyboardEvent) {
    const bannedKeys: number[] = [37, 38, 39, 40];
    // tslint:disable-next-line: deprecation
    return bannedKeys.includes($event.keyCode);
  }
}
