<div class="mat-dialog-ngx-image-cropper">
    <div class="detail-card-header mat-dialog-ngx-image-header">
        <span whFlex class="w-h-header-icon-span">
            <i mat-card-avatar class="workheld-icons header-icon" [innerHtml]="cropIcon"></i>
        </span>
        <span whFlex class="w-h-header-span">
            <div class="single-header">
                {{ 'app.picturecrop.label' | translate }}
            </div>
        </span>
    </div>
    <div>
        <span whFlex="20" style="text-align: center;">
            <img alt="croppedImage" class="picture mat-elevation-z4" [src]="croppedImage" />
        </span>
        <span whFlex>
            <div class="cropper-container" fxLayout="column" whFlex>
                <div class="image-cropper-wrapper">
                    <image-cropper
                        [imageChangedEvent]="data.imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="1" 
                        [resizeToWidth]="270"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        output="base64"
                        (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()">
                    </image-cropper>
                </div>
            </div>
        </span>
    </div>
    <div class="dialog-action-wrapper" mat-dialog-actions>
        
        <button 
            mat-flat-button 
            class="dialog-button w-h-default-button mat-elevation-z2" 
            (click)="cancel()">
            <i class="workheld-icons" [innerHTML]="cancelIcon"></i> 
            {{ ('app.cancel.label' | translate) | uppercase }}
        </button>
        <button 
            mat-flat-button 
            class="dialog-button mat-elevation-z2" 
            color="accent" 
            data-cy="image-crop-submit"
            [mat-dialog-close]="croppedImage">
            <i class="workheld-icons" [innerHTML]="saveIcon"></i> 
            {{ ('app.save.label' | translate) | uppercase }}
        </button>
    </div>
</div>
