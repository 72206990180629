// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { EntryFileAsyncService } from 'src/app/app-services-async/entry-file-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHEntryFilePreviewStoreActionTypes,
  WHEntryFilePreviewStoreActions,
  GetWHEntryFilePreviewDtoWithParams,
  SetWHEntryFilePreview,
} from './w-h-entry-file-preview-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import {
  IWHEntryFilePreviewDTO,
  WHEntryFilePreviewDOM,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WHEntryFilePreviewStoreEffects {
  getWHEntryFilePreviewDtoWithParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHEntryFilePreviewStoreActionTypes.GetWHEntryFilePreviewDtoWithParams
      ),
      switchMap((action: GetWHEntryFilePreviewDtoWithParams) => {
        return this.entryFileAsyncService.getEntryFilePreview(
          action.payload.params,
          action.payload.targetParent,
          action.payload.targetID,
          action.payload.workObjectTypeId
        );
      }),
      mergeMap((entryFilePreviewDTO: IWHEntryFilePreviewDTO) => {
        const entryFilePreviewDOM: WHEntryFilePreviewDOM =
          this.dataModelHelperService.initEntryFilePreviewDOM(
            entryFilePreviewDTO
          );
        return [new SetWHEntryFilePreview(entryFilePreviewDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHEntryFilePreviewStoreActions>,
    private entryFileAsyncService: EntryFileAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
