<div class="html-overflow">
  <div class="w-h-label html-overflow" *ngIf="withLabel">
    {{ nameLabel | translate }}
  </div>
  <div class="html-overflow" *ngIf="materialDTO">
    <span class="material-icon-span">
      <img
        alt="MaterialImage"
        *ngIf="
          materialDTO?.pictureURL &&
            (materialDTO?.pictureURL
              | WHImgAuthHeader : materialPlaceholderPNG
              | async) as image;
          else loading
        "
        class="material-avatar"
        [src]="image"
      />
      <ng-template #loading>
        <!-- Loading... -->
      </ng-template>
      <i
        *ngIf="!materialDTO?.pictureURL"
        class="workheld-icons material-icon"
        [innerHTML]="materialConsumptionIcon"
      >
      </i>
    </span>
    <span whFlex class="material-detail-span">
      <div class="html-overflow">
        {{ materialDTO?.extId }}
      </div>
      <div class="html-overflow">
        {{ materialDTO?.title }}
      </div>
    </span>
  </div>
  <div *ngIf="!materialDTO" class="material-default-value">-</div>
</div>
