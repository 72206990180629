// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHCustomerDOM } from '@workheld/workheld-shared-lib';

export enum WHCustomerCrudStoreActionTypes {
  GetWHCustomerDTO = '[WHCustomerCrudStore] GetWHCustomerDTO',
  SetWHCustomerDOM = '[WHCustomerCrudStore] SetWHCustomerDOM',
  ClearWHCustomerCrudSTORE = '[WHCustomerCrudStore] ClearWHCustomerCrudSTORE',
}

export class GetWHCustomerDTO implements Action {
  readonly type = WHCustomerCrudStoreActionTypes.GetWHCustomerDTO;

  constructor(public payload: WHHttpParams) {
    // console.log('GetWHCustomerDTO: ' + JSON.stringify(payload));
  }
}

export class SetWHCustomerDOM implements Action {
  readonly type = WHCustomerCrudStoreActionTypes.SetWHCustomerDOM;

  constructor(public payload: WHCustomerDOM) {
    // console.log('SetWHCustomerDOM: ' + JSON.stringify(payload));
  }
}

export class ClearWHCustomerCrudSTORE implements Action {
  readonly type = WHCustomerCrudStoreActionTypes.ClearWHCustomerCrudSTORE;

  constructor() {
    // console.log('ClearWHCustomerCrudSTORE');
  }
}

export type WHCustomerCrudStoreActions =
  GetWHCustomerDTO
  | SetWHCustomerDOM
  | ClearWHCustomerCrudSTORE
  ;
