// REDUX
import { WHToolMasterDataStoreActions, WHToolMasterDataStoreActionTypes } from './w-h-tool-master-data-store.actions';

// MODELS
import { WHToolDOM } from '@workheld/workheld-shared-lib';

export const workheldToolMasterDataStoreFeatureKey = 'workheldToolMasterDataStore$';

export interface IWHToolMasterDataStore {
  toolDomList: WHToolDOM[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHToolMasterDataStore = {
  toolDomList: [],
  loading: false,
  loaded: false,
};

export function workheldToolMasterDataReducer(state = initialState, action: WHToolMasterDataStoreActions): IWHToolMasterDataStore {
  switch (action.type) {

    case WHToolMasterDataStoreActionTypes.GetWHToolDtoList:
      return {
        toolDomList: [],
        loading: true,
        loaded: false,
      };

    case WHToolMasterDataStoreActionTypes.SetWHToolDtoList:
      return action.payload;

    default:
      return state;
  }
}
