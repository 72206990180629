// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHMaterialCrudStore from './w-h-material-crud-store.reducer';
import { WHMaterialCrudStoreEffects } from './w-h-material-crud-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHMaterialCrudStore.workheldMaterialCrudStoreFeatureKey, fromWHMaterialCrudStore.workheldMaterialCrudReducer),
    EffectsModule.forFeature([WHMaterialCrudStoreEffects])
  ]
})
export class WHMaterialCrudStoreModule { }
