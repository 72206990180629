import workheldWebClient from '../../package.json';
import { AuthConfig } from '@auth0/auth0-angular';

import {
  googleMapsApiKeyPROD,
  IWHEnvironmentModel,
  WHClientIdENUM,
  WHDomainENUM,
  WHApiUrlENUM,
  WHClientBaseUrlENUM,
  WHFeatureKeyENUM,
} from '@workheld/workheld-shared-lib';
export const workheldWebClientVersion = workheldWebClient.version as string;

const devFeatureConfigMap: Map<string, boolean> = new Map()
  .set(WHFeatureKeyENUM.PROFILE, true)
  .set(WHFeatureKeyENUM.SETTINGS, false)
  .set(WHFeatureKeyENUM.DIAGNOSTICS, false)
  .set(WHFeatureKeyENUM.DARK, false);

export const environment: IWHEnvironmentModel = {
  production: process.env['PRODUCTION'] == 'true' || false,
  stage: process.env['STAGE'] || 'dev',
  version: `${workheldWebClientVersion}`,
  apiUrl: process.env['API_URL'] || WHApiUrlENUM.DEV_API_URL,
  mgmtUrl: process.env['MGMT_URL'] || WHClientBaseUrlENUM.MGMT_DEV,
  flowUrl: process.env['FLOW_URL'] || WHClientBaseUrlENUM.FLOW_DEV,
  callUrl: process.env['CALL_URL'] || WHClientBaseUrlENUM.CALL_DEV,
  whSenseUrl: process.env['WH_SENSE_URL'] || WHClientBaseUrlENUM.WH_SENSE,
  instrumentationKey: process.env['INSTRUMENTATION_KEY'] || '',
  wssUrl: WHApiUrlENUM.CALL_DEV_WSS_URL,
  auth0Config: {
    authorizationParams: {
      audience: 'workheld',
      redirect_uri: window.location.origin,
    },
    domain: process.env['AUTH0_DOMAIN'] || WHDomainENUM.DEV,
    clientId: process.env['AUTH0_CLIENT_ID'] || WHClientIdENUM.FLOW_DEV,
    httpInterceptor: {
      allowedList: [
        `${process.env['API_URL'] || WHApiUrlENUM.DEV_API_URL}*`,
        `${'https://workheld-callservice-dev.azurewebsites.net/'}*`,
      ],
    },
    additionalParameters: {
      response_type: 'token',
    },
    cacheLocation: 'localstorage',
    cookieDomain: '.workheld.com',
  } as AuthConfig,

  googleMapsApiKey: process.env['GOOGLE_MAPS_API_KEY'] || googleMapsApiKeyPROD,
  googleAnalyticsKey: process.env['GOOGLE_ANALYTICS_ID'] || 'G-5B792L49W9',
  devFeatureConfigMap,
};
