// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { WHStatusBarUtilRoutingModule } from './w-h-status-bar-util-routing.module';
import { WHStatusBarUtilComponent } from './w-h-status-bar-util.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';

@NgModule({
  declarations: [
    WHStatusBarUtilComponent
  ],
  exports: [
    WHStatusBarUtilComponent
  ],
  imports: [
    AppSharedModule,
    WHStatusBarUtilRoutingModule
  ]
})
export class WHStatusBarUtilModule { }
