// API
import { updateObject, WHReportFilePreviewDOM } from '@workheld/workheld-shared-lib';

// REDUX
import { WHReportFilePreviewStoreActions, WHReportFilePreviewStoreActionTypes } from './w-h-report-file-preview-store.actions';

// MODELS

export const workheldReportFilePreviewStoreFeatureKey = 'workheldReportFilePreviewStore$';

export const initialState: WHReportFilePreviewDOM = new WHReportFilePreviewDOM();

export function workheldReportFilePreviewReducer(state = initialState, action: WHReportFilePreviewStoreActions): WHReportFilePreviewDOM {
  switch (action.type) {

    case WHReportFilePreviewStoreActionTypes.GetWHReportFilePreviewDtoWithParams:
      //const payload: WHHttpParams = action.payload;
      return updateObject<WHReportFilePreviewDOM>(state, {
        loading: true,
      } as any);

    case WHReportFilePreviewStoreActionTypes.SetWHReportFilePreview:
      return action.payload;

    default:
      return state;
  }
}
