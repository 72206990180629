<div class="location-form">
  <form [formGroup]="locationFormGroup">
    <span class="location-form-span" whFlex="50">
      <div>
        <ng-bee-w-h-agm-core-search-util
          [tabIndex]="searchTabIndex"
          (onPlaceChanged)="placeChanged($event)"
        >
        </ng-bee-w-h-agm-core-search-util>
      </div>
      <div>
        <mat-form-field whFlex>
          <mat-label>{{ "location.model.name" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'location.model.name' | translate }}"
            formControlName="name"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="coordination-wrapper">
        <span class="flex-1">
          <div>
            <mat-form-field whFlex>
              <mat-label>{{ "location.model.latitude" | translate }}</mat-label>
              <input
                type="number"
                matInput
                placeholder="{{ 'location.model.latitude' | translate }}"
                formControlName="latitude"
              />
            </mat-form-field>
          </div>
        </span>
        <span class="flex-1">
          <div>
            <mat-form-field whFlex>
              <mat-label>{{
                "location.model.longitude" | translate
              }}</mat-label>
              <input
                type="number"
                matInput
                placeholder="{{ 'location.model.longitude' | translate }}"
                formControlName="longitude"
              />
            </mat-form-field>
          </div>
        </span>
        <div class="search-by-coords-span">
          <button
            type="button"
            mat-icon-button
            color="accent"
            (click)="geoLocationSearch()"
            [disabled]="!isGeoSet"
            matTooltip="{{
              'location.ui.searchbycoordinates.tooltip' | translate
            }}"
          >
            <mat-icon>
              {{ locationByGeoIcon }}
            </mat-icon>
          </button>
        </div>
      </div>
      <div>
        <mat-form-field whFlex>
          <mat-label>{{
            "location.model.formattedaddress" | translate
          }}</mat-label>
          <input
            matInput
            placeholder="{{ 'location.model.formattedaddress' | translate }}"
            formControlName="formattedAddress"
            [tabIndex]="formattedAddressTabIndex"
            autocomplete="off"
          />
        </mat-form-field>
        <div class="is-exact-wrapper">
          <i
            class="workheld-icons"
            [ngStyle]="{ color: isExact ? '#63a021' : '#aaa' }"
            [innerHTML]="isExact ? locationExactIcon : locationNotExactIcon"
          ></i>
          {{
            (isExact ? "location.ui.exact.label" : "location.ui.notexact.label")
              | translate
          }}
        </div>
      </div>
      <div>
        <mat-form-field whFlex>
          <mat-label>{{ "location.model.country" | translate }}</mat-label>
          <mat-select formControlName="countryCodeISO">
            <mat-option
              *ngFor="let countryISO of supportedCountryIsoEnums"
              [value]="countryISO"
            >
              {{ COUNTRY[countryISO] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field whFlex>
          <mat-label>{{
            "location.model.locationsupplement" | translate
          }}</mat-label>
          <textarea
            rows="3"
            matInput
            formControlName="locationSupplement"
            placeholder="{{ 'location.model.locationsupplement' | translate }}"
          >
          </textarea>
        </mat-form-field>
      </div>
    </span>
    <span whFlex="50">
      <div
        class="mat-elevation-z2"
        *ngIf="!supportDragNDrop || (supportDragNDrop && !draggable.data)"
      >
        <google-map
          #map
          height="400px"
          width="100%"
          [center]="{ lat: latitude, lng: longitude }"
          [zoom]="zoom"
          (mapClick)="addMarker($event)"
        >
          <map-marker
            #marker
            *ngIf="isGeoSet"
            [icon]="'./assets/img/location-map-pin.png'"
            [position]="{ lat: latitude, lng: longitude }"
            [options]="markerOptions"
            (mapDragend)="markerDragEnd($event)"
          ></map-marker>
          <map-info-window>
            <div style="display: flex; align-items: center; gap: 12px">
              <i
                class="workheld-icons"
                [innerHTML]="isExact ? locationExactIcon : locationNotExactIcon"
              ></i>
              <strong>{{
                (isExact
                  ? "location.ui.exact.label"
                  : "location.ui.notexact.label"
                ) | translate
              }}</strong>
            </div>
          </map-info-window>
        </google-map>
      </div>
      <div
        *ngIf="supportDragNDrop && draggable.data"
        class="support-drag-n-drop-placeholder"
      ></div>
    </span>
  </form>
</div>
