<mat-form-field class="search-field">
  <mat-icon class="search-term-input-icon" matPrefix>search</mat-icon>
  <input
    #inputField
    matInput
    data-cy="mat-form-search-input"
    style="line-height: 1.9"
    placeholder="{{ 'app.search.label' | translate }}"
  />
  <mat-icon
    class="close-icon"
    *ngIf="searchTerm"
    matSuffix
    (click)="clearSearchInput()"
  >
    close
  </mat-icon>
</mat-form-field>
