// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { ChecklistInstanceFileImgGalleryRoutingModule } from './checklist-instance-file-img-gallery-routing.module';
import { ChecklistInstanceFileImgGalleryComponent } from './checklist-instance-file-img-gallery.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';
import { WHImgAuthHeaderModule } from '@workheld/workheld-shared-lib';

@NgModule({
  declarations: [
    ChecklistInstanceFileImgGalleryComponent
  ],
  exports: [
    ChecklistInstanceFileImgGalleryComponent
  ],
  imports: [
    AppSharedModule,
    WHImgAuthHeaderModule,
    ChecklistInstanceFileImgGalleryRoutingModule
  ]
})
export class ChecklistInstanceFileImgGalleryModule { }
