// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import {
  FLOW_GATEWAY,
  IWHCustomerEndpointConfigModel,
  WHCustomerDataService,
} from '@workheld/workheld-shared-lib';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHCustomerPreviewStoreActionTypes,
  WHCustomerPreviewStoreActions,
  GetWHCustomerPreviewDtoWithParams,
  SetWHCustomerPreview,
} from './w-h-customer-preview-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import {
  IWHCustomerPreviewDTO,
  WHCustomerPreviewDOM,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WHCustomerPreviewStoreEffects {
  getWHCustomerPreviewDtoWithParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHCustomerPreviewStoreActionTypes.GetWHCustomerPreviewDtoWithParams
      ),
      switchMap((action: GetWHCustomerPreviewDtoWithParams) => {
        const endpointConfig: IWHCustomerEndpointConfigModel = {
          apiUrl: environment.apiUrl + FLOW_GATEWAY,
        };
        return this.customerDataService.getCustomerPreview(
          endpointConfig,
          action.payload
        );
      }),
      mergeMap((customerPreviewDTO: IWHCustomerPreviewDTO) => {
        const customerPreviewDOM: WHCustomerPreviewDOM =
          new WHCustomerPreviewDOM(customerPreviewDTO);
        return [new SetWHCustomerPreview(customerPreviewDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHCustomerPreviewStoreActions>,
    private customerDataService: WHCustomerDataService,
  ) {}
}
