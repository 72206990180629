// ANGULAR
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// APP
import { EntryProcessFormRoutingModule } from './entry-process-form-routing.module';
import { EntryProcessFormComponent } from './entry-process-form.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';
import { WHPermissionCheckModule } from '@workheld/workheld-shared-lib';

@NgModule({
  declarations: [EntryProcessFormComponent],
  exports: [EntryProcessFormComponent],
  imports: [
    ReactiveFormsModule,
    AppSharedModule,
    EntryProcessFormRoutingModule,
    WHPermissionCheckModule,
  ],
})
export class EntryProcessFormModule {}
