// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHWorkObjectPreviewStore from './w-h-work-object-preview-store.reducer';
import { WHWorkObjectPreviewStoreEffects } from './w-h-work-object-preview-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHWorkObjectPreviewStore.workheldWorkObjectPreviewStoreFeatureKey, fromWHWorkObjectPreviewStore.workheldWorkObjectPreviewReducer),
    EffectsModule.forFeature([WHWorkObjectPreviewStoreEffects])
  ]
})
export class WHWorkObjectPreviewStoreModule { }
