// ANGULAR
import { Component, Input, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// SERVICES
import { WHDataService, WHIconENUM } from '@workheld/workheld-shared-lib';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// RxJS 6
import { Subscription } from 'rxjs';

// MODELS
import { WHWorkStepDOM, IWHWorkStepDTO } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-work-step-static-data',
  templateUrl: './work-step-static-data.component.html',
  styleUrls: ['./work-step-static-data.component.scss']
})
export class WorkStepStaticDataComponent implements OnChanges, OnDestroy {

  // INPUT
  @Input() public label: string = 'workstep.model.root';
  @Input() public detailURL: string;
  @Input() public withLabel: boolean = false;
  public workStepDOM: WHWorkStepDOM;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  constructor(
    private dataService: WHDataService,
    private dataModelHelperService: DataModelHelperService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.detailURL && changes.detailURL.currentValue) {
      this.subscriptions.push(this.dataService.getByUrl(changes.detailURL.currentValue)
        .subscribe((workStepDTO: IWHWorkStepDTO | any) => {
          this.workStepDOM = this.dataModelHelperService.initWorkStepDOM(workStepDTO);
          this.changeDetectorRef.detectChanges();
        })
      );
    }
  }

  public get workStepIcon(): string {
    return WHIconENUM.WorkStepIcon as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
