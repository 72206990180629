// REDUX
import { WHEquipmentMasterDataStoreActions, WHEquipmentMasterDataStoreActionTypes } from './w-h-equipment-master-data-store.actions';

// MODELS
import { WHEquipmentDOM } from '@workheld/workheld-shared-lib';

export const workheldEquipmentMasterDataStoreFeatureKey = 'workheldEquipmentMasterDataStore$';

export interface IWHEquipmentMasterDataStore {
  equipmentDomList: WHEquipmentDOM[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHEquipmentMasterDataStore = {
  equipmentDomList: [],
  loading: false,
  loaded: false,
};

export function workheldEquipmentMasterDataReducer(state = initialState, action: WHEquipmentMasterDataStoreActions): IWHEquipmentMasterDataStore {
  switch (action.type) {

    case WHEquipmentMasterDataStoreActionTypes.GetWHEquipmentDtoList:
      return {
        equipmentDomList: [],
        loading: true,
        loaded: false,
      };

    case WHEquipmentMasterDataStoreActionTypes.SetWHEquipmentDtoList:
      return action.payload;

    default:
      return state;
  }
}
