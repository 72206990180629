// HELPERS
import { updateObject, WHToolPreviewDOM } from '@workheld/workheld-shared-lib';

// REDUX
import { WHToolPreviewStoreActions, WHToolPreviewStoreActionTypes } from './w-h-tool-preview-store.actions';

// MODELS

export const workheldToolPreviewStoreFeatureKey = 'workheldToolPreviewStore$';

export const initialState: WHToolPreviewDOM = new WHToolPreviewDOM();

export function workheldToolPreviewReducer(state = initialState, action: WHToolPreviewStoreActions): WHToolPreviewDOM {
  switch (action.type) {

    case WHToolPreviewStoreActionTypes.GetWHToolPreviewDtoWithParams:
      //const payload: WHHttpParams = action.payload;
      return updateObject<WHToolPreviewDOM>(state, {
        loading: true,
        loaded: false
      } as any);

    case WHToolPreviewStoreActionTypes.SetWHToolPreview:
      return action.payload;

    default:
      return state;
  }
}
