// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHEquipmentMasterDataStore from './w-h-equipment-master-data-store.reducer';
import { WHEquipmentMasterDataStoreEffects } from './w-h-equipment-master-data-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHEquipmentMasterDataStore.workheldEquipmentMasterDataStoreFeatureKey, fromWHEquipmentMasterDataStore.workheldEquipmentMasterDataReducer),
    EffectsModule.forFeature([WHEquipmentMasterDataStoreEffects])
  ]
})
export class WHEquipmentMasterDataStoreModule { }
