// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { ChecklistInstanceAsyncService } from 'src/app/app-services-async/checklist-instance-async.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHChecklistInstancePreviewStoreActionTypes,
  GetWHChecklistInstancePreviewDtoWithParams,
  SetWHChecklistInstancePreview,
} from './w-h-checklist-instance-preview-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import {
  WHChecklistInstancePreviewDOM,
  IWHChecklistInstancePreviewDTO,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WHChecklistInstancePreviewStoreEffects {
  getWHChecklistInstancePreviewDtoWithParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHChecklistInstancePreviewStoreActionTypes.GetWHChecklistInstancePreviewDtoWithParams
      ),
      switchMap((action: GetWHChecklistInstancePreviewDtoWithParams) => {
        return this.checklistInstanceAsyncService.getChecklistInstancePreview(
          action.payload.params,
          action.payload.targetParent,
          action.payload.targetID,
          action.payload.workObjectTypeId
        );
      }),
      mergeMap(
        (checklistInstancePreviewDTO: IWHChecklistInstancePreviewDTO) => {
          const checklistInstancePreviewDOM: WHChecklistInstancePreviewDOM =
            this.dataModelHelperService.initChecklistInstancePreviewDOM(
              checklistInstancePreviewDTO
            );
          // console.log(checklistInstancePreviewDOM);
          return [
            new SetWHChecklistInstancePreview(checklistInstancePreviewDOM),
          ];
        }
      )
    );
  });

  constructor(
    private actions$: Actions,
    private dataModelHelperService: DataModelHelperService,
    private checklistInstanceAsyncService: ChecklistInstanceAsyncService
  ) {}
}
