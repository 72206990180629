// API

import { IWHBomNodeDTO, sortBySortPosition, WHIconENUM, WHNestedNodeDOM } from "@workheld/workheld-shared-lib";

export function searchTree(element: WHNestedBomNodeDOM, nodeId: string) {
  if (element.id == nodeId) {
    return element;
  } else if (element.children != null) {
    let i: number;
    let result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = this.searchTree(element.children[i], nodeId);
    }
    return result;
  }
  return null;
}

export function list_to_tree(list: WHNestedBomNodeDOM[]) {
  let map = {}, node, roots = [], i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentNodeId) {
      // if you have dangling branches check that map[node.parentNodeId] exists
      list[map[node.parentNodeId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  roots = sortNodes(roots);
  return roots;
}

function sortNodes(roots: any[]): WHNestedBomNodeDOM[] {
  roots.forEach((root: WHNestedBomNodeDOM) => {
    if (root.children) {
      root.children = sortBySortPosition(root.children);
      sortNodes(root.children);
    }
  });
  return roots;
}

export class WHNestedBomNodeDOM extends WHNestedNodeDOM {

  // DOM
  children: WHNestedNodeDOM[] = [];
  icon?: string = WHIconENUM.BomIcon;

  constructor(data: IWHBomNodeDTO) {
    super(data)
    if (data) {
      // DTO
      Object.entries(data).forEach((property: any[]) => {
        this[property[0]] = property[1];
      });
    }
  }
}
