// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { EntryAsyncService } from 'src/app/app-services-async/entry-async.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHEntryPreviewStoreActionTypes,
  WHEntryPreviewStoreActions,
  GetWHEntryPreviewDtoWithParams,
  SetWHEntryPreview,
} from './w-h-entry-preview-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import {
  IWHEntryPreviewDTO,
  WHEntryPreviewDOM,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WHEntryPreviewStoreEffects {
  getWHEntryPreviewDtoWithParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHEntryPreviewStoreActionTypes.GetWHEntryPreviewDtoWithParams),
      switchMap((action: GetWHEntryPreviewDtoWithParams) => {
        return this.entryAsyncService.getEntryPreview(
          action.payload.params,
          action.payload.targetParent,
          action.payload.targetID,
          action.payload.workObjectTypeId
        );
      }),
      mergeMap((entryPreviewDTO: IWHEntryPreviewDTO) => {
        const entryPreviewDOM: WHEntryPreviewDOM =
          this.dataModelHelperService.initEntryPreviewDOM(entryPreviewDTO);
        return [new SetWHEntryPreview(entryPreviewDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHEntryPreviewStoreActions>,
    private dataModelHelperService: DataModelHelperService,
    private entryAsyncService: EntryAsyncService
  ) {}
}
