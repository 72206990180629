// HELPERS
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHCustomerCrudStoreActions, WHCustomerCrudStoreActionTypes } from './w-h-customer-crud-store.actions';

// CONST
export const workheldCustomerCrudStoreFeatureKey = 'workheldCustomerCrudStore$';

// MODELS
import { WHCustomerDOM } from '@workheld/workheld-shared-lib';

export const initialState: WHCustomerDOM = new WHCustomerDOM();

export function workheldCustomerCrudReducer(state = initialState, action: WHCustomerCrudStoreActions): WHCustomerDOM {
  switch (action.type) {

    case WHCustomerCrudStoreActionTypes.ClearWHCustomerCrudSTORE:
      return new WHCustomerDOM();
    case WHCustomerCrudStoreActionTypes.GetWHCustomerDTO:
      return updateObject(new WHCustomerDOM(), { loading: true, loaded: false } as any);

    case WHCustomerCrudStoreActionTypes.SetWHCustomerDOM:
      return updateObject(action.payload, {
        loading: false,
        loaded: true,
      } as any);

    default:
      return state;
  }
}
