// ANGULAR
import { Injectable } from '@angular/core';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHMaterialCrudStoreActionTypes,
  WHMaterialCrudStoreActions,
  GetWHMaterialDTO,
  SetWHMaterialDOM,
} from './w-h-material-crud-store.actions';

// SERVICES
import { MaterialAsyncService } from 'src/app/app-services-async/material-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import { IWHMaterialDTO, WHMaterialDOM } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHMaterialCrudStoreEffects {
  getWHMaterialDTO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHMaterialCrudStoreActionTypes.GetWHMaterialDTO),
      switchMap((action: GetWHMaterialDTO) => {
        return this.materialAsyncService.getMaterialDtoByMaterialId(
          action.payload.targetID
        );
      }),
      mergeMap((materialDTO: IWHMaterialDTO) => {
        const materialDOM: WHMaterialDOM =
          this.dataModelHelperService.initMaterialDOM(materialDTO);
        return [new SetWHMaterialDOM(materialDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHMaterialCrudStoreActions>,
    private materialAsyncService: MaterialAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
