import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  tap,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  FLOW_GATEWAY,
  WHDomEventService,
  WHJobRequirementsDataService,
  WHNgxToastrENUM,
  WHNgxToastrService,
  noWhitespaceValidator,
} from '@workheld/workheld-shared-lib';
import {
  IWHEquipmentJobRequirementsDTO,
  IWHJobRequirementsDTO,
} from '@workheld/workheld-shared-lib/lib/models-flow/w-h-job-requirements.model';
class JobRequirementEntity {
  _jobRequirements: BehaviorSubject<JobRequirementsDOM>;
  jobRequirements$: Observable<JobRequirementsDOM>;
  _allActiveJobRequirements: BehaviorSubject<IWHJobRequirementsDTO[]>;
  allActiveJobRequirements$: Observable<IWHJobRequirementsDTO[]>;
  _jobRequirement: BehaviorSubject<IWHJobRequirementsDTO>;
  jobRequirement$: Observable<any>;
  _equipmentJobRequirements: BehaviorSubject<IWHEquipmentJobRequirementsDTO[]>;
  equipmentJobRequirements$: Observable<any>;
  _loading: BehaviorSubject<boolean>;
  loading$: Observable<boolean>;
  constructor() {
    this._jobRequirements = new BehaviorSubject<JobRequirementsDOM>(null);
    this._equipmentJobRequirements = new BehaviorSubject<
      IWHEquipmentJobRequirementsDTO[]
    >(null);
    this._allActiveJobRequirements = new BehaviorSubject<
      IWHJobRequirementsDTO[]
    >(null);
    this._jobRequirement = new BehaviorSubject<any>(null);
    this._loading = new BehaviorSubject<boolean>(false);
    this.jobRequirements$ = this._jobRequirements.asObservable();
    this.equipmentJobRequirements$ =
      this._equipmentJobRequirements.asObservable();
    this.allActiveJobRequirements$ =
      this._allActiveJobRequirements.asObservable();
    this.jobRequirement$ = this._jobRequirement.asObservable();
    this.loading$ = this._loading.asObservable();
  }
}

export enum COMPETENCE_TYPES {
  skills = 'SKILLS',
  qualifications = 'QUALIFICATIONS',
}

export enum REQUIREMENT_TYPES {
  mustHave = 'MUST_HAVE',
  shouldHave = 'SHOULD_HAVE',
  couldHave = 'COULD_HAVE',
}
export class JobRequirementsDOM {
  data: IWHJobRequirementsDTO[];
  numberOfItems: number;
  numberOfItemsTotal: number;
  numberOfPages: number;
  page: number;
  pageSize: number;
}
@Injectable({
  providedIn: 'root',
})
export class JobRrequirementsDataService {
  public jrData = new JobRequirementEntity();
  public jobRequirementStatuses = [
    { key: 'ACTIVE', translationKey: 'material.enum.status.ACTIVE' },
    { key: 'INACTIVE', translationKey: 'material.enum.status.INACTIVE' },
  ];
  constructor(
    private jrService: WHJobRequirementsDataService,
    private domEventService: WHDomEventService,
    private ngxToastrService: WHNgxToastrService,
  ) {}

  // with no pagination
  async getAllActiveJobRequirements(params?: any): Promise<void> {
    const departmentId =
      await this.domEventService.domEvents$.getValue()?.payload?.id;

    if (departmentId) {
      params.departmentId = departmentId;
    }
    this.jrData._loading.next(true);
    this.jrService
      .getAllActiveJobRequirements(
        { apiUrl: environment.apiUrl + FLOW_GATEWAY },
        params,
      )
      .pipe(
        map((jobRequirements: any) => {
          this.jrData._allActiveJobRequirements.next(jobRequirements);
          this.jrData._loading.next(false);
        }),
        catchError((err) => {
          this.jrData._loading.next(false);
          return throwError(err);
        }),
      )
      .subscribe();
  }

  async getJobRequirements(params: any): Promise<void> {
    const departmentId =
      await this.domEventService.domEvents$.getValue()?.payload?.id;

    if (departmentId) {
      params.departmentId = departmentId;
    }
    this.jrData._loading.next(true);
    this.jrService
      .getJobRequirements({ apiUrl: environment.apiUrl + FLOW_GATEWAY }, params)
      .pipe(
        map((jobRequirements: JobRequirementsDOM) => {
          this.jrData._jobRequirements.next(jobRequirements);
          this.jrData._loading.next(false);
        }),
        catchError((err) => {
          this.jrData._loading.next(false);
          return throwError(err);
        }),
      )
      .subscribe();
  }

  getJobRequirement(id: string): void {
    this.jrData._loading.next(true);
    this.jrService
      .getJobRequirementById({ apiUrl: environment.apiUrl + FLOW_GATEWAY, id })
      .pipe(
        map((jobRequirement: any) => {
          this.jrData._jobRequirement.next(jobRequirement);
          this.jrData._loading.next(false);
        }),
        catchError((err) => {
          this.jrData._loading.next(false);
          return throwError(err);
        }),
      )
      .subscribe();
  }

  getEquipmentJobRequirements(equipmentId: string, page: number): void {
    this.jrData._loading.next(true);
    this.jrService
      .getEquipmentJobRequirements(
        { apiUrl: environment.apiUrl + FLOW_GATEWAY, equipmentId: equipmentId },
        { size: 5, page },
      )
      .pipe(
        map((jobRequirements) => {
          this.jrData._equipmentJobRequirements.next(jobRequirements);
          this.jrData._loading.next(false);
        }),
        catchError((err) => {
          this.jrData._loading.next(false);
          return throwError(err);
        }),
      )
      .subscribe();
  }

  upsertEquipmentJobRequirements(equipmentId, payload) {
    this.jrData._loading.next(true);

    return this.jrService
      .upsertJobRequirementToEquipment(
        {
          apiUrl: environment.apiUrl + FLOW_GATEWAY,
          equipmentId: equipmentId,
        },
        payload,
      )
      .pipe(
        tap(() => {
          this.jrData._loading.next(false);

          if (payload.id) {
            this.ngxToastrService.displayToastr({
              toastrType: WHNgxToastrENUM.SUCCESS,
              messageTranslateKey:
                'jobprofilesequipment.ui.updatesuccess.notification',
            });
          } else {
            this.ngxToastrService.displayToastr({
              toastrType: WHNgxToastrENUM.SUCCESS,
              messageTranslateKey:
                'jobprofilesequipment.ui.assignsuccess.notification',
            });
          }
        }),
      );
  }

  createJobRequirement(payload) {
    this.jrData._loading.next(true);
    return this.jrService.addJobRequirements(
      { apiUrl: environment.apiUrl + FLOW_GATEWAY },
      payload,
    );
  }

  deleteQualification(id: string) {
    this.jrData._loading.next(true);
    return this.jrService.deleteJobRequirement({
      apiUrl: environment.apiUrl + FLOW_GATEWAY,
      id: id,
    });
  }

  unassignJobRequirementFromEquipment(id: string) {
    this.jrData._loading.next(true);
    return this.jrService
      .unassingJobRequirementFromEquipment({
        apiUrl: environment.apiUrl + FLOW_GATEWAY,
        equipmentId: id,
      })
      .pipe(
        tap(() => {
          this.jrData._loading.next(false);

          this.ngxToastrService.displayToastr({
            toastrType: WHNgxToastrENUM.SUCCESS,
            messageTranslateKey:
              'jobprofilesequipment.ui.unassignsuccess.notification',
          });
        }),
      );
  }

  public initJobRequirementFormGroup(): FormGroup {
    return new FormBuilder().group({
      id: [null],
      extId: [null], //
      name: [null, [Validators.required, noWhitespaceValidator]], //
      role: [null, [Validators.required, noWhitespaceValidator]], //
      description: [null],
      organisationalStructure: [null],
      minimumStaffNumber: [
        0,
        [Validators.pattern('^[0-9]*$'), Validators.maxLength(5)],
      ], //
      departmentId: [null, Validators.required], //
      supervisorId: [null, Validators.required],
      skills: [null], //
      qualification: [null],
      status: ['ACTIVE', Validators.required],
    });
  }

  public initParamsFormGroup() {
    return new FormBuilder().group({
      page: [0],
      sort: null,
      sortMode: null,
      term: [null],
      pageSize: [20],
      departmentId: [null],
    });
  }

  public initCompetenceFormGroup(): FormGroup {
    return new FormBuilder().group({
      jobRequirementId: [null, Validators.required],
      competenceId: [null, Validators.required],
      requirementType: [null, Validators.required],
    });
  }
}
