// ANGULAR
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

// RxJS 6
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

// MODELS
import { ListColumn } from './list-column.model';
import { WHIconENUM } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListComponent {

  // INPUT
  @Input() public focusElement: boolean = false;
  @Input() public isWithSearchHeaderIcon: boolean = false;
  @Input() public name: string;
  @Input() public columns: ListColumn[];
  @Input() public icon: string;
  @Input() public isMaterialIcon: boolean = false;

  // STATE
  @Input() public term: string = '';

  // OUTPUT
  @Output() filterChange = new EventEmitter<string>();

  onSearchTermChange(term) {
    of(term)
      .pipe(
        distinctUntilChanged(),
        debounceTime(200),
        tap((term) => {
          this.filterChange.emit(term);
        })
      )
      .subscribe();
  }

  public toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  public get displayIcon() {
    return this.icon ? this.icon : WHIconENUM.ServerIcon as string;
  }

  public get searchIcon() {
    return WHIconENUM.SearchIcon as string;
  }
}
