// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHWorkOrderDOM } from '@workheld/workheld-shared-lib';

export enum WHWorkOrderMasterDataStoreActionTypes {
  GetWHWorkOrderDtoList = '[WHWorkOrderMasterDataStore] GetWHWorkOrderDtoList',
  SetWHWorkOrderDomList = '[WHWorkOrderMasterDataStore] SetWHWorkOrderDomList',
}

export class GetWHWorkOrderDtoList implements Action {
  readonly type = WHWorkOrderMasterDataStoreActionTypes.GetWHWorkOrderDtoList;

  constructor(public payload: { workObjectTypeId: number, workObjectId: string, withTargetTimes: boolean}) {
    // console.log('GetWHWorkOrderDtoList ');
  }
}

export class SetWHWorkOrderDomList implements Action {
  readonly type = WHWorkOrderMasterDataStoreActionTypes.SetWHWorkOrderDomList;

  constructor(public payload: { workOrderDomList: WHWorkOrderDOM[], loading: boolean, loaded: boolean }) {
    // console.log('SetWHWorkOrderDomList ' + JSON.stringify(payload));
  }
}


export type WHWorkOrderMasterDataStoreActions = GetWHWorkOrderDtoList | SetWHWorkOrderDomList;
