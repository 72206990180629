// HELPERS
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHWorkerMasterDataStoreActions, WHWorkerMasterDataStoreActionTypes } from './w-h-worker-master-data-store.actions';

// MODELS
import { WHWorkerDOM } from '@workheld/workheld-shared-lib';

export const workheldWorkerMasterDataStoreFeatureKey = 'workheldWorkerMasterDataStore$';

export interface IWHWorkerMasterDataStore {
  workerDomList: WHWorkerDOM[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHWorkerMasterDataStore = {
  workerDomList: [],
  loading: false,
  loaded: false
};

export function workheldWorkerMasterDataReducer(state = initialState, action: WHWorkerMasterDataStoreActions): IWHWorkerMasterDataStore {
  switch (action.type) {

    case WHWorkerMasterDataStoreActionTypes.GetWHWorkerDtoList:
      return updateObject(state, {
        workerDomList: [],
        loading: true,
        loaded: false
      });

    case WHWorkerMasterDataStoreActionTypes.SetWHWorkerDomList:
      return action.payload;

    // case WHWorkerMasterDataStoreActionTypes.SetWHWorkerWorkOrderAssignment:
    //   action.payload.forEach((payloadWorkerDOM: WHWorkerDOM) => {
    //     const index: number = state.workerDomList.findIndex((workerDOM: WHWorkerDOM) => {
    //       return payloadWorkerDOM.id === workerDOM.id;
    //     });
    //     if (!!index) {
    //       state.workerDomList[index] = updateObject(state.workerDomList[index], payloadWorkerDOM);
    //     }
    //   });
    //   return state;

    default:
      return state;
  }
}
