// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHWorkerTimeReportResultDOM } from '@workheld/workheld-shared-lib';

export enum WHWorkerTimeReportStoreActionTypes {
  GetWHWorkerTimeReportDTO = '[WHWorkerTimeReportStore] GetWHWorkerTimeReportDTO',
  SetWHWorkerTimeReportDOM = '[WHWorkerTimeReportStore] SetWHWorkerTimeReportDOM',
}

export class GetWHWorkerTimeReportDTO implements Action {
  readonly type = WHWorkerTimeReportStoreActionTypes.GetWHWorkerTimeReportDTO;

  constructor(public payload: WHHttpParams) {
    // console.log('GetWHWorkerTimeReportDTO ' + JSON.stringify(payload));
  }
}

export class SetWHWorkerTimeReportDOM implements Action {
  readonly type = WHWorkerTimeReportStoreActionTypes.SetWHWorkerTimeReportDOM;

  constructor(public payload: WHWorkerTimeReportResultDOM) {
    // console.log('SetWHWorkerTimeReportDOM ' + JSON.stringify(payload));
  }
}


export type WHWorkerTimeReportStoreActions = GetWHWorkerTimeReportDTO | SetWHWorkerTimeReportDOM;
