<div class="agm-core-search">
  <mat-form-field whFlex appearance="outline" [floatLabel]="'always'">
    <mat-label>{{ "location.ui.filterheader.label" | translate }}</mat-label>
    <mat-icon matPrefix color="accent">search</mat-icon>
    <input
      data-cy="search-location"
      matInput
      #search
      [placeholder]="'location.ui.filterheader.placeholder' | translate"
      autocomplete="off"
      [tabIndex]="tabIndex"
    />
    <!-- <mat-hint>Search via google</mat-hint> -->
  </mat-form-field>
</div>
