
// THIRD LIB
import * as moment from 'moment';

// ANGULAR
import { Injectable } from '@angular/core';

@Injectable()
export class DateTimeHelperService {

  // DATE TO ISO STRING WITH OFFSET
  // Requests sends post/get requests with date offset
  public dateToIsoStringOffset(date: Date): string {
    let tzOff: number = date.getTimezoneOffset();
    const tzSig: string = (tzOff < 0 && '+') || '-';

    tzOff = Math.abs(tzOff / 60);

    return (
      date.getFullYear() +
      '-' + ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' + ('0' + date.getDate()).slice(-2) +
      'T' + ('0' + date.getHours()).slice(-2) +
      ':' + ('0' + date.getMinutes()).slice(-2) +
      ':' + ('0' + date.getSeconds()).slice(-2) +
      tzSig + ('0' + tzOff).slice(-2) + ':00'
    );
  }

  // WEEK - DATE SPAN
  public initWeekDateSpanISO(startDate: string): Array<string> {
    const weekTimeSpanISO = [];

    for (let i = 0; i < 7; i++) {
      const momentStartDate: moment.Moment = moment(startDate);
      // TODO CHECK IF SETTING TIME TO 00:00 is reqired
      momentStartDate.add(i, 'days');
      weekTimeSpanISO.push(momentStartDate.toISOString());
    }
    return weekTimeSpanISO;
  }

  // TIME DROPDOWN - INTERVAL
  public initTimeOptionDropdownArray(intervalInMinutes: number): Array<string> {
    const timeOptions: Array<string> = [];
    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes += intervalInMinutes) {
        timeOptions.push(
          (hours < 10 ? '0' : '') +
          (hours + ':') +
          (minutes < 10 ? '0' : '') + minutes);
      }
    }
    return timeOptions;
  }

  // PARSE GIVEN DATE STRING TO TIME DIGITS
  public parseDateToTimeDigits(dateString: string): string {
    if (dateString !== undefined && dateString !== null) {
      const date: Date = new Date(dateString);
      return (
        (date.getHours() < 10 ? '0' : '') +
        (date.getHours() + ':') +
        (date.getMinutes() < 10 ? '0' : '') + date.getMinutes());
    } else {
      return null;
    }
  }

  // TODO - REMOVE THIS
  public initDateISO(dateString: string): string {
    let momentDate: moment.Moment;
    if (dateString !== undefined && dateString !== null) {
      momentDate = moment(dateString).utc(false);
    } else {

    }
    return momentDate ? momentDate.toISOString() : null;
  }

}
