// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHEquipmentDOM } from '@workheld/workheld-shared-lib';

export enum WHEquipmentMasterDataStoreActionTypes {
  GetWHEquipmentDtoList = '[WHEquipmentMasterDataStore] GetWHEquipmentDtoList',
  SetWHEquipmentDtoList = '[WHEquipmentMasterDataStore] SetWHEquipmentDtoList',
}

export class GetWHEquipmentDtoList implements Action {
  readonly type = WHEquipmentMasterDataStoreActionTypes.GetWHEquipmentDtoList;

  constructor(public payload: { targetParent: string, customerId: string }) {
    // console.log('GetWHEquipmentDtoList ' + JSON.stringify(payload));
  }
}

export class SetWHEquipmentDtoList implements Action {
  readonly type = WHEquipmentMasterDataStoreActionTypes.SetWHEquipmentDtoList;

  constructor(public payload: { equipmentDomList: WHEquipmentDOM[], loading: boolean, loaded: boolean }) {
    // console.log('SetWHEquipmentDtoList ' + JSON.stringify(payload));
  }
}

export type WHEquipmentMasterDataStoreActions = GetWHEquipmentDtoList | SetWHEquipmentDtoList;
