// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { EquipmentAsyncService } from 'src/app/app-services-async/equipment-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHEquipmentCrudStoreActionTypes,
  WHEquipmentCrudStoreActions,
  GetWHEquipmentDTO,
  SetWHEquipmentDOM,
} from './w-h-equipment-crud-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';
import { IWHEquipmentDTO, WHEquipmentDOM } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHEquipmentCrudStoreEffects {
  getWHEquipmentDTO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHEquipmentCrudStoreActionTypes.GetWHEquipmentDTO),
      switchMap((action: GetWHEquipmentDTO) => {
        return this.equipmentAsyncService.getEquipmentDtoByEquimentId(
          action.payload.targetID
        );
      }),
      mergeMap((equipmentDTO: IWHEquipmentDTO) => {
        const equipmentDOM: WHEquipmentDOM =
          this.dataModelHelperService.initEquipmentDOM(equipmentDTO);
        return [new SetWHEquipmentDOM(equipmentDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHEquipmentCrudStoreActions>,
    private equipmentAsyncService: EquipmentAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
