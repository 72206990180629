// ANGULAR
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/app-services/ui.service';

@Component({
  selector: 'ng-bee-w-h-date-static-data',
  templateUrl: './w-h-date-static-data.component.html',
  styleUrls: ['./w-h-date-static-data.component.scss'],
})
export class WHDateStaticDataComponent implements AfterViewInit, OnDestroy {
  currentLocale: string;
  subs: Subscription = new Subscription();
  constructor(private uiService: UIService) {}
  ngAfterViewInit(): void {
    this.subs.add(
      this.uiService.currentLocale$.subscribe((locale) => {
        this.currentLocale = locale;
      })
    );
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // INPUT CONFIG
  @Input() public isSingleDate: boolean = false;
  @Input() public withTimeFrameLabel: boolean = false;
  @Input() public timeFrameLabel: string = 'workobject.ui.timeframe.label';
  @Input() public isStartTimeVisible: boolean = false;
  @Input() public isEndTimeVisible: boolean = false;

  // INPUT DATE CONFLICT
  @Input() public displayConflict: boolean = false;
  @Input() public startConflict: boolean = false;
  @Input() public endConflict: boolean = false;

  // INPUT START DATE
  @Input() public isWithStartDateLabel: boolean = false;
  @Input() public startDateLabel: string = 'workobject.model.scheduledstart';
  @Input() public startDate: string;

  // INPUT END DATE
  @Input() public isWithEndDateLabel: boolean = false;
  @Input() public endDateLabel: string = 'workobject.model.scheduledend';
  @Input() public endDate: string;
}
