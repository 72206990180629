// ANGULAR
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// APP
import { WHSearchTermRoutingModule } from './w-h-search-term-routing.module';
import { WHSearchTermComponent } from './w-h-search-term.component';
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';

@NgModule({
  declarations: [
    WHSearchTermComponent
  ],
  exports: [
    WHSearchTermComponent
  ],
  imports: [
    ReactiveFormsModule,
    AppSharedModule,
    WHSearchTermRoutingModule
  ]
})
export class WHSearchTermModule { }
