// API
import { updateObject, WHEntryFilePreviewDOM } from '@workheld/workheld-shared-lib';

// REDUX
import { WHEntryFilePreviewStoreActions, WHEntryFilePreviewStoreActionTypes } from './w-h-entry-file-preview-store.actions';

// MODELS

export const workheldEntryFilePreviewStoreFeatureKey = 'workheldEntryFilePreviewStore$';

export const initialState: WHEntryFilePreviewDOM = new WHEntryFilePreviewDOM();

export function workheldEntryFilePreviewReducer(state = initialState, action: WHEntryFilePreviewStoreActions): WHEntryFilePreviewDOM {
  switch (action.type) {

    case WHEntryFilePreviewStoreActionTypes.GetWHEntryFilePreviewDtoWithParams:
      //const payload: WHHttpParams = action.payload;
      return updateObject<WHEntryFilePreviewDOM>(state, {
        loading: true,
        loaded: false
      } as any);

    case WHEntryFilePreviewStoreActionTypes.SetWHEntryFilePreview:
      return action.payload;

    default:
      return state;
  }

}
