// HELPER
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHChecklistInstancePreviewStoreActionTypes, WHChecklistInstancePreviewStoreActions } from './w-h-checklist-instance-preview-store.actions';

// MODELS
import { WHChecklistInstancePreviewDOM } from '@workheld/workheld-shared-lib';

// CONST
export const workheldChecklistInstancePreviewStoreFeatureKey = 'workheldChecklistInstancePreviewStore$';

export const initialState: WHChecklistInstancePreviewDOM = new WHChecklistInstancePreviewDOM();

export function workheldChecklistInstancePreviewReducer(state = initialState, action: WHChecklistInstancePreviewStoreActions): WHChecklistInstancePreviewDOM {
  switch (action.type) {

    case WHChecklistInstancePreviewStoreActionTypes.GetWHChecklistInstancePreviewDtoWithParams:
     // const payload: WHHttpParams = action.payload;
      return updateObject<WHChecklistInstancePreviewDOM>(state, {
        loading: true,
        loaded: false
      } as any);

    case WHChecklistInstancePreviewStoreActionTypes.SetWHChecklistInstancePreview:
      return action.payload;

    default:
      return state;
  }
}
