import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  FLOW_GATEWAY,
  IWHShiftDefinitionDTO,
  IWHShiftDefinitionPayload,
  PageResultShiftDefinitionDto,
  WHDomEventService,
  WHShiftDefinitionsService,
  noWhitespaceValidator,
} from '@workheld/workheld-shared-lib';

class ShiftDefinitionsEntity {
  _shiftDefinitions: BehaviorSubject<PageResultShiftDefinitionDto>;
  shiftDefinitions$: Observable<PageResultShiftDefinitionDto>;
  _shiftDefinition: BehaviorSubject<IWHShiftDefinitionDTO>;
  shiftDefinition$: Observable<any>;
  _loading: BehaviorSubject<boolean>;
  loading$: Observable<boolean>;
  constructor() {
    this._shiftDefinitions = new BehaviorSubject<PageResultShiftDefinitionDto>(
      null
    );
    this._shiftDefinition = new BehaviorSubject<IWHShiftDefinitionDTO>(null);
    this._loading = new BehaviorSubject<boolean>(false);
    this.shiftDefinitions$ = this._shiftDefinitions.asObservable();
    this.shiftDefinition$ = this._shiftDefinition.asObservable();
    this.loading$ = this._loading.asObservable();
  }
}

export const DaysOfWeek = [
  { value: 1, translateKey: 'shift.days.monday' },
  { value: 2, translateKey: 'shift.days.tuesday' },
  { value: 3, translateKey: 'shift.days.wednesday' },
  { value: 4, translateKey: 'shift.days.thursday' },
  { value: 5, translateKey: 'shift.days.friday' },
  { value: 6, translateKey: 'shift.days.saturday' },
  { value: 0, translateKey: 'shift.days.sunday' },
];
export type DayOfWeek = keyof typeof DaysOfWeek;

@Injectable({
  providedIn: 'root',
})
export class ShiftDefinitionsDataService {
  public shiftDefinitionsData = new ShiftDefinitionsEntity();
  public shiftDefinitionStatuses = [
    { key: 'ACTIVE', translationKey: 'material.enum.status.ACTIVE' },
    { key: 'INACTIVE', translationKey: 'material.enum.status.INACTIVE' },
  ];

  constructor(
    private shiftDefinitionService: WHShiftDefinitionsService,
    private domEventService: WHDomEventService
  ) {}

  async getShiftDefinitions(params: any): Promise<void> {
    const departmentId = await this.domEventService.domEvents$.getValue()
      ?.payload?.id;

    // If params has departmentId, use that. Otherwise, use the departmentId from the domEventService
    params.departmentId = params.departmentId || departmentId;

    this.shiftDefinitionsData._loading.next(true);
    this.shiftDefinitionService
      .getShiftDefinitions(
        { apiUrl: environment.apiUrl + FLOW_GATEWAY },
        params
      )
      .pipe(
        map((shiftDefinitions: PageResultShiftDefinitionDto) => {
          this.shiftDefinitionsData._shiftDefinitions.next(shiftDefinitions);
          this.shiftDefinitionsData._loading.next(false);
        }),
        catchError((err) => {
          this.shiftDefinitionsData._loading.next(false);
          return throwError(err);
        })
      )
      .subscribe();
  }

  getShiftDefinition(id: string): void {
    this.shiftDefinitionsData._loading.next(true);
    this.shiftDefinitionService
      .getShiftDefinitionById({ apiUrl: environment.apiUrl + FLOW_GATEWAY, id })
      .pipe(
        map((jobRequirement: IWHShiftDefinitionDTO) => {
          this.shiftDefinitionsData._shiftDefinition.next(jobRequirement);
          this.shiftDefinitionsData._loading.next(false);
        }),
        catchError((err) => {
          this.shiftDefinitionsData._loading.next(false);
          return throwError(err);
        })
      )
      .subscribe();
  }

  createShiftDefinition(payload: IWHShiftDefinitionPayload) {
    this.shiftDefinitionsData._loading.next(true);
    return this.shiftDefinitionService.createShiftDefinition(
      { apiUrl: environment.apiUrl + FLOW_GATEWAY },
      payload
    );
  }

  deleteShiftDefinition(id: string) {
    this.shiftDefinitionsData._loading.next(true);
    return this.shiftDefinitionService.deleteShiftDefinition({
      apiUrl: environment.apiUrl + FLOW_GATEWAY,
      id: id,
    });
  }

  public initShiftDefinitionFormGroup(): FormGroup {
    return new FormBuilder().group({
      id: [null],
      extId: [null],
      name: [null, [Validators.required, noWhitespaceValidator]],
      description: [null],
      departmentId: [null, Validators.required],
      shiftGroupId: [null, Validators.required],
      dayOfWeek: [null, Validators.required],
      shiftManagerRequired: [true],
      qualificationIds: [null],
      jobRequirements: [null],
      status: ['ACTIVE', Validators.required],
    });
  }

  public initParamsFormGroup() {
    return new FormBuilder().group({
      page: [0],
      sort: null,
      sortMode: null,
      term: [null],
      pageSize: [20],
      departmentId: [null],
    });
  }
}
