<div class="entry-detail-mat-dialog">
  <div class="detail-card-header">
    <span whFlex class="w-h-header-icon-span">
      <i
        mat-card-avatar
        class="workheld-icons header-icon"
        [innerHtml]="entryTypeIcon"
      ></i>
    </span>
    <span whFlex class="w-h-header-span">
      <div class="single-header">
        {{ featuredEntryTypeHeader | translate }}
      </div>
    </span>
  </div>
  <div class="entry-detail-mat-dialog-body">
    <ng-bee-entry-static-data
      [entryDOM]="data.entryDOM"
      (onEntryUpdate)="onEntryUpdate($event)"
    >
    </ng-bee-entry-static-data>
  </div>
  <div>
    <ng-bee-entry-process-form
      class="entry-detail-mat-dialog-entry-process-form"
      [entryDOM]="data.entryDOM"
      (onEntryUpdate)="onEntryUpdate($event)"
    >
    </ng-bee-entry-process-form>
  </div>
</div>
