<div class="location-formatted-address">
    <div class="w-h-label html-overflow" *ngIf="withLabel">
        {{ (formattedAddressLabel | translate) }}
    </div>
    <div class="html-overflow default-value" *ngIf="locationDTO?.name">
        {{ locationDTO?.name }}
    </div>
    <div class="default-value">
        {{ locationDTO?.formattedAddress ? locationDTO?.formattedAddress : '-' }}
    </div>
    <div class="default-value" *ngIf="locationDTO?.locationSupplement">
        {{ locationDTO?.locationSupplement }}
    </div>
</div>