// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHChecklistInstancePreviewStore from './w-h-checklist-instance-preview-store.reducer';
import { WHChecklistInstancePreviewStoreEffects } from './w-h-checklist-instance-preview-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHChecklistInstancePreviewStore.workheldChecklistInstancePreviewStoreFeatureKey, fromWHChecklistInstancePreviewStore.workheldChecklistInstancePreviewReducer),
    EffectsModule.forFeature([WHChecklistInstancePreviewStoreEffects])
  ]
})
export class WHChecklistInstancePreviewStoreModule { }
