import {
  FLOW_GATEWAY,
  initHttpParams,
  noWhitespaceValidator,
  IWHWorkOrderDTO,
  IWHAllAssginmentsDTO,
  WHScheduleAllWorkStepPayload,
  setWorkObjectTypeIdPath,
  WHHttpParams,
  IWHEquipmentDTO,
  IWHToolDTO,
  IWHWorkerDTO,
  IWHWorkStepDTO,
} from '@workheld/workheld-shared-lib';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class WorkOrderAsyncService {
  constructor(private httpClient: HttpClient) {}

  // WORK ORDER
  public initWorkOrderFormGroup() {
    return new FormBuilder().group({
      id: [null],
      caseId: [null],
      projectId: [null],
      numberExt: [null],
      status: [null],
      workOrderStatusId: [null],
      location: [null],
      title: [null, [Validators.required, noWhitespaceValidator]],
      workers: [null], // HR ID LIST
      workObjectId: [null],
      description: [null],
      category: [null],
    });
  }

  // CRUD
  public createNewWorkOrder(
    workOrderDTO: IWHWorkOrderDTO
  ): Observable<IWHWorkOrderDTO> {
    return this.httpClient.post<IWHWorkOrderDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/workOrders`,
      workOrderDTO
    );
  }

  // CRUD
  public updateWorkOrder(
    workOrderId: string,
    workOrderDTO: IWHWorkOrderDTO
  ): Observable<IWHWorkOrderDTO> {
    return this.httpClient.post<IWHWorkOrderDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/workOrders/${workOrderId}`,
      workOrderDTO
    );
  }

  // CRUD
  public deleteWorkOrder(workOrderId: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${environment.apiUrl + FLOW_GATEWAY}/workOrders/${workOrderId}`
    );
  }

  // SCHEDULE ALL WORK STEPS
  public scheduleAllWorkStepsForWorkOrder(
    scheduleAllWorkStepPayload: WHScheduleAllWorkStepPayload
  ): Observable<IWHWorkStepDTO[]> {
    return this.httpClient.post<IWHWorkStepDTO[]>(
      `${environment.apiUrl + FLOW_GATEWAY}/workOrders/${
        scheduleAllWorkStepPayload.workOrderId
      }/schedule`,
      scheduleAllWorkStepPayload
    );
  }

  // WORK ORDER CRUD
  public getWorkOrderDtoByWorkOrderId(
    workOrderId: string
  ): Observable<IWHWorkOrderDTO> {
    return this.httpClient.get<IWHWorkOrderDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/workOrders/${workOrderId}`
    );
  }

  // WORK ORDER DTO LIST
  public getWorkOrderDtoList(
    workObjectTypeId: number,
    workObjectId: string,
    withTargetTimes?: boolean
  ): Observable<IWHWorkOrderDTO[]> {
    return this.getWorkOrderDtoListForWorkObject(
      workObjectTypeId,
      workObjectId,
      withTargetTimes
    );
  }

  // WORK OBJECT DTO LIST
  private getWorkOrderDtoListForWorkObject(
    workObjectTypeId: number,
    workObjectId: string,
    withTargetTimes: boolean = false
  ): Observable<IWHWorkOrderDTO[]> {
    const workObjectTargetName: string =
      setWorkObjectTypeIdPath(workObjectTypeId);
    return this.httpClient.get<IWHWorkOrderDTO[]>(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/${workObjectTargetName}/${workObjectId}/workOrders`,
      { params: { withTargetTimes: withTargetTimes } }
    );
  }

  // ASSIGNMENTS
  public getAllWorkOrderAssignments(
    workOrderId: string
  ): Observable<IWHAllAssginmentsDTO> {
    return this.httpClient.get<IWHAllAssginmentsDTO>(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/workOrders/${workOrderId}/allassignments`
    );
  }

  // UNASSIGN
  public unassignFromWorkOrder(
    targetParent: string,
    workOrderId: string,
    targetId: string
  ) {
    switch (targetParent) {
      case 'WORKER':
        return this.unassignWorkerFromWorkOrder(workOrderId, targetId);
      case 'EQUIPMENT':
        return this.unassignEquipmentFromWorkOrder(workOrderId, targetId);
      case 'TOOL':
        return this.unassignToolFromWorkOrder(workOrderId, targetId);
      case 'SKILL':
        return this.unassignSkillToWorkOrder(workOrderId, targetId);
      default:
        return of(undefined);
    }
  }

  // ASSIGN
  public assignToWorkOrder(
    targetParent: string,
    workOrderId: string,
    params: WHHttpParams
  ) {
    const assignmentPayload: any = this.initWorkOrderAssignmentPayload(
      targetParent,
      workOrderId,
      params
    );
    switch (targetParent) {
      case 'WORKER':
        return this.assignWorkerToWorkOrder(workOrderId, assignmentPayload);
      case 'EQUIPMENT':
        return this.assignEquipmentToWorkOrder(workOrderId, assignmentPayload);
      case 'TOOL':
        return this.assignToolToWorkOrder(workOrderId, assignmentPayload);
      case 'SKILL':
        return this.assignSkillToWorkOrder(workOrderId, params.targetID);
      default:
        return of(undefined);
    }
  }

  private initWorkOrderAssignmentPayload(
    targetParent: string,
    workOrderId: string,
    params: WHHttpParams
  ): any {
    let assignmentPayload: any;
    if (targetParent === 'WORKER') {
      assignmentPayload = {
        workOrderId,
        workerId: params.targetID,
      };
    } else if (targetParent === 'EQUIPMENT') {
      assignmentPayload = {
        workOrderId,
        equipmentId: params.targetID,
      };
    } else if (targetParent === 'TOOL') {
      assignmentPayload = {
        workOrderId,
        toolId: params.targetID,
      };
    }
    return assignmentPayload;
  }

  // ASSIGNMENTS WORKER
  private assignWorkerToWorkOrder(
    workOrderId: string,
    assignmentPayload: { workOrderId: string; workerId: string }
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl + FLOW_GATEWAY}/workOrders/${workOrderId}/workers`,
      assignmentPayload
    );
  }

  private unassignWorkerFromWorkOrder(workOrderId: string, workerId: string) {
    return this.httpClient.delete(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/workOrders/${workOrderId}/workers/${workerId}`
    );
  }

  public getWorkOrderWorkerAssignmentDtoList(
    workOrderId: string,
    params: WHHttpParams
  ) {
    const httpParams: HttpParams = initHttpParams(params);
    return this.httpClient.get<IWHWorkerDTO[]>(
      `${environment.apiUrl + FLOW_GATEWAY}/workOrders/${workOrderId}/workers`,
      { params: httpParams }
    );
  }

  // ASSIGNMENTS EQUIPMENT
  private assignEquipmentToWorkOrder(
    workOrderId: string,
    assignmentPayload: { workOrderId: string; equipmentId: string }
  ) {
    return this.httpClient.post<any>(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/workOrders/${workOrderId}/equipments`,
      assignmentPayload
    );
  }

  public overwriteEquipmentAssignment(
    workOrderId: string,
    assignmentPayload: {
      equipmentId: string;
      overrideExistingAssignments: boolean;
    }
  ) {
    return this.httpClient.post(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/workOrders/${workOrderId}/equipments`,
      assignmentPayload
    );
  }

  private unassignEquipmentFromWorkOrder(
    workOrderId: string,
    workerId: string
  ) {
    return this.httpClient.delete(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/workOrders/${workOrderId}/equipments/${workerId}`
    );
  }

  public getWorkOrderEquipmentAssignmentDtoList(
    workOrderID: string,
    params: WHHttpParams
  ): Observable<IWHEquipmentDTO[]> {
    const httpParams: HttpParams = initHttpParams(params);
    return this.httpClient.get<IWHEquipmentDTO[]>(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/workOrders/${workOrderID}/equipments`,
      { params: httpParams }
    );
  }

  private assignSkillToWorkOrder(workOrderId: string, skillId: string) {
    return this.httpClient.post<any>(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/workSteps/workOrder/${workOrderId}/skill/${skillId}`,
      null
    );
  }

  private unassignSkillToWorkOrder(workOrderId: string, skillId: string) {
    return this.httpClient.delete<any>(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/workSteps/workOrder/${workOrderId}/skill/${skillId}`
    );
  }

  // ASSIGNMENTS TOOL
  private assignToolToWorkOrder(
    workOrderId: string,
    assignmentPayload: { workOrderId: string; toolId: string }
  ) {
    return this.httpClient.post<any>(
      `${environment.apiUrl + FLOW_GATEWAY}/workOrders/${workOrderId}/tools`,
      assignmentPayload
    );
  }

  private unassignToolFromWorkOrder(workOrderId: string, workerId: string) {
    return this.httpClient.delete(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/workOrders/${workOrderId}/tools/${workerId}`
    );
  }

  public getWorkOrderToolAssignmentDtoList(
    workOrderID: string,
    params: WHHttpParams
  ): Observable<IWHToolDTO[]> {
    const httpParams: HttpParams = initHttpParams(params);
    return this.httpClient.get<IWHToolDTO[]>(
      `${environment.apiUrl + FLOW_GATEWAY}/workOrders/${workOrderID}/tools`,
      { params: httpParams }
    );
  }
}
