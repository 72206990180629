// ENVIRONMENT

// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES

// RxJS 6
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHChecklistDefinitionPreviewStoreActionTypes,
  WHChecklistDefinitionPreviewStoreActions,
} from './w-h-checklist-definition-preview-store.actions';

// RxJS 6

@Injectable()
export class WHChecklistDefinitionPreviewStoreEffects {
  getWHChecklistDefinitionPreviewDtoWithParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHChecklistDefinitionPreviewStoreActionTypes.GetWHChecklistDefinitionPreviewDtoWithParams
      )
    );
  });

  constructor(
    private actions$: Actions<WHChecklistDefinitionPreviewStoreActions>,
  ) {}
}
