// ANGULAR
import { Component, OnInit, Inject } from '@angular/core';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// ASSETS
import { WHIconENUM } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-mat-dialog-comment-entry',
  templateUrl: './mat-dialog-comment-entry.component.html',
  styleUrls: ['./mat-dialog-comment-entry.component.scss']
})
export class MatDialogCommentEntryComponent implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<MatDialogCommentEntryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { comment: string }
  ) { }

  ngOnInit(): void {
    console.log(this.data.comment);
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  public close() {
    this.matDialogRef.close();
  }

}
