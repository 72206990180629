<div class="preview-mat-dialog">
  <div class="flex--r-c" style="background-color: var(--primary-color-500)">
    <i
      mat-card-avatar
      class="workheld-icons header-icon"
      [innerHtml]="materialIcon"
    ></i>
    <div class="w-h-header-span single-header">
      {{ "materiallist.ui.assignment.header" | translate }}
    </div>
  </div>
  <div>
    <w-h-material-preview
      class="mat-preview-dialog"
      [workstep]="data.workstep"
      [endpointConfig]="{
        apiUrl: apiUrl,
        workStepId: data.targetId
      }"
      (payloadEmmiter)="payloadEmmiter($event)"
    >
    </w-h-material-preview>
  </div>
</div>
