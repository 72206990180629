// ANGULAR
import { NgModule } from '@angular/core';

// INDEX STORE MODULE

// WORKHELD STORES
// CUSTOMER
import { WHCustomerCrudStoreModule } from './w-h-customer-stores/w-h-customer-crud-store/w-h-customer-crud-store.module';
import { WHCustomerMasterDataStoreModule } from './w-h-customer-stores/w-h-customer-master-data-store/w-h-customer-master-data-store.module';
import { WHCustomerPreviewStoreModule } from './w-h-customer-stores/w-h-customer-preview-store/w-h-customer-preview-store.module';

// WORKER
import { WHWorkerMasterDataStoreModule } from './w-h-worker-stores/w-h-worker-master-data-store/w-h-worker-master-data-store.module';
import { WHWorkerTimeReportStoreModule } from './w-h-worker-stores/w-h-worker-time-report-store/w-h-worker-time-report-store.module';

// EQUIPMENT
import { WHEquipmentCrudStoreModule } from './w-h-equipment-stores/w-h-equipment-crud-store/w-h-equipment-crud-store.module';
import { WHEquipmentMasterDataStoreModule } from './w-h-equipment-stores/w-h-equipment-master-data-store/w-h-equipment-master-data-store.module';
import { WHEquipmentPreviewStoreModule } from './w-h-equipment-stores/w-h-equipment-preview-store/w-h-equipment-preview-store.module';

// MATERIAL
import { WHMaterialCrudStoreModule } from './w-h-material-stores/w-h-material-crud-store/w-h-material-crud-store.module';
import { WHMaterialPreviewStoreModule } from './w-h-material-stores/w-h-material-preview-store/w-h-material-preview-store.module';

// TOOL
import { WHToolCrudStoreModule } from './w-h-tool-stores/w-h-tool-crud-store/w-h-tool-crud-store.module';
import { WHToolMasterDataStoreModule } from './w-h-tool-stores/w-h-tool-master-data-store/w-h-tool-master-data-store.module';
import { WHToolPreviewStoreModule } from './w-h-tool-stores/w-h-tool-preview-store/w-h-tool-preview-store.module';

// C-H
import { WHChecklistDefinitionPreviewStoreModule } from './w-h-checklist-definition-stores/w-h-checklist-definition-preview-store/w-h-checklist-definition-preview-store.module';

// TEMPLATES
import { WHWorkObjectTemplatePreviewStoreModule } from './w-h-template-stores/w-h-work-object-template-preview-store/w-h-work-object-template-preview-store.module';
import { WHWorkObjectTemplateCrudStoreModule } from './w-h-template-stores/w-h-work-object-template-crud-store/w-h-work-object-template-crud-store.module';

// CHECKLIST DEFINITION
import { WHChecklistDefinitionCrudStoreModule } from './w-h-checklist-definition-stores/w-h-checklist-definition-crud-store/w-h-checklist-definition-crud-store.module';
import { WHChecklistDefinitionMasterDataStoreModule } from './w-h-checklist-definition-stores/w-h-checklist-definition-master-data-store/w-h-checklist-definition-master-data-store.module';

// CHECKLIST INSTANCE
import { WHChecklistInstancePreviewStoreModule } from './w-h-checklist-instance-stores/w-h-checklist-instance-preview-store/w-h-checklist-instance-preview-store.module';

// WORK OBJECT
import { WHWorkObjectPreviewStoreModule } from './w-h-work-object-stores/w-h-work-object-preview-store/w-h-work-object-preview-store.module';
import { WHWorkObjectCrudStoreModule } from './w-h-work-object-stores/w-h-work-object-crud-store/w-h-work-object-crud-store.module';

// WORK ORDER
import { WHWorkOrderMasterDataStoreModule } from './w-h-work-order-stores/w-h-work-order-master-data-store/w-h-work-order-master-data-store.module';
import { WHWorkOrderCrudStoreModule } from './w-h-work-order-stores/w-h-work-order-crud-store/w-h-work-order-crud-store.module';
import { WHAllAssignmentStoreModule } from './w-h-all-assignment-store/w-h-all-assignment-store.module';

// WORK STEP
import { WHWorkStepMasterDataStoreModule } from './w-h-work-step-stores/w-h-work-step-master-data-store/w-h-work-step-master-data-store.module';

// WORK ORDER FILE
import { WHWorkOrderFileMasterDataStoreModule } from './w-h-work-order-file-stores/w-h-work-order-file-master-data-store/w-h-work-order-file-master-data-store.module';

// ENTRY
import { WHEntryPreviewStoreModule } from './w-h-entry-stores/w-h-entry-preview-store/w-h-entry-preview-store.module';

// ENTRY FILE
import { WHEntryFilePreviewStoreModule } from './w-h-entry-file-stores/w-h-entry-file-preview-store/w-h-entry-file-preview-store.module';

// REPORT FILE
import { WHReportFilePreviewStoreModule } from './w-h-report-file-stores/w-h-report-file-preview-store/w-h-report-file-preview-store.module';

// DND ASSIGNMENT
import { WHDndAssignmentStoreModule } from './w-h-dnd-assignment-store/w-h-dnd-assignment-store.module';
import { WHWorkObjectEffortReportModule } from './w-h-work-object-stores/w-h-work-object-effort-report/w-h-work-object-effort-report.module';
import { WHWorkObjectMaterialUsageReportModule } from './w-h-work-object-stores/w-h-work-object-material-usage-report/w-h-work-object-material-usage-report.module';

// BRYNTUM
import { WHBryResourceStoreModule } from './w-h-bry-resource-store/w-h-bry-resource-store.module';
import { WHBryEventStoreModule } from './w-h-bry-event-store/w-h-bry-event-store.module';

@NgModule({
  declarations: [],
  exports: [],
  imports: [

    // CUSTOMER
    WHCustomerCrudStoreModule,
    WHCustomerMasterDataStoreModule,
    WHCustomerPreviewStoreModule,

    // EQUIPMENT
    WHEquipmentPreviewStoreModule,
    WHEquipmentMasterDataStoreModule,
    WHEquipmentCrudStoreModule,

    // WORKER
    WHWorkerMasterDataStoreModule,
    WHWorkerTimeReportStoreModule,

    // MATERIAL
    WHMaterialCrudStoreModule,
    WHMaterialPreviewStoreModule,

    // TOOL
    WHToolCrudStoreModule,
    WHToolMasterDataStoreModule,
    WHToolPreviewStoreModule,

    // CHECKLIST DEFINITION
    WHChecklistDefinitionCrudStoreModule,
    WHChecklistDefinitionMasterDataStoreModule,
    WHChecklistDefinitionPreviewStoreModule,

    // CHECKLIST INSTANCE
    WHChecklistInstancePreviewStoreModule,

    // TEMPLATES
    WHWorkObjectTemplateCrudStoreModule,
    WHWorkObjectTemplatePreviewStoreModule,

    // WORK OBJECT
    WHWorkObjectPreviewStoreModule,
    WHWorkObjectCrudStoreModule,
    WHWorkObjectEffortReportModule,
    WHWorkObjectMaterialUsageReportModule,

    // WORK ORDER
    WHWorkOrderMasterDataStoreModule,
    WHWorkOrderCrudStoreModule,
    WHAllAssignmentStoreModule,

    // WORK STEP
    WHWorkStepMasterDataStoreModule,

    // WORK ORDER FILE
    WHWorkOrderFileMasterDataStoreModule,

    // ENTRY
    WHEntryPreviewStoreModule,

    // ENTRY FILE
    WHEntryFilePreviewStoreModule,

    // REPORT FILE
    WHReportFilePreviewStoreModule,

    // DND
    WHDndAssignmentStoreModule,

    // BRYNTUM
    WHBryResourceStoreModule,
    WHBryEventStoreModule,
  ]
})
export class IndexStoresModule { }
