// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { WorkerStaticDataRoutingModule } from './worker-static-data-routing.module';
import { WorkerStaticDataComponent } from './worker-static-data.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';

// API
import { WHImgAuthHeaderModule } from '@workheld/workheld-shared-lib';


@NgModule({
  declarations: [
    WorkerStaticDataComponent
  ],
  exports: [
    WorkerStaticDataComponent
  ],
  imports: [
    AppSharedModule,
    WHImgAuthHeaderModule,
    WorkerStaticDataRoutingModule
  ]
})
export class WorkerStaticDataModule { }
