// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHWorkObjectCrudStore from './w-h-work-object-crud-store.reducer';
import { WHWorkObjectCrudStoreEffects } from './w-h-work-object-crud-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHWorkObjectCrudStore.workheldWorkObjectCrudStoreFeatureKey, fromWHWorkObjectCrudStore.workheldWorkObjectCrudReducer),
    EffectsModule.forFeature([WHWorkObjectCrudStoreEffects])
  ]
})
export class WHWorkObjectCrudStoreModule { }
