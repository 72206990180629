import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  Observable,
  Subject,
  Subscription,
  debounceTime,
  filter,
  fromEvent,
  map,
  tap,
} from 'rxjs';
import { IWHJobRequirementsDTO } from '@workheld/workheld-shared-lib/lib/models-flow/w-h-job-requirements.model';
import { WHAssetsENUM } from '@workheld/workheld-shared-lib';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'w-h-autocomplete-scroll',
  templateUrl: './w-h-autocomplete-scroll.component.html',
  styleUrls: ['./w-h-autocomplete-scroll.component.scss'],
})
export class WHAutocompleteScrollComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() public tabIndex: number = 0;
  @Input() public required: boolean = false;
  @Input() public itemString: string;
  @Input() public subItemStrings = [];
  @Input() public disableAutoComplete: boolean = false;
  @Input() public disableOnInit: boolean = false;
  @Input() public resetValue: boolean = false;
  @Input() public labelKey: string;
  @Input() public inputPlaceholder: string;
  @Input() clearEvent: Observable<void>;
  fallbackImagePath: string = WHAssetsENUM.equipmentPlaceholderPNG;
  selectedItems = [];
  @Input('initialValue') set _initialValue(term: string) {
    console.log('VALUE ', term);

    this.term = term;
    this.searchCtrl.setValue(term);
    this.selectedItemName = term;
  }
  @Input('data$') set _data(data$: Observable<any>) {
    data$
      .pipe(
        filter((data) => data != null),
        map((data) => {
          if ('page' in data) {
            this.page = data.page;
          } else {
            this.page = data.pageable?.pageNumber;
          }

          if (this.page === 0) this.itemDomList = [];
          this.numPages =
            data.numberOfPages || data.numPages || data.totalPages;
          this.itemDomList = this.itemDomList.concat(data.data || data.content);

          this.changeDetectorRef.detectChanges();
        }),
      )
      .subscribe();
  }
  @Input() public loading$: Observable<boolean> = new Observable();
  @Input() public placeholderImg: string = '';
  @Input('clearSelected') set _clearSelection(value: boolean) {
    if (value) {
      this.clear();
    }
  }
  @Input() imageUrlKey: string;
  @Input() isMultiselect: boolean = false;
  @Input('fallbackImage') set _fallbackImage(type: string) {
    if (type === 'EQUIPMENT') {
      this.fallbackImagePath = WHAssetsENUM.equipmentPlaceholderPNG;
    }
    if (type === 'MATERIAL') {
      this.fallbackImagePath = WHAssetsENUM.materialPlaceholderPNG;
    }
  }
  @Output() public onItemSelect: EventEmitter<any> = new EventEmitter();
  @Output() public onItemsSelect: EventEmitter<any> = new EventEmitter();
  @Output() public onCloseItems: EventEmitter<any> = new EventEmitter();
  @Output() public onScrollEvent: EventEmitter<{ term: string; page: number }> =
    new EventEmitter();

  @ViewChild('searchInput', { read: ElementRef, static: true })
  searchInput: ElementRef;

  itemDom: any;
  itemDomList = [];

  searchCtrl = new FormControl();
  next$ = new Subject<void>();
  loading: boolean = false;
  numPages: number;
  page: number = 0;
  term: string;
  selectedItemName: string;
  private subscriptions: Subscription[] = [];
  jrs: IWHJobRequirementsDTO[];

  @HostListener('keydown', ['$event']) onKeyDown(e) {
    this.setSearchCtrlValue(e?.keyCode === 9);
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    const clickedInside = this.eRef.nativeElement.contains(event.target);
    this.setSearchCtrlValue(!clickedInside);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private eRef: ElementRef,
    private dialog: MatDialog,
  ) {}

  ngAfterViewInit(): void {
    if (this.tabIndex !== 0) {
      this.searchInput.nativeElement.setAttribute('tabindex', this.tabIndex);
    }

    this.subscriptions.push(
      this.loading$
        .pipe(
          tap((loading) => {
            this.loading = loading;
          }),
        )
        .subscribe(),
    );

    if (this.disableAutoComplete) {
      this.searchCtrl.disable();
    } else {
      this.searchCtrl.enable();
    }

    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(debounceTime(800))
      .subscribe((event: any) => {
        const value = event.target.value;

        //if value is empty then return
        if (!value && typeof value !== 'string') {
          return;
        }
        this.itemDomList = [];
        this.term = value;
        this.page = 0;
        this.onScrollEvent.emit({ term: this.term, page: this.page });
      });
  }

  public ngOnInit(): void {
    this.page = 0;
    this.onScrollEvent.emit({ term: this.term, page: this.page });

    if (this.clearEvent) {
      this.subscriptions.push(
        this.clearEvent.subscribe(() => {
          this.clear();
        }),
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disableAutoComplete) {
      if (changes.disableAutoComplete.currentValue) {
        this.searchCtrl.disable();
      } else {
        this.searchCtrl.enable();
        this.searchInput?.nativeElement?.focus();
      }
      this.changeDetectorRef.detectChanges();
    }

    if (changes?.resetValue?.currentValue) {
      this.searchCtrl.reset();
      this.onItemSelect.emit(undefined);
    }
  }

  // ENDLESS SCROLL
  public onScrollDown() {
    this.next$.next();
    if (this.page + 1 === this.numPages || this.loading) {
      return;
    }

    this.page = this.page + 1;
    this.onScrollEvent.emit({ term: this.term, page: this.page });
  }

  public setItem(event): void {
    if (!this.isMultiselect) {
      const selectedItemName = event?.option?.value;
      this.selectedItemName = selectedItemName;
      this.onItemSelect.emit(event?.option?.value);
    }
  }

  onOpenStateChange(event) {
    console.log('OPEN STATE CHANGE', event);
  }

  public clear(event = null) {
    this.selectedItemName = '';
    this.searchCtrl.reset();
    this.onItemSelect.emit(undefined);
    this.page = 0;
    //if this.term is not empty, then we need to reset the list
    if (this.term) {
      this.itemDomList = [];
      this.term = '';
      this.onScrollEvent.emit({ term: this.term, page: this.page });
    }
  }

  optionClicked(event: Event, item) {
    event.stopPropagation();
    this.toggleSelection(item);
  }

  toggleSelection(item) {
    item.selected = !item.selected;

    if (item.selected) {
      this.selectedItems.push(item);
    } else {
      const i = this.selectedItems.findIndex(
        (value) => value.item === item.item,
      );
      this.selectedItems.splice(i, 1);
    }

    this.onItemsSelect.emit(this.selectedItems);
  }

  onClose() {
    if (this.isMultiselect) {
      this.onCloseItems.emit(this.selectedItems);
    }
  }

  private setSearchCtrlValue(reps: boolean) {
    if (
      reps &&
      this.selectedItemName &&
      this.selectedItemName !== this.searchCtrl.value
    ) {
      this.searchCtrl.setValue(this.selectedItemName);
    }
  }

  private _buildSelectedString() {
    const selectedItemsCount = this.selectedItems.length;
    const selectedItemsString = this.selectedItems
      .slice(0, 2)
      .map((item) => item[this.itemString] || item?.name)
      .join(', ');

    if (selectedItemsCount > 2) {
      return `Selected work objects ${selectedItemsString} + (${selectedItemsCount - 2})`;
    } else {
      return selectedItemsString;
    }
  }

  displayFn(item) {
    if (this.isMultiselect) {
      return this._buildSelectedString();
    } else {
      if (!item) return '';
      if (typeof item === 'string') return item;
      return item && this.itemString ? item[this.itemString] : item.name;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.onItemSelect.unsubscribe();
    this.onScrollEvent.unsubscribe();
  }
}
