<button
  mat-flat-button
  (click)="onBackButtonClick()"
  class="w-h-default-button mat-elevation-z2 gap--8"
  matTooltip="{{ labelTranslateKey | translate | uppercase }}"
>
  <mat-icon color="accent">arrow_back</mat-icon>
  <span *ngIf="!hideShowLabel || (hideShowLabel && (isLargeScreen$ | async))">
    {{ labelTranslateKey | translate | uppercase }}</span
  >
</button>
