<mat-form-field id="matauto">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    type="text"
    aria-label="Number"
    matInput
    [placeholder]="placeholder"
    [formControl]="valueFormControl"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
    <mat-option
      *ngFor="let option of filteredItems"
      [value]="optionValue ? option[optionValue] : option"
    >
      <div class="dial-code-container">
        {{ optionLabel ? option[optionLabel] : option }}
        <span *ngIf="optionIcon" class="fi" [class]="option[optionIcon]"></span>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
