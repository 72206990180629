// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHMaterialPreviewStore from './w-h-material-preview-store.reducer';
import { WHMaterialPreviewStoreEffects } from './w-h-material-preview-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHMaterialPreviewStore.workheldMaterialPreviewStoreFeatureKey, fromWHMaterialPreviewStore.workheldMaterialPreviewReducer),
    EffectsModule.forFeature([WHMaterialPreviewStoreEffects])
  ]
})
export class WHMaterialPreviewStoreModule { }
