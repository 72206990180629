// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// SERVICES
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHWorkObjectTemplatePreviewStoreActionTypes,
  WHWorkObjectTemplatePreviewStoreActions,
  SetWHWorkObjectTemplatePreview,
  GetWHWorkObjectTemplatePreviewDtoWithParams,
} from './w-h-work-object-template-preview-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import {
  initHttpParams,
  IWHWorkObjectTemplatePreviewDTO,
  WHWorkObjectTemplatePreviewDOM,
} from '@workheld/workheld-shared-lib';
import { FLOW_GATEWAY } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHWorkObjectTemplatePreviewStoreEffects {
  getWHWorkObjectTemplatePreviewDtoWithParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHWorkObjectTemplatePreviewStoreActionTypes.GetWHWorkObjectTemplatePreviewDtoWithParams
      ),
      switchMap((action: GetWHWorkObjectTemplatePreviewDtoWithParams) => {
        const httpParams: HttpParams = initHttpParams(action.payload);
        // console.log(httpParams);
        return this.httpClient.get<IWHWorkObjectTemplatePreviewDTO>(
          `${environment.apiUrl + FLOW_GATEWAY}/workObjectTemplates/preview`,
          { params: httpParams }
        );
      }),
      mergeMap(
        (workObjectTemplatePreviewDTO: IWHWorkObjectTemplatePreviewDTO) => {
          const workObjectTemplatePreviewDOM: WHWorkObjectTemplatePreviewDOM =
            this.dataModelHelperService.initWorkObjectTemplatePreviewDOM(
              workObjectTemplatePreviewDTO
            );
          return [
            new SetWHWorkObjectTemplatePreview(workObjectTemplatePreviewDOM),
          ];
        }
      )
    );
  });

  constructor(
    // TODO build SERVICE
    private httpClient: HttpClient,
    private actions$: Actions<WHWorkObjectTemplatePreviewStoreActions>,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
