// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHWorkStepMasterDataStore from './w-h-work-step-master-data-store.reducer';
import { WHWorkStepMasterDataStoreEffects } from './w-h-work-step-master-data-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHWorkStepMasterDataStore.workheldWorkStepMasterDataStoreFeatureKey, fromWHWorkStepMasterDataStore.workheldWorkStepMasterDataReducer),
    EffectsModule.forFeature([WHWorkStepMasterDataStoreEffects])
  ]
})
export class WHWorkStepMasterDataStoreModule { }
