// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { CustomerStaticDataRoutingModule } from './customer-static-data-routing.module';
import { CustomerStaticDataComponent } from './customer-static-data.component';

// ANGULAR
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';

@NgModule({
  declarations: [
    CustomerStaticDataComponent
  ],
  exports: [
    CustomerStaticDataComponent
  ],
  imports: [
    AppSharedModule,
    CustomerStaticDataRoutingModule
  ]
})
export class CustomerStaticDataModule { }
