// ANGULAR
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// ANGULAR MATERIAL
import { MatCardModule } from '@angular/material/card';

// APP
import { WHContentPageRoutingModule } from './w-h-content-page-routing.module';
import { WHContentPageComponent } from './w-h-content-page/w-h-content-page.component';

// MDOUEL
import { WHSharedModule } from '@workheld/workheld-shared-lib';
// API
import { WHImgAuthHeaderModule } from '@workheld/workheld-shared-lib';
import { ScrollbarModule } from '../app-modules-layout/scrollbar/scrollbar.module';
import { AppSharedModule } from '../app-shared/app-shared.module';
import { WHSearchTermUtilModule } from '../app-modules-pages/w-h-search-term-util/w-h-search-term-util.module';
import { DepartmentListComponent } from './w-h-content-page/department-list/department-list.component';

@NgModule({
  declarations: [
    WHContentPageComponent,
    DepartmentListComponent
  ],
  exports: [
    WHContentPageComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WHSharedModule,
    WHImgAuthHeaderModule,
    ScrollbarModule,
    AppSharedModule,
    MatCardModule,
    WHContentPageRoutingModule,
    WHSearchTermUtilModule
  ]
})
export class WHContentPageModule { }
