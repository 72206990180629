import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDialogConfirmUnsavedChangesComponent } from '../app-dialogs/mat-dialog-confirm-unsaved-changes/mat-dialog-confirm-unsaved-changes.component';

@Injectable()
export class FormReferenceService {
  private _unsavedChanges: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  readonly unsavedChanges$ = this._unsavedChanges.asObservable();

  set unsavedChanges(isDirty: boolean) {
    this._unsavedChanges.next(isDirty);
  }

  get isDirty() {
    return this._unsavedChanges.getValue();
  }
  constructor(private dialog: MatDialog) {}

  public createDialog() {
    const config: MatDialogConfig = new MatDialogConfig();
    config.autoFocus = false;
    config.panelClass = 'wh-dialog-panel';
    config.backdropClass = 'cdk-overlay-backdrop';
    config.disableClose = true;

    const ref = this.dialog.open(
      MatDialogConfirmUnsavedChangesComponent,
      config
    );
    return ref.afterClosed();
  }
}
