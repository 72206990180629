// HELPER
import {
  FLOW_GATEWAY,
  IWHPageableMaintenanceNotificationsDTO,
  initHttpParams,
  setWorkObjectTypeIdPath,
} from '@workheld/workheld-shared-lib';

// ENVIRONMENT
import { environment } from '../../environments/environment';

// ANGULAR
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS 6
import { Observable, of } from 'rxjs';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { IWHEntryPreviewDTO } from '@workheld/workheld-shared-lib';
@Injectable()
export class EntryAsyncService {
  constructor(private httpClient: HttpClient) {}

  // ENTRY PREVIEW
  public initEntryPreviewHttpParamsFormGroup(): FormGroup {
    return new FormBuilder().group({
      page: [0],
      sort: ['createdDate'],
      term: [null],
      sortMode: ['DESC'],
      filter: [null],
      entryTypeId: [null],
      entryStatusId: [null],
    });
  }

  // ENTRY PROCESS
  public initEntryProcessFormGroup(): FormGroup {
    return new FormBuilder().group({
      entryStatusId: [null],
      managerComment: [null],
      feedback: [null, Validators.required],
    });
  }

  public getEntryPreview(
    params: WHHttpParams,
    targetParent: string,
    targetID: string,
    workObjectTypeId: number
  ): Observable<IWHEntryPreviewDTO> {
    const httpParams: HttpParams = initHttpParams(params);
    switch (targetParent) {
      case 'SUBSIDIARY':
        return this.getEntryPreviewForSubsidiary(httpParams);
      case 'EQUIPMENT':
        return this.getEntryPreviewByForEquipment(targetID, httpParams);
      case 'WORK_OBJECT':
        return this.getEntryPreviewByForWorkObject(
          targetID,
          workObjectTypeId,
          httpParams
        );
      case 'WORK_ORDER':
        return this.getEntryPreviewByForWorkOrder(targetID, httpParams);
      default:
        return of(null);
    }
  }

  public getMaintenanceNotifications(
    params: WHHttpParams
  ): Observable<IWHPageableMaintenanceNotificationsDTO> {
    const httpParams: HttpParams = initHttpParams(params);

    return this.httpClient.get<IWHPageableMaintenanceNotificationsDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/entries/maintenanceNotifications`,
      { params: httpParams }
    );
  }

  public generateWorkObjectFromEntry(entryId: string) {
    return this.httpClient.post(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/projects/maintenancenotification/${entryId}/generate`,
      {}
    );
  }

  // SUBSIDIARY LEVEL
  private getEntryPreviewForSubsidiary(
    httpParams: HttpParams
  ): Observable<IWHEntryPreviewDTO> {
    return this.httpClient.get<IWHEntryPreviewDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/subsidiaries/entries/preview`,
      { params: httpParams }
    );
  }

  // WORK OBJECT LEVEL
  private getEntryPreviewByForWorkObject(
    workObjectId: string,
    workObjectTypeId: number,
    httpParams: HttpParams
  ): Observable<IWHEntryPreviewDTO> {
    const workObjectTargetEndpoint: string =
      setWorkObjectTypeIdPath(workObjectTypeId);
    return this.httpClient.get<IWHEntryPreviewDTO>(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/${workObjectTargetEndpoint}/${workObjectId}/entries/preview`,
      { params: httpParams }
    );
  }

  // WORK ORDER LEVEL
  private getEntryPreviewByForWorkOrder(
    workOrderId: string,
    httpParams: HttpParams
  ): Observable<IWHEntryPreviewDTO> {
    return this.httpClient.get<IWHEntryPreviewDTO>(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/workOrders/${workOrderId}/entries/preview`,
      { params: httpParams }
    );
  }

  // EQUIPMENT LEVEL
  private getEntryPreviewByForEquipment(
    equipmentId: string,
    httpParams: HttpParams
  ): Observable<IWHEntryPreviewDTO> {
    return this.httpClient.get<IWHEntryPreviewDTO>(
      `${
        environment.apiUrl + FLOW_GATEWAY
      }/equipments/${equipmentId}/entries/preview`,
      { params: httpParams }
    );
  }

  public updateEntryByEntryID(
    entryID: string,
    entryPayload: any
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl + FLOW_GATEWAY}/entries/${entryID}`,
      entryPayload
    );
  }

  public updateDefectEntryByEntryID(
    entryID: string,
    entryPayload: any
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl + FLOW_GATEWAY}/entries/defect/${entryID}`,
      entryPayload
    );
  }
}
