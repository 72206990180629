import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IWHFlowContactDTO, IWHFlowMetadataTranslationModel, WHMetadataDataService } from '@workheld/workheld-shared-lib';
import { Subscription } from 'rxjs';

@Component({
  selector: 'w-h-contact-card',
  templateUrl: './w-h-contact-card.component.html',
  styleUrl: './w-h-contact-card.component.scss'
})
export class WHContactCardComponent implements OnInit {
  
  @Input() public contactDTO: IWHFlowContactDTO;
  @Output() public openModal: EventEmitter<any> = new EventEmitter();
  
  private subscriptions: Subscription[] = [];

  public CONTACT_SALUTATION: any;

  constructor(private metadataDataService: WHMetadataDataService) { }
  ngOnInit(): void {
    
    // WH ENUMS
    this.subscriptions.push(
      this.metadataDataService.metadataTranslation$.subscribe(
        (metadataTranslation: IWHFlowMetadataTranslationModel) => {
          this.CONTACT_SALUTATION = metadataTranslation.CONTACT_SALUTATION;
        }
      )
    );
  }
  
  contactCrud(contact: IWHFlowContactDTO) {
    this.openModal.emit(contact);
  }


}
