// ANGULAR
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// MODELS
import { WHNgxDndDraggableModel } from '@workheld/workheld-shared-lib';
export class WHWorkOrderDndDropData {
  targetParent: string;
  draggedDataId: string;
  draggedDataCachedDetailURL: string;
}

export class WHWorkOrderUnassignData {
  targetParent: string;
  targetId: string;
}

@Injectable()
export class WHDndBehaviorSubjectHelperService {

  public dndBehaviorSubject: BehaviorSubject<WHNgxDndDraggableModel> = new BehaviorSubject({
    disable: false,
    handle: false,
    data: null,
    effectAllowed: 'copy'
  } as WHNgxDndDraggableModel);

  public onWorkOrderDrop: BehaviorSubject<WHWorkOrderDndDropData> = new BehaviorSubject(null);
  public onWorkOrderUnassign: BehaviorSubject<WHWorkOrderUnassignData> = new BehaviorSubject(null);

  public initWorkOrderDropData(targetParent: string, assignmentResponse: any): WHWorkOrderDndDropData {
    switch (targetParent) {
      case 'WORKER':
        return {
          targetParent,
          draggedDataId: assignmentResponse.workerId,
          draggedDataCachedDetailURL: assignmentResponse.workerCachedDetailURL
        };
      case 'EQUIPMENT':
        return {
          targetParent,
          draggedDataId: assignmentResponse.equipmentId,
          draggedDataCachedDetailURL: assignmentResponse.equipmentCachedDetailURL
        };
      case 'TOOL':
        return {
          targetParent,
          draggedDataId: assignmentResponse.toolId,
          draggedDataCachedDetailURL: assignmentResponse.toolCachedDetailURL
        };
      default:
        return undefined;
    }
  }
}
