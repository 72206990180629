<div class="mat-dialog-add-skill-container">
  <div class="detail-card-header mat-dialog-add-content">
    <span whFlex class="w-h-header-span">
      <div class="single-header">
        {{ "app.ui.addAlternativeJobProfiles.label" | translate }}
      </div>
    </span>
  </div>
  <mat-divider class="wh-dialog-divider"></mat-divider>
  <div class="mat-dialog-add-content">
    <div class="flex--r-fs gap--16">
      <div class="flex--col flex--grow gap--16">
        <lib-w-h-dropdown
          data-cy="job-profile-dropdown"
          [required]="false"
          [valueLabel]="'app.ui.alternativeJobProfiles.label'"
          [itemKeys]="['name']"
          [dropdownItemDomList]="data.jobProfilesDropdownList$ | async"
          (onDomEvent)="setJobrequirement($event)"
        >
        </lib-w-h-dropdown>
      </div>
    </div>
    <div class="dialog-action-wrapper" mat-dialog-actions>
      <button
        class="w-h-default-button dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="cancel()"
        tabindex="-1"
      >
        {{ "app.cancel.label" | translate | uppercase }}
      </button>

      <button
        color="accent"
        class="dialog-button mat-elevation-z2"
        mat-flat-button
        data-cy="job-profile-confirm-button"
        type="button"
        (click)="confirm()"
        tabindex="-1"
      >
        {{ "app.confirm.label" | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
