// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHEquipmentCrudStore from './w-h-equipment-crud-store.reducer';
import { WHEquipmentCrudStoreEffects } from './w-h-equipment-crud-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHEquipmentCrudStore.workheldEquipmentCrudStoreFeatureKey, fromWHEquipmentCrudStore.workheldEquipmentCrudReducer),
    EffectsModule.forFeature([WHEquipmentCrudStoreEffects])
  ]
})
export class WHEquipmentCrudStoreModule { }
