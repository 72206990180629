// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHWorkOrderDOM } from '@workheld/workheld-shared-lib';

export enum WHWorkOrderCrudStoreActionTypes {
  GetWHWorkOrderDTO = '[WHWorkOrderCrudStore] GetWHWorkOrderDTO',
  SetWHWorkOrderDOM = '[WHWorkOrderCrudStore] SetWHWorkOrderDOM',
  ClearWHWorkOrderCrudSTORE = '[WHWorkOrderCrudStore] ClearWHWorkOrderCrudSTORE',
}

export class GetWHWorkOrderDTO implements Action {
  readonly type = WHWorkOrderCrudStoreActionTypes.GetWHWorkOrderDTO;

  constructor(public payload: { workOrderId: string }) {
    // console.log('GetWHWorkOrderDTO ');
  }
}

export class SetWHWorkOrderDOM implements Action {
  readonly type = WHWorkOrderCrudStoreActionTypes.SetWHWorkOrderDOM;

  constructor(public payload: WHWorkOrderDOM) {
    // console.log('SetWHWorkOrderDOM ' + JSON.stringify(payload));
  }
}

export class ClearWHWorkOrderCrudSTORE implements Action {
  readonly type = WHWorkOrderCrudStoreActionTypes.ClearWHWorkOrderCrudSTORE;

  constructor() {
    // console.log('ClearWHWorkOrderCrudSTORE');
  }
}


export type WHWorkOrderCrudStoreActions = GetWHWorkOrderDTO | SetWHWorkOrderDOM | ClearWHWorkOrderCrudSTORE;
