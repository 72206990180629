import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  getEntryIconByEntryTypeId,
  WHEntryDOM,
  WHIconENUM,
  entryUiConfig$,
  IWHUIConfigModel,
} from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-mat-dialog-entry-detail-entry',
  templateUrl: './mat-dialog-entry-detail-entry.component.html',
  styleUrls: ['./mat-dialog-entry-detail-entry.component.scss'],
})
export class MatDialogEntryDetailEntryComponent implements OnInit {
  public featuredEntryTypeHeader: string;

  constructor(
    public matDialogRef: MatDialogRef<MatDialogEntryDetailEntryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { entryDOM: WHEntryDOM }
  ) {}

  ngOnInit() {
    this.featuredEntryTypeHeader = entryUiConfig$.value.find(
      (enumModelDOM: IWHUIConfigModel) => {
        return enumModelDOM.typeId === this.data?.entryDOM?.entryTypeId;
      }
    )?.translateKey;
  }

  public onEntryUpdate($event) {
    this.matDialogRef.close($event);
  }

  public get entryTypeIcon() {
    return this.data?.entryDOM?.entryTypeId
      ? getEntryIconByEntryTypeId(this.data?.entryDOM?.entryTypeId)
      : WHIconENUM.EntryIcon;
  }
}
