// AUTH0
import { AuthConfig } from '@auth0/auth0-angular';
import {
  googleMapsApiKeyPROD,
  IWHEnvironmentModel,
  WHClientIdENUM,
  WHDomainENUM,
  WHApiUrlENUM,
  WHFeatureKeyENUM,
  WHClientBaseUrlENUM,
  WHLayoutNavItemDOM,
  WHRouteZeroENUM,
  WHIconENUM,
  WHLogMessage,
} from '@workheld/workheld-shared-lib';

import workheldWebClient from '../../package.json';
import workheldSharedLib from '../../node_modules/@workheld/workheld-shared-lib/package.json';
import bryntumSchedulerLib from '../../node_modules/@bryntum/schedulerpro/package.json';

// PACKAGE
export const workheldWebClientVersion = workheldWebClient.version as string;
export const workheldSharedLibVersion = workheldSharedLib.version as string;
export const bryntumSchedulerLibVersion = bryntumSchedulerLib.version as string;

export const i18nJargon: string[] = ['de', 'en'];

export const clientLoggerConfig: WHLogMessage | any = {
  applicationName: 'flow-client',
  applicationVersion: workheldWebClientVersion,
};

export const localDiagnosticsNavItems: WHLayoutNavItemDOM[] = [
  {
    enabled: true,
    translateKey: 'nav.elementtestpage.label',
    type: 'navigate',
    routeUrl: WHRouteZeroENUM.DIAGNOSTICS + '/test',
    icon: WHIconENUM.SettingsIcon,
  },
];

const devFeatureConfigMap: Map<string, boolean> = new Map()
  .set(WHFeatureKeyENUM.PROFILE, true)
  .set(WHFeatureKeyENUM.SETTINGS, false)
  .set(WHFeatureKeyENUM.DIAGNOSTICS, false)
  .set(WHFeatureKeyENUM.DARK, false);

// SET STAGES
export const environmentConfigDev: IWHEnvironmentModel = {
  production: false,
  stage: 'dev',
  version: `${workheldWebClientVersion}`,
  apiUrl: WHApiUrlENUM.DEV_API_URL,
  instrumentationKey: '',
  mgmtUrl: WHClientBaseUrlENUM.MGMT_DEV,
  flowUrl: WHClientBaseUrlENUM.FLOW_DEV,
  callUrl: WHClientBaseUrlENUM.CALL_DEV,
  whSenseUrl: WHClientBaseUrlENUM.WH_SENSE,
  wssUrl: WHApiUrlENUM.CALL_DEV_WSS_URL,
  auth0Config: {
    authorizationParams: {
      audience: 'workheld',
      redirect_uri: window.location.origin,
    },
    domain: WHDomainENUM.DEV,
    clientId: WHClientIdENUM.FLOW_DEV,
    httpInterceptor: {
      allowedList: [
        `${WHApiUrlENUM.DEV_API_URL}*`,
        `${'https://blue-desert-0e4cac903.3.azurestaticapps.net/'}*`,
        `${'https://workheld-callservice-dev.azurewebsites.net/'}*`,
      ],
    },
    additionalParameters: {
      response_type: 'token',
    },
    cacheLocation: 'localstorage',
    cookieDomain: '.workheld.com',
  } as AuthConfig,

  googleMapsApiKey: googleMapsApiKeyPROD,
  googleAnalyticsKey: 'G-5B792L49W9',
  devFeatureConfigMap,
};

// SET STAGES 
export const environmentConfigDev2: IWHEnvironmentModel = {
  production: process.env['PRODUCTION'] === 'true',
  stage: 'dev',
  version: `${workheldWebClientVersion}`,
  instrumentationKey: '',
  apiUrl: process.env['API_URL'],
  mgmtUrl: process.env['MGMT_URL'],
  flowUrl: process.env['FLOW_URL'],
  callUrl: process.env['CALL_URL'],
  whSenseUrl: process.env['WH_SENSE_URL'],
  wssUrl: WHApiUrlENUM.CALL_DEV_WSS_URL,
  auth0Config: {
    authorizationParams: {
      audience: 'workheld',
      redirect_uri: window.location.origin,
    },
    domain: process.env['AUTH0_DOMAIN'],
    clientId: process.env['AUTH0_CLIENT_ID'],
    httpInterceptor: {
      allowedList: [
        `${process.env['API_URL']}*`,
        `${'https://workheld-callservice-dev.azurewebsites.net/'}*`,
      ],
    },
    additionalParameters: {
      response_type: 'token',
    },
    cacheLocation: 'localstorage',
    cookieDomain: '.workheld.com',
  } as AuthConfig,
  googleMapsApiKey: process.env['GOOGLE_MAPS_API_KEY'],
  googleAnalyticsKey: process.env['GOOGLE_ANALYTICS_ID'],
  devFeatureConfigMap,
};

// TEST STAGE - workheld-stages
export const environmentConfigTest: IWHEnvironmentModel = {
  production: true,
  stage: 'test',
  version: `${workheldWebClientVersion}`,
  instrumentationKey: '83b36a94-78ab-407a-9775-ab5f4a84508c',
  apiUrl: WHApiUrlENUM.TEST_API_URL,
  mgmtUrl: WHClientBaseUrlENUM.MGMT_TEST,
  flowUrl: WHClientBaseUrlENUM.FLOW_TEST,
  callUrl: WHClientBaseUrlENUM.CALL_TEST,
  whSenseUrl: WHClientBaseUrlENUM.WH_SENSE,
  wssUrl: WHApiUrlENUM.CALL_DEV_WSS_URL,
  auth0Config: {
    authorizationParams: {
      audience: 'workheld',
      redirect_uri: window.location.origin,
    },
    domain: WHDomainENUM.TEST,
    clientId: WHClientIdENUM.FLOW_TEST,
    httpInterceptor: {
      allowedList: [
        `${WHApiUrlENUM.TEST_API_URL}*`,
        'https://workheld-callservice-dev.azurewebsites.net/*',
      ],
    },
    additionalParameters: {
      response_type: 'token',
    },
    cacheLocation: 'localstorage',
  } as AuthConfig,
  googleMapsApiKey: googleMapsApiKeyPROD,
  devFeatureConfigMap,
};

export const environmentConfigProd: IWHEnvironmentModel = {
  production: true,
  stage: 'prod',
  version: `${workheldWebClientVersion}`,
  apiUrl: WHApiUrlENUM.PROD_API_URL,
  instrumentationKey: '3145b9ac-3d69-49e9-9abe-b4c2946eae8f',
  mgmtUrl: WHClientBaseUrlENUM.MGMT_PROD,
  flowUrl: WHClientBaseUrlENUM.FLOW_PROD,
  callUrl: WHClientBaseUrlENUM.CALL_PROD,
  whSenseUrl: WHClientBaseUrlENUM.WH_SENSE,
  wssUrl: WHApiUrlENUM.CALL_DEV_WSS_URL,
  auth0Config: {
    authorizationParams: {
      audience: 'workheld',
      redirect_uri: window.location.origin,
    },
    domain: WHDomainENUM.PROD,
    clientId: WHClientIdENUM.FLOW_PROD,
    httpInterceptor: {
      allowedList: [`${WHApiUrlENUM.PROD_API_URL}*`],
    },
    additionalParameters: {
      response_type: 'token',
    },
    cacheLocation: 'localstorage',
  } as AuthConfig,
  googleMapsApiKey: googleMapsApiKeyPROD,
  googleAnalyticsKey: 'G-PV2SSX5Y4S',
  devFeatureConfigMap,
};
