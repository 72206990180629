import { Component, Input } from '@angular/core';
import { WHAssetsENUM, WHIconENUM } from '@workheld/workheld-shared-lib';
import { MaterialDTO } from 'src/app/app-services-async/material-async.service';

@Component({
  selector: 'ng-bee-material-static-data',
  templateUrl: './material-static-data.component.html',
  styleUrls: ['./material-static-data.component.scss'],
})
export class MaterialStaticDataComponent {
  // INPUT
  @Input() public nameLabel: string = 'material.model.root';
  @Input() public withLabel: boolean = false;
  @Input() public materialDTO: MaterialDTO;

  public get materialConsumptionIcon(): string {
    return WHIconENUM.MaterialConsumptionIcon as string;
  }

  public get materialPlaceholderPNG(): string {
    return WHAssetsENUM.materialPlaceholderPNG as string;
  }
}
