// ENVIRONMENT
import { environment } from '../../environments/environment';

// ANGULAR
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS 6
import { Observable } from 'rxjs';
import { FLOW_GATEWAY } from '@workheld/workheld-shared-lib';

@Injectable()
export class CheckInstanceFileAsyncService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public getChecklistInstanceFilesByChecklistInstanceId(checklistInstanceId: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl + FLOW_GATEWAY}/checklistInstances/${checklistInstanceId}/checkInstanceFiles`);
  }
}
