// HELPER
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHWorkOrderCrudStoreActions, WHWorkOrderCrudStoreActionTypes } from './w-h-work-order-crud-store.actions';

// MODELS
import { WHWorkOrderDOM } from '@workheld/workheld-shared-lib';
export const workheldWorkOrderCrudStoreFeatureKey = 'workheldWorkOrderCrudStore$';

export const initialState: WHWorkOrderDOM = new WHWorkOrderDOM();

export function workheldWorkOrderCrudReducer(state = initialState, action: WHWorkOrderCrudStoreActions): WHWorkOrderDOM {
  switch (action.type) {

    case WHWorkOrderCrudStoreActionTypes.GetWHWorkOrderDTO:
      return updateObject(new WHWorkOrderDOM(), {
        loading: true,
        loaded: false,
      } as any);

    case WHWorkOrderCrudStoreActionTypes.SetWHWorkOrderDOM:
      return action.payload;

    case WHWorkOrderCrudStoreActionTypes.ClearWHWorkOrderCrudSTORE:
      return new WHWorkOrderDOM();

    default:
      return state;
  }
}
