// ANGULAR
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ng-bee-w-h-status-bar-util',
  templateUrl: './w-h-status-bar-util.component.html',
  styleUrls: ['./w-h-status-bar-util.component.scss']
})
export class WHStatusBarUtilComponent {

  // INPUT STATE
  @Input() public statusLabel: string;
  @Input() public isActive: boolean = false;
  @Input() public mainBar: boolean = false;
  @Input() public matBadgeColor: 'primary' | 'accent' | 'warn' = 'accent';
  @Input() public statusArray: number[] = [];
  @Input() public workObjectStatusId: number;
  @Input() public entriesCountArray: {id: number, count: number}[];

  public getEntriesCount(statusNumber) {
    return this.entriesCountArray?.find(s=> s.id === statusNumber)?.count
  }
}
