// API
import { updateObject, WHBryResourceDOM } from '@workheld/workheld-shared-lib';

// REDUX
import { WHBryResourceStoreActions, WHBryResourceStoreActionTypes } from './w-h-bry-resource-store.actions';

export const workheldBryResourceStoreFeatureKey = 'workheldBryResourceStore$';

export interface IWHBryResourceStore {
  resources: WHBryResourceDOM[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHBryResourceStore = {
  resources: [],
  loading: false,
  loaded: false
};

export function workheldBryResourceReducer(state = initialState, action: WHBryResourceStoreActions): IWHBryResourceStore {
  switch (action.type) {

    case WHBryResourceStoreActionTypes.GetWHBryResourceDTOList:
      return updateObject(state, {
        resources: [],
        loading: true,
        loaded: false
      });

    case WHBryResourceStoreActionTypes.SetWHBryResourceDOMList:
      return action.payload;

    default:
      return state;
  }
}
