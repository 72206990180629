// REDUX
import { WHCustomerMasterDataStoreActions, WHCustomerMasterDataStoreActionTypes } from './w-h-customer-master-data-store.actions';

// MODELS
import { WHCustomerDOM } from '@workheld/workheld-shared-lib';

// CONST
export const workheldCustomerMasterDataStoreFeatureKey = 'workheldCustomerMasterDataStore$';

export interface IWHCustomerMasterDataStore {
  customerDomList: WHCustomerDOM[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHCustomerMasterDataStore = {
  customerDomList: [],
  loading: false,
  loaded: false,
};

export function workheldCustomerMasterDataReducer(state = initialState, action: WHCustomerMasterDataStoreActions): IWHCustomerMasterDataStore {
  switch (action.type) {

    case WHCustomerMasterDataStoreActionTypes.GetCustomerDtoList:
      return {
        customerDomList: [],
        loading: true,
        loaded: false,
      };

    case WHCustomerMasterDataStoreActionTypes.SetCustomerDomList:
      return action.payload;

    default:
      return state;
  }
}
