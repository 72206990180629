// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHBryEventStore from './w-h-bry-event-store.reducer';
import { WHBryEventStoreEffects } from './w-h-bry-event-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHBryEventStore.workheldBryEventStoreFeatureKey, fromWHBryEventStore.workheldBryEventReducer),
    EffectsModule.forFeature([WHBryEventStoreEffects])
  ]
})
export class WHBryEventStoreModule { }
