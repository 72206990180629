// ANGULAR
import { Injectable } from '@angular/core';
import {
  FLOW_GATEWAY,
  IWHWorkObjectEndpointConfigModel,
  WHWorkObjectDataService,
  WHWorkObjectTypeENUM,
  IWHWorkObjectPreviewDTO,
  WHWorkObjectPreviewDOM,
} from '@workheld/workheld-shared-lib';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHWorkObjectPreviewStoreActionTypes,
  WHWorkObjectPreviewStoreActions,
  SetWHWorkObjectPreview,
} from './w-h-work-object-preview-store.actions';
import { switchMap, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class WHWorkObjectPreviewStoreEffects {
  private workObjectTypeId: number;

  getWHWorkObjectPreviewDtoWithParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHWorkObjectPreviewStoreActionTypes.GetWHWorkObjectPreviewDtoWithParams
      ),
      switchMap((action) => {
        this.workObjectTypeId = action.payload.workObjectTypeId;
        const endpointConfig: IWHWorkObjectEndpointConfigModel = {
          apiUrl: environment.apiUrl + FLOW_GATEWAY,
          targetParent: action.payload.targetParent,
          targetId: action.payload.targetID,
          workObjectTypeId: action.payload.workObjectTypeId,
          equipmentId: action.payload.targetID,
          bomNodeId: action.payload.targetID,
        };
        // TODO: remove this when the backend is fixed
        if (!action?.payload?.params?.countDefect) {
          action.payload.params.countDefect = false;
        }
        return this.workObjectDataService.getWorkObjectPreview(
          endpointConfig,
          action.payload.params
        );
      }),
      mergeMap((workObjectPreviewDTO: IWHWorkObjectPreviewDTO) => {
        const workObjectPreviewDOM: WHWorkObjectPreviewDOM =
          this.initWorkObjectPreviewDomType(workObjectPreviewDTO);
        return [new SetWHWorkObjectPreview(workObjectPreviewDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHWorkObjectPreviewStoreActions>,
    private workObjectDataService: WHWorkObjectDataService,
    private dataModelHelperService: DataModelHelperService
  ) {}

  private initWorkObjectPreviewDomType(
    workObjectPreviewDTO: IWHWorkObjectPreviewDTO | any
  ): any {
    if (!this.workObjectTypeId) {
      return this.dataModelHelperService.initWorkObjectPreviewDOM(
        workObjectPreviewDTO
      );
    }

    switch (this.workObjectTypeId) {
      case WHWorkObjectTypeENUM.PROJECT:
        return this.initProjectWorkObjectPreviewDOM(workObjectPreviewDTO);
      case WHWorkObjectTypeENUM.CASE:
        return this.dataModelHelperService.initCaseWorkObjectPreviewDOM(
          workObjectPreviewDTO
        );
      case WHWorkObjectTypeENUM.STANDING_ORDER:
        return this.dataModelHelperService.initStandingOrderWorkObjectPreviewDOM(
          workObjectPreviewDTO
        );
      case WHWorkObjectTypeENUM.JOB:
        return this.initProjectWorkObjectPreviewDOM(workObjectPreviewDTO);
    }
  }

  private initProjectWorkObjectPreviewDOM(previewDTO: any) {
    return this.dataModelHelperService.initProjectWorkObjectPreviewDOM(
      previewDTO
    );
  }
}
