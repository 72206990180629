// REDUX
import { Action } from '@ngrx/store';

// MODELS
import {
  WHHttpParams,
  WHWorkObjectPreviewDOM,
} from '@workheld/workheld-shared-lib';

export enum WHWorkObjectPreviewStoreActionTypes {
  GetWHWorkObjectPreviewDtoWithParams = '[WHWorkObjectPreviewStore] GetWHWorkObjectPreviewDtoWithParams',
  SetWHWorkObjectPreview = '[WHWorkObjectPreviewStore] SetWHWorkObjectPreview',
}

export class GetWHWorkObjectPreviewDtoWithParams implements Action {
  readonly type =
    WHWorkObjectPreviewStoreActionTypes.GetWHWorkObjectPreviewDtoWithParams;

  constructor(
    public payload: {
      params: WHHttpParams;
      targetParent: string;
      targetID: string;
      workObjectTypeId: number;
    }
  ) {}
}

export class SetWHWorkObjectPreview implements Action {
  readonly type = WHWorkObjectPreviewStoreActionTypes.SetWHWorkObjectPreview;

  constructor(public payload: WHWorkObjectPreviewDOM) {}
}

export type WHWorkObjectPreviewStoreActions =
  | GetWHWorkObjectPreviewDtoWithParams
  | SetWHWorkObjectPreview;
