// ANGULAR
import { Component, OnInit, Inject } from '@angular/core';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ng-bee-mat-dialog-confirm-entry',
  templateUrl: './mat-dialog-confirm-entry.component.html',
  styleUrls: ['./mat-dialog-confirm-entry.component.scss']
})
export class MatDialogConfirmEntryComponent implements OnInit {

  public isPlural: boolean = false;
  public isOk: boolean = false;
  public customButton: string;

  constructor(
    public matDialogRef: MatDialogRef<MatDialogConfirmEntryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      translateCode: string,
      isPlural: boolean;
      isOk: boolean;
      customButton: string;
    }
  ) { }

  ngOnInit() {
    this.isPlural = this.data.isPlural;
    this.isOk = this.data.isOk;
    if (this.data.customButton) {
      this.customButton = this.data.customButton;
    }
  }


  closeDialog() {
    this.matDialogRef.close();
  }

  confirm() {
    this.matDialogRef.close(true);
  }

  cancel() {
    this.matDialogRef.close(false);
  }

}
