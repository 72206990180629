// ANGULAR
import { Action } from '@ngrx/store';

// MODELS
import { IWHMaterialUsageReportDTO } from '@workheld/workheld-shared-lib';

export enum WHWorkObjectMaterialUsageReportStoreActionTypes {
  GetWHWorkObjectMaterialUsageReportDTO = '[WHWorkObjectMaterialUsageReportStore] GetWHWorkObjectMaterialUsageReportDTO',
  SetWHWorkObjectMaterialUsageReportDOM = '[WHWorkObjectMaterialUsageReportStore] SetWHWorkObjectMaterialUsageReportDOM',
}

export class GetWHWorkObjectMaterialUsageReportDTO implements Action {
  readonly type = WHWorkObjectMaterialUsageReportStoreActionTypes.GetWHWorkObjectMaterialUsageReportDTO;

  constructor(public payload: { departmentIds: string[], startDate: string, endDate: string }) {
  }
}

export class SetWHWorkObjectMaterialUsageReportDOM implements Action {
  readonly type = WHWorkObjectMaterialUsageReportStoreActionTypes.SetWHWorkObjectMaterialUsageReportDOM;

  constructor(public payload: { materialUsageReportDTO: IWHMaterialUsageReportDTO }) {
  }
}


export type WHWorkObjectMaterialUsageReportStoreActions = GetWHWorkObjectMaterialUsageReportDTO | SetWHWorkObjectMaterialUsageReportDOM;
