// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHToolDOM } from '@workheld/workheld-shared-lib';

export enum WHToolCrudStoreActionTypes {
  GetWHToolDTO = '[WHToolCrudStore] GetWHToolDTO',
  SetWHToolDOM = '[WHToolCrudStore] SetWHToolDOM',
  ClearWHToolCrudSTORE = '[WHToolCrudStore] ClearWHToolCrudSTORE',
}

export class GetWHToolDTO implements Action {
  readonly type = WHToolCrudStoreActionTypes.GetWHToolDTO;

  constructor(public payload: WHHttpParams) {
    // console.log('GetWHToolDTO ' + JSON.stringify(payload));
  }
}

export class SetWHToolDOM implements Action {
  readonly type = WHToolCrudStoreActionTypes.SetWHToolDOM;

  constructor(public payload: WHToolDOM) {
    // console.log('SetWHToolDOM ' + JSON.stringify(payload));
  }
}

export class ClearWHToolCrudSTORE implements Action {
  readonly type = WHToolCrudStoreActionTypes.ClearWHToolCrudSTORE;

  constructor() {
    // console.log('ClearWHToolCrudSTORE');
  }
}


export type WHToolCrudStoreActions = GetWHToolDTO | SetWHToolDOM | ClearWHToolCrudSTORE;
