// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHWorkObjectTemplateCrudStore from './w-h-work-object-template-crud-store.reducer';
import { WHWorkObjectTemplateCrudStoreEffects } from './w-h-work-object-template-crud-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHWorkObjectTemplateCrudStore.workheldWorkObjectTemplateCrudStoreFeatureKey, fromWHWorkObjectTemplateCrudStore.workheldWorkObjectTemplateCrudReducer),
    EffectsModule.forFeature([WHWorkObjectTemplateCrudStoreEffects])
  ]
})
export class WHWorkObjectTemplateCrudStoreModule { }
