// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHToolPreviewDOM } from '@workheld/workheld-shared-lib';

export enum WHToolPreviewStoreActionTypes {
  GetWHToolPreviewDtoWithParams = '[WHToolPreviewStore] GetWHToolPreviewDtoWithParams',
  SetWHToolPreview = '[WHToolPreviewStore] SetWHToolPreview',
}

export class GetWHToolPreviewDtoWithParams implements Action {
  readonly type = WHToolPreviewStoreActionTypes.GetWHToolPreviewDtoWithParams;

  constructor(public payload: WHHttpParams) {
    // console.log('GetWHToolPreviewDtoWithParams ' + JSON.stringify(payload));
  }
}

export class SetWHToolPreview implements Action {
  readonly type = WHToolPreviewStoreActionTypes.SetWHToolPreview;

  constructor(public payload: WHToolPreviewDOM) {
    // console.log('SetWHMaterialPreview ' + JSON.stringify(payload));
  }
}

export type WHToolPreviewStoreActions = GetWHToolPreviewDtoWithParams | SetWHToolPreview;
