// HELPER
import { FLOW_GATEWAY, initHttpParams, setWorkObjectTypeIdPath } from '@workheld/workheld-shared-lib';

// ENVIRONMENT
import { environment } from '../../environments/environment';

// ANGULAR
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS 6
import { Observable, of } from 'rxjs';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { IWHChecklistInstancePreviewDTO, IWHChecklistInstanceDTO } from '@workheld/workheld-shared-lib';

@Injectable()
export class ChecklistInstanceAsyncService {

  constructor(
    private httpClient: HttpClient
  ) { }

  // CHECKLIST INSTANCE PROCESS
  public initChecklistInstanceProcessFormGroup(): FormGroup {
    return new FormBuilder().group({
      checklistInstanceStatusId: [null],
      managerComment: [null]
    });
  }

  // CHECKLIST INSTANCE PREVIEW
  public initChecklistInstancePreviewHttpParamsFormGroup(): FormGroup {
    return new FormBuilder().group({
      page: [0],
      sort: ['createdDate'],
      term: [null],
      sortMode: ['DESC'],
    });
  }

  // CRUD
  public getChecklistInstanceDtoByChecklistInstanceId(checklistInstanceId: string): Observable<IWHChecklistInstanceDTO> {
    return this.httpClient.get<IWHChecklistInstanceDTO>(`${environment.apiUrl + FLOW_GATEWAY}/checklistInstances/${checklistInstanceId}`);
  }

  public updateChecklistInstanceByChecklistInstanceID(checklistInstanceId: string, checklistInstancePayload: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl + FLOW_GATEWAY}/checklistInstances/${checklistInstanceId}`, checklistInstancePayload);
  }

  // PREVIEW
  public getChecklistInstancePreview(params: WHHttpParams, targetParent: string, targetID: string, workObjectTypeId: number): Observable<IWHChecklistInstancePreviewDTO> {
    const httpParams: HttpParams = initHttpParams(params);
    switch (targetParent) {
      // case 'SUBSIDIARY':
      //   return this.getChecklistInstancePreviewForSubsidiary(httpParams);
      case 'EQUIPMENT':
        return this.getChecklistInstancePreviewByForEquipment(targetID, httpParams);
      case 'WORK_OBJECT':
        return this.getChecklistInstancePreviewByForWorkObject(targetID, workObjectTypeId, httpParams);
      case 'WORK_ORDER':
        return this.getChecklistInstancePreviewByForWorkOrder(targetID, httpParams);
      default:
        return of(null);
    }
  }

  // SUBSIDIARY LEVEL
  // private getChecklistInstancePreviewForSubsidiary(httpParams: HttpParams): Observable<IWHChecklistInstancePreviewDTO> {
  //   return this.httpClient.get<IWHChecklistInstancePreviewDTO>(`${environment.apiUrl}/subsidiaries/checklistInstances/preview`, { params: httpParams });
  // }

  // WORK OBJECT LEVEL
  private getChecklistInstancePreviewByForWorkObject(workObjectId: string, workObjectTypeId: number, httpParams: HttpParams): Observable<IWHChecklistInstancePreviewDTO> {
    const workObjectTargetEndpoint: string = setWorkObjectTypeIdPath(workObjectTypeId);
    return this.httpClient.get<IWHChecklistInstancePreviewDTO>(`${environment.apiUrl + FLOW_GATEWAY}/${workObjectTargetEndpoint}/${workObjectId}/checklistInstances/preview`, { params: httpParams });
  }

  // WORK ORDER LEVEL
  private getChecklistInstancePreviewByForWorkOrder(workOrderId: string, httpParams: HttpParams): Observable<IWHChecklistInstancePreviewDTO> {
    return this.httpClient.get<IWHChecklistInstancePreviewDTO>(`${environment.apiUrl + FLOW_GATEWAY}/workOrders/${workOrderId}/checklistInstances/preview`, { params: httpParams });
  }

  // EQUIPMENT LEVEL
  private getChecklistInstancePreviewByForEquipment(equipmentId: string, httpParams: HttpParams): Observable<IWHChecklistInstancePreviewDTO> {
    return this.httpClient.get<IWHChecklistInstancePreviewDTO>(`${environment.apiUrl + FLOW_GATEWAY}/equipments/${equipmentId}/checklistInstances/preview`, { params: httpParams });
  }
}
