// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { WorkOrderAsyncService } from 'src/app/app-services-async/work-order-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHWorkOrderCrudStoreActionTypes,
  WHWorkOrderCrudStoreActions,
  GetWHWorkOrderDTO,
  SetWHWorkOrderDOM,
} from './w-h-work-order-crud-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';
import { IWHWorkOrderDTO, WHWorkOrderDOM } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHWorkOrderCrudStoreEffects {
  getWHWorkOrderDTO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHWorkOrderCrudStoreActionTypes.GetWHWorkOrderDTO),
      switchMap((actions: GetWHWorkOrderDTO) => {
        return this.workOrderAsyncService.getWorkOrderDtoByWorkOrderId(
          actions.payload.workOrderId
        );
      }),
      mergeMap((workOrderDTO: IWHWorkOrderDTO) => {
        const workOrderDOM: WHWorkOrderDOM =
          this.dataModelHelperService.initWorkOrderDOM(workOrderDTO);
        return [new SetWHWorkOrderDOM(workOrderDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHWorkOrderCrudStoreActions>,
    private workOrderAsyncService: WorkOrderAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
