import { DateHelper, SchedulerConfig } from '@bryntum/schedulerpro';
import {
  PLANNER_PRESET_DAY_ID,
  PLANNER_PRESET_MONTH_ID,
  PLANNER_PRESET_QUARTER_ID,
  PLANNER_PRESET_WEEK_ID,
  PLANNER_SHIFT_UNITS,
} from 'src/app/app-pages/w-h-team-planner-page/team-planner-configs/team-planner-view-presets.config';
import { getEventEditorConfig } from './equipment-planner-event-editor.config';

const DATE_TIME_FORMAT = 'DD.MM.YYYY{ }HH:mm';

// to calculate the offset of nested events
function normalizeAgendaItems(eventData: any) {
  eventData.startDate = DateHelper.parse(eventData.startDate);
  eventData.agenda?.forEach((nestedEvent: any) => {
    nestedEvent.startDate = DateHelper.parse(nestedEvent.startDate);
    nestedEvent.endDate = DateHelper.parse(nestedEvent.endDate);
    // Calculate offsets, more useful for rendering in case event is dragged to a new date
    nestedEvent.startOffset = DateHelper.diff(
      eventData.startDate,
      nestedEvent.startDate,
    );
    nestedEvent.endOffset = DateHelper.diff(
      nestedEvent.startDate,
      nestedEvent.endDate,
    );
  });
}

const timeResolution = {
  unit: 'minute',
  increment: 30,
};

export const viewPresetsConfig = [
  {
    id: PLANNER_PRESET_DAY_ID,
    name: 'Planner Day View',
    base: 'hourAndDay',
    shiftUnit: PLANNER_SHIFT_UNITS[PLANNER_PRESET_DAY_ID] as 'day', // ENUM
    shiftIncrement: 1,
    timeResolution: { ...timeResolution },
    headers: [
      {
        unit: 'day',
        dateFormat: 'ddd DD.MM', // Mon 01.10
      },
      {
        unit: 'hour',
        increment: 1,
        dateFormat: 'HH:mm', // 17:00
      },
    ],
  },
  {
    id: PLANNER_PRESET_WEEK_ID,
    name: 'Planner Week View',
    base: 'weekAndDay',
    tickWidth: 50,
    shiftUnit: PLANNER_SHIFT_UNITS[PLANNER_PRESET_WEEK_ID] as 'week', // ENUM
    shiftIncrement: 1,
    timeResolution: { ...timeResolution },
    headers: [
      {
        unit: 'day',
        dateFormat: 'ddd DD.MM', // Mon 01.10
      },
      {
        unit: 'hour',
        increment: 4,
        dateFormat: 'HH:mm', // 17:00
      },
    ],
  },
  {
    id: PLANNER_PRESET_MONTH_ID,
    name: 'Planner Month View',
    base: 'dayAndMonth',
    tickWidth: 50,
    shiftUnit: PLANNER_SHIFT_UNITS[PLANNER_PRESET_MONTH_ID], // ENUM
    shiftIncrement: 1,
    timeResolution: { ...timeResolution },
    headers: [
      {
        unit: 'month',
        dateFormat: 'MMMM', // July
      },
      {
        unit: 'week',
        dateFormat: 'WWp0',
      },
      {
        unit: 'day',
        dateFormat: 'dd DD', // Su 04
      },
    ],
  },
  {
    id: PLANNER_PRESET_QUARTER_ID,
    name: 'Planner Quarter View',
    base: 'year',
    tickWidth: 50,
    shiftUnit: PLANNER_SHIFT_UNITS[PLANNER_PRESET_QUARTER_ID], // ENUM
    shiftIncrement: 1,
    timeResolution: { ...timeResolution },
    headers: [
      {
        unit: 'quarter',
        dateFormat: 'Qo', // 3d
      },
      {
        unit: 'month',
        dateFormat: 'MMMM YYYY', // July 2023
      },
      {
        unit: 'week',
        dateFormat: 'WWp0',
      },
    ],
  },
];

export function createSchedulerConfig(component: any) {
  return {
    rowHeight: 310,
    barMargin: 8,
    resourceMargin: 20,
    autoAdjustTimeAxis: false,
    multiEventSelect: false,
    presets: [...viewPresetsConfig],
    viewPreset: PLANNER_PRESET_MONTH_ID,
    enableDeleteKey: false,
    mode: 'horizontal',
    columns: [
      {
        type: 'resourceInfo',
        text: 'L{TranslateService.nav.equipment.root}',
        field: 'name',
        width: '16em',
        renderer(event: any): Array<any> {
          const { record, grid: scheduler } = event;

          return [
            {
              className: 'name',
              text: record.name,
            },
          ];
        },
      },
    ],
    enableTextSelection: true,
    createEventOnDblClick: false,
    zoomKeepsOriginalTimespan: false,
    zoomOnMouseWheel: false,
    zoomOnTimeAxisDoubleClick: false,
    timeRanges: [],
    eventLayout: 'stack',
    features: {
      taskEdit: false,
      nestedEvents: {
        // Stack nested events initially (can be changed from the toolbar)
        eventLayout: 'stack',
        // Grow nested events a bit, compared to the default which is 30
        eventHeight: 85,
        // Reserve more space above the nested events container
        headerHeight: 25,
        // Space between nested events
        barMargin: 5,
        maxNesting: 1,
      },
      timeRanges: {
        showHeaderElements: true,
        enableResizing: true,
        headerRenderer({ timeRange }: any) {
          return `<span style="display: block; text-overflow: ellipsis; white-space: nowrap; overflow:hidden; padding: 0px 4px;">${
            timeRange.name
          } ${timeRange.notes ? ' | ' + timeRange.notes : ''}</span>`;
        },
      },
      dependencies: false,
      cellEdit: false,
      columnPicker: false,
      columnReorder: false,
      group: false,
      sort: false,
      eventFilter: false,
      eventResize: false,
      eventDragCreate: false,
      headerMenu: false,
      timeAxisHeaderMenu: false,
      cellMenu: false,
      scheduleMenu: false,
      stickyEvents: false,
      eventDrag: true,
      labels: {
        top: {
          field: 'projectName',
          renderer: ({ taskRecord }) =>
            taskRecord.data.isWorkOrderView
              ? taskRecord.data.projectName
              : null,
        },
        bottom: {
          field: 'departmentName',
        },
      },
      eventMenu: {
        items: {
          routerLink: {
            icon: 'b-fa-arrow-right',
            text: 'L{TranslateService.teamplanner.viewworkobjectdetail.label}',
          },
          splitEvent: false,
          unassignEvent: false,
          deleteEvent: false,
          editEvent: false,
          copyEvent: false,
          cutEvent: false,
        },
      },
      eventEdit: {
        ...getEventEditorConfig(),
      },
      eventTooltip: {
        style: `background: white;`,
        cls: 'wh-event-tooltip',
        htmlCls: 'wh-event-tooltip-content',
        template: ({ eventRecord, startDate, endDate }: any): string => {
          return `<div>
            <div style="margin-bottom: 8px; font-weight: bold">
              ${eventRecord.eventStyle === 'dashed' ? '' : eventRecord.extId} ${eventRecord.name}
            </div>
            <div class="flex--col gap--2">
              <div class="flex--col">
                <span style="font-weight: bold">L{TranslateService.workobject.model.scheduledstart}:</span>
                ${DateHelper.format(startDate, DATE_TIME_FORMAT)}
              </div>
            
              ${
                !eventRecord.isMilestone
                  ? `
              <div class="flex--col">
                <span style="font-weight: bold">L{TranslateService.workobject.model.scheduledend}:</span>
                ${DateHelper.format(endDate, DATE_TIME_FORMAT)}
              </div>
              `
                  : ''
              }

               ${
                 eventRecord.departmentName
                   ? `
              <div class="flex--col">
                <span style="font-weight: bold">L{TranslateService.shift.model.department}:</span>
                ${eventRecord.departmentName}
              </div>
              `
                   : ''
               }

               ${
                 eventRecord.isMilestone
                   ? `
               <div style="margin-top: 8px; color: #ffc107; font-weight: bold">L{TranslateService.planner.workstepdates.error.label}</div>
           `
                   : ''
               }
               </div>
            
            
          </div>`;
        },
      },
    },

    crudManager: {
      autoLoad: true,
      listeners: {
        // Will be called after data is fetched but before it is loaded into stores
        beforeLoadApply({ response }: any) {
          // Turn "nested event" dates into actual dates, to not have to process them each time during render
          response.events.rows.forEach((event: any) =>
            normalizeAgendaItems(event),
          );
        },
      },
    },

    eventRendererThisObj: component,
    eventRenderer: ({ eventRecord, renderData }: any) => {
      if (eventRecord.isMilestone) {
        return `<i class="workheld-icons">${eventRecord.data.icon}</i>`;
      }

      const startDate = eventRecord.get('startDate'),
        // getDistanceForDuration gives us a px distance value
        dateToPx = (date: any) =>
          component._schedulerInstance?.timeAxisViewModel.getDistanceForDuration(
            date - startDate,
          ),
        { workSteps } = eventRecord.originalData;

      if (eventRecord.isParent) {
        return [eventRecord.name];
      } else if (workSteps) {
        // Calculate coordinates for all nested events and put in a DomConfig object
        let nestedEventData = workSteps.map((ws: any) => {
          let position = dateToPx(DateHelper.add(startDate, ws.startOffset)),
            size = dateToPx(DateHelper.add(startDate, ws.endOffset));
          let smallSize = false;
          if (workSteps.length === 1 && size < 10) {
            smallSize = true;
          }

          return {
            tag: 'div',
            id: ws.id,
            class: `nested ${eventRecord.cls}`,
            style: {
              ['left']: `${position}px`,
              ['width']: smallSize ? '100%' : `${size}px`,
            },
          };
        });

        renderData.cls.eventWithAgenda = 1;
        const staticProjectData = {
          tag: 'div',
          id: 'proj',
          class: 'bry-project-data-info',
          style: {
            ['left']: `${0}px`,
            ['width']: `${100}%`,
            ['position']: 'absolute',
            ['overflow']: 'visible',
          },
          html: `<div class="flex--row gap--8">
          <i class="${eventRecord.data.iconCls}">${eventRecord.data.icon}</i>
          <div class="flex--col gap--4">
            <span>${eventRecord.originalData.extId} ${
              eventRecord.originalData.name
            }</span>
          <div class="sub-items flex--row gap--4">
          ${
            eventRecord.data.confirmed
              ? `<i class="event-icon status ${eventRecord.data.iconCls}">${eventRecord.data.confirmedIcon}</i>`
              : ''
          }
          ${
            eventRecord.data.fixed
              ? `<i class="event-icon status ${eventRecord.data.iconCls}">${eventRecord.data.fixedIcon}</i>`
              : ''
          }
          <span>${DateHelper.format(
            eventRecord.data.startDate,
            'DD.MM.YYYY',
          )} - ${DateHelper.format(
            eventRecord.data.endDate,
            'DD.MM.YYYY',
          )}</span>
              </div>
          </div>
        </div>`,
        };
        // used to display project data instead of showing worksteps data
        nestedEventData.push(staticProjectData);

        return nestedEventData;
      }
    },
  } as Partial<SchedulerConfig>;
}
