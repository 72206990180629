// HELPER
import { FLOW_GATEWAY, initHttpParams, setWorkObjectTypeIdPath } from '@workheld/workheld-shared-lib';

// ENVIRONMENT
import { environment } from '../../environments/environment';

// ANGULAR
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS 6
import { Observable, of } from 'rxjs';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { IWHEntryFilePreviewDTO, IWHEntryFileDTO } from '@workheld/workheld-shared-lib';

@Injectable()
export class EntryFileAsyncService {

  constructor(
    private httpClient: HttpClient
  ) { }

  // GET ALL
  public getEntryFileDtoListByEntryId(entryId: string): Observable<IWHEntryFileDTO[]> {
    return this.httpClient.get<IWHEntryFileDTO[]>(`${environment.apiUrl + FLOW_GATEWAY}/entries/${entryId}/entryfiles`);
  }

  // ENTRY PREVIEW
  public initEntryFilePreviewHttpParamsFormGroup(): FormGroup {
    return new FormBuilder().group({
      page: [0],
      sort: ['createdDate'],
      term: [null],
      sortMode: ['DESC'],
      filter: null,
      entryTypeId: [null],
      entryStatusId: [null]
    });
  }

  public getEntryFilePreview(params: WHHttpParams, targetParent: string, targetID: string, workObjectTypeId: number): Observable<IWHEntryFilePreviewDTO> {
    const httpParams: HttpParams = initHttpParams(params);
    switch (targetParent) {
      case 'SUBSIDIARY':
        return this.getEntryFilePreviewByForSubsidiary(httpParams);
      case 'EQUIPMENT':
        return this.getEntryFilePreviewByForEquipment(targetID, httpParams);
      case 'WORK_OBJECT':
        return this.getEntryFilePreviewByForWorkObject(targetID, workObjectTypeId, httpParams);
      case 'WORK_ORDER':
        return this.getEntryFilePreviewByForWorkOrder(targetID, httpParams);
      default:
        return of(null);
    }
  }

  // WORK OBJECT LEVEL
  private getEntryFilePreviewByForSubsidiary(httpParams: HttpParams): Observable<IWHEntryFilePreviewDTO> {
    return this.httpClient.get<IWHEntryFilePreviewDTO>(`${environment.apiUrl + FLOW_GATEWAY}/subsidiaries/entryfiles/preview`, { params: httpParams });
  }

  // WORK OBJECT LEVEL
  private getEntryFilePreviewByForWorkObject(workObjectId: string, workObjectTypeId: number, httpParams: HttpParams): Observable<IWHEntryFilePreviewDTO> {
    const workObjectTargetEndpoint: string = setWorkObjectTypeIdPath(workObjectTypeId);
    return this.httpClient.get<IWHEntryFilePreviewDTO>(`${environment.apiUrl + FLOW_GATEWAY}/${workObjectTargetEndpoint}/${workObjectId}/entryfiles/preview`, { params: httpParams });
  }

  // WORK ORDER LEVEL
  private getEntryFilePreviewByForWorkOrder(workOrderId: string, httpParams: HttpParams): Observable<IWHEntryFilePreviewDTO> {
    return this.httpClient.get<IWHEntryFilePreviewDTO>(`${environment.apiUrl + FLOW_GATEWAY}/workOrders/${workOrderId}/entryfiles/preview`, { params: httpParams });
  }

  // EQUIPMENT LEVEL
  private getEntryFilePreviewByForEquipment(equipmentId: string, httpParams: HttpParams): Observable<IWHEntryFilePreviewDTO> {
    return this.httpClient.get<IWHEntryFilePreviewDTO>(`${environment.apiUrl + FLOW_GATEWAY}/equipments/${equipmentId}/entryfiles/preview`, { params: httpParams });
  }

}
