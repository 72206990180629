// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHCustomerCrudStoreActionTypes,
  WHCustomerCrudStoreActions,
  SetWHCustomerDOM,
  GetWHCustomerDTO,
} from './w-h-customer-crud-store.actions';

// RxJS 6
import { mergeMap, switchMap } from 'rxjs/operators';

// MODELS
import {
  WHCustomerDataService,
  IWHCustomerDTO,
  WHCustomerDOM,
  IWHCustomerEndpointConfigModel,
  FLOW_GATEWAY,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WHCustomerCrudStoreEffects {
  getWHCustomerDTO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHCustomerCrudStoreActionTypes.GetWHCustomerDTO),
      switchMap((action: GetWHCustomerDTO) => {
        const endpointConfig: IWHCustomerEndpointConfigModel = {
          apiUrl: environment.apiUrl + FLOW_GATEWAY,
          customerId: action.payload.targetID,
        };
        return this.customerDataService.getCustomerDtoByCustomerId(
          endpointConfig
        );
      }),
      mergeMap((customerDTO: IWHCustomerDTO) => {
        const customerDOM: WHCustomerDOM =
          this.dataModelHelperService.initCustomerDOM(customerDTO);
        return [new SetWHCustomerDOM(customerDOM)];
      })
    );
  });

  constructor(
    private actions$: Actions<WHCustomerCrudStoreActions>,
    private customerDataService: WHCustomerDataService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
