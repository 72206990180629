// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { WorkOrderAsyncService } from 'src/app/app-services-async/work-order-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHWorkOrderMasterDataStoreActionTypes,
  WHWorkOrderMasterDataStoreActions,
  GetWHWorkOrderDtoList,
  SetWHWorkOrderDomList,
} from './w-h-work-order-master-data-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import { IWHWorkOrderDTO, WHWorkOrderDOM } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHWorkOrderMasterDataStoreEffects {
  getWHWorkOrderDtoList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHWorkOrderMasterDataStoreActionTypes.GetWHWorkOrderDtoList),
      switchMap((action: GetWHWorkOrderDtoList) => {
        return this.workOrderAsyncService.getWorkOrderDtoList(
          action.payload.workObjectTypeId,
          action.payload.workObjectId,
          action.payload.withTargetTimes
        );
      }),
      mergeMap((workOrders: IWHWorkOrderDTO[]) => {
        let workOrderDomList: WHWorkOrderDOM[] = [];
        (workOrders as Array<IWHWorkOrderDTO>).forEach(
          (workOrderDTO: IWHWorkOrderDTO) => {
            workOrderDomList.push(
              this.dataModelHelperService.initWorkOrderDOM(workOrderDTO)
            );
          }
        );
        workOrderDomList = workOrderDomList.sort(
          (a: WHWorkOrderDOM, b: WHWorkOrderDOM) => {
            if (a.sortPosition < b.sortPosition) {
              return -1;
            }
            if (a.sortPosition > b.sortPosition) {
              return 1;
            }
            return 0;
          }
        );
        return [
          new SetWHWorkOrderDomList({
            workOrderDomList,
            loading: false,
            loaded: true,
          }),
        ];
      })
    );
  });

  constructor(
    private actions$: Actions<WHWorkOrderMasterDataStoreActions>,
    private workOrderAsyncService: WorkOrderAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
