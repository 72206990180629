// ANGULAR
import { Action } from '@ngrx/store';

// MODELS
import { IWHEffortReportDTO } from '@workheld/workheld-shared-lib';

export enum WHWorkObjectEffortReportStoreActionTypes {
  GetWHWorkObjectEffortReportDTO = '[WHWorkObjectEffortReportStore] GetWHWorkObjectEffortReportDTO',
  SetWHWorkObjectEffortReportDOM = '[WHWorkObjectEffortReportStore] SetWHWorkObjectEffortReportDOM',
}

export class GetWHWorkObjectEffortReportDTO implements Action {
  readonly type = WHWorkObjectEffortReportStoreActionTypes.GetWHWorkObjectEffortReportDTO;

  constructor(public payload: { startDate: string, endDate: string }) {
    // console.log('GetWHWorkObjectEffortReportDTO ');
  }
}

export class SetWHWorkObjectEffortReportDOM implements Action {
  readonly type = WHWorkObjectEffortReportStoreActionTypes.SetWHWorkObjectEffortReportDOM;

  constructor(public payload: { effortReportDTO: IWHEffortReportDTO }) {
    // console.log('SetWHWorkObjectEffortReportDOM ');
  }
}


export type WHWorkObjectEffortReportStoreActions = GetWHWorkObjectEffortReportDTO | SetWHWorkObjectEffortReportDOM;
