// REDUX
import { Action } from '@ngrx/store';

// MODELS
import { WHAllAssginmentsDOM } from '@workheld/workheld-shared-lib';

export enum WHAllAssignmentStoreActionTypes {
  GetWHAllAssignmentDTO = '[WHAllAssignmentStore] GetWHAllAssignmentDTO',
  SetWHAllAssignmentDOM = '[WHAllAssignmentStore] SetWHAllAssignmentDOM',
}

export class GetWHAllAssignmentDTO implements Action {
  readonly type = WHAllAssignmentStoreActionTypes.GetWHAllAssignmentDTO;

  constructor(public payload: { targetId: string, type: string }) {
    // console.log('GetWHAllAssignmentDTO' + JSON.stringify(payload));
  }
}

export class SetWHAllAssignmentDOM implements Action {
  readonly type = WHAllAssignmentStoreActionTypes.SetWHAllAssignmentDOM;

  constructor(public payload: { allAssignmentsDOM: WHAllAssginmentsDOM, loading: boolean, loaded: boolean }) {
    // console.log('SetWHAllAssignmentDOM' + JSON.stringify(payload));
  }
}

export type WHAllAssignmentStoreActions = GetWHAllAssignmentDTO | SetWHAllAssignmentDOM;
