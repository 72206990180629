// ANGULAR
import { Action } from '@ngrx/store';

// MODELS
import { WHHttpParams } from '@workheld/workheld-shared-lib';
import { WHWorkObjectTemplateDOM } from '@workheld/workheld-shared-lib';

export enum WHWorkObjectTemplateCrudStoreActionTypes {
  GetWHWorkObjectTemplateDTO = '[WHWorkObjectTemplateCrudStore] GetWHWorkObjectTemplateDTO',
  SetWHWorkObjectTemplateDOM = '[WHWorkObjectTemplateCrudStore] SetWHWorkObjectTemplateDOM',
  ClearWHWorkObjectTemplateCrudSTORE = '[WHWorkObjectTemplateCrudStore] ClearWHWorkObjectTemplateCrudSTORE',
}

export class GetWHWorkObjectTemplateDTO implements Action {
  readonly type = WHWorkObjectTemplateCrudStoreActionTypes.GetWHWorkObjectTemplateDTO;

  constructor(public payload: WHHttpParams) {
    // console.log('GetWHWorkObjectTemplateDTO ' + JSON.stringify(payload));
  }
}

export class SetWHWorkObjectTemplateDOM implements Action {
  readonly type = WHWorkObjectTemplateCrudStoreActionTypes.SetWHWorkObjectTemplateDOM;

  constructor(public payload: WHWorkObjectTemplateDOM) {
    // console.log('SetWHWorkObjectTemplateDOM ' + JSON.stringify(payload));
  }
}

export class ClearWHWorkObjectTemplateCrudSTORE implements Action {
  readonly type = WHWorkObjectTemplateCrudStoreActionTypes.ClearWHWorkObjectTemplateCrudSTORE;

  constructor() {
    // console.log('ClearWHWorkObjectTemplateCrudSTORE');
  }
}

export type WHWorkObjectTemplateCrudStoreActions = GetWHWorkObjectTemplateDTO | SetWHWorkObjectTemplateDOM | ClearWHWorkObjectTemplateCrudSTORE;
