// ANGULAR
import { NgModule } from '@angular/core';

// REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromWHWorkOrderMasterDataStore from './w-h-work-order-master-data-store.reducer';
import { WHWorkOrderMasterDataStoreEffects } from './w-h-work-order-master-data-store.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromWHWorkOrderMasterDataStore.workheldWorkOrderMasterDataStoreFeatureKey, fromWHWorkOrderMasterDataStore.workheldWorkOrderMasterDataReducer),
    EffectsModule.forFeature([WHWorkOrderMasterDataStoreEffects])
  ]
})
export class WHWorkOrderMasterDataStoreModule { }
