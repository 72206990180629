// HELPERS
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHChecklistDefinitionPreviewStoreActions, WHChecklistDefinitionPreviewStoreActionTypes } from './w-h-checklist-definition-preview-store.actions';

// COSNT
export const workheldChecklistDefinitionPreviewStoreFeatureKey = 'workheldChecklistDefinitionPreviewStore$';

// MODELS
import { WHChecklistDefinitionDOM } from '@workheld/workheld-shared-lib';

export interface IWHChecklistDefinitionPreviewStore {
  checklistDefinitions?: WHChecklistDefinitionDOM[];
  page?: number;
  pageSize?: number;
  numCurrentEntries?: number;
  numPages?: number;
  numTotalEntries?: number;

  // CUSTOM
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHChecklistDefinitionPreviewStore = {
  checklistDefinitions: [],
  page: 0,
  pageSize: 20,
  numCurrentEntries: 0,
  numPages: 0,
  numTotalEntries: 0,

  // CUSTOM
  loading: false,
  loaded: false,
};

export function workheldChecklistDefinitionPreviewReducer(state = initialState, action: WHChecklistDefinitionPreviewStoreActions): IWHChecklistDefinitionPreviewStore {
  switch (action.type) {

    case WHChecklistDefinitionPreviewStoreActionTypes.GetWHChecklistDefinitionPreviewDtoWithParams:
      //const payload: WHHttpParams = action.payload;
      return updateObject<IWHChecklistDefinitionPreviewStore>(state, {
        loading: true,
        loaded: false
      });

    case WHChecklistDefinitionPreviewStoreActionTypes.SetWHChecklistDefinitionPreview:
      return updateObject<IWHChecklistDefinitionPreviewStore>(state, {
        checklistDefinitions: action.payload.data,
        page: action.payload.page,
        numCurrentEntries: action.payload.numCurrentEntries,
        pageSize: action.payload.pageSize,
        numPages: action.payload.numPages,
        numTotalEntries: action.payload.numTotalEntries,
        loading: false,
        loaded: true
      });

    default:
      return state;
  }
}
