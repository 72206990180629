// THIRD
import { ImageCroppedEvent } from 'ngx-image-cropper';

// ANGULAR
import { Component, OnInit, Inject } from '@angular/core';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// API
import { WHIconENUM } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-mat-dialog-ngx-image-cropper-entry',
  templateUrl: './mat-dialog-ngx-image-cropper-entry.component.html',
  styleUrls: ['./mat-dialog-ngx-image-cropper-entry.component.scss']
})
export class MatDialogNgxImageCropperEntryComponent implements OnInit {

  croppedImage: any = '';

  constructor(
    public matDialogRef: MatDialogRef<MatDialogNgxImageCropperEntryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { imageChangedEvent: any }
  ) { }

  public ngOnInit(): void {
    console.log(this.data);
  }

  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  public imageLoaded() {
    // TODO - show cropper
  }

  public cropperReady() {
    // TODO - cropper ready
  }

  public loadImageFailed() {
    // TODO - show message
  }

  public cancel() {
    this.matDialogRef.close();
  }

  public get cropIcon(): string {
    return WHIconENUM.FileIcon as string;
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

}
