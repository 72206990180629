// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { EntryStaticDataRoutingModule } from './entry-static-data-routing.module';
import { EntryStaticDataComponent } from './entry-static-data.component';
import { Type1TravelEntryStaticDataComponent } from './type1-travel-entry-static-data/type1-travel-entry-static-data.component';
import { Type2WorkingTimeEntryStaticDataComponent } from './type2-working-time-entry-static-data/type2-working-time-entry-static-data.component';
import { Type3ExpenceEntryStaticDataComponent } from './type3-expence-entry-static-data/type3-expence-entry-static-data.component';
import { Type4DefectEntryStaticDataComponent } from './type4-defect-entry-static-data/type4-defect-entry-static-data.component';
import { Type5MaterialConsumptionEntryStaticDataComponent } from './type5-material-consumption-entry-static-data/type5-material-consumption-entry-static-data.component';
import { Type6MeasurmentEntryStaticDataComponent } from './type6-measurment-entry-static-data/type6-measurment-entry-static-data.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';
import { CustomerStaticDataModule } from '../../customer/customer-static-data/customer-static-data.module';
import { WorkObjectStaticDataModule } from '../../work-object/work-object-static-data/work-object-static-data.module';
import { WorkOrderStaticDataModule } from '../../work-order/work-order-static-data/work-order-static-data.module';
import { WorkStepStaticDataModule } from '../../work-step/work-step-static-data/work-step-static-data.module';
import { WorkerStaticDataModule } from '../../worker/worker-static-data/worker-static-data.module';
import { MaterialStaticDataModule } from '../../material/material-static-data/material-static-data.module';
import { EntryFileImgGalleryModule } from '../../entry-file/entry-file-img-gallery/entry-file-img-gallery.module';
import { StaticHeaderStandaloneComponent } from './standalone-static-header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { WHPermissionCheckModule } from '@workheld/workheld-shared-lib';

@NgModule({
  declarations: [
    EntryStaticDataComponent,
    Type1TravelEntryStaticDataComponent,
    Type2WorkingTimeEntryStaticDataComponent,
    Type3ExpenceEntryStaticDataComponent,
    Type4DefectEntryStaticDataComponent,
    Type5MaterialConsumptionEntryStaticDataComponent,
    Type6MeasurmentEntryStaticDataComponent,
  ],
  exports: [EntryStaticDataComponent],
  imports: [
    AppSharedModule,
    ReactiveFormsModule,
    CustomerStaticDataModule,
    WorkObjectStaticDataModule,
    WorkOrderStaticDataModule,
    WorkStepStaticDataModule,
    WorkerStaticDataModule,
    MaterialStaticDataModule,
    EntryFileImgGalleryModule,
    EntryStaticDataRoutingModule,
    StaticHeaderStandaloneComponent,
    WHPermissionCheckModule,
  ],
})
export class EntryStaticDataModule {}
