import { Pipe, PipeTransform } from '@angular/core';
import { DaysOfWeek } from 'src/app/app-services-async/shift-definitions-data.service';

@Pipe({
  name: 'dayofweek',
})
export class DayOfWeekPipe implements PipeTransform {
  transform(value: any): any {
    return DaysOfWeek.find((day) => day.value === value).translateKey;
  }
}
