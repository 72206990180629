// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { WorkOrderAsyncService } from 'src/app/app-services-async/work-order-async.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHDndAssignmentStoreActionTypes,
  WHDndAssignmentStoreActions,
  GetWHDndAssignmentDtoListWithParams,
  SetWHDndAssignmentDtoList,
} from './w-h-dnd-assignment-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class WHDndAssignmentStoreEffects {
  getWHDndAssignmentDtoListWithParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHDndAssignmentStoreActionTypes.GetWHDndAssignmentDtoListWithParams
      ),
      switchMap((action: GetWHDndAssignmentDtoListWithParams) => {
        if (action.payload.targetParent === 'WORKER') {
          return this.workOrderAsyncService.getWorkOrderWorkerAssignmentDtoList(
            action.payload.workOrderId,
            action.payload.httpParams
          );
        }
        if (action.payload.targetParent === 'EQUIPMENT') {
          return this.workOrderAsyncService.getWorkOrderEquipmentAssignmentDtoList(
            action.payload.workOrderId,
            action.payload.httpParams
          );
        }
        if (action.payload.targetParent === 'TOOL') {
          return this.workOrderAsyncService.getWorkOrderToolAssignmentDtoList(
            action.payload.workOrderId,
            action.payload.httpParams
          );
        }
        return of([]);
      }),
      mergeMap((dndAssignmentDtoList: any[]) => {
        return [
          new SetWHDndAssignmentDtoList({
            dndAssignmentDtoList,
            loading: false,
            loaded: true,
          }),
        ];
      })
    );
  });

  constructor(
    private actions$: Actions<WHDndAssignmentStoreActions>,
    private workOrderAsyncService: WorkOrderAsyncService,
  ) {}
}
