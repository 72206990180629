// HELPERS
import { WHBryEventDOM } from '@workheld/workheld-shared-lib';

// ANGULAR
import { WHBryEventStoreActions, WHBryEventStoreActionTypes } from './w-h-bry-event-store.actions';

// MODELS
export const workheldBryEventStoreFeatureKey = 'workheldBryEventStore$';

export interface IWHBryEventStore {
  events: WHBryEventDOM[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHBryEventStore = {
  events: [],
  loading: false,
  loaded: false
};

export function workheldBryEventReducer(state = initialState, action: WHBryEventStoreActions): IWHBryEventStore {
  switch (action.type) {

    case WHBryEventStoreActionTypes.GetWHWorkObjectTeamPlannerEventDTOList:
      return {
        events: [],
        loading: true,
        loaded: false
      };

    case WHBryEventStoreActionTypes.GetWHWorkOrderTeamPlannerEventDTOList:
      return {
        events: [],
        loading: true,
        loaded: false
      };

    case WHBryEventStoreActionTypes.SetWHBryEventDOMList:
      return action.payload;

    default:
      return state;
  }
}
