// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { EquipmentAsyncService } from 'src/app/app-services-async/equipment-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHEquipmentMasterDataStoreActionTypes,
  WHEquipmentMasterDataStoreActions,
  GetWHEquipmentDtoList,
  SetWHEquipmentDtoList,
} from './w-h-equipment-master-data-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import { IWHEquipmentDTO, WHEquipmentDOM } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHEquipmentMasterDataStoreEffects {
  getWHEquipmentDtoList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WHEquipmentMasterDataStoreActionTypes.GetWHEquipmentDtoList),
      switchMap((action: GetWHEquipmentDtoList) => {
        return this.equipmentAsyncService.getEquipmentDtoList(
          action.payload.targetParent,
          action.payload.customerId
        );
      }),
      mergeMap((equipmentDtoList: IWHEquipmentDTO[]) => {
        let equipmentDomList: WHEquipmentDOM[] = [];
        // console.log(equipmentDtoList);
        if (equipmentDtoList && equipmentDtoList.length > 0) {
          equipmentDtoList.forEach((equipmentDTO: IWHEquipmentDTO) => {
            const equipmentDOM: WHEquipmentDOM =
              this.dataModelHelperService.initEquipmentDOM(equipmentDTO);
            equipmentDomList.push(equipmentDOM);
          });
          // SORT EQUIPMENT
          equipmentDomList = equipmentDomList.sort(
            (a: WHEquipmentDOM, b: WHEquipmentDOM) => {
              if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              }
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              }
              return 0;
            }
          );
        }
        return [
          new SetWHEquipmentDtoList({
            equipmentDomList,
            loading: false,
            loaded: true,
          }),
        ];
      })
    );
  });

  constructor(
    private actions$: Actions<WHEquipmentMasterDataStoreActions>,
    private equipmentAsyncService: EquipmentAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
