// ANGULAR
import { NgModule } from '@angular/core';

// APP
import { WHAgmCoreSearchUtilRoutingModule } from './w-h-agm-core-search-util-routing.module';
import { WHAgmCoreSearchUtilComponent } from './w-h-agm-core-search-util.component';

// MODULE
import { AppSharedModule } from 'src/app/app-shared/app-shared.module';

@NgModule({
  declarations: [
    WHAgmCoreSearchUtilComponent
  ],
  exports: [
    WHAgmCoreSearchUtilComponent
  ],
  imports: [
    AppSharedModule,
    WHAgmCoreSearchUtilRoutingModule
  ]
})
export class WHAgmCoreSearchUtilModule { }
