// ANGULAR
import { Injectable } from '@angular/core';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, mergeMap } from 'rxjs/operators';
import * as WHWorkObjectMaterialUsageReportActions from './w-h-work-object-material-usage-report.actions';

// API
import {
  WHWorkObjectDataService,
  IWHMaterialUsageReportDTO,
  IWHWorkObjectEndpointConfigModel,
  FLOW_GATEWAY,
} from '@workheld/workheld-shared-lib';

// RxJS 6'
import { environment } from 'src/environments/environment';

@Injectable()
export class WHWorkObjectMaterialUsageReportEffects {
  getWHWorkObjectMaterialUsageReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHWorkObjectMaterialUsageReportActions
          .WHWorkObjectMaterialUsageReportStoreActionTypes
          .GetWHWorkObjectMaterialUsageReportDTO
      ),
      switchMap(
        (
          action: WHWorkObjectMaterialUsageReportActions.GetWHWorkObjectMaterialUsageReportDTO
        ) => {
          const endpointConfig: IWHWorkObjectEndpointConfigModel = {
            apiUrl: environment.apiUrl + FLOW_GATEWAY,
          };
          const payload: any = {
            departmentIds: action.payload.departmentIds,
            startDate: action.payload.startDate,
            endDate: action.payload.endDate,
          };
          return this.workObjectAsyncService.getMaterialUsageReport(
            endpointConfig,
            payload
          );
        }
      ),
      mergeMap((materialUsageReportDTO: IWHMaterialUsageReportDTO) => {
        return [
          new WHWorkObjectMaterialUsageReportActions.SetWHWorkObjectMaterialUsageReportDOM(
            {
              materialUsageReportDTO,
            }
          ),
        ];
      })
    );
  });

  constructor(
    private actions$: Actions,
    private workObjectAsyncService: WHWorkObjectDataService
  ) {}
}
