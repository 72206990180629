// ANGULAR
import { Injectable } from '@angular/core';

// SERVICES
import { WorkOrderFileAsyncService } from 'src/app/app-services-async/work-order-file-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// REDUX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  WHWorkOrderFileMasterDataStoreActionTypes,
  WHWorkOrderFileMasterDataStoreActions,
  GetWHWorkOrderFileDtoList,
  SetWHWorkOrderFileDomList,
} from './w-h-work-order-file-master-data-store.actions';

// RxJS 6
import { switchMap, mergeMap } from 'rxjs/operators';

// MODELS
import { IWHWorkOrderFileDTO, WHFileDOM } from '@workheld/workheld-shared-lib';

@Injectable()
export class WHWorkOrderFileMasterDataStoreEffects {
  getWHWorkOrderFileDtoList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WHWorkOrderFileMasterDataStoreActionTypes.GetWHWorkOrderFileDtoList
      ),
      switchMap((action: GetWHWorkOrderFileDtoList) => {
        return this.workOrderFileAsyncService.getWorkOrderFileDtoList(
          action.payload.workOrderId
        );
      }),
      mergeMap((workOrderFileDtoList: IWHWorkOrderFileDTO[]) => {
        const workOrderFileDomList: WHFileDOM[] = [];
        workOrderFileDtoList.forEach(
          (workOrderFileDTO: IWHWorkOrderFileDTO) => {
            const workOrderFileDOM: WHFileDOM =
              this.dataModelHelperService.initWorkOrderFileDOM(
                workOrderFileDTO
              );
            workOrderFileDomList.push(workOrderFileDOM);
          }
        );
        return [
          new SetWHWorkOrderFileDomList({
            workOrderFileDomList,
            loading: false,
            loaded: true,
          }),
        ];
      })
    );
  });

  constructor(
    private actions$: Actions<WHWorkOrderFileMasterDataStoreActions>,
    private workOrderFileAsyncService: WorkOrderFileAsyncService,
    private dataModelHelperService: DataModelHelperService
  ) {}
}
