// HELPER
import { updateObject } from '@workheld/workheld-shared-lib';

// REDUX
import { WHWorkObjectEffortReportStoreActionTypes } from './w-h-work-object-effort-report.actions';
import { IWHEffortReportDTO } from '@workheld/workheld-shared-lib';

export const workheldWorkObjectEffortReportFeatureKey = 'workheldWorkObjectEffortReportStore$';

export interface IWHWorkObjectEffortReportStore {
  effortReportDTO: IWHEffortReportDTO;
  loading: boolean;
  loaded: boolean;
}

export const initialState: IWHWorkObjectEffortReportStore = {
  effortReportDTO: null,
  loading: false,
  loaded: false
};

export function workheldWorkObjectEffortReportReducer(state: IWHWorkObjectEffortReportStore | undefined = initialState, action: any) {
  switch (action.type) {

    case WHWorkObjectEffortReportStoreActionTypes.GetWHWorkObjectEffortReportDTO:
      return updateObject<IWHWorkObjectEffortReportStore>(state, {
        effortReportDTO: state.effortReportDTO,
        loading: true,
        loaded: false
      });

    case WHWorkObjectEffortReportStoreActionTypes.SetWHWorkObjectEffortReportDOM:
      return updateObject<IWHWorkObjectEffortReportStore>(state, {
        effortReportDTO: action.payload.effortReportDTO,
        loading: false,
        loaded: true
      });

    default:
      return state;
  }
}
