<div class="flex--row gap--8">
  <div
    class="flex--row gap--8 selected-department-wrapper"
    *ngIf="selectedDepartmentDOM"
    (click)="onRemove()"
  >
    {{ selectedDepartmentDOM?.name }}
    <mat-icon style="transform: scale(0.8)" *ngIf="canRemoveFilter"
      >close</mat-icon
    >
  </div>

  <button color="accent" mat-icon-button [matMenuTriggerFor]="departmentMenu">
    <mat-icon>
      <span
        [ngClass]="{
          'material-icons': selectedDepartmentDOM,
          'material-icons-outlined': !selectedDepartmentDOM
        }"
      >
        filter_alt
      </span>
    </mat-icon>
  </button>
  <mat-menu
    #departmentMenu="matMenu"
    xPosition="before"
    [backdropClass]="'backdrop'"
  >
    <div class="search-wrapper">
      <ng-bee-w-h-search-term-util
        (click)="$event.stopPropagation()"
        [focusElement]="true"
        [isCharTriggerLimited]="false"
        (onSearchTermInput)="onSearchTerm($event)"
      >
      </ng-bee-w-h-search-term-util>
    </div>
    <div class="mat-menu-items-wrapper">
        <button
        mat-menu-item
        (click)="onSelect(department)"
        *ngFor="let department of filteredDepartmentList"
      >
        <div class="flex--r-c-sb gap--4 flex-1">
          <span>{{ department.name }}</span>
          <mat-icon
            style="transform: scale(0.8)"
            *ngIf="selectedDepartmentDOM?.id === department.id"
            >done</mat-icon
          >
        </div>
      </button>
    </div>
  </mat-menu>
</div>
