<section
  class="entry-file-img-gallery flex--col gap--8"
  *ngIf="entryFileDomArray"
>
  <div class="w-h-label attachment-label">
    {{ "entry.ui.entryfiles.label" | translate }}
  </div>

  <div>
    <div fxFill fxLayout="column" fxLayoutAlign="space-between">
      <div
        class="thumbnail-list-wrapper"
        *ngIf="loaded && entryFileDomArray.length === 0"
      >
        <div class="entry-file-message">
          {{ "app.nodata.label" | translate }}
        </div>
      </div>

      <div class="thumbnail-list-wrapper" *ngIf="loading">
        <div class="entry-file-message">
          {{ "app.loading.label" | translate }}
        </div>
      </div>

      <div
        class="thumbnail-list-wrapper flex--row gap--16 flex--wrap"
        *ngIf="loaded && entryFileDomArray.length > 0"
      >
        <span
          class="thumbnail-list-item-wrapper"
          *ngFor="let entryFileDOM of entryFileDomDisplayPage"
        >
          <div
            class="thumbnail-wrapper"
            (keydown.enter)="
              filePreview({
                file: entryFileDOM.entryFile,
                comment: entryFileDOM.comment
              })
            "
            (click)="
              filePreview({
                file: entryFileDOM.entryFile,
                comment: entryFileDOM.comment
              })
            "
          >
            <img
              alt="entryFileDOM"
              class="thumbnail mat-elevation-z2"
              *ngIf="
                entryFileDOM.thumbnailURL
                  | WHImgAuthHeader : 'noCache'
                  | async as image
              "
              [src]="image"
            />
          </div>
          <div class="thumbnail-meta-wrapper flex--row gap--4">
            <span class="html-overflow">
              <div
                class="file-name"
                matTooltip="{{ entryFileDOM.entryFile.fileName }}{{
                  entryFileDOM.entryFile.extension
                }}"
              >
                {{ entryFileDOM.entryFile.fileName
                }}{{ entryFileDOM.entryFile.extension }}
              </div>
            </span>

            <span>
              <i
                class="workheld-icons download-img-icon"
                [innerHTML]="downloadIcon"
                (keydown.enter)="downloadFile(entryFileDOM.entryFile)"
                (click)="downloadFile(entryFileDOM.entryFile)"
                matTooltip="{{ 'app.download.label' | translate }}"
              >
              </i>
            </span>
          </div>
        </span>
      </div>

      <mat-paginator
        class="entry-file-paginator"
        [length]="entryFileDomArray.length"
        [pageSize]="10"
        [pageSizeOptions]="[10]"
        (page)="pageEvent($event)"
      >
      </mat-paginator>
    </div>
  </div>
</section>
